import Container from '@/components/ui/Container'
import TitleSection from '@/features/contributors/components/title-section/TitleSection'
import {Trans, useTranslation} from 'react-i18next'
import logoEuropeanaSubtitled from '@assets/images/logo-europeana-subtitled.png'
import {StyledLogo, StyledPartnersList, StyledRoot, StyledSubtitle, StyledText} from './style'
import {useTheme} from 'styled-components'
import ContributorContainer from '@/components/ui/ContributorContainer/ContributorContainer'

const TheProject: React.FC = () => {
    const {t} = useTranslation()
    const {infoContainerSize} = useTheme()
    const partnersList = [
        'the_project:partner_1',
        'the_project:partner_2',
        'the_project:partner_3',
        'the_project:partner_4',
        'the_project:partner_5',
        'the_project:partner_6',
        'the_project:partner_7',
        'the_project:partner_8',
        'the_project:partner_9',
        'the_project:partner_10'
    ]
    return (
        <StyledRoot>
            <ContributorContainer>
                <TitleSection title={t('the_project:title')} />
                <Container maxWidth={infoContainerSize}>
                    <StyledLogo src={logoEuropeanaSubtitled} alt={'logo'} />
                    <StyledText>
                        <Trans
                            t={t}
                            i18nKey="the_project:text"
                            components={{
                                a: <a href="https://pro.europeana.eu/page/generic-services-projects" target="_blank" />
                            }}
                        />
                    </StyledText>
                    <StyledSubtitle>{t('the_project:subtitle')}</StyledSubtitle>
                    <StyledPartnersList>
                        {partnersList.map((partner, index) => (
                            <p key={partner}> {`${index + 1}. ${t(partner)}`}</p>
                        ))}
                    </StyledPartnersList>
                </Container>
            </ContributorContainer>
        </StyledRoot>
    )
}
export default TheProject

TheProject.displayName = 'TheProject'
