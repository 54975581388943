import {StyledReadyForAuditRoot} from './style'
import {Trans, useTranslation} from 'react-i18next'

interface ReadyForAuditProps {
    points: number
}

const ReadyForAudit: React.FC<ReadyForAuditProps> = ({points}) => {
    const {t} = useTranslation()

    return (
        <StyledReadyForAuditRoot>
            <div>{t('video_details_page:details_section:congratulations')}</div>
            <p>
                <Trans
                    t={t}
                    i18nKey="video_details_page:details_section:congratulations_description"
                    values={{POINTS: points ?? 0}}
                    components={{
                        span: <span />
                    }}
                />
            </p>
        </StyledReadyForAuditRoot>
    )
}

export default ReadyForAudit

ReadyForAudit.displayName = 'ReadyForAudit'
