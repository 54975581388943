import {Navigate, Outlet, useSearchParams} from 'react-router-dom'
import {routes} from '@/utilities/constants'
import useAuthStore from '@/store/authStore'
import {useEffect} from 'react'
import {LocalStorageManager} from '@/utilities/localStorage'

const PublicRoute = () => {
    const accessToken = useAuthStore(store => store.accessToken)
    const [searchParams] = useSearchParams()
    const invitationToken = searchParams.get('token')
    const workspaceId = searchParams.get('workspaceId')
    const invitationId = searchParams.get('invitationId')

    const invitation =
        workspaceId && invitationId && invitationToken ? {workspaceId, invitationId, token: invitationToken} : null

    useEffect(() => {
        if (invitation) {
            LocalStorageManager.workspaceInvitation.set(invitation)
        }
    }, [workspaceId, invitationId, invitationToken])

    return accessToken ? <Navigate to={routes.HOME.path} /> : <Outlet />
}

export default PublicRoute
