import React, {useEffect} from 'react'
import {EVENT_MODEL, EventSchema, EventValidationSchema} from '@/features/events/services/EventFormModel'
import {zodResolver} from '@hookform/resolvers/zod/src/zod.js'
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import Modal from '@/components/commons/Modal'
import {useParams} from 'react-router-dom'
import {useCreateEvent} from '@/features/events/queries/useCreateEvent'
import Input from '@components/ui/Input'
import {formatDate, handleApiError} from '@/utilities/helpers'
import Spinner from '@/components/ui/Spinner'
import {StyledDatesWrapper, StyledSectionWrapper, StyledPrivateEventLabel, StyledName} from './style'
import Textarea from '@/components/ui/Textarea'
import Radio from '@/components/ui/Radio'
import {CompletedEventForm, EventForm, EventType} from '../../types'
import {EventCreationRequest} from '@/types/event'
import Switch from '@/components/ui/Switch'
import {useGetWorkspaceDetails} from '@/features/workspaces/queries/useGetWorkspaceDetails'
import {USER_SIGNUP_SOURCE} from '@/utilities/constants'
import {getEventStatus} from '@/utilities/event'
import dayjs from 'dayjs'
import {EVENT_STATUS} from '@/utilities/constants/event'
import {useEditEvent} from '@/features/events/queries/useEditEvent'

interface EventModalProps {
    onCancel: () => void
    onSubmit: () => void
    eventToEdit: EventType | null
}

const adaptEventToBeSent = (videoForm: CompletedEventForm): EventCreationRequest => ({
    isPublic: videoForm.isPublic === 'true',
    mustRequestToJoin: videoForm.mustRequestToJoin,
    startDate: videoForm.dates.startDate,
    endDate: dayjs(videoForm.dates.endDate).endOf('day').toDate(),
    name: videoForm.name,
    description: videoForm.description
})

const EventModal: React.FC<EventModalProps> = ({eventToEdit, onSubmit, onCancel}: EventModalProps) => {
    const {t} = useTranslation()
    const {workspaceId} = useParams()
    const {
        mutate: createMutate,
        isError: isCreateError,
        error: createError,
        isPending: isCreatePending
    } = useCreateEvent({
        workspaceId: `${workspaceId}`,
        onSuccess: () => {
            reset()
            onSubmit()
        }
    })

    const {
        mutate: editMutate,
        isError: isEditError,
        error: editError,
        isPending: isEditPending
    } = useEditEvent({
        workspaceId: `${workspaceId}`,
        eventId: `${eventToEdit?.id}`,
        onSuccess: () => {
            reset()
            onSubmit()
        }
    })

    const error = createError || editError
    const isError = isCreateError || isEditError
    const isPending = isCreatePending || isEditPending
    const {data: workspace} = useGetWorkspaceDetails({id: `${workspaceId}`})
    const isEuropeanaWorkspace = workspace?.user.signupSource === USER_SIGNUP_SOURCE.europeana
    const eventStatus = eventToEdit ? getEventStatus(eventToEdit) : null
    const disabledForEdit = !!eventToEdit && eventStatus === EVENT_STATUS.Open

    const enum IS_PUBLIC {
        true = 'true',
        false = 'false'
    }

    const defaultIsPublic = eventToEdit?.isPublic ? IS_PUBLIC.true : IS_PUBLIC.false

    const defaultValues = {
        [EVENT_MODEL.IsPublic]: eventToEdit ? defaultIsPublic : undefined,
        [EVENT_MODEL.MustRequestToJoin]: eventToEdit?.mustRequestToJoin,
        [EVENT_MODEL.Name]: eventToEdit?.name ?? undefined,
        [EVENT_MODEL.Description]: eventToEdit?.description
    }

    const methods = useForm<EventValidationSchema>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(EventSchema),
        defaultValues: {...defaultValues}
    })

    const {
        register,
        handleSubmit,
        setError,
        clearErrors,
        reset,
        getValues,
        watch,
        formState: {errors, isValid, isDirty}
    } = methods

    const watchStartDate = watch(`${EVENT_MODEL.Dates}.${EVENT_MODEL.StartDate}`)
    const watchEndDate = watch(`${EVENT_MODEL.Dates}.${EVENT_MODEL.EndDate}`)
    const isPastDate = dayjs(watchStartDate) <= dayjs()

    const minStartDate = disabledForEdit ? formatDate(`${dayjs(eventToEdit.startDate)}`) : formatDate(`${dayjs()}`)
    const minEndDate = !isPastDate ? dayjs(watchStartDate).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD')

    useEffect(() => {
        if (dayjs(watchEndDate) < dayjs(watchStartDate)) {
            setError(`${EVENT_MODEL.Dates}.${EVENT_MODEL.EndDate}`, {type: 'custom', message: 'validation:date_range'})
        } else {
            dayjs(watchEndDate).isValid() && clearErrors(`${EVENT_MODEL.Dates}.${EVENT_MODEL.EndDate}`)
        }
    }, [watchStartDate, watchStartDate])

    useEffect(() => {
        if (isError && error instanceof Error) {
            handleApiError({setError, error})
        }
    }, [isError])

    const onClickClose = () => {
        reset()
        onCancel()
    }

    const onSubmitForm: SubmitHandler<EventForm> = values => {
        const remappedData = adaptEventToBeSent(values as CompletedEventForm)

        const editDataToSend = {
            ...remappedData,
            [EVENT_MODEL.StartDate]: EVENT_STATUS.Scheduled === eventStatus ? remappedData?.startDate : undefined
        }

        if (eventToEdit) {
            editMutate(editDataToSend)
        } else {
            createMutate(remappedData)
        }
    }

    return (
        <FormProvider {...methods}>
            <Modal
                overlay
                title={eventToEdit ? 'event:edit_event' : 'event:add_event'}
                onClose={onClickClose}
                cancelButtonCallback={onClickClose}
                isSubmitButtonDisabled={!isValid || !isDirty || !!errors?.dates?.endDate}
                submitButtonCallback={handleSubmit(onSubmitForm)}
                submitButtonText={eventToEdit ? 'event:edit_button' : 'event:submit_button'}
            >
                {isPending && <Spinner size={48} overlay />}
                {eventToEdit && (
                    <StyledSectionWrapper>
                        <StyledName>{eventToEdit?.name}</StyledName>
                    </StyledSectionWrapper>
                )}

                <StyledSectionWrapper>
                    {isEuropeanaWorkspace ? (
                        <Radio
                            {...register(EVENT_MODEL.IsPublic)}
                            label={`${t('event:is_public')}*`}
                            helperText={t('event:is_public_helper_text')}
                            errorMessage={`${t(errors.isPublic?.message || '')}`}
                            options={[
                                {
                                    value: 'true',
                                    label: t('common:public')
                                },
                                {
                                    value: 'false',
                                    label: t('common:private')
                                }
                            ]}
                            disabled={disabledForEdit}
                        />
                    ) : (
                        <StyledPrivateEventLabel>{t('event:private_event_label')}</StyledPrivateEventLabel>
                    )}
                </StyledSectionWrapper>
                <StyledSectionWrapper>
                    <Switch
                        {...register(EVENT_MODEL.MustRequestToJoin)}
                        label={`${t('event:request_to_join')}*`}
                        helperText={t('event:request_to_join_helper_text')}
                        errorMessage={`${t(errors.mustRequestToJoin?.message || '')}`}
                        disabled={disabledForEdit}
                    />
                </StyledSectionWrapper>
                <StyledDatesWrapper>
                    <div>
                        <Input
                            {...register(`${EVENT_MODEL.Dates}.${EVENT_MODEL.StartDate}`)}
                            label={`${t('event:start_date')}*`}
                            errorMessage={`${t(errors.dates?.startDate?.message || '')}`}
                            type="date"
                            defaultValue={eventToEdit ? dayjs(eventToEdit?.startDate).format('YYYY-MM-DD') : undefined}
                            disabled={disabledForEdit && !!getValues(`${EVENT_MODEL.Dates}.${EVENT_MODEL.StartDate}`)}
                            min={minStartDate}
                        />
                    </div>
                    <div>
                        <Input
                            {...register(`${EVENT_MODEL.Dates}.${EVENT_MODEL.EndDate}`)}
                            label={`${t('event:end_date')}*`}
                            errorMessage={`${t(errors.dates?.endDate?.message || '')}`}
                            type="date"
                            defaultValue={eventToEdit ? dayjs(eventToEdit?.endDate).format('YYYY-MM-DD') : undefined}
                            min={minEndDate}
                        />
                    </div>
                </StyledDatesWrapper>
                <StyledSectionWrapper>
                    <Input
                        {...register(EVENT_MODEL.Name)}
                        label={`${t('common:name')}*`}
                        placeholder={`${t('common:name')}*`}
                        errorMessage={`${t(errors.name?.message || '')}`}
                    />
                </StyledSectionWrapper>
                <StyledSectionWrapper>
                    <Textarea
                        {...register(EVENT_MODEL.Description)}
                        label={`${t('common:description')}*`}
                        errorMessage={`${t(errors.description?.message || '')}`}
                        maxLength={500}
                    />
                </StyledSectionWrapper>
            </Modal>
        </FormProvider>
    )
}

export default EventModal
