import {useTranslation} from 'react-i18next'
import {StyledVideoInfoRoot, StyledTitle, StyledItemWrapper, StyledDivider, StyledItem, StyledLink} from './style'
import {ScaleIcon} from '@/assets/icons/icons'
import {VideoType} from '@/types/video'
import React from 'react'
import {formatDuration} from '@/utilities/helpers'

interface DetailsProps {
    video: VideoType
}

const AdditionalVideoInformation: React.FC<DetailsProps> = ({video}) => {
    const {t} = useTranslation()

    const additionalInfo = [
        {
            name: 'video_details_page:video_information_section:title',
            value: formatDuration(video?.projectInfos?.duration),
            url: null
        },
        {
            name: 'video_details_page:video_information_section:item_name:source_language',
            value: video?.projectInfos?.sourceLanguage?.name,
            url: null
        },
        {
            name: 'video_details_page:video_information_section:item_name:target_language',
            value: video?.targetLanguage?.name,
            url: null
        },
        {
            name: 'video_details_page:video_information_section:item_name:year',
            value: video?.year ?? null,
            url: null
        },
        {
            name: 'video_details_page:video_information_section:item_name:providing_institution',
            value: video?.providingInstitution ?? null,
            url: video?.providingInstitutionUrl ?? null
        },
        {
            name: 'video_details_page:video_information_section:item_name:publisher',
            value: video?.publisher ?? null,
            url: null
        },
        {
            name: 'video_details_page:video_information_section:item_name:providing_country',
            value: video?.providingCountry ?? null,
            url: null
        },
        {
            name: 'video_details_page:video_information_section:item_name:copyright',
            value: video?.providingInstitution
                ? t('video_details_page:video_information_section:copyright_text', {
                      PROVIDING_INSTITUTION: video?.providingInstitution
                  })
                : null,
            url: null
        }
    ]

    return (
        <StyledVideoInfoRoot>
            <StyledTitle>{t('video_details_page:video_information_section:title')}</StyledTitle>
            <StyledItemWrapper>
                {additionalInfo.map(
                    item =>
                        item.value && (
                            <React.Fragment key={item.name}>
                                <StyledItem>
                                    <div>{t(item.name)}</div>
                                    {item.url ? (
                                        <StyledLink href={item.url} target="_blank">
                                            <span>{item.value}</span> <ScaleIcon />
                                        </StyledLink>
                                    ) : (
                                        <span>{item.value}</span>
                                    )}
                                </StyledItem>
                                <StyledDivider />
                            </React.Fragment>
                        )
                )}
            </StyledItemWrapper>
        </StyledVideoInfoRoot>
    )
}

export default AdditionalVideoInformation

AdditionalVideoInformation.displayName = 'AdditionalVideoInformation'
