import {Dispatch, HTMLAttributes, SetStateAction, useState} from 'react'
import {Accept, FileWithPath, useDropzone} from 'react-dropzone'
import {StyledInfoSection, StyledTitle, StyledUploader} from './style'
import {useTranslation} from 'react-i18next'
import DragStatus from './drag-status/DragStatus'
import Preview from './preview/Preview'
import Info from './info/Info'

export type UploaderProps = Omit<HTMLAttributes<HTMLElement>, 'onChange'> & {
    error?: string
    onChange: Dispatch<SetStateAction<FileWithPath[]>>
    onBlur?: () => void
    accept?: Accept
    maxSize?: number
    multiple?: boolean
    defaultImageUrls?: string[]
}

export const FileUploader = ({
    onChange,
    onBlur,
    accept,
    maxSize,
    multiple = false,
    defaultImageUrls,
    ...props
}: UploaderProps) => {
    const {t} = useTranslation()
    const [uploadedFiles, setUploadedFiles] = useState<FileWithPath[]>([])
    const [error, setError] = useState<string>('')

    const handleRemove = (event?: React.MouseEvent<HTMLDivElement>) => {
        event && event.stopPropagation()
        onChange([])
        setUploadedFiles([])
    }

    const {getRootProps, getInputProps, isDragReject, isDragActive} = useDropzone({
        onDrop: (acceptedFiles: FileWithPath[], fileRejections) => {
            if (fileRejections?.length > 0) {
                setError(fileRejections[0]?.errors[0]?.code)
                handleRemove()
            }

            if (acceptedFiles?.length) {
                setError('')
                setUploadedFiles(prev => (multiple ? [...prev, ...acceptedFiles] : acceptedFiles))
                onChange(prev => (multiple ? [...prev, ...acceptedFiles] : acceptedFiles))
            }
        },
        accept,
        maxSize,
        multiple
    })

    return (
        <div>
            <section
                {...getRootProps({
                    ...props
                })}
                onBlur={onBlur}
            >
                <input {...getInputProps()} data-testid="upload-file-input" />

                <StyledUploader>
                    <Preview uploadedFiles={uploadedFiles} defaultImageUrls={defaultImageUrls} />

                    <StyledInfoSection>
                        <StyledTitle>{t('file_uploader:uploader_label')}</StyledTitle>
                        <DragStatus isDragActive={isDragActive} isDragReject={isDragReject} error={error} />
                        <Info fileName={uploadedFiles[0]?.name} handleRemove={handleRemove} />
                    </StyledInfoSection>
                </StyledUploader>
            </section>
        </div>
    )
}
