import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {StyledDivider, StyledDropdownRoot, StyledMenuItem, StyledMenuWrapper} from './style'
import {DeleteIcon, DotsVerticalIcon, EditIcon} from '@/assets/icons/icons'
import DropDown from '@/components/ui/DropDown'
import {useTheme} from 'styled-components'
import {EventType} from '@/features/workspaces/types'
import {EVENT_STATUS} from '@/utilities/constants/event'
interface DropdownMenuProps {
    item: EventType
    handleSetEventToEdit: (event: EventType) => void
    onDeleteClick: () => void
    eventStatus: string
}

const EventDropdownMenu: React.FC<DropdownMenuProps> = ({item, handleSetEventToEdit, onDeleteClick, eventStatus}) => {
    const {t} = useTranslation()
    const theme = useTheme()
    const [openMenuId, setOpenMenuId] = useState<number | undefined>(undefined)

    return (
        <StyledDropdownRoot>
            <DotsVerticalIcon fill={theme.palette.neutral['900']} onClick={() => setOpenMenuId(item.id)} height={20} />
            <DropDown isOpen={openMenuId === item.id} onClose={() => setOpenMenuId(undefined)}>
                <StyledMenuWrapper>
                    {eventStatus !== EVENT_STATUS.Closed && (
                        <>
                            <StyledMenuItem
                                onClick={() => {
                                    handleSetEventToEdit(item)
                                    setOpenMenuId(undefined)
                                }}
                            >
                                <div>
                                    <EditIcon height={18} width={18} fill={theme.palette.gray.dark} />
                                </div>
                                <span>{t('common:edit')}</span>
                            </StyledMenuItem>
                            <StyledDivider />
                        </>
                    )}
                    <>
                        <StyledMenuItem
                            onClick={() => {
                                onDeleteClick()
                                setOpenMenuId(undefined)
                            }}
                        >
                            <div>
                                <DeleteIcon height={18} width={18} fill={theme.palette.gray.dark} />
                            </div>
                            <span>{t('common:delete')}</span>
                        </StyledMenuItem>
                    </>
                </StyledMenuWrapper>
            </DropDown>
        </StyledDropdownRoot>
    )
}

export default EventDropdownMenu

EventDropdownMenu.displayName = 'EventDropdownMenu'
