import {z} from 'zod'

export const enum EVENT_MODEL {
    IsPublic = 'isPublic',
    MustRequestToJoin = 'mustRequestToJoin',
    Dates = 'dates',
    StartDate = 'startDate',
    EndDate = 'endDate',
    Name = 'name',
    Description = 'description'
}

const dateTransformer = (dateString: string, ctx: z.RefinementCtx) => {
    const date = new Date(dateString)
    if (!z.date().safeParse(date).success) {
        ctx.addIssue({
            code: z.ZodIssueCode.invalid_date
        })
    }
    return date
}

export const EventSchema = z
    .object({
        [EVENT_MODEL.IsPublic]: z.enum(['true', 'false']),
        [EVENT_MODEL.MustRequestToJoin]: z.boolean(),
        [EVENT_MODEL.Dates]: z.object({
            [EVENT_MODEL.StartDate]: z.string().transform(dateTransformer),
            [EVENT_MODEL.EndDate]: z.string().transform(dateTransformer)
        }),
        [EVENT_MODEL.Name]: z.string().min(1),
        [EVENT_MODEL.Description]: z.string().min(1)
    })
    .partial()

export type EventValidationSchema = z.infer<typeof EventSchema>
