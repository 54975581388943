import useAuthStore from '@/store/authStore'
import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useSearchParams} from 'react-router-dom'

const EuropeanaCallback: React.FC = () => {
    const {t} = useTranslation()
    const callbackEuropeana = useAuthStore(store => store.callbackEuropeana)
    const isError = useAuthStore(store => store.isError)
    const [searchParams] = useSearchParams()
    const code = searchParams.get('code')

    useEffect(() => {
        if (code) callbackEuropeana(`${code}`)
    }, [code])

    if (isError) return <>{t('sso_callback:error')}</>

    if (!code) return <>{t('sso_callback:no_code_provided')}</>

    return <>{t('sso_callback:redirecting')}</>
}

export default EuropeanaCallback

EuropeanaCallback.displayName = 'EuropeanaCallback'
