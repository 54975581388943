import {QUERY_KEYS} from '@/queryClient'
import {useInfiniteQuery} from '@tanstack/react-query'
import {httpGetEventLeadersList} from '../services/events.http'

export const useGetEventLeadersList = ({eventId}: {pageParam?: string; eventId: string}) =>
    useInfiniteQuery({
        queryKey: [QUERY_KEYS.EVENT_LEADERS, eventId],
        queryFn: ({pageParam}) => httpGetEventLeadersList({eventId, pageParam}),
        initialPageParam: '',
        getNextPageParam: lastPage => lastPage.meta.next_page_url
    })
