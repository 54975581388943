import styled, {css} from 'styled-components'

export const StyledFormRoot = styled.form`
    ${({theme: {palette, typography}}) => css`
        ${typography.textM};
        color: ${palette.gray.middle};
        display: grid;
        gap: 30px;
        & span {
            font-weight: 500;
        }
    `}
`
