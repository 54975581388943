import Container from '@/components/ui/Container'
import Spinner from '@/components/ui/Spinner'
import {ValidationError, handleApiError} from '@/utilities/helpers'
import React, {useEffect, useState} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {StyledButtonWrapper, StyledRoot, StyledSubTitle, StyledTitle} from './style'
import {Trans, useTranslation} from 'react-i18next'
import Button from '@/components/ui/Button'
import {ROUTE_LOGIN} from '@/utilities/constants'
import {useEmailActivation} from './queries/useEmailActivation'

enum STATUS {
    Success = 'success',
    Failure = 'failure'
}

const EmailActivation: React.FC = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const [status, setStatus] = useState<STATUS | null>(null)
    const activationToken = searchParams.get('activationToken')
    const encodedActivationToken = activationToken && encodeURIComponent(activationToken)
    const decodedActivationToken = encodedActivationToken && decodeURIComponent(encodedActivationToken)
    const isSuccess = status === STATUS.Success
    const {mutate, isPending, isError, error} = useEmailActivation()

    useEffect(() => {
        if (isError) handleApiError({error: error as ValidationError<string>})
    }, [isError])

    const onSuccess = () => {
        setStatus(STATUS.Success)
    }

    const activateEmail = () => {
        mutate(decodedActivationToken, {onSuccess})
    }

    useEffect(() => {
        if (activationToken) {
            activateEmail()
        }
    }, [activationToken])

    return (
        <Container>
            {isPending && <Spinner size={48} />}
            <StyledRoot>
                <StyledTitle>{t('email_activation:title')}</StyledTitle>
                {!!status && (
                    <>
                        <StyledSubTitle>
                            <Trans t={t} i18nKey={'email_activation:subtitle'} values={{context: status}} />
                        </StyledSubTitle>
                        {isSuccess && (
                            <StyledButtonWrapper>
                                <Button onClick={() => navigate(ROUTE_LOGIN)}>{t('common:log_in')}</Button>
                            </StyledButtonWrapper>
                        )}
                    </>
                )}
            </StyledRoot>
        </Container>
    )
}

export default EmailActivation

EmailActivation.displayName = 'EmailActivation'
