import styled, {css, DefaultTheme} from 'styled-components'

export const StyledFooter = styled.div(
    ({theme: {spacing}}: {theme: DefaultTheme}) => css`
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: ${spacing * 2}px;
        margin-top: ${spacing * 4}px;
    `
)
