import {useTranslation} from 'react-i18next'
import {StyledStatusLabel} from './style'
import {EventStatus} from '@/types/event'

interface EventTagProps {
    status: EventStatus
}

const EventTag = ({status}: EventTagProps) => {
    const {t} = useTranslation()
    return <StyledStatusLabel status={status}>{t(`event_status:${status}`)}</StyledStatusLabel>
}

export default EventTag

EventTag.displayName = 'EventTag'
