import styled, {DefaultTheme, css} from 'styled-components'

export const StyledMainWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 1.5}px;
    `}
`

export const StyledInputWrapper = styled.div`
    ${({theme: {spacing, palette}}) => css`
        display: flex;
        gap: ${spacing * 1.5}px;
        border: 1px solid ${palette.neutral['300']};
        border-radius: 15px;
        padding: ${spacing * 2}px ${spacing * 4}px;
        justify-content: space-between;
        & > input {
            width: 85%;
        }
    `}
`

export const StyledListWrapper = styled.div`
    ${({theme: {spacing, palette}, isTopBorderVisible}: {theme: DefaultTheme; isTopBorderVisible: boolean}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 1.5}px;
        border: 1px solid ${palette.neutral['300']};
        border-radius: ${isTopBorderVisible ? `15px` : '0 0 15px 15px'};
        border-top: ${isTopBorderVisible ? `1px solid ${palette.neutral['300']}` : 'none'};
        padding: ${spacing * 2}px;
        overflow-y: auto;
        min-width: 280px;
        min-height: 280px;
    `}
`

export const StyledHelperText = styled.span`
    ${({theme: {typography, palette}}) => css`
        ${typography.textS}
        display: block;
        color: ${palette.neutral['600']};
    `}
`

export const StyledErrorMessage = styled.span`
    ${({theme: {typography, palette}}) => css`
        ${typography.textS}
        display: block;
        color: ${palette.red.error};
    `}
`

export const StyledListItem = styled.span`
    ${({theme: {spacing, typography}, disabled}: {theme: DefaultTheme; disabled: boolean}) => css`
        ${typography.textM}
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        padding: ${spacing * 0.5}px ${spacing * 2}px;
        cursor: ${disabled ? 'not-allowed' : 'pointer'};
        position: relative;
    `}
`

export const StyledCheckboxEmpty = styled.div`
    ${({theme: {spacing, palette}, disabled}: {theme: DefaultTheme; disabled: boolean}) => css`
        width: 16px;
        height: 16px;
        border-radius: 4px;
        border: solid 1px ${disabled ? palette.neutral['300'] : palette.neutral['500']};
        margin-right: ${spacing * 2}px;
    `}
`

export const StyledCheckboxSelected = styled.div`
    ${({theme: {spacing, palette}, disabled}: {theme: DefaultTheme; disabled: boolean}) => css`
        width: 16px;
        height: 16px;
        border-radius: 4px;
        border: solid 1px ${disabled ? palette.neutral['300'] : palette.neutral['600']};
        background-color: ${disabled ? palette.neutral['300'] : palette.neutral['600']};
        margin-right: ${spacing * 2}px;
        display: flex;
        align-items: center;
        justify-content: center;
    `}
`

export const StyledItemLabel = styled.div`
    ${({
        theme: {palette, typography, truncateText},
        disabled,
        isSuggested
    }: {
        theme: DefaultTheme
        disabled: boolean
        isSuggested: boolean
    }) => css`
        ${typography.textS}
        color ${disabled ? palette.neutral['300'] : palette.neutral['600']};
        ${truncateText}
        font-weight: ${isSuggested ? '700' : 'normal'};
    `}
`

type StyledStyledInputProps = {
    disabled?: boolean
}

export const InputRadioWrapper = styled.div`
    height: 16px;
`
export const StyledInput = styled.input<StyledStyledInputProps>`
    ${({theme: {palette}}) => css`
        &[type='radio'] {
            position: absolute;
            opacity: 0;
            width: 16px;
            height: 16px;
            &:checked + ${StyledRadioSelected}:before {
                border-color: ${palette.gray.main};
                background-color: ${palette.gray.main};
                box-shadow: inset 0 0 0 2px white;
            }
        }
    `}
`

export const StyledRadioSelected = styled.label<StyledStyledInputProps>`
    ${({disabled, theme: {palette, spacing}}) => css`
        cursor: pointer;
        margin-right: ${spacing * 3}px;
        &:before {
            content: '';
            border-radius: 100%;
            border: 2px solid ${palette.gray.light};
            display: inline-block;
            width: 16px;
            height: 16px;
            position: relative;
            vertical-align: top;
            cursor: pointer;
            text-align: center;
        }

        ${disabled &&
        css`
            color: ${palette.gray.disabled};
            cursor: not-allowed;
            &:before {
                cursor: not-allowed;
                border-color: ${palette.gray.disabled};
            }
        `};

        &:empty {
            &:before {
                margin-right: 0;
            }
        }
    `}
`
