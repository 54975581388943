import styled, {DefaultTheme, css} from 'styled-components'

export const StyledMemberItem = styled.div`
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    padding: 12px 0;
    & svg {
        cursor: pointer;
    }
`

export const StyledContent = styled.div`
    ${({theme: {spacing}}) => css`
        padding: ${spacing * 2}px;
        display: flex;
        gap: ${spacing * 4}px;
        align-items: center;
    `}
`

export const StyledName = styled.div`
    ${({theme: {spacing, typography}}) => css`
        ${typography.textS}
        max-width: 115px;
        font-weight: 700;
        margin-bottom: ${spacing}px;
    `}
`
export const StyledEmail = styled.div`
    ${({theme: {typography}}) => css`
        ${typography.textXS}
        max-width: 115px;
    `}
`
export const StyledStatus = styled.div`
    ${({
        theme: {typography, palette, spacing},
        isPendingStatus
    }: {
        theme: DefaultTheme
        isPendingStatus: boolean
    }) => css`
        ${typography.textXS}
        padding: ${spacing}px ${spacing * 3}px;
        color: ${isPendingStatus ? palette.orange.main : palette.gray.main};
        border-radius: 16px;
        border: 1px solid ${isPendingStatus ? palette.orange.middle : palette.gray.disabled};
        background: ${isPendingStatus ? palette.orange.light : palette.gray.disabled};
        width: fit-content;
        margin-top: ${spacing * 2}px;
    `}
`
export const StyledButtonsWrapper = styled.div`
    ${({theme: {spacing, typography, mediaQueries}}) => css`
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        gap: ${spacing * 3}px;

        & button {
            ${typography.textS};
            & svg {
                height: 12px;
                width: 12px;
            }
        }

        ${mediaQueries.m} {
            flex-direction: row;
        }
    `}
`
