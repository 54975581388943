import styled, {css} from 'styled-components'

export const StyledAssignButtonRoot = styled.div`
    ${({theme: {spacing, typography, mediaQueries}}) => css`
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: ${spacing * 3}px;

        & button {
            padding: ${spacing * 2}px ${spacing * 6}px;
            ${spacing * 6}px;
        }

        ${mediaQueries.m} {
            & button {
                ${typography.textL};
            }
        }
    `}
`

export const StyledPoints = styled.div`
    ${({theme: {palette, mediaQueries}}) => css`
        & span {
            color: ${palette.gray.main};
            font-weight: 700;
        }

        ${mediaQueries.m} {
            & span {
                font-weight: 500;
            }
        }
    `}
`
