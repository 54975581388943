import Container from '@/components/ui/Container'
import TitleSection from '@/features/contributors/components/title-section/TitleSection'
import {Trans, useTranslation} from 'react-i18next'
import {useTheme} from 'styled-components'
import {StyledList, StyledLoadMoreWrapper, StyledRoot, StyledWrapper} from './style'
import ContributorContainer from '@/components/ui/ContributorContainer/ContributorContainer'
import {useParams} from 'react-router-dom'
import PreviewEventCard from '@/features/events/components/preview-event-card/PreviewEventCard'
import {useGetEventsList} from '@/features/events/queries/useGetEventsList'
import {useEffect} from 'react'
import {handleApiError} from '@/utilities/helpers'
import Spinner from '@/components/ui/Spinner'
import LoadMore from '@/components/ui/LoadMore'
import useGetScreenSize from '@/hooks/useGetScreenSize'
import {SCREEN_SIZE} from '@/types/commons'

const EventsListPage: React.FC = () => {
    const {t} = useTranslation()
    const {status} = useParams()
    const {contributorContainerSize} = useTheme()
    const screenSize = useGetScreenSize()

    const NUMBERS_OF_ITEMS_TO_SHOW = {
        [SCREEN_SIZE.MOBILE]: 10,
        [SCREEN_SIZE.TABLET]: 12,
        [SCREEN_SIZE.DESKTOP]: 12
    }

    const {data, error, isLoading, isError, hasNextPage, isFetchingNextPage, isFetching, fetchNextPage} =
        useGetEventsList({limit: NUMBERS_OF_ITEMS_TO_SHOW[screenSize], status: status})

    useEffect(() => {
        if (isError && error instanceof Error) {
            handleApiError({error})
        }
    }, [isError])

    return isLoading ? (
        <Spinner size={48} overlay={true} />
    ) : (
        <StyledRoot>
            <ContributorContainer>
                <TitleSection
                    title={t('event_page:title')}
                    subtitle={t('event_page:subtitle')}
                    description={
                        <Trans
                            t={t}
                            i18nKey="event_page:description"
                            components={{
                                span: <span />
                            }}
                        />
                    }
                />
                <StyledWrapper>
                    <Container maxWidth={contributorContainerSize}>
                        <span>{t('event_page:events', {context: status})}</span>
                        {data?.pages?.at(0)?.data.length && (
                            <div>
                                {data?.pages.map((page, index) => (
                                    <StyledList key={index}>
                                        {page?.data?.map(event => (
                                            <PreviewEventCard event={event} key={event.id} />
                                        ))}
                                    </StyledList>
                                ))}
                            </div>
                        )}

                        <StyledLoadMoreWrapper>
                            <LoadMore
                                hasNextPage={hasNextPage}
                                isFetchingNextPage={isFetchingNextPage}
                                isFetching={isFetching}
                                fetchNextPage={fetchNextPage}
                            />
                        </StyledLoadMoreWrapper>
                    </Container>
                </StyledWrapper>
            </ContributorContainer>
        </StyledRoot>
    )
}
export default EventsListPage

EventsListPage.displayName = 'EventsListPage'
