import styled, {css} from 'styled-components'

export const StyledTitleSection = styled.div`
    ${({theme: {palette, typography, mediaQueries, spacing}}) => css`
        color: ${palette.neutral.white};
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        & > h2 {
            ${typography.textXXL}
            font-weight: 700;
            margin-bottom: ${spacing * 4}px;
        }
        & > h3 {
            ${typography.textL}
            font-weight: 700;
        }
        padding: 100px ${spacing * 4}px;

        ${mediaQueries.m} {
            padding: 0 ${spacing * 4}px;
            height: 400px;
            & > h2 {
                ${typography.text2XL}
            }
            & > h3 {
                ${typography.textXXL}
            }
        }
        ${mediaQueries.desktopXs} {
            & > h2 {
                ${typography.text3XL}
            }
        }
        ${mediaQueries.l} {
            padding: 0 ${spacing * 4}px;
            height: 400px;
            & > h2 {
                ${typography.titleM}
            }
        }
    `}
`

export const StyledDescription = styled.p`
    ${({theme: {typography, palette}}) => css`
        ${typography.textXL}
        line-height: 27px;
        & span {
            color: ${palette.blue.link};
        }
    `}
`
