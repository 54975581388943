import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {httpMarkAsDone} from '@/features/videos/services/video.http'
import {useMutation, useQueryClient} from '@tanstack/react-query'

export interface MarkAsDoneRequest {
    projectId: string
    jobId: string
    userId: string
    workspaceId?: string
    onSuccess: () => void
}

export const useMarkAsDone = ({projectId, jobId, userId, workspaceId, onSuccess}: MarkAsDoneRequest) => {
    const client = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.MARK_AS_DONE],
        mutationFn: () => httpMarkAsDone(projectId, jobId, userId),
        onSuccess: () => {
            onSuccess()
            client.invalidateQueries({queryKey: [QUERY_KEYS.WORKSPACE_VIDEO_DETAILS, workspaceId, projectId]})
            client.invalidateQueries({queryKey: [QUERY_KEYS.JOB_STATUS_HISTORY, projectId]})
        }
    })
}
