import {PlusIcon, ThreeDotsIcon} from '@/assets/icons/icons'
import Container from '@/components/ui/Container'
import {useTranslation} from 'react-i18next'
import {StyledAddWorkspaceCard, StyledHeader, StyledRoot, StyledTitle, StyledWorkspaceContainer} from './style'
import Button from '@/components/ui/Button'
import CreateWorkspaceModal from '@/features/workspaces/components/create-workspace-modal/CreateWorkspaceModal.tsx'
import {useEffect, useState} from 'react'
import WorkspaceCard from '@/features/workspaces/components/workspace-card/WorkspaceCard'
import useAuthStore from '@/store/authStore'
import Spinner from '@/components/ui/Spinner'
import {handleApiError} from '@/utilities/helpers'
import {useGetWorkspacesList} from '@/features/workspaces/queries/useGetWorkspacesList'
import {useTheme} from 'styled-components'

const Workspaces = () => {
    const {t} = useTranslation()
    const {providerContainerSize} = useTheme()
    const user = useAuthStore(store => store.user)
    const {data, isLoading, isError, error} = useGetWorkspacesList({enabled: true})
    const workspaces = data?.data ?? []
    const [isCreateWorkspaceModalOpen, setIsCreateWorkspaceModalOpen] = useState(false)

    const MAX_NUMBER_WORKSPACES = 2
    const isCreateWorkspaceDisabled = !!workspaces?.length && workspaces?.length >= MAX_NUMBER_WORKSPACES

    useEffect(() => {
        if (isError && error instanceof Error) {
            handleApiError({error})
        }
    }, [isError])

    const handleCreateWorkspaceModalOpen = () => {
        if (!isCreateWorkspaceDisabled) {
            setIsCreateWorkspaceModalOpen(true)
        }
    }
    return (
        <Container maxWidth={providerContainerSize} isPositionRight={false}>
            {isLoading && <Spinner size={48} />}
            <StyledRoot>
                <StyledHeader>
                    <StyledTitle>
                        <ThreeDotsIcon />
                        <h4>{t('common:workspaces')}</h4>
                    </StyledTitle>
                </StyledHeader>
                <StyledWorkspaceContainer>
                    {workspaces?.map(workspace => {
                        return <WorkspaceCard workspace={workspace} key={workspace?.id} userId={user?.id} />
                    })}
                    {!isCreateWorkspaceDisabled && (
                        <StyledAddWorkspaceCard>
                            <span>{t('common:add_workspace_label')}</span>
                            <Button
                                style={{backgroundColor: '#3d63ae'}}
                                variant={'primary'}
                                size={'md'}
                                onClick={handleCreateWorkspaceModalOpen}
                                disabled={isCreateWorkspaceDisabled}
                            >
                                <PlusIcon width={15} height={15} />
                                {t('common:add_workspace_button')}
                            </Button>
                        </StyledAddWorkspaceCard>
                    )}
                </StyledWorkspaceContainer>
            </StyledRoot>

            {isCreateWorkspaceModalOpen && !isCreateWorkspaceDisabled && (
                <CreateWorkspaceModal onClose={() => setIsCreateWorkspaceModalOpen(false)} />
            )}
        </Container>
    )
}

export default Workspaces

Workspaces.displayName = 'Workspaces'
