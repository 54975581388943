import React from 'react'
import {
    StyledCheckbox,
    StyledDeleteButton,
    StyledEditButton,
    StyledInfo,
    StyledRankCard,
    StyledRankName,
    StyledRankPoints
} from './style'
import {DeleteIcon, EditIcon} from '@/assets/icons/icons'
import Checkbox from '@/components/ui/Checkbox'
import badge from '@assets/images/badge.png'
import {Trans, useTranslation} from 'react-i18next'
import {RankType} from '../../types'

interface RankCardProps {
    rank: RankType
    setSelectedRanks: React.Dispatch<React.SetStateAction<RankType[]>>
    selectedRanks: RankType[]
    onEditRankClick: (rank: RankType | null) => void
    handleDeleteRanks: ({singleRankId}: {singleRankId?: number[]}) => void
}

const RankCard: React.FC<RankCardProps> = ({
    rank,
    setSelectedRanks,
    selectedRanks,
    onEditRankClick,
    handleDeleteRanks
}) => {
    const {t} = useTranslation()

    const handleCheckRank = () => {
        if (!selectedRanks.find(item => item.id === rank?.id)) {
            setSelectedRanks([...selectedRanks, rank])
        } else {
            setSelectedRanks(selectedRanks.filter(item => item.id !== rank?.id))
        }
    }

    return (
        <StyledRankCard>
            <StyledCheckbox>
                <Checkbox
                    onChange={() => handleCheckRank()}
                    checked={!!selectedRanks.find(item => item.id === rank?.id)}
                />
            </StyledCheckbox>
            <img src={rank?.url ?? badge} />
            <StyledInfo>
                <StyledRankName>{rank?.name}</StyledRankName>
                <StyledRankPoints>
                    <Trans
                        t={t}
                        i18nKey="my_profile:manage_leaderboard:points"
                        values={{
                            FROM: rank?.minScore,
                            TO: rank?.maxScore
                        }}
                    />
                </StyledRankPoints>
                <StyledEditButton onClick={() => onEditRankClick(rank)}>
                    <EditIcon width={15} height={15} />
                    <span>{t('common:edit')}</span>
                </StyledEditButton>
            </StyledInfo>
            <StyledDeleteButton onClick={() => handleDeleteRanks({singleRankId: [rank?.id]})}>
                <DeleteIcon />
            </StyledDeleteButton>
        </StyledRankCard>
    )
}

export default RankCard

RankCard.displayName = 'RankCard'
