import {NavLink} from 'react-router-dom'
import styled, {DefaultTheme, css} from 'styled-components'

export const StyledTopNavRoot = styled.div`
    ${({theme: {spacing, mediaQueries, palette}}: {theme: DefaultTheme}) => css`
        display: none;
        background: ${palette.neutral.white};
        border-bottom: 1px solid ${palette.gray.xlight};
        width: 100%;
        padding: ${spacing * 4}px;
        ${mediaQueries.m} {
            display: block;
            padding: ${spacing * 6}px;
        }
    `}
`

export const StyledItemWrapper = styled.div`
    ${({theme: {spacing, flexUtils, mediaQueries}}) => css`
        display: none;

        ${mediaQueries.m} {
            ${flexUtils.centered};
            gap: ${spacing * 6}px;
        }

        ${mediaQueries.l} {
            ${flexUtils.start};
            gap: ${spacing * 7}px;
        }
    `}
`

export const StyledItem = styled(NavLink)`
    ${({theme: {palette, typography}}) => css`
        ${typography.textL};
        cursor: pointer;
        color: ${palette.gray.darkNav};
        &.active {
            color: ${palette.blue.link};
        }
        & :hover {
            color: ${palette.blue.link};
        }
    `}
`
