import styled, {css} from 'styled-components'

export const StyledWrapper = styled.div`
    ${({theme: {mediaQueries, spacing}}) => css`
        width: 100%;
        margin-top: ${spacing * 12}px;
        ${mediaQueries.m} {
        }
    `}
`
