import styled, {css} from 'styled-components'

export const StyledViewAllRoot = styled.div`
    ${({theme: {flexUtils, palette, typography}}) => css`
        ${flexUtils.columnSpaceBetween};
        width: 100%;
        & div {
            ${typography.textXXL}
            font-weight: 700;
            color: ${palette.gray.main};
        }

        & span {
            ${typography.textL}
            color: ${palette.blue.link};
            cursor: pointer;
        }
    `}
`
