import {QUERY_KEYS} from '@/queryClient'
import {httpGetVideoDetails} from '@/features/videos/services/video.http'
import {useQuery} from '@tanstack/react-query'

export const useGetVideoDetails = ({
    workspaceId,
    videoId,
    enabled = true
}: {
    workspaceId: string
    videoId: string
    enabled?: boolean
}) =>
    useQuery({
        queryKey: [QUERY_KEYS.WORKSPACE_VIDEO_DETAILS, workspaceId, videoId],
        queryFn: () => httpGetVideoDetails(workspaceId, videoId),
        enabled,
        refetchInterval: 5000,
        refetchOnWindowFocus: 'always'
    })
