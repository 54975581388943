import {EventInfos, VideoType} from '@/types/video'
import {
    StyledButtonSection,
    StyledDescription,
    StyledDetailsRoot,
    StyledDivider,
    StyledImage,
    StyledLanguageWrapper,
    StyledName,
    StyledPreviewLink,
    StyledThumbnailWrapper,
    StyledVideoCardWrapper,
    StyledVideoInfo,
    StyledLoginMessage,
    StyledLink,
    StyledProjectDescription,
    StyledEventLink
} from './style'
import {Trans, useTranslation} from 'react-i18next'
import {ScaleIcon} from '@/assets/icons/icons'
import useAuthStore from '@/store/authStore'
import {ROUTE_EVENT_DETAILS, USER_ROLE_CONTRIBUTOR} from '@/utilities/constants'
import AssignButton from '../assign-button/AssignButton'
import {VIDEO_STATUS} from '@/utilities/constants/video'
import UpdateVideoAssignmentButton from '../update-video-assignment/UpdateVideoAssignmentButton'
import ReadyForAudit from '../ready-for-audit/ReadyForAudit'
import Completed from '../completed/Completed'
import {generatePath, useNavigate} from 'react-router-dom'

const getEventPath = (eventInfos: EventInfos) => {
    if (eventInfos.privateHash) {
        return `${generatePath(ROUTE_EVENT_DETAILS, {eventId: `${eventInfos.id}`})}?t=${eventInfos.privateHash}`
    }
    return generatePath(ROUTE_EVENT_DETAILS, {eventId: `${eventInfos.id}`})
}

interface DetailsProps {
    video: VideoType
}

const Details: React.FC<DetailsProps> = ({video}) => {
    const {t} = useTranslation()
    const {user, accessToken} = useAuthStore(store => store)
    const showAssignButton = user?.type === USER_ROLE_CONTRIBUTOR
    const isMine = !video.userId || video.userId === user?.id
    const navigate = useNavigate()

    return (
        <StyledDetailsRoot>
            {/* Video info section */}
            <StyledVideoCardWrapper>
                <StyledThumbnailWrapper>
                    <StyledImage src={video.projectInfos.thumbnailUrl} alt={video.projectInfos.name} />
                </StyledThumbnailWrapper>
                <StyledVideoInfo>
                    <StyledName>{video?.projectInfos?.name}</StyledName>

                    <StyledLanguageWrapper>
                        <div>
                            {video.projectInfos.sourceLanguage?.name} &gt; {video?.targetLanguage?.name}
                        </div>
                        <StyledPreviewLink
                            href={video?.projectInfos?.europeanaUrl || video?.projectInfos?.originalUrl}
                            target="_blank"
                        >
                            <span>{t('video_details_page:details_section:preview_video')}</span>
                            <ScaleIcon />
                        </StyledPreviewLink>
                        <StyledProjectDescription>{video?.projectInfos?.description}</StyledProjectDescription>
                    </StyledLanguageWrapper>

                    <StyledDescription>{video?.videoDescription}</StyledDescription>
                </StyledVideoInfo>
            </StyledVideoCardWrapper>

            {/* Assign button section */}
            <StyledButtonSection>
                {showAssignButton ? (
                    <>
                        <StyledDivider />
                        {isMine ? (
                            <>
                                {!video?.eventInfos ||
                                (new Date() >= new Date(video?.eventInfos?.startDate) &&
                                    new Date() <= new Date(video?.eventInfos?.endDate)) ? (
                                    <>
                                        {(video?.status === VIDEO_STATUS.Ready || !accessToken) && (
                                            <AssignButton
                                                points={video?.projectInfos?.points}
                                                projectId={video?.projectId}
                                                videoId={video?.id}
                                                alreadyHasAssignment={user.typeAttributes?.alreadyHasAssignment}
                                            />
                                        )}

                                        {video?.status === VIDEO_STATUS.Assigned && accessToken && (
                                            <UpdateVideoAssignmentButton
                                                projectId={video?.projectId}
                                                videoId={video?.id}
                                                assignedAt={video?.assignedAt || ''}
                                                subPlatformUrl={video.subPlatformUrl}
                                                isCompleteDisabled={!video.contributorSubtitlesUrl}
                                            />
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {new Date() < new Date(video?.eventInfos?.startDate) && (
                                            <span>
                                                <Trans
                                                    t={t}
                                                    i18nKey={'video_details_page:details_section:event_not_started'}
                                                    components={{
                                                        b: (
                                                            <StyledEventLink
                                                                onClick={() =>
                                                                    navigate(
                                                                        generatePath(getEventPath(video?.eventInfos))
                                                                    )
                                                                }
                                                            />
                                                        )
                                                    }}
                                                    values={{
                                                        EVENT: video.eventInfos.name
                                                    }}
                                                />
                                            </span>
                                        )}
                                        {new Date() > new Date(video?.eventInfos?.endDate) && (
                                            <span>
                                                <Trans
                                                    t={t}
                                                    i18nKey={'video_details_page:details_section:event_closed'}
                                                    components={{
                                                        b: (
                                                            <StyledEventLink
                                                                onClick={() =>
                                                                    navigate(
                                                                        generatePath(getEventPath(video?.eventInfos))
                                                                    )
                                                                }
                                                            />
                                                        )
                                                    }}
                                                    values={{
                                                        EVENT: video.eventInfos.name
                                                    }}
                                                />
                                            </span>
                                        )}
                                    </>
                                )}

                                {video?.status === VIDEO_STATUS.ReadyForAudit && accessToken && (
                                    <ReadyForAudit points={video?.projectInfos?.points} />
                                )}

                                {(video?.status === VIDEO_STATUS.Completed ||
                                    video?.status === VIDEO_STATUS.Delivered) &&
                                    accessToken && <Completed points={video?.projectInfos?.points} />}

                                {video?.status === VIDEO_STATUS.Rejected && accessToken && (
                                    <span>{t('video_details_page:details_section:rejected_video')}</span>
                                )}
                            </>
                        ) : (
                            <span>{t('video_details_page:details_section:not_available_for_assignment')}</span>
                        )}
                        <StyledDivider />
                    </>
                ) : (
                    <>
                        {!user?.type && (
                            <>
                                <StyledDivider />

                                {!video?.eventInfos ||
                                (new Date() >= new Date(video?.eventInfos?.startDate) &&
                                    new Date() <= new Date(video?.eventInfos?.endDate)) ? (
                                    <StyledLoginMessage>
                                        <Trans
                                            t={t}
                                            i18nKey={'video_details_page:details_section:login_message'}
                                            components={{
                                                login: <StyledLink href="/login" />,
                                                signup: <StyledLink href="/signup" />
                                            }}
                                        />
                                    </StyledLoginMessage>
                                ) : (
                                    <>
                                        {new Date() < new Date(video?.eventInfos?.startDate) && (
                                            <span>
                                                <Trans
                                                    t={t}
                                                    i18nKey={'video_details_page:details_section:event_not_started'}
                                                    components={{
                                                        b: (
                                                            <StyledEventLink
                                                                onClick={() =>
                                                                    navigate(
                                                                        generatePath(getEventPath(video?.eventInfos))
                                                                    )
                                                                }
                                                            />
                                                        )
                                                    }}
                                                    values={{
                                                        EVENT: video.eventInfos.name
                                                    }}
                                                />
                                            </span>
                                        )}
                                        {new Date() > new Date(video?.eventInfos?.endDate) && (
                                            <span>
                                                <Trans
                                                    t={t}
                                                    i18nKey={'video_details_page:details_section:event_closed'}
                                                    components={{
                                                        b: (
                                                            <StyledEventLink
                                                                onClick={() =>
                                                                    navigate(
                                                                        generatePath(getEventPath(video?.eventInfos))
                                                                    )
                                                                }
                                                            />
                                                        )
                                                    }}
                                                    values={{
                                                        EVENT: video.eventInfos.name
                                                    }}
                                                />
                                            </span>
                                        )}
                                    </>
                                )}

                                <StyledDivider />
                            </>
                        )}
                    </>
                )}
            </StyledButtonSection>
        </StyledDetailsRoot>
    )
}

export default Details

Details.displayName = 'Details'
