import styled, {css, DefaultTheme} from 'styled-components'

export const StyledHeaderWrapper = styled.div`
    ${({theme: {spacing, mediaQueries, palette}}: {theme: DefaultTheme}) => css`
        background: ${palette.neutral.white};
        border-bottom: 1px solid ${palette.gray.xlight};
        width: 100%;
        padding: ${spacing * 4}px;
        margin: 0 auto;
        ${mediaQueries.m} {
            padding: ${spacing * 6}px;
        }
    `}
`

export const HeaderGrid = styled.div`
    ${({theme: {mediaQueries, spacing}}: {theme: DefaultTheme}) => css`
        display: grid;
        align-items: center;
        grid-template-columns: 1fr auto 1fr;
        height: 100%;
        gap: ${spacing * 2}px;
        & > svg {
            cursor: pointer;
        }
        ${mediaQueries.m} {
            gap: ${spacing * 4}px;
            grid-template-columns: 1fr 1fr 1fr;
        }
    `}
`
export const StyledLogoWrapper = styled.div`
    ${({theme: {mediaQueries, spacing}}: {theme: DefaultTheme}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing}px;
        & svg {
            cursor: pointer;
        }
        & > div {
            height: 28px;
        }
        & > a {
            height: 32px;

            ${mediaQueries.m} {
                gap: ${spacing * 4}px;
                height: 40px;
            }
        }
    `}
`

export const StyledLogo = styled.img`
    ${({theme: {mediaQueries}}: {theme: DefaultTheme}) => css`
        cursor: pointer;
        width: 102px;
        ${mediaQueries.m} {
            width: 127px;
        }
    `}
`

export const StyledInputWrapper = styled.div`
    ${({theme: {mediaQueries, spacing, palette}}) => css`
        display: none;

        ${mediaQueries.l} {
            display: block;
            width: 100%;
            width: 560px;
            margin: 0 auto;
            & input {
                background: ${palette.gray.lightBG};
                border: none;
                padding: ${spacing * 2}px ${spacing * 4}px;
                border-radius: 30px;
            }
            & span {
                & :hover {
                    & svg path {
                        fill: ${palette.blue.link};
                        fill-opacity: 1;
                    }
                }
            }
        }
    `}
`

export const StyledButtonWrapper = styled.div`
    ${({theme: {mediaQueries, spacing}}: {theme: DefaultTheme}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing * 2}px;
        justify-content: flex-end;

        & button {
            padding: ${spacing * 2}px ${spacing * 3}px;
            font-weight: 700;
        }

        ${mediaQueries.m} {
            gap: ${spacing * 4}px;
            & button {
                padding: ${spacing * 3}px ${spacing * 5}px;
            }
        }
    `}
`

export const StyledAvatarWrapper = styled.div`
    ${({theme: {spacing}}: {theme: DefaultTheme}) => css`
        position: relative;
        margin-left: auto;
        display: flex;
        align-items: center;
        gap: ${spacing * 4}px;

        & > svg {
            cursor: pointer;
        }
    `}
`
