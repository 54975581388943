import {useEffect} from 'react'
import Container from '@/components/ui/Container'
import AdditionalVideoInformation from '@/features/videos/components/video-details/additional-video-information/AdditionalVideoInformation'
import Details from '@/features/videos/components/video-details/details/Details'
import {useTheme} from 'styled-components'
import {StyledVideoDetailsRoot, StyledContentWrapper, StyledRoot} from './style'
import SimilarVideos from '@/features/videos/components/video-details/similar-videos/SimilarVideos'
import Spinner from '@/components/ui/Spinner'
import {handleApiError} from '@/utilities/helpers'
import {useGetVideoInformation} from '@/features/videos/queries/useGetVideoInformation'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'
import ContributorContainer from '@/components/ui/ContributorContainer/ContributorContainer'
import {ROUTE_VIDEOS} from '@/utilities/constants'

const VideoDetails: React.FC = () => {
    const {contributorContainerSize} = useTheme()
    const {videoId} = useParams()
    const [searchParams] = useSearchParams()
    const hash = searchParams.get('t')
    const navigate = useNavigate()
    const {data: videoData, isLoading, isError, error} = useGetVideoInformation({videoId: `${videoId}`, hash})

    useEffect(() => {
        if (isError) {
            handleApiError({error})
            navigate(ROUTE_VIDEOS)
        }
    }, [isError])

    if (isLoading) return <Spinner size={48} overlay />

    return (
        <StyledRoot>
            <ContributorContainer>
                <Container maxWidth={contributorContainerSize}>
                    <StyledVideoDetailsRoot>
                        {videoData && <Details video={videoData} />}
                        <StyledContentWrapper>
                            {videoData && <AdditionalVideoInformation video={videoData} />}
                        </StyledContentWrapper>
                        {!!videoData?.relatedJobs && <SimilarVideos similarVideosList={videoData?.relatedJobs ?? []} />}
                    </StyledVideoDetailsRoot>
                </Container>
            </ContributorContainer>
        </StyledRoot>
    )
}

export default VideoDetails

VideoDetails.displayName = 'VideoDetails'
