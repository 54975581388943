import {Event, EventStatus} from '@/types/event'
import {EVENT_STATUS} from './constants/event'
import {EventType} from '@/features/workspaces/types'
import dayjs from 'dayjs'

export const getEventStatus = (event: EventType | Event): EventStatus => {
    const startDate = dayjs(event.startDate).startOf('day')
    const endDate = dayjs(event.endDate).startOf('day')
    const startOfToday = dayjs().startOf('day')

    if (startDate > startOfToday) {
        return EVENT_STATUS.Scheduled
    } else if (endDate < startOfToday) {
        return EVENT_STATUS.Closed
    } else {
        return EVENT_STATUS.Open
    }
}
