import {StyledButtonWrapper, StyledCompleteButtonRoot, StyledUnassignButton} from './style'
import {useTranslation} from 'react-i18next'
import Button from '@/components/ui/Button'
import useAuthStore from '@/store/authStore'
import {ROUTE_LOGIN} from '@/utilities/constants'
import {useNavigate} from 'react-router-dom'
import {ValidationError, getLocalDate, handleApiError} from '@/utilities/helpers'
import {useEffect} from 'react'
import Spinner from '@/components/ui/Spinner'
import {useUpdateVideoAssignment} from '@/features/videos/queries/useUpdateVideoAssignment'
import {VideoStatus} from '@/types/video'
import {VIDEO_STATUS} from '@/utilities/constants/video'
import {envVars} from '@/envVars'

interface UpdateVideoAssignmentButtonProps {
    projectId: number
    videoId: number
    assignedAt: string
    subPlatformUrl: string | null
    isCompleteDisabled: boolean
}

const UpdateVideoAssignmentButton: React.FC<UpdateVideoAssignmentButtonProps> = ({
    projectId,
    videoId,
    assignedAt,
    subPlatformUrl,
    isCompleteDisabled
}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {accessToken, userMe} = useAuthStore(store => store)
    const localDateAssignedAt = assignedAt ? getLocalDate(assignedAt, 'DD/MM/YYYY') : '--'

    const {mutate, isPending, isError, error} = useUpdateVideoAssignment({
        projectId: `${projectId}`,
        videoId: `${videoId}`,
        onSuccess: userMe
    })

    const handleUpdateAssignment = (status: VideoStatus) => {
        if (!accessToken) {
            navigate(ROUTE_LOGIN)
        } else {
            mutate(status)
        }
    }

    const handleRedirectToReview = () => {
        const url = subPlatformUrl ?? ''
        if (envVars.VITE_OPEN_SOURCE_URL) {
            window.open(`${envVars.VITE_OPEN_SOURCE_URL}?job=${videoId}&token=${accessToken}`, '_blank')
        } else {
            window.open(url, '_blank')
        }
    }

    useEffect(() => {
        if (isError) handleApiError({error: error as ValidationError<string>})
    }, [isError])

    return (
        <StyledCompleteButtonRoot>
            {isPending && <Spinner size={48} overlay={true} />}
            <p>{t('video_details_page:details_section:started_at', {START_DATE: localDateAssignedAt})}</p>
            <StyledButtonWrapper>
                <Button
                    disabled={isCompleteDisabled}
                    size="md"
                    variant="success"
                    onClick={() => handleUpdateAssignment(VIDEO_STATUS.ReadyForAudit)}
                >
                    {t('video_details_page:details_section:mark_as_completed_button')}
                </Button>
                <Button size="md" variant="outline" onClick={handleRedirectToReview}>
                    {t('video_details_page:details_section:go_on_subplatform_button')}
                </Button>
                <StyledUnassignButton onClick={() => handleUpdateAssignment(VIDEO_STATUS.Ready)}>
                    {t('video_details_page:details_section:unassign_button')}
                </StyledUnassignButton>
            </StyledButtonWrapper>
        </StyledCompleteButtonRoot>
    )
}

export default UpdateVideoAssignmentButton

UpdateVideoAssignmentButton.displayName = 'UpdateVideoAssignmentButton'
