import {Outlet} from 'react-router-dom'
import React from 'react'
import {StyledOutletWrapper, StyledPublicLayout} from './style'
import Header from '@/components/commons/Header'

type PublicLayoutProps = {
    withHeader?: boolean
}

const PublicLayout: React.FC<PublicLayoutProps> = ({withHeader = true}) => {
    return (
        <StyledPublicLayout>
            {withHeader && <Header />}
            <StyledOutletWrapper>
                <Outlet />
            </StyledOutletWrapper>
        </StyledPublicLayout>
    )
}

export default PublicLayout
