import React, {useEffect, useRef, useState} from 'react'
import {
    StyledContentWrapper,
    StyledHeaderWrapper,
    StyledInputWrapper,
    StyledListAction,
    StyledListItemImage,
    StyledListItemTitle,
    StyledListItemUrl,
    StyledListItemWrapper,
    StyledListLabel,
    StyledListValue,
    StyledListValueLabel,
    StyledListWrapper,
    StyledDisclaimer,
    StyledWarningIconWrapper,
    StyledTotalVideos
} from './style'
import {DefaultTFuncReturn, t} from 'i18next'
import {useController, useFormContext} from 'react-hook-form'
import {HttpListResponse, ValueLabel} from '@/types/commons'
import {ValidationError, handleApiError} from '@/utilities/helpers'
import Button from '@/components/ui/Button'
import {SearchIcon, WarningIcon} from '@/assets/icons/icons'

export interface VideoSelectProps<AdapterParamType> {
    name: string
    errorMessage?: string | DefaultTFuncReturn
    placeholder: string
    fetchFunction: (filter: string, page?: string) => Promise<HttpListResponse<AdapterParamType>>
    adapterFunction: (data: HttpListResponse<AdapterParamType>) => ValueLabel[]
    onSelect: () => void
}

function VideoSelect<AdapterParamType>({
    name,
    fetchFunction,
    placeholder,
    adapterFunction,
    onSelect
}: VideoSelectProps<AdapterParamType>) {
    const {control} = useFormContext()
    const {
        field: {onChange}
    } = useController({
        name,
        control,
        rules: {required: true}
    })
    const [filterValue, setFilterValue] = useState('')
    const [filterParam, setFilterParam] = useState('')
    const debounceTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)
    const [data, setData] = useState<ValueLabel[]>([])
    const [total, setTotal] = useState<number>(0)
    const [nextPage, setNextPage] = useState<string | undefined>(undefined)

    const fetchData = async (pageParam?: string) => {
        try {
            const response = await fetchFunction(filterParam, pageParam)
            setNextPage(response?.meta?.next_page_url?.split('=')[1])
            if (pageParam) {
                setData(value => [...value, ...adapterFunction(response)])
            } else {
                setData(adapterFunction(response))
            }
            setTotal(response?.meta?.total ?? 0)
        } catch (error) {
            handleApiError({error: error as ValidationError<string>})
        }
    }

    useEffect(() => {
        fetchData()
    }, [filterParam])

    const onClickItem = (item: ValueLabel): void => {
        onChange({target: {value: item}})
        onSelect()
    }

    useEffect(() => {
        if (debounceTimeoutRef.current) clearTimeout(debounceTimeoutRef.current)
    }, [])

    const onChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        const eventValue = event.target.value
        setFilterValue(eventValue)
        if (debounceTimeoutRef.current) clearTimeout(debounceTimeoutRef.current)
        debounceTimeoutRef.current = setTimeout(() => {
            setFilterParam(eventValue)
        }, 500)
    }

    const onClickLoadMore = () => {
        fetchData(nextPage)
    }

    return (
        <div>
            <StyledWarningIconWrapper>
                <WarningIcon fill="#ff7875" width={30} height={32} />
            </StyledWarningIconWrapper>
            <StyledHeaderWrapper>
                <div>
                    <StyledDisclaimer>{t('video_select:disclaimer')}</StyledDisclaimer>
                </div>
                <div>
                    <StyledInputWrapper>
                        <input placeholder={placeholder} value={filterValue} onChange={onChangeFilter} />
                        <SearchIcon />
                    </StyledInputWrapper>
                </div>
            </StyledHeaderWrapper>
            <StyledContentWrapper>
                <StyledListWrapper>
                    {data?.map((item: ValueLabel, index: number) => (
                        <StyledListItemWrapper key={index}>
                            <StyledListItemImage src={item.thumbnailUrl} />
                            <StyledListItemTitle>
                                {item.label.length > 35 ? `${item.label.substring(0, 35)}...` : item.label}
                            </StyledListItemTitle>
                            <StyledListItemUrl target="_blank" href={item.europeanaPlatformSourceUrl}>
                                {item.europeanaPlatformSourceUrl}
                            </StyledListItemUrl>
                            <StyledListValueLabel>
                                <StyledListLabel>{t('video_select:language')}</StyledListLabel>
                                <StyledListValue>{item.language?.name}</StyledListValue>
                            </StyledListValueLabel>
                            <StyledListAction>
                                <Button
                                    disabled={item.isDisabled}
                                    style={{width: '100%'}}
                                    onClick={() => onClickItem(item)}
                                >
                                    {item.isDisabled
                                        ? t('video_select:video_already_used')
                                        : t('video_select:add_video')}
                                </Button>
                            </StyledListAction>
                        </StyledListItemWrapper>
                    ))}
                </StyledListWrapper>
                {nextPage ? (
                    <Button variant="transparent" size="xs" onClick={onClickLoadMore}>
                        {t('load_more:load_more')}
                    </Button>
                ) : null}
            </StyledContentWrapper>
            <StyledTotalVideos>
                <span>{t('video_select:total_videos')}</span>
                <b>{total.toLocaleString()}</b>
            </StyledTotalVideos>
        </div>
    )
}

export default VideoSelect

VideoSelect.displayName = 'VideoSelect'
