import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
    StyledBox,
    StyledButtonWrap,
    StyledDivider,
    StyledFormRoot,
    StyledPasswordLink,
    StyledRoot,
    StyledSubTitle,
    StyledText,
    StyledTextDivider,
    StyledTextWrap,
    StyledTitle
} from './style'
import {EyeIcon, EyeInvisibleIcon} from '@/assets/icons/icons'
import Checkbox from '@/components/ui/Checkbox'
import {useForm, SubmitHandler} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {LOGIN_MODEL, LoginSchema, ValidationSchema} from '../../features/login/services/LoginFormModel'
import {Link} from 'react-router-dom'
import Spinner from '@/components/ui/Spinner'
import {ROUTE_SIGNUP} from '@/utilities/constants'
import Container from '@/components/ui/Container'
import Button from '@/components/ui/Button'
import Input from '@/components/ui/Input'
import StatusModal from '@/components/commons/StatusModal'
import {handleApiError} from '@/utilities/helpers'
import useAuthStore from '@/store/authStore'
import PasswordRecoverModal from '@/features/login/components/password-recover-modal/PasswordRecoverModal'

const Login: React.FC = () => {
    const {t} = useTranslation()
    const login = useAuthStore(store => store.login)
    const isLoading = useAuthStore(store => store.isLoading)
    const error = useAuthStore(store => store.error)
    const isError = useAuthStore(store => store.isError)
    const loginEuropeana = useAuthStore(store => store.loginEuropeana)

    const [showPassword, setShowPassword] = useState(false)
    const [isPasswordRecoverModalOpen, setPasswordRecoverModalOpen] = useState(false)
    const [isSuccessModalOpen, setSuccessModalOpen] = useState(false)
    const {
        register,
        handleSubmit,
        setError,
        formState: {errors, isValid, isDirty}
    } = useForm<ValidationSchema>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(LoginSchema)
    })
    const isSubmitDisabled = !isValid || !isDirty || isLoading

    useEffect(() => {
        if (isError && error instanceof Error) handleApiError({setError, error})
    }, [isError])

    const onSubmit: SubmitHandler<ValidationSchema> = values => {
        const dataToSend = {
            ...values,
            remember_me: values.remember_me ?? false
        }
        login(dataToSend)
    }

    return (
        <Container>
            {isLoading && <Spinner size={48} />}
            <StyledRoot>
                <StyledTitle>{t('common:log_in')}</StyledTitle>
                <StyledFormRoot onSubmit={handleSubmit(onSubmit)}>
                    <StyledSubTitle>{t('log_in_page:subtitle')}</StyledSubTitle>
                    <StyledButtonWrap>
                        <Button variant={'secondary'} onClick={loginEuropeana}>
                            {t('common:signin_europeana')}
                        </Button>
                        <StyledTextDivider>{t('common:or')}</StyledTextDivider>
                    </StyledButtonWrap>
                    <Input
                        {...register(LOGIN_MODEL.Email)}
                        label={`${t('common:email')}*`}
                        errorMessage={`${t(errors[LOGIN_MODEL.Email]?.message || '')}`}
                    />

                    <div>
                        <Input
                            {...register(LOGIN_MODEL.Password)}
                            label={`${t('common:password')}*`}
                            type={showPassword ? 'text' : 'password'}
                            icon={showPassword ? <EyeIcon /> : <EyeInvisibleIcon />}
                            iconCallback={() => setShowPassword(!showPassword)}
                            errorMessage={`${t(errors[LOGIN_MODEL.Password]?.message || '')}`}
                        />
                        <StyledBox>
                            <Checkbox
                                {...register(LOGIN_MODEL.Remember_me)}
                                label={`${t('log_in_page:remember_me')}`}
                                id={'remember_me'}
                                disabled={isSubmitDisabled}
                            />
                            <StyledPasswordLink onClick={() => setPasswordRecoverModalOpen(true)}>
                                {t('log_in_page:forgot_password')}
                            </StyledPasswordLink>
                        </StyledBox>
                    </div>
                    <Button type={'submit'} disabled={isSubmitDisabled}>
                        {t('common:log_in')}
                    </Button>

                    <StyledDivider />

                    <StyledTextWrap>
                        <span>{t('log_in_page:has_account')}</span>
                        <Link to={ROUTE_SIGNUP}>
                            <StyledText>{t('common:sign_up')}</StyledText>
                        </Link>
                    </StyledTextWrap>
                </StyledFormRoot>
            </StyledRoot>

            {isPasswordRecoverModalOpen && (
                <PasswordRecoverModal
                    onClose={() => setPasswordRecoverModalOpen(false)}
                    setSuccessModalOpen={setSuccessModalOpen}
                />
            )}
            {isSuccessModalOpen && (
                <StatusModal
                    overlay
                    variant={'success'}
                    title={'log_in_page:password_recover_modal:success_modal_title'}
                    onClose={() => {
                        setSuccessModalOpen(false)
                    }}
                >
                    {t('log_in_page:password_recover_modal:success_modal_description')}
                </StatusModal>
            )}
        </Container>
    )
}

export default Login

Login.displayName = 'Login'
