import {StyledBackButton, StyledBreadcrumbs, StyledCrumb, StyledRoot, StyledWrapper} from './style'
import Container from '@/components/ui/Container'
import {Outlet, useMatches, useNavigate, useParams, useLocation, matchPath, generatePath} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {useGetWorkspaceDetails} from '@/features/workspaces/queries/useGetWorkspaceDetails'
import {useTheme} from 'styled-components'
import {useGetVideoDetails} from '@/features/videos/queries/useGetVideoDetails'
import {useGetWorkspaceEventDetails} from '@/features/events/queries/useGetWorkspaceEventDetails'
import {DropdownArrowIcon} from '@/assets/icons/icons'
import MembersSidebar from '@/features/workspaces/components/members-sidebar/MembersSidebar'
import {
    ROUTE_VIDEO_INGESTION,
    ROUTE_WORKSPACES,
    ROUTE_WORKSPACE_DETAILS,
    ROUTE_WORKSPACE_EVENT_DETAILS
} from '@/utilities/constants'

const WorkspaceLayout = () => {
    const matches = useMatches()
    const location = useLocation()
    const navigate = useNavigate()
    const {t} = useTranslation()
    const {providerContainerSize} = useTheme()
    const {workspaceId} = useParams()
    const {videoId} = useParams()
    const {eventId} = useParams()
    const {data: workspaceData} = useGetWorkspaceDetails({id: `${workspaceId}`})
    const {data: videoData} = useGetVideoDetails({
        workspaceId: `${workspaceId}`,
        videoId: `${videoId}`,
        enabled: videoId !== undefined
    })
    const {data: eventData} = useGetWorkspaceEventDetails({
        workspaceId: `${workspaceId}`,
        eventId: `${eventId}`,
        enabled: eventId !== undefined
    })

    const segments = matches.find(segment => segment.pathname !== '/')
    const breadcrumbs: string[] = []
    let workspaceBreadcrumbLoaded = false
    segments?.pathname?.split('/').forEach(item => {
        if (isNaN(parseInt(item))) {
            breadcrumbs.push(t(`breadcrumb:${item}`))
        } else if (workspaceBreadcrumbLoaded && videoId !== undefined) {
            breadcrumbs.push(videoData?.name ?? '')
        } else if (workspaceBreadcrumbLoaded && eventId !== undefined) {
            breadcrumbs.push(eventData?.name ?? '')
        } else {
            breadcrumbs.push(workspaceData?.name ?? '')
            workspaceBreadcrumbLoaded = true
        }
    })

    const goBack = () => {
        if (matchPath({path: ROUTE_WORKSPACE_DETAILS}, location.pathname)) {
            navigate(ROUTE_WORKSPACES)
        } else if (matchPath({path: ROUTE_WORKSPACE_EVENT_DETAILS}, location.pathname)) {
            navigate(generatePath(ROUTE_WORKSPACE_DETAILS, {workspaceId: `${workspaceId}`}))
        } else if (matchPath({path: ROUTE_VIDEO_INGESTION}, location.pathname)) {
            navigate(generatePath(ROUTE_WORKSPACE_DETAILS, {workspaceId: `${workspaceId}`}))
        } else {
            navigate(-1)
        }
    }

    return (
        <Container maxWidth={providerContainerSize} isPositionRight={false}>
            <StyledRoot>
                <StyledWrapper>
                    <StyledBackButton onClick={goBack}>
                        <DropdownArrowIcon /> <span>{t('common:back')}</span>
                    </StyledBackButton>
                    <StyledBreadcrumbs>
                        {breadcrumbs?.map((crumb, index) => {
                            const isLast = index === breadcrumbs.length - 1
                            return (
                                <StyledCrumb isLast={isLast} key={`${crumb}-${index}`}>
                                    {crumb}
                                    {!isLast && '/ '}
                                </StyledCrumb>
                            )
                        })}
                    </StyledBreadcrumbs>
                </StyledWrapper>
                <Outlet />

                <MembersSidebar />
            </StyledRoot>
        </Container>
    )
}

export default WorkspaceLayout

WorkspaceLayout.displayName = 'WorkspaceLayout'
