import Container from '@/components/ui/Container'
import {useTheme} from 'styled-components'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {useEffect, useState} from 'react'
import TabSection from '@/features/events/components/tab-section/TabSection'
import {useGetEventDetails} from '@/features/events/queries/useGetEventDetails'
import {getLocalDate, handleApiError} from '@/utilities/helpers'
import {
    StyledButton,
    StyledData,
    StyledDescription,
    StyledTitleSection,
    StyledWrapper,
    StyledLoginModalWrapper
} from './style'
import {ROUTE_EVENTS, ROUTE_LOGIN, ROUTE_SIGNUP_CONTRIBUTOR, USER_ROLE_PROVIDER} from '@/utilities/constants'
import Spinner from '@/components/ui/Spinner'
import LeaderboardTab from '@/features/events/components/leaderboard-tab/LeaderboardTab'
import VideosTab from '@/features/events/components/video-tab/VideosTab'
import {StyledRoot} from '../style'
import ContributorContainer from '@/components/ui/ContributorContainer/ContributorContainer'
import hero_events from '@assets/images/background/hero_events.png'
import {useJoinEvent} from '@/features/events/queries/useJoinEvent'
import {toast} from 'react-hot-toast'
import BackgroundFullScreenImage from '@/components/ui/BackgroundFullScreenImage'
import {getEventStatus} from '@/utilities/event'
import {EVENT_STATUS} from '@/utilities/constants/event'
import {useAcceptEventInvitation} from '@/features/events/queries/useAcceptEventInvitation'
import StatusModal from '@/components/commons/StatusModal'
import {Trans, useTranslation} from 'react-i18next'
import useAuthStore from '@/store/authStore'
import Button from '@/components/ui/Button'
import {LocalStorageManager} from '@/utilities/localStorage'

export const EVENT_DETAILS_TABS = [
    {
        id: 'videos',
        name: 'common:videos'
    },
    {
        id: 'leaderboard',
        name: 'common:leaderboard'
    }
]

const EventDetails: React.FC = () => {
    const {t} = useTranslation()
    const {contributorContainerSize} = useTheme()
    const accessToken = useAuthStore(store => store.accessToken)
    const {eventId} = useParams()
    const user = useAuthStore(store => store.user)
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const activeTabId = searchParams.get('tab')
    const hash = searchParams.get('t')
    const {isLoading, data, isError, error} = useGetEventDetails({eventId: `${eventId}`, hash})
    const onSuccessJoinEvent = () => {
        toast.success(t(data?.mustRequestToJoin ? 'event_page:pending_joined_event' : 'event_page:joined_event'))
    }
    const eventStatus = data ? getEventStatus(data) : null
    const {
        mutate: joinEventMutate,
        isError: joinEventIsError,
        error: joinEventError,
        isPending
    } = useJoinEvent({eventId: `${eventId}`, onSuccess: onSuccessJoinEvent})

    const startLocalDate = data?.startDate ? getLocalDate(`${data?.startDate}`, 'MMM D, YYYY') : ''
    const endLocalDate = data?.endDate ? getLocalDate(`${data?.endDate}`, 'MMM D, YYYY') : ''

    const token = searchParams.get('token')
    const eventUserInvitationId = searchParams.get('eventUserInvitationId')
    const invitation = token && eventUserInvitationId ? {eventUserInvitationId, token} : null

    const [eventInvitation, setEventInvitation] = useState(invitation)
    const [isSuccessAcceptInvitationModalOpen, setIsSuccessAcceptInvitationModalOpen] = useState(false)
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false)

    const {
        mutate,
        isError: isErrorEventInvitation,
        error: errorEventInvitation
    } = useAcceptEventInvitation({
        eventId: `${eventId}`,
        onSuccess: () => {
            setEventInvitation(null)
            LocalStorageManager.eventInvitationLink.remove()
            setIsSuccessAcceptInvitationModalOpen(true)
        }
    })

    useEffect(() => {
        if (eventInvitation) {
            if (!accessToken) {
                LocalStorageManager.eventInvitationLink.set(`${location.pathname}${location.search}`)
                setEventInvitation(null)
                setIsLoginModalOpen(true)
            } else {
                const timeout = setTimeout(() => mutate({...eventInvitation}), 100)
                return () => clearTimeout(timeout)
            }
        }
    }, [eventInvitation])

    useEffect(() => {
        if (isErrorEventInvitation && errorEventInvitation instanceof Error) {
            setEventInvitation(null)
            LocalStorageManager.eventInvitationLink.remove()
            handleApiError({error: errorEventInvitation})
        }
    }, [isErrorEventInvitation])

    useEffect(() => {
        if (joinEventIsError && joinEventError instanceof Error) {
            handleApiError({error: joinEventError})
        }
    }, [joinEventIsError])

    useEffect(() => {
        if (isError && error instanceof Error) {
            handleApiError({error})
            navigate(ROUTE_EVENTS)
        }
    }, [isError])

    const setActiveTabId = (tab: string) => {
        setSearchParams(params => {
            params.set('tab', tab)
            return params
        })
    }

    const videos = data?.jobs.length ? data?.jobs : []

    const eventDetailsTabContent = {
        [EVENT_DETAILS_TABS[0].id]: <VideosTab videos={videos} />,
        [EVENT_DETAILS_TABS[1].id]: <LeaderboardTab />
    }

    const isMine = user?.id === data?.user[0]?.id

    const showToJoinButton =
        !isMine &&
        !!user &&
        user?.type !== USER_ROLE_PROVIDER &&
        !data?.hasJoined &&
        eventStatus === EVENT_STATUS.Open &&
        data?.mustRequestToJoin

    return isLoading || isPending ? (
        <Spinner size={48} />
    ) : (
        <StyledRoot>
            <ContributorContainer>
                <BackgroundFullScreenImage backgroundImage={hero_events}>
                    <StyledTitleSection>
                        <h2>{data?.name}</h2>
                        <StyledData>{`${startLocalDate} ${endLocalDate && `- ${endLocalDate}`}`}</StyledData>

                        {eventStatus === EVENT_STATUS.Closed && data?.mustRequestToJoin && (
                            <span>{t('event_page:event_closed')}</span>
                        )}

                        {eventStatus === EVENT_STATUS.Scheduled && data?.mustRequestToJoin && (
                            <span>{t('event_page:event_not_started')}</span>
                        )}

                        {showToJoinButton && (
                            <>
                                <StyledButton
                                    disabled={data?.isPending}
                                    onClick={() => !data?.isPending && joinEventMutate()}
                                >
                                    {t('event_page:join_now')}
                                </StyledButton>
                                {data?.isPending && <span>{t('event_page:request_already_sent')}</span>}
                            </>
                        )}
                    </StyledTitleSection>
                </BackgroundFullScreenImage>

                <Container maxWidth={contributorContainerSize}>
                    <StyledWrapper>
                        <StyledDescription>{data?.description}</StyledDescription>
                        {(isMine || !data?.mustRequestToJoin || !!data?.hasJoined) && (
                            <>
                                <TabSection
                                    tabsList={EVENT_DETAILS_TABS}
                                    activeTabId={activeTabId ?? EVENT_DETAILS_TABS[0].id}
                                    setActiveTabId={setActiveTabId}
                                />

                                {eventDetailsTabContent[activeTabId ?? EVENT_DETAILS_TABS[0].id]}
                            </>
                        )}
                    </StyledWrapper>
                </Container>
            </ContributorContainer>
            {isSuccessAcceptInvitationModalOpen && (
                <StatusModal
                    overlay
                    title={t('event_page:success_modal_title')}
                    onClose={() => setIsSuccessAcceptInvitationModalOpen(false)}
                >
                    <div>{t('event_page:success_modal_description')}</div>
                </StatusModal>
            )}
            {isLoginModalOpen && (
                <StatusModal
                    overlay
                    title={t('event_page:login_modal_title')}
                    onClose={() => setIsLoginModalOpen(false)}
                    variant="pending"
                >
                    <StyledLoginModalWrapper>
                        <Trans
                            t={t}
                            i18nKey={'event_page:login_modal_description'}
                            components={{
                                span: (
                                    <span
                                        onClick={() => {
                                            setIsLoginModalOpen(false)
                                            navigate(ROUTE_SIGNUP_CONTRIBUTOR)
                                        }}
                                    />
                                )
                            }}
                        />

                        <Button
                            size="sm"
                            variant="primary"
                            onClick={() => {
                                setIsLoginModalOpen(false)
                                navigate(ROUTE_LOGIN)
                            }}
                        >
                            {t('common:log_in')}
                        </Button>
                    </StyledLoginModalWrapper>
                </StatusModal>
            )}
        </StyledRoot>
    )
}
export default EventDetails

EventDetails.displayName = 'EventDetails'
