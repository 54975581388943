import styled, {DefaultTheme, css} from 'styled-components'
import {JOB_STATUS} from '@/utilities/constants/video'
import {JobStatus} from '@/types/job'

const getColorsByType = (theme: DefaultTheme, type: JobStatus) => {
    switch (type) {
        case JOB_STATUS.Processing:
            return `
                border: solid 1px ${theme.palette.status.processing.border};
                background-color: ${theme.palette.status.processing.background};
                color: ${theme.palette.status.processing.text};
                & svg path {
                    fill: ${theme.palette.status.processing.text};
                }
            `
        case JOB_STATUS.Completed:
            return `
                border: solid 1px ${theme.palette.status.completed.border};
                background-color: ${theme.palette.status.completed.background};
                color: ${theme.palette.status.completed.text};
                & svg path {
                    fill: ${theme.palette.status.completed.text};
                }
            `
        case JOB_STATUS.Ready:
            return `
                border: solid 1px ${theme.palette.status.ready.border};
                background-color: ${theme.palette.status.ready.background};
                color: ${theme.palette.status.ready.text};
                & svg path {
                    fill: ${theme.palette.status.ready.text};
                }
            `
        case JOB_STATUS.Assigned:
            return `
                border: solid 1px ${theme.palette.status.assigned.border};
                background-color: ${theme.palette.status.assigned.background};
                color: ${theme.palette.status.assigned.text};
                & svg path {
                    fill: ${theme.palette.status.assigned.text};
                }
            `
        case JOB_STATUS.ReadyForAudit:
            return `
                border: solid 1px ${theme.palette.status.ready_for_audit.border};
                background-color: ${theme.palette.status.ready_for_audit.background};
                color: ${theme.palette.status.ready_for_audit.text};
                & svg path {
                    fill: ${theme.palette.status.ready_for_audit.text};
                }
            `
        case JOB_STATUS.Delivered:
            return `
                border: solid 1px ${theme.palette.status.delivered.border};
                background-color: ${theme.palette.status.delivered.background};
                color: ${theme.palette.status.delivered.text};
                & svg path {
                    fill: ${theme.palette.status.delivered.text};
                }
            `
        case JOB_STATUS.Rejected:
            return `
                    border: solid 1px ${theme.palette.status.rejected.border};
                    background-color: ${theme.palette.status.rejected.background};
                    color: ${theme.palette.status.rejected.text};
                    & svg path {
                        fill: ${theme.palette.status.rejected.text};
                    }
                `
        case JOB_STATUS.Fail:
            return `
                    border: solid 1px ${theme.palette.status.fail.border};
                    background-color: ${theme.palette.status.fail.background};
                    color: ${theme.palette.status.fail.text};
                    & svg path {
                        fill: ${theme.palette.status.fail.text};
                    }
                `
        default:
            return `
                border: solid 1px ${theme.palette.status.default.border};
                background-color: ${theme.palette.status.default.background};
                color: ${theme.palette.status.default.text};
                & svg path {
                    fill: ${theme.palette.status.default.text};
                }
            `
    }
}

export const StyledTagWrapper = styled.div`
    ${({theme, type}: {theme: DefaultTheme; type: JobStatus}) => css`
        ${theme.flexUtils.centered}
        gap:   ${theme.spacing * 2}px;
        ${theme.typography.textXS}
        border-radius: 16px;
        padding: ${theme.spacing * 2}px;
        width: fit-content;
        ${getColorsByType(theme, type)}
        white-space: nowrap;
    `}
`

export const StyledInfoIcon = styled.div`
    height: 11px;
    cursor: pointer;
    position: relative;
`
