import React from 'react'
import {StyledButtonSection, StyledDescription, StyledError, StyledRoot, StyledTitle} from './style'
import Container from '@/components/ui/Container'
import {useTheme} from 'styled-components'
import Button from '@/components/ui/Button'
import {useNavigate} from 'react-router-dom'
import {ROUTE_HOME} from '@/utilities/constants'
import {useTranslation} from 'react-i18next'
import Header from '@/components/commons/Header'

const PageNotFound: React.FC = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {contributorContainerSize} = useTheme()

    return (
        <>
            <Header />
            <StyledRoot>
                <Container maxWidth={contributorContainerSize}>
                    <StyledError>{t('page_not_found:error')}</StyledError>
                    <StyledTitle>{t('page_not_found:title')}</StyledTitle>
                    <StyledDescription>{t('page_not_found:description')}</StyledDescription>
                    <StyledButtonSection>
                        <Button size="xs" variant="outline" onClick={() => navigate(-1)}>
                            {t('page_not_found:go_back')}
                        </Button>
                        <Button size="xs" variant="primary" onClick={() => navigate(ROUTE_HOME)}>
                            {t('page_not_found:go_home')}
                        </Button>
                    </StyledButtonSection>
                </Container>
            </StyledRoot>
        </>
    )
}

export default PageNotFound

PageNotFound.displayName = 'PageNotFound'
