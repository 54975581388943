import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import {FieldError} from 'react-hook-form'
import toast from 'react-hot-toast'
import i18next from 'i18next'
import {VideoSearchParams} from '@/hooks/useFiltersParams'
import {PLATFORMS} from '@/features/workspaces/services/constants'

export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1)

type ReactSelect = {
    label: string
    value: string | number
}
export const retrieveSelectSingleValue = (options: ReactSelect[], value: string) => {
    return options.find(option => option.value.toString() === value)
}

export const retrieveSelectMultiValues = (options: ReactSelect[], values: [string]) => {
    return options.filter(option => values.find(value => value.toString() === option.value.toString()))
}

export const getInitials = (name: string) => {
    const checkedName = name ? name : 'unknown'
    const splitedName = checkedName.match(/^[\w\W]{2}/g) || []

    return splitedName[0]
}

/*date time helpers*/
export const formatDate = (date: string, formatType = 'YYYY-MM-DD') => (date ? dayjs(date).format(formatType) : '-')

export const formatLocalDate = (date: string) => new Date(date).toLocaleDateString()

export const isPasteDate = (date: string): boolean => dayjs(date)?.valueOf() < dayjs(new Date()).valueOf()

export const getTimezone = (): string => {
    dayjs.extend(utc)
    dayjs.extend(timezone)

    return dayjs.tz.guess()
}

export const getLocalDate = (date: string, formatType?: string) => {
    const timeZone = getTimezone()
    const localDate = dayjs(date).tz(timeZone)

    if (formatType) {
        return formatDate(dayjs(localDate).format(), formatType)
    } else {
        return localDate.format()
    }
}

type Field<T extends string> = `${T}`

export type GeneralError = Error & {
    response?: {data: {message: string}; status: number}
}

export type ValidationError<T extends string> = Error & {
    response?: {data: {errors: [{field: Field<T>; message: string}]}}
}

const getErrorMessage = <T extends string>(error: ValidationError<T> | GeneralError) => {
    if (typeof error === 'object' && error !== null) {
        const response = error?.response
        if (typeof response?.data === 'string') {
            return response.data
        } else {
            if (response && response.data) {
                if ('message' in response.data) {
                    return response.data.message
                } else if ('errors' in response.data && response.data.errors.length > 0) {
                    return response.data.errors[0].message
                }
            }
        }
    }
    if (error instanceof Error && error.message) {
        return error.message
    }
    return 'An unknown error occurred.'
}

type handleApiErrorProps<T extends string> = {
    setError?: (field: Field<T>, arg: FieldError) => void
    error: ValidationError<T>
}

export const handleApiError = <T extends string>({setError, error}: handleApiErrorProps<T>) => {
    const t = i18next.t.bind(i18next)
    const errorsList = error?.response?.data?.errors
    if (error) {
        // Errors with field name for react-hook-form
        if (errorsList?.length && setError) {
            errorsList?.map(err => {
                const field = err?.field

                return setError(field, {
                    type: 'backend_error',
                    message: `api_error:${err?.message}`
                })
            })
        } else {
            // General API errors
            const getTranslatedMessage = (errorMessage: string) => t(`api_error:${errorMessage}`)
            const translatedValidationMessage = getTranslatedMessage(getErrorMessage(error))
            if (translatedValidationMessage === 'invalidTokenProvided') return null
            return toast.error(translatedValidationMessage)
        }
    }
}

export const checkInputRemoveValue = (value: string) => {
    const t = i18next.t.bind(i18next)
    return value === t('common:remove')
}

export const formatSecondsToMinutesAndSeconds = (seconds: number) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = Math.round(seconds % 60)

    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes
    const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds

    return `${formattedMinutes}:${formattedSeconds}`
}

export const getElementNumber = ({
    index,
    currentPage,
    perPage
}: {
    index: number
    currentPage?: number
    perPage?: number
}) => {
    if (currentPage && perPage && currentPage > 1) {
        const multiplier = currentPage - 1
        const itemNumber = multiplier * perPage + index + 1
        return itemNumber
    } else {
        return index + 1
    }
}

export const getIsEuropeanaFilterValue = (filtersParams: VideoSearchParams) => {
    const isEuropeanaSelected =
        !!filtersParams.platform && !!filtersParams?.platform.split(',').includes(PLATFORMS.Europeana)
    const isAllSelected =
        !!filtersParams.platform &&
        !!filtersParams?.platform.split(',').includes(PLATFORMS.Europeana) &&
        !!filtersParams?.platform.split(',').includes(PLATFORMS.NonEuropeana)
    const isEuropeana = !!filtersParams.platform && !isAllSelected ? isEuropeanaSelected : undefined
    return isEuropeana
}

export const formatDuration = (duration: string) => {
    const [hours, minutes, seconds] = duration.split(':').map(Number)
    if (hours > 0) {
        return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
    } else {
        return `${minutes}:${seconds.toString().padStart(2, '0')}`
    }
}

export const convertDurationToMilliseconds = ({videoDuration}: {videoDuration: string}) => {
    const [hours, minutes, seconds] = videoDuration.split(':').map(Number)
    const milliseconds = (hours * 3600 + minutes * 60 + seconds) * 1000
    return milliseconds
}
