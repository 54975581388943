import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {httpAcceptEventRequest} from '../services/workspaces.http'

type UseAcceptEventProps = {
    workspaceId: string
    eventId: string
}

export const useAcceptEventRequest = ({workspaceId, eventId}: UseAcceptEventProps) => {
    const client = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.ACCEPT_EVENT_REQUEST, workspaceId, eventId],
        mutationFn: ({userId}: {userId: string}) => httpAcceptEventRequest({workspaceId, eventId, userId}),
        onSuccess: () => {
            client.invalidateQueries({queryKey: [QUERY_KEYS.WORKSPACE_EVENT_MEMBERS, workspaceId, eventId]})
        }
    })
}
