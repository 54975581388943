import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {z} from 'zod'
import {checkInputRemoveValue, handleApiError} from '@utilities/helpers.ts'
import WorkspaceActionModal from '@/components/commons/WorkspaceActionModal/WorkspaceActionModal.tsx'
import {useDeleteEventMember} from '@/features/events/queries/useDeleteEventMember'
import {Trans, useTranslation} from 'react-i18next'

const enum DELETE_MODEL {
    Type = 'type'
}

const DeleteEventMemberSchema = z.object({
    [DELETE_MODEL.Type]: z
        .string()
        .min(1, {message: 'validation:required_field'})
        .refine(value => checkInputRemoveValue(value), {message: 'validation:invalid_value'})
        .default('')
})

type ValidationSchema = z.infer<typeof DeleteEventMemberSchema>

export interface DeleteEventMemberModalProps {
    onClose: () => void
    workspaceId: string
    eventId: string
    selectedMembers: number[]
    onSuccess: () => void
}

const DeleteEventMemberModal: React.FC<DeleteEventMemberModalProps> = ({
    onClose,
    workspaceId,
    eventId,
    selectedMembers,
    onSuccess
}) => {
    const {t} = useTranslation()
    const {mutate, isPending, isError, error} = useDeleteEventMember({
        workspaceId: `${workspaceId}`,
        eventId: `${eventId}`,
        onSuccess
    })

    const {
        register,
        handleSubmit,
        formState: {errors, isValid, isDirty}
    } = useForm<ValidationSchema>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(DeleteEventMemberSchema)
    })
    useEffect(() => {
        if (isError) handleApiError({error})
    }, [isError])

    const onSubmit = () => {
        if (workspaceId) {
            mutate({userIds: selectedMembers})
        }
    }

    return (
        <WorkspaceActionModal
            onClose={onClose}
            title={'event_ingestion:delete_member_modal:title'}
            description={'event_ingestion:delete_member_modal:description'}
            subDescription={
                <div>
                    <Trans
                        t={t}
                        i18nKey="event_ingestion:delete_member_modal:sub_description"
                        components={{
                            span: <span />
                        }}
                    />
                </div>
            }
            errorMessage={errors.type?.message || ''}
            handleSubmit={handleSubmit(onSubmit)}
            isConfirmButtonDisabled={!isValid || !isDirty || isPending}
            isSubmitting={isPending}
            {...register(DELETE_MODEL.Type)}
        />
    )
}

export default DeleteEventMemberModal

DeleteEventMemberModal.displayName = 'DeleteEventMemberModal'
