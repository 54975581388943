import styled, {css} from 'styled-components'

type StyledStyledInputProps = {
    disabled?: boolean
}

export const StyledLabel = styled.label<StyledStyledInputProps>`
    ${({theme: {palette, typography, spacing}, disabled}) => css`
        ${typography.textS}
        font-weight: 500;
        line-height: 120%; /* 14.4px */
        display: flex;
        gap: ${spacing * 2}px;
        margin-bottom: ${spacing * 2}px;
        color: ${disabled ? palette.gray.disabled : palette.gray.main};
    `}
`

export const StyledInputRoot = styled.div`
    ${({theme: {spacing}}) => css`
        position: relative;
        display: flex;
        gap: ${spacing * 2}px;
        align-items: center;
    `}
`

export const StyledInput = styled.input<StyledStyledInputProps>`
    ${({disabled, theme: {palette}}) => css`
        &[type='radio'] {
            position: absolute;
            opacity: 0;
            &:checked + ${StyledOptionLabel}:before {
                border-color: ${palette.gray.main};
                background-color: ${palette.gray.main};
                box-shadow: inset 0 0 0 2px white;
                ${disabled &&
                css`
                    border-color: ${palette.gray.disabled};
                    background-color: ${palette.gray.disabled};
                    cursor: not-allowed;
                `};
            }
        }
    `}
`

export const StyledOptionLabel = styled.label<StyledStyledInputProps>`
    ${({disabled, theme: {palette, typography, spacing}}) => css`
        ${typography.textS}
        font-weight: 500;
        line-height: normal;
        color: ${palette.gray.main};
        cursor: pointer;
        margin-right: ${spacing * 2}px;

        &:before {
            content: '';
            border-radius: 100%;
            border: 2px solid ${palette.gray.light};
            display: inline-block;
            width: 16px;
            height: 16px;
            position: relative;
            margin-right: ${spacing * 3}px;
            vertical-align: top;
            cursor: pointer;
            text-align: center;
        }

        ${disabled &&
        css`
            color: ${palette.gray.disabled};
            cursor: not-allowed;
            &:before {
                border-color: ${palette.gray.disabled};
                cursor: not-allowed;
            }
        `};

        &:empty {
            &:before {
                margin-right: 0;
            }
        }
    `}
`

export const StyledErrorMessage = styled.small`
    ${({theme: {palette, typography}}) => css`
        ${typography.textXS}
        color: ${palette.red.error};
    `}
`
