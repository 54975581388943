import React, {useRef, useState} from 'react'
import {StyledInfoIcon, StyledTagWrapper} from './style'
import {JobStatus} from '@/types/job'
import {useTranslation} from 'react-i18next'
import {InfoIcon} from '@/assets/icons/icons'
import Tooltip from '../Tooltip'

interface TagProps {
    children: React.ReactNode
    type: JobStatus
    withInfoIcon?: boolean
}

const Tag = ({type, children, withInfoIcon = false}: TagProps) => {
    const {t} = useTranslation()
    const [isStatusTooltipVisible, setIsStatusTooltipVisible] = useState(false)
    const tooltipRef = useRef<HTMLDivElement | null>(null)

    return (
        <StyledTagWrapper type={type}>
            {children}
            {withInfoIcon && (
                <StyledInfoIcon ref={tooltipRef}>
                    <InfoIcon
                        onClick={() => {
                            setIsStatusTooltipVisible(!isStatusTooltipVisible)
                        }}
                    />
                    {isStatusTooltipVisible && (
                        <Tooltip
                            isOpen={isStatusTooltipVisible}
                            onClose={() => setIsStatusTooltipVisible(false)}
                            minWidth={265}
                            tooltipElement={tooltipRef?.current}
                        >
                            {t('video_ingestion:status_tooltip', {
                                context: type
                            })}
                        </Tooltip>
                    )}
                </StyledInfoIcon>
            )}
        </StyledTagWrapper>
    )
}

export default Tag

Tag.displayName = 'Tag'
