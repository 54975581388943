import {MUTATION_KEYS} from '@/queryClient'
import {httpSignupContributor} from '@/services/auth.http'
import {SignupContributorFormData} from '@/types/auth'
import {useMutation} from '@tanstack/react-query'

export const useSignupContributor = () =>
    useMutation({
        mutationKey: [MUTATION_KEYS.SIGNUP_CONTRIBUTOR],
        mutationFn: (params: SignupContributorFormData) => httpSignupContributor(params)
    })
