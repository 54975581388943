import {FC, useState} from 'react'
import {
    StyledActionSection,
    StyledButtonsSection,
    StyledDataSection,
    StyledOverlay,
    StyledRoot,
    StyledTooltip,
    StyledTooltipWrapper,
    StyledTitle,
    StyledTypeSection,
    StyledMainWrapper
} from './style'
import {
    CloseCircleIcon,
    DeleteIcon,
    DotsVerticalIcon,
    EarthIcon,
    LockIcon,
    ShareIcon,
    StarIcon
} from '@/assets/icons/icons'
import {useTranslation} from 'react-i18next'
import Button from '@/components/ui/Button'
import {generatePath, useNavigate} from 'react-router-dom'
import {ROUTE_WORKSPACE_DETAILS} from '@/utilities/constants'
import WorkspaceAbandonmentModal from '../workspace-abandonment-modal/WorkspaceAbandonmentModal'
import {Workspace} from '../../types'
import {useTheme} from 'styled-components'
import WorkspaceDeletionModal from '../workspace-deletion-modal/WorkspaceDeletionModal'

interface WorkspaceCardProps {
    workspace: Workspace
    userId?: number
}

const WorkspaceCard: FC<WorkspaceCardProps> = ({workspace, userId}) => {
    const theme = useTheme()
    const navigate = useNavigate()
    const {t} = useTranslation()
    const {id, usersCount, projectsCount, eventsCount, name, user, isPublic, isDefault, isAnyUserWorkingOnJobs} =
        workspace
    const imOwner = user?.id === userId
    // if there is only one member is the owner
    const hasOtherMembers = usersCount > 1

    const [isWorkspaceAbandonmentModalOpen, setIsWorkspaceAbandonmentModalOpen] = useState(false)
    const [isWorkspaceDeletionModalOpen, setIsWorkspaceDeletionModalOpen] = useState(false)
    const [isDeleteActionTooltipOpen, setDeleteActionTooltipOpen] = useState(false)

    return (
        <StyledRoot>
            <StyledActionSection>
                {isPublic ? (
                    <StyledTypeSection>
                        <EarthIcon />
                        <span>{t('common:shared')}</span>
                    </StyledTypeSection>
                ) : (
                    <StyledTypeSection>
                        <LockIcon />
                        <span>{t('common:personal')}</span>
                    </StyledTypeSection>
                )}

                {imOwner && !isDefault && (
                    <DotsVerticalIcon onClick={() => setDeleteActionTooltipOpen(!isDeleteActionTooltipOpen)} />
                )}

                {isDeleteActionTooltipOpen && (
                    <>
                        <StyledOverlay onClick={() => setDeleteActionTooltipOpen(false)} />
                        <StyledTooltipWrapper>
                            <StyledTooltip
                                onClick={() => {
                                    setIsWorkspaceDeletionModalOpen(true)
                                    setDeleteActionTooltipOpen(false)
                                }}
                            >
                                <DeleteIcon fill={theme.palette.neutral['500']} />
                                {t('common:delete')}
                            </StyledTooltip>
                        </StyledTooltipWrapper>
                    </>
                )}
            </StyledActionSection>

            <StyledMainWrapper>
                <StyledTitle>
                    {isPublic ? <ShareIcon /> : <StarIcon />}
                    <div>
                        <span>{name}</span>
                    </div>
                </StyledTitle>

                <StyledDataSection>
                    {!!isPublic && <span>{t('workspace:members', {COUNT: usersCount ?? 0})}</span>}
                    <span>{t('workspace:videos', {COUNT: projectsCount})}</span>
                    <span>{t('workspace:events', {COUNT: eventsCount})}</span>
                </StyledDataSection>

                <StyledButtonsSection>
                    {hasOtherMembers && !isDefault && !imOwner && (
                        <Button
                            variant={'outline'}
                            size={'md'}
                            onClick={() => setIsWorkspaceAbandonmentModalOpen(true)}
                        >
                            <CloseCircleIcon width={16} height={16} fill={theme.palette.gray.main} />
                            {t('common:leave')}
                        </Button>
                    )}
                    <Button
                        variant={'primary'}
                        size={'md'}
                        onClick={() => navigate(generatePath(ROUTE_WORKSPACE_DETAILS, {workspaceId: `${id}`}))}
                    >
                        {t('common:see_details')}
                    </Button>
                </StyledButtonsSection>
            </StyledMainWrapper>
            {isWorkspaceAbandonmentModalOpen && (
                <WorkspaceAbandonmentModal
                    onClose={() => setIsWorkspaceAbandonmentModalOpen(false)}
                    workspaceData={workspace}
                    imOwner={imOwner}
                    hasOtherMembers={hasOtherMembers}
                    hasPendingRequest={isAnyUserWorkingOnJobs}
                    userId={userId ?? 0}
                />
            )}
            {isWorkspaceDeletionModalOpen && (
                <WorkspaceDeletionModal
                    onClose={() => setIsWorkspaceDeletionModalOpen(false)}
                    workspaceData={workspace}
                    hasOtherMembers={hasOtherMembers}
                    hasPendingRequest={isAnyUserWorkingOnJobs}
                />
            )}
        </StyledRoot>
    )
}

WorkspaceCard.displayName = 'WorkspaceCard'
export default WorkspaceCard
