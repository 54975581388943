import {Event} from '@/types/event'
import {StyledCardWrapper, StyledImage, StyledName, StyledVideosCount} from './style'
import {ROUTE_EVENT_DETAILS} from '@/utilities/constants'
import {useTranslation} from 'react-i18next'
import {generatePath, useNavigate} from 'react-router-dom'
import hero_events from '@assets/images/background/hero_events.png'
const PreviewEventCard = ({event}: {event: Event}) => {
    const {t} = useTranslation()
    const {id, name, avatar, jobsCount} = event
    const navigate = useNavigate()

    return (
        <StyledCardWrapper
            onClick={() => {
                navigate(generatePath(ROUTE_EVENT_DETAILS, {eventId: `${id}`}))
            }}
        >
            <StyledImage src={avatar ?? hero_events} alt={name} />
            <StyledName>{name}</StyledName>
            <StyledVideosCount>{t('event_page:video', {VIDEO_COUNT: jobsCount ?? 0})}</StyledVideosCount>
        </StyledCardWrapper>
    )
}

export default PreviewEventCard

PreviewEventCard.displayName = 'PreviewEventCard'
