import React, {useEffect, useState} from 'react'
import {
    StyledEventWrapper,
    StyledEventLabel,
    StyledSectionWrapper,
    StyledActionsWrapper,
    StyledChooseVideoSourceWrapper,
    StyledTitle,
    StyledDescription,
    StyledVideoSourceCard,
    StyledVideoSourceTitle,
    StyledVideoSourceDescription,
    StyledVideoSourceAction,
    StyledVideoSourceCardsWrapper,
    StyledErrorMessage
} from './style'
import Label from '@/components/ui/Label'
import Button from '@/components/ui/Button'
import {PlusIcon, RefreshIcon, DeleteIcon, CheckCircleIcon, InfoIcon} from '@/assets/icons/icons'
import Input from '@/components/ui/Input'
import {useTranslation} from 'react-i18next'
import {useForm} from 'react-hook-form'
import {VIDEO_MODEL, VideoInfoSchema} from '@/features/videos/services/VideoCreationFormModel'
import {VideoInfoValidationSchema} from '@features/videos/services/VideoCreationFormModel'
import {zodResolver} from '@hookform/resolvers/zod/src/zod.js'
import {VideoEventForm, VideoInfoForm, VideoUrlForm} from '@/features/videos/types/video'
import AddEventModal from '../add-event-modal/AddEventModal'
import {useTheme} from 'styled-components'
import {ValidationError, handleApiError} from '@/utilities/helpers'
import AddVideoModal from '../add-video-modal/AddVideoModal'
import {USER_SIGNUP_SOURCE} from '@/utilities/constants'
import {Language} from '@/types/commons'
import VideoInfoModal from '../video-info-modal/VideoInfoModal'

interface InfoStepProps {
    onSubmit: (values: VideoInfoForm) => void
    error: Error | null
    isError: boolean
    isEuropeanaUser: boolean
    setIsEuropeanaSource: React.Dispatch<React.SetStateAction<boolean>>
    setEuropeanaLanguage: React.Dispatch<React.SetStateAction<Language | null>>
}

const InfoStep: React.FC<InfoStepProps> = ({
    onSubmit,
    isError,
    error,
    isEuropeanaUser,
    setIsEuropeanaSource,
    setEuropeanaLanguage
}: InfoStepProps) => {
    const {t} = useTranslation()
    const theme = useTheme()
    const [isEventModalVisible, setIsEventModalVisible] = useState(false)
    const [source, setSource] = useState<string | undefined>(undefined)
    const [isAddVideoModalOpen, setIsAddVideoModalOpen] = useState(false)
    const isEuropeanaSource = source === USER_SIGNUP_SOURCE.europeana
    const [isVideoInfoModalVisible, setIsVideoInfoModalVisible] = useState(false)

    useEffect(() => {
        if (isError) handleApiError({setError, error: error as ValidationError<'event'>})
    }, [isError])

    useEffect(() => {
        setIsEuropeanaSource(isEuropeanaSource)
    }, [source])

    const {
        register,
        handleSubmit,
        formState: {errors, isValid},
        setValue,
        getValues,
        resetField,
        setError
    } = useForm<VideoInfoValidationSchema>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(VideoInfoSchema)
    })

    // add event Modal
    const onClickAddEvent = () => setIsEventModalVisible(true)
    const onCancelAddEventModal = () => setIsEventModalVisible(false)

    // Add video Modal
    const onCancelAddVideo = () => {
        setIsAddVideoModalOpen(false)
    }

    const onSubmitAddEventModal = (data: VideoEventForm) => {
        setIsEventModalVisible(false)
        register(VIDEO_MODEL.Event)
        setValue(VIDEO_MODEL.Event, {value: parseInt(`${data.event?.value}`), label: data.event?.label ?? ''})
    }

    // Add video URL
    const onSubmitAddVideo = (data: VideoUrlForm) => {
        const name = isEuropeanaSource ? data?.europeanaVideo?.label : data?.name
        const description = isEuropeanaSource ? data?.europeanaVideo?.description : data?.description
        const url = isEuropeanaSource ? data?.europeanaVideo?.url : data?.url
        const language = isEuropeanaSource ? data?.europeanaVideo?.language : null
        const thumbnailUrl =
            isEuropeanaSource && data?.europeanaVideo?.thumbnailUrl ? data?.europeanaVideo?.thumbnailUrl : undefined
        const europeanaRightsUrl =
            isEuropeanaSource && data?.europeanaVideo?.europeanaRightsUrl
                ? data?.europeanaVideo?.europeanaRightsUrl
                : undefined
        const europeanaProjectId =
            isEuropeanaSource && data?.europeanaVideo?.europeanaProjectId
                ? data?.europeanaVideo?.europeanaProjectId
                : undefined
        const europeanaPlatformSourceUrl =
            isEuropeanaSource && data?.europeanaVideo?.europeanaPlatformSourceUrl
                ? data?.europeanaVideo?.europeanaPlatformSourceUrl
                : undefined
        setIsAddVideoModalOpen(false)

        setValue(VIDEO_MODEL.ThumbnailUrl, thumbnailUrl ?? '', {shouldValidate: true})
        setValue(VIDEO_MODEL.europeanaRightsUrl, europeanaRightsUrl ?? '', {shouldValidate: true})
        setValue(VIDEO_MODEL.europeanaProjectId, europeanaProjectId ?? '', {shouldValidate: true})
        setValue(VIDEO_MODEL.europeanaPlatformSourceUrl, europeanaPlatformSourceUrl ?? '', {shouldValidate: true})
        setValue(VIDEO_MODEL.Name, name ?? '', {shouldValidate: true})
        setValue(VIDEO_MODEL.Description, description ?? '', {shouldValidate: true})
        setValue(VIDEO_MODEL.Url, url ?? '', {shouldValidate: true})
        setValue(VIDEO_MODEL.language, language ?? '', {shouldValidate: true})
        setEuropeanaLanguage(language ?? null)
    }

    const onClickDeleteEvent = () => {
        register(VIDEO_MODEL.Event)
        resetField(VIDEO_MODEL.Event)
    }

    let eventLabel = getValues()?.[VIDEO_MODEL.Event]?.label
    const videoName = getValues()?.[VIDEO_MODEL.Name]

    const isEuropeanaVideoSelected = source === USER_SIGNUP_SOURCE.europeana && videoName && !isAddVideoModalOpen
    const isProprietaryVideoSelected = source === USER_SIGNUP_SOURCE.proprietary && videoName && !isAddVideoModalOpen

    if (eventLabel && eventLabel.length > 15) eventLabel = `${eventLabel.substring(0, 15)}...`

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                {/* event section */}
                <StyledSectionWrapper>
                    <StyledEventWrapper>
                        <Label>{t('video_creation:event')}</Label>
                        <StyledEventLabel>{eventLabel ?? t('video_creation:none')}</StyledEventLabel>
                        {getValues()?.[VIDEO_MODEL.Event]?.value ? (
                            <>
                                <Button size="sm" variant="transparent" onClick={onClickAddEvent}>
                                    <RefreshIcon fill={theme.palette.neutral['900']} width={10} height={10} />
                                    {t('video_creation:change_event')}
                                </Button>
                                <Button size="sm" variant="transparent" onClick={onClickDeleteEvent}>
                                    <DeleteIcon fill={theme.palette.neutral['900']} width={10} height={10} />
                                    {t('video_creation:remove_event')}
                                </Button>
                            </>
                        ) : (
                            <Button size="sm" variant="transparent" onClick={onClickAddEvent}>
                                <PlusIcon fill={theme.palette.neutral['900']} width={10} height={10} />
                                {t('video_creation:add_to_event')}
                            </Button>
                        )}
                    </StyledEventWrapper>
                </StyledSectionWrapper>

                {isEuropeanaUser ? (
                    <StyledSectionWrapper>
                        <StyledChooseVideoSourceWrapper>
                            <StyledTitle> {t('video_creation:chose_source_title')}</StyledTitle>
                            <StyledDescription>{t('video_creation:chose_source_description')}</StyledDescription>
                            <StyledVideoSourceCardsWrapper>
                                <StyledVideoSourceCard
                                    style={{
                                        backgroundColor: isProprietaryVideoSelected ? '#dcffcd' : '#ffffff'
                                    }}
                                >
                                    {isProprietaryVideoSelected && <CheckCircleIcon width={25} height={25} />}
                                    <StyledVideoSourceTitle>
                                        {t('video_creation:proprietary_video_tile')}
                                    </StyledVideoSourceTitle>
                                    <StyledVideoSourceDescription>
                                        {isProprietaryVideoSelected
                                            ? videoName
                                            : t('video_creation:proprietary_video_description')}
                                    </StyledVideoSourceDescription>
                                    <StyledVideoSourceAction>
                                        <Button
                                            style={{
                                                backgroundColor: isProprietaryVideoSelected ? 'transparent' : '#3d63ae'
                                            }}
                                            size="md"
                                            variant={isProprietaryVideoSelected ? 'outline' : 'primary'}
                                            onClick={() => {
                                                setSource(USER_SIGNUP_SOURCE.proprietary)
                                                setIsAddVideoModalOpen(true)
                                            }}
                                        >
                                            {isProprietaryVideoSelected
                                                ? t('video_creation:change_video')
                                                : t('video_creation:proprietary_video_button')}
                                        </Button>
                                    </StyledVideoSourceAction>
                                </StyledVideoSourceCard>
                                <StyledVideoSourceCard
                                    style={{
                                        backgroundColor: isEuropeanaVideoSelected ? '#dcffcd' : '#ffffff'
                                    }}
                                >
                                    {isEuropeanaVideoSelected && <CheckCircleIcon width={25} height={25} />}
                                    <StyledVideoSourceTitle>
                                        {t('video_creation:europeana_video_tile')}
                                    </StyledVideoSourceTitle>
                                    <StyledVideoSourceDescription>
                                        {isEuropeanaVideoSelected
                                            ? videoName
                                            : t('video_creation:europeana_video_description')}
                                    </StyledVideoSourceDescription>
                                    <StyledVideoSourceAction>
                                        <Button
                                            style={{
                                                backgroundColor: isEuropeanaVideoSelected ? 'transparent' : '#ec7b5f'
                                            }}
                                            size="md"
                                            variant={isEuropeanaVideoSelected ? 'outline' : 'primary'}
                                            onClick={() => {
                                                setSource(USER_SIGNUP_SOURCE.europeana)
                                                setIsAddVideoModalOpen(true)
                                            }}
                                        >
                                            {isEuropeanaVideoSelected
                                                ? t('video_creation:change_video')
                                                : t('video_creation:europeana_video_button')}
                                        </Button>
                                    </StyledVideoSourceAction>
                                </StyledVideoSourceCard>
                            </StyledVideoSourceCardsWrapper>
                            {errors[VIDEO_MODEL.Url]?.message && (
                                <StyledErrorMessage>{t('common:invalidUrl')}</StyledErrorMessage>
                            )}
                        </StyledChooseVideoSourceWrapper>
                    </StyledSectionWrapper>
                ) : (
                    <>
                        <StyledSectionWrapper>
                            <Input
                                {...register(VIDEO_MODEL.Name)}
                                label={`${t('video_creation:name')}*`}
                                type="text"
                                errorMessage={`${t(errors[VIDEO_MODEL.Name]?.message || '')}`}
                            />
                        </StyledSectionWrapper>
                        <StyledSectionWrapper>
                            <Input
                                {...register(VIDEO_MODEL.Description)}
                                label={`${t('video_creation:description')}*`}
                                type="text"
                                errorMessage={`${t(errors[VIDEO_MODEL.Description]?.message || '')}`}
                            />
                        </StyledSectionWrapper>
                        <StyledSectionWrapper>
                            <Input
                                icon={<InfoIcon fill={theme.palette.neutral[800]} />}
                                iconCallback={() => setIsVideoInfoModalVisible(true)}
                                {...register(VIDEO_MODEL.Url)}
                                label={`${t('video_creation:url')}*`}
                                type="text"
                                errorMessage={`${t(errors[VIDEO_MODEL.Url]?.message || '')}`}
                            />
                        </StyledSectionWrapper>
                    </>
                )}
                <StyledSectionWrapper>
                    <StyledActionsWrapper>
                        <Button type="submit" size="sm" disabled={!isValid}>
                            {t('video_creation:languages')}
                        </Button>
                    </StyledActionsWrapper>
                </StyledSectionWrapper>
            </form>
            <AddEventModal
                onCancel={onCancelAddEventModal}
                onSubmit={onSubmitAddEventModal}
                isVisible={isEventModalVisible}
            />
            {isAddVideoModalOpen && (
                <AddVideoModal onClose={onCancelAddVideo} onSubmit={onSubmitAddVideo} source={source} />
            )}
            {isVideoInfoModalVisible && <VideoInfoModal onClose={() => setIsVideoInfoModalVisible(false)} />}
        </>
    )
}

export default InfoStep

InfoStep.displayName = 'InfoStep'
