import Container from '@/components/ui/Container'
import {useTranslation} from 'react-i18next'
import {FC, useState} from 'react'
import Spinner from '@/components/ui/Spinner'
import {useNavigate} from 'react-router-dom'
import {StyledRoot, StyledSubTitle, StyledTitle} from './style'
import StatusModal from '@/components/commons/StatusModal'
import {ROUTE_LOGIN} from '@/utilities/constants'
import Form from '@/features/password-reset/components/form/Form'

const PasswordReset: FC = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false)
    const [isOpenErrorModal, setIsOpenErrorModal] = useState(false)
    const isOpenStatusModal = isOpenSuccessModal || isOpenErrorModal

    return (
        <>
            {isLoading && <Spinner size={48} />}
            <Container>
                <StyledRoot>
                    <StyledTitle>{t('password_reset:title')}</StyledTitle>
                    <StyledSubTitle>{t('password_reset:subtitle')}</StyledSubTitle>
                    <Form
                        setIsLoading={setIsLoading}
                        setIsOpenErrorModal={setIsOpenErrorModal}
                        setIsOpenSuccessModal={setIsOpenSuccessModal}
                    />
                </StyledRoot>
                {isOpenStatusModal && (
                    <StatusModal
                        overlay
                        variant={isOpenSuccessModal ? 'success' : 'error'}
                        title={isOpenSuccessModal ? t('change_password:success_modal_title') : t('common:error')}
                        onClose={() => navigate(ROUTE_LOGIN)}
                    >
                        <div>
                            {isOpenSuccessModal
                                ? t('change_password:success_modal_text')
                                : t('email_activation:subtitle_failure')}
                        </div>
                    </StatusModal>
                )}
            </Container>
        </>
    )
}

export default PasswordReset
PasswordReset.displayName = 'PasswordReset'
