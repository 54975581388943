import styled, {DefaultTheme, css} from 'styled-components'

export const StyledRoot = styled.div`
    ${({theme: {mediaQueries, spacing}}) => css`
        margin: 0;
        ${mediaQueries.xl} {
            margin-top: ${spacing * 12}px;
        }
    `}
`
export const StyledBreadcrumbs = styled.div`
    ${({theme: {palette, typography}}) => css`
        ${typography.textS};
        color: ${palette.gray.main};
    `}
`

export const StyledCrumb = styled.span`
    ${({theme: {palette, truncateText}, isLast}: {theme: DefaultTheme; isLast: boolean}) => css`
        ${isLast &&
        css`
            color: ${palette.gray.light};
        `}

        ${truncateText};
        max-width: 250px;
        display: inline-block;
        vertical-align: middle;
    `}
`

export const StyledWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        margin-bottom: ${spacing * 9}px;
    `}
`

export const StyledBackButton = styled.div`
    ${({theme: {palette, spacing, typography}}) => css`
        display: grid;
        grid-template-columns: 0fr 1fr;
        align-items: center;
        gap: ${spacing * 2}px;
        color: ${palette.gray.main};
        font-weight: 700;
        ${typography.textXL};
        cursor: pointer;
        margin-right: ${spacing * 10}px;

        & svg {
            transform: rotate(90deg);
        }
    `}
`
