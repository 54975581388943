import styled, {css} from 'styled-components'

export const StyledCompletedRoot = styled.div`
    ${({theme: {spacing, palette, typography}}) => css`
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: ${spacing}px;

        & div {
            color: ${palette.status.completed.text};
            text-align: center;
            font-weight: 700;
            ${typography.textXL};
        }

        & p {
            color: ${palette.gray.main};
            ${typography.textS};
        }

        & span {
            font-weight: 700;
        }
    `}
`
