import React, {forwardRef, InputHTMLAttributes} from 'react'

import {StyledInput, StyledLabel, StyledInputRoot, StyledOptionLabel, StyledErrorMessage} from './style'
import {ValueLabel} from '@/types/commons'
import HoverTooltip from '../HoverTooltip'
import {InfoCircleIcon} from '@/assets/icons/icons'
import {useTheme} from 'styled-components'
import {DefaultTFuncReturn} from 'i18next'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string
    name?: string
    options: ValueLabel[]
    errorMessage?: string
    helperText?: DefaultTFuncReturn
}

const Radio = forwardRef<HTMLInputElement, InputProps>(
    ({label, name, options, errorMessage, disabled, id, helperText, ...rest}, ref) => {
        const theme = useTheme()
        return (
            <>
                <StyledLabel htmlFor={id} disabled={disabled}>
                    <span>{label}</span>
                    {helperText && (
                        <HoverTooltip text={helperText}>
                            <InfoCircleIcon width={15} height={15} stroke={theme.palette.neutral[900]} />
                        </HoverTooltip>
                    )}
                </StyledLabel>
                <StyledInputRoot>
                    {options.map(item => (
                        <React.Fragment key={`${item.value}`}>
                            <StyledInput
                                type="radio"
                                id={`${item.value}`}
                                value={item.value}
                                name={name}
                                disabled={disabled}
                                ref={ref}
                                {...rest}
                            ></StyledInput>
                            <StyledOptionLabel htmlFor={`${item.value}`} disabled={disabled}>
                                {item.label}
                            </StyledOptionLabel>
                        </React.Fragment>
                    ))}
                </StyledInputRoot>
                {!!errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
            </>
        )
    }
)

export default Radio
