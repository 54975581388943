import {useTranslation} from 'react-i18next'
import {StyledSimilarVideosRoot} from './style'
import PreviewVideosList from '../../preview-videos-list/PreviewVideosList'
import {VideoType} from '@/types/video'
import ViewAllSection from '@/features/contributors/components/view-all-section/ViewAllSection'
import {useState} from 'react'

const SimilarVideos = ({similarVideosList = []}: {similarVideosList?: VideoType[]}) => {
    const {t} = useTranslation()
    const [videosListToShow, setVideosListToShow] = useState<VideoType[]>([])

    return (
        <StyledSimilarVideosRoot>
            <ViewAllSection
                title={t('video_details_page:similar_videos:title')}
                initialArray={similarVideosList}
                onViewAllCallback={setVideosListToShow}
            />
            <div>
                <PreviewVideosList videos={videosListToShow} />
            </div>
        </StyledSimilarVideosRoot>
    )
}

export default SimilarVideos

SimilarVideos.displayName = 'SimilarVideos'
