import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {z} from 'zod'
import {ValidationError, checkInputRemoveValue, handleApiError} from '@utilities/helpers.ts'
import WorkspaceActionModal from '@/components/commons/WorkspaceActionModal/WorkspaceActionModal.tsx'
import {useRemoveMembers} from '../../queries/useRemoveMembers'

const enum UNASSIGN_MODEL {
    Type = 'type'
}

const WorkspaceUnassignSchema = z.object({
    [UNASSIGN_MODEL.Type]: z
        .string()
        .min(1, {message: 'validation:required_field'})
        .refine(value => checkInputRemoveValue(value), {message: 'validation:invalid_value'})
        .default('')
})

type ValidationSchema = z.infer<typeof WorkspaceUnassignSchema>

export interface WorkspaceUnassignModalProps {
    onClose: () => void
    workspaceId?: number
    selectedMembers: number[]
    onSuccess: () => void
}

const WorkspaceUnassignModal: React.FC<WorkspaceUnassignModalProps> = ({
    onClose,
    workspaceId,
    selectedMembers,
    onSuccess
}) => {
    const {mutate, isPending, isError, error} = useRemoveMembers({workspaceId: `${workspaceId}`, onSuccess})

    const {
        register,
        handleSubmit,
        formState: {errors, isValid, isDirty}
    } = useForm<ValidationSchema>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(WorkspaceUnassignSchema)
    })
    useEffect(() => {
        if (isError) handleApiError({error: error as ValidationError<string>})
    }, [isError])

    const onSubmit = () => {
        if (workspaceId) {
            mutate({userIds: selectedMembers})
        }
    }

    return (
        <WorkspaceActionModal
            onClose={onClose}
            title={'workspace:workspace_unassign_modal:title'}
            description={'workspace:workspace_unassign_modal:description'}
            errorMessage={errors.type?.message || ''}
            handleSubmit={handleSubmit(onSubmit)}
            isConfirmButtonDisabled={!isValid || !isDirty || isPending}
            isSubmitting={isPending}
            {...register(UNASSIGN_MODEL.Type)}
        />
    )
}

export default WorkspaceUnassignModal

WorkspaceUnassignModal.displayName = 'WorkspaceUnassignModal'
