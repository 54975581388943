import {z} from 'zod'
import {dropboxUrlRegex, googleUrlRegex} from '../utilities/constants'

export const enum VIDEO_MODEL {
    Event = 'event',
    Name = 'name',
    Description = 'description',
    Url = 'url',
    EuropeanaVideo = 'europeanaVideo',
    SourceLanguageId = 'sourceLanguageId',
    TargetLanguagesIds = 'targetLanguagesIds',
    ThumbnailUrl = 'thumbnailUrl',
    europeanaRightsUrl = 'europeanaRightsUrl',
    europeanaProjectId = 'europeanaProjectId',
    europeanaPlatformSourceUrl = 'europeanaPlatformSourceUrl',
    language = 'language'
}

const videoExtensions = ['mp4', 'mov', 'wmv', 'avi']

const validateGoogleDriveLink = (url: string): boolean => {
    return googleUrlRegex.test(url)
}

const validateDropboxLink = (url: string): boolean => {
    return dropboxUrlRegex.test(url)
}

const urlExtensionValidator = (url: string) => {
    try {
        const parsedUrl = new URL(url)
        if (validateGoogleDriveLink(url)) return true
        if (validateDropboxLink(url)) return true
        for (let i = 0; i < videoExtensions.length; i++) {
            if (parsedUrl.pathname.endsWith(videoExtensions[i])) return true
        }
        return false
    } catch (e) {
        return false
    }
}

export const VideoEventSchema = z.object({
    [VIDEO_MODEL.Event]: z.object({
        value: z.number(),
        label: z.string()
    })
})

export type VideoEventValidationSchema = z.infer<typeof VideoEventSchema>

export const VideoUrlSchema = z.object({
    [VIDEO_MODEL.Name]: z.string().min(1, {message: 'validation:required'}),
    [VIDEO_MODEL.Description]: z.string().min(1, {message: 'validation:required'}),
    [VIDEO_MODEL.Url]: z.string().url().min(6, {message: 'validation:url'}).default('').refine(urlExtensionValidator, {
        message: 'validation:video'
    })
})

export type VideoUrlValidationSchema = z.infer<typeof VideoUrlSchema>

export const EuropeanaVideoSchema = z.object({
    [VIDEO_MODEL.EuropeanaVideo]: z.object({
        value: z.string(),
        label: z.string(),
        url: z.string(),
        thumbnailUrl: z.string(),
        europeanaRightsUrl: z.string(),
        europeanaProjectId: z.string(),
        europeanaPlatformSourceUrl: z.string(),
        description: z.string().optional(),
        language: z.any().optional()
    })
})

export type EuropeanaVideoValidationSchema = z.infer<typeof VideoUrlSchema>

export const VideoInfoSchema = z.object({
    [VIDEO_MODEL.Event]: z
        .object({
            value: z.number(),
            label: z.string()
        })
        .optional(),
    [VIDEO_MODEL.Name]: z.string().min(1, {message: 'validation:required'}),
    [VIDEO_MODEL.Description]: z.string().min(1, {message: 'validation:required'}),
    [VIDEO_MODEL.Url]: z.string().url().min(6, {message: 'validation:url'}).default('').refine(urlExtensionValidator, {
        message: 'validation:video'
    }),
    [VIDEO_MODEL.ThumbnailUrl]: z.string().optional(),
    [VIDEO_MODEL.europeanaRightsUrl]: z.string().optional(),
    [VIDEO_MODEL.europeanaProjectId]: z.string().optional(),
    [VIDEO_MODEL.europeanaPlatformSourceUrl]: z.string().optional(),
    [VIDEO_MODEL.language]: z.any().optional()
})

export type VideoInfoValidationSchema = z.infer<typeof VideoInfoSchema>

export const VideoLanguagesSchema = z.object({
    [VIDEO_MODEL.SourceLanguageId]: z.object({
        value: z.number(),
        label: z.string()
    }),
    [VIDEO_MODEL.TargetLanguagesIds]: z
        .object({
            value: z.number(),
            label: z.string()
        })
        .array()
        .min(1)
        .default([])
})

export type VideoLanguagesValidationSchema = z.infer<typeof VideoLanguagesSchema>
