import {useMutation} from '@tanstack/react-query'
import {ResetPasswordData} from '@/types/auth.ts'
import {httpRecoveryPassword} from '@/services/auth.http'
import {MUTATION_KEYS} from '@/queryClient'

export const useRecoveryPassword = () =>
    useMutation({
        mutationKey: [MUTATION_KEYS.RECOVERY_PASSWORD],
        mutationFn: (params: ResetPasswordData) => httpRecoveryPassword(params)
    })
