import styled, {css} from 'styled-components'

export const StyledVideoCardRoot = styled.div`
    display: flex;
    flex-direction: column;
`

export const StyledBottomDivider = styled.div`
    ${({theme: {palette}}) => css`
        border-top: 1px solid ${palette.gray.xlight};
        margin-bottom: 0px;
    `}
`

export const StyledCardWrapper = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        gap: ${spacing * 5}px;
        padding: ${spacing * 7}px 0;

        ${mediaQueries.m} {
            padding: ${spacing * 7}px;
        }
    `}
`

export const StyledInfoWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        gap: ${spacing * 3}px;
    `}
`

export const StyledName = styled.div`
    ${({theme: {typography, palette}}) => css`
        ${typography.textL};
        color: ${palette.gray.main};
        font-weight: 500;
    `}
`

export const StyledLanguage = styled.div`
    ${({theme: {typography}}) => css`
        ${typography.textM}
    `}
`

export const StyledThumbnailWrapper = styled.div`
    ${({theme: {typography, spacing, palette, mediaQueries}}) => css`
        position: relative;
        height: 137px;
        & span {
            ${typography.textS}
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: ${palette.neutral['900']};
            border-top-left-radius: 10px;
            color: ${palette.neutral[100]};
            padding: ${spacing * 2}px ${spacing * 3}px;
            opacity: 0.75;
        }
        ${mediaQueries.m} {
            height: 170px;
            max-width: 270px;
        }
    `}
`

export const StyledImage = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
`
