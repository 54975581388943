import {QUERY_KEYS} from '@/queryClient'
import {useInfiniteQuery} from '@tanstack/react-query'
import {httpGetEventsList} from '../services/events.http'

export const useGetEventsList = ({limit, status}: {limit?: number; status?: string}) =>
    useInfiniteQuery({
        queryKey: [QUERY_KEYS.EVENTS, limit, status],
        queryFn: ({pageParam}) => httpGetEventsList({limit, status, pageParam}),
        initialPageParam: '',
        getNextPageParam: lastPage => lastPage.meta.next_page_url
    })
