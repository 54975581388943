import {MUTATION_KEYS} from '@/queryClient'
import {httpSignupProvider} from '@/services/auth.http'
import {SignupProviderFormData} from '@/types/auth'
import {useMutation} from '@tanstack/react-query'

export const useSignupProvider = () =>
    useMutation({
        mutationKey: [MUTATION_KEYS.SIGNUP_PROVIDER],
        mutationFn: (params: SignupProviderFormData) => httpSignupProvider(params)
    })
