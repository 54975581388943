import React from 'react'
import {StyledRoot, StyledTabSection} from './style'
import ProfileVideosList from '../profile-videos-list/ProfileVideosList'
import {VIDEO_STATUS} from '@/utilities/constants/video'
import TabSection from '@/features/events/components/tab-section/TabSection'
import {useTranslation} from 'react-i18next'
import {useSearchParams} from 'react-router-dom'

const ProfileVideosHistory: React.FC = () => {
    const {t} = useTranslation()
    const [searchParams, setSearchParams] = useSearchParams()
    const activeTabId = searchParams.get('tab')

    const setActiveTabId = (tab: string) => {
        setSearchParams(params => {
            params.set('tab', tab)
            return params
        })
    }

    const VIDEOS_HISTORY_TABS = [
        {
            id: 'assigned',
            name: t('contributor_my_profile:videos:title_assigned')
        },
        {
            id: 'ready',
            name: t('contributor_my_profile:videos:title_ready_for_audit')
        },
        {
            id: 'completed',
            name: t('contributor_my_profile:videos:title_completed')
        }
    ]

    const videosHistoryTabContent = {
        [VIDEOS_HISTORY_TABS[0].id]: (
            <ProfileVideosList title="contributor_my_profile:videos:title_assigned" status={VIDEO_STATUS.Assigned} />
        ),
        [VIDEOS_HISTORY_TABS[1].id]: (
            <ProfileVideosList
                title="contributor_my_profile:videos:title_ready_for_audit"
                status={VIDEO_STATUS.ReadyForAudit}
            />
        ),
        [VIDEOS_HISTORY_TABS[2].id]: (
            <ProfileVideosList title="contributor_my_profile:videos:title_completed" status={VIDEO_STATUS.Completed} />
        )
    }

    return (
        <StyledRoot>
            <StyledTabSection>
                <TabSection
                    tabsList={VIDEOS_HISTORY_TABS}
                    activeTabId={activeTabId ?? VIDEOS_HISTORY_TABS[0].id}
                    setActiveTabId={setActiveTabId}
                />
            </StyledTabSection>
            {videosHistoryTabContent[activeTabId ?? VIDEOS_HISTORY_TABS[0].id]}
        </StyledRoot>
    )
}

export default ProfileVideosHistory

ProfileVideosHistory.displayName = 'ProfileVideosHistory'
