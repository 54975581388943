import styled, {css, DefaultTheme} from 'styled-components'

export const StyledMenuWrapper = styled.div`
    ${({theme: {spacing}}: {theme: DefaultTheme}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 3}px;
        padding: ${spacing * 3}px;
    `}
`

export const StyledMenuItem = styled.div`
    ${({theme: {palette, typography, spacing}}: {theme: DefaultTheme}) => css`
        ${typography.textS}
        display: grid;
        grid-template-columns: 0fr auto;
        gap: ${spacing * 2}px;
        align-items: center;
        cursor: pointer;
        color: ${palette.gray.main};
        font-weight: 500;
        transition: color 0.3s ease;
        padding: 0px ${spacing * 3}px;

        &:hover {
            color: ${palette.blue.main};
        }

        & > svg {
            width: 32px;
        }
        & > span {
            white-space: nowrap;
        }
    `}
`

export const StyledDivider = styled.div`
    ${({theme: {palette}}) => css`
        border-top: 0.5px solid ${palette.gray.disabled};
    `}
`
