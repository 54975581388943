import styled, {css} from 'styled-components'

export const StyledMainWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        margin-bottom: ${spacing * 6}px;
        border-radius: 16px;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-width: 220px;
    `}
`

export const StyledContentWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        padding: ${spacing * 5}px;
        display: flex;
        flex-direction: column;
    `}
`

export const StyledHeaderWrapper = styled.div`
    ${() => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
    `}
`

export const StyledVisibilityWrapper = styled.div`
    ${({theme: {spacing, palette, typography}}) => css`
        display: flex;
        gap: ${spacing * 2}px;
        align-items: center;
        color: ${palette.primary['900']};
        ${typography.textM}
    `}
`

export const StyledName = styled.span`
    ${({theme: {spacing, typography, palette, truncateText}}) => css`
        ${typography.textXL};
        font-weight: 700;
        padding-bottom: ${spacing * 2}px;
        color: ${palette.blue.main};
        ${truncateText};
        margin: ${spacing * 3}px 0;
        display: block;
    `}
`

export const StyledVideosCount = styled.span`
    ${({theme: {spacing, typography, palette}}) => css`
        ${typography.textM};
        padding-bottom: ${spacing * 2}px;
        color: ${palette.blue.main};
        display: block;
    `}
`

export const StyledDescription = styled.span`
    ${({theme: {spacing, typography, palette, truncateText}}) => css`
        ${typography.textS};
        padding-bottom: ${spacing * 2}px;
        color: ${palette.neutral['700']};
        ${truncateText}
    `}
`

export const StyledValueLabelWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing * 2}px;
    `}
`

export const StyledLabel = styled.span`
    ${({theme: {palette, spacing, typography}}) => css`
        ${typography.textM}
        display: block;
        margin-bottom: ${spacing * 2}px;
        color: ${palette.neutral['900']};
        font-weight: 500;
    `}
`

export const StyledValue = styled.span`
    ${({theme: {palette, spacing, typography}}) => css`
        ${typography.textM}
        display: block;
        margin-bottom: ${spacing * 2}px;
        color: ${palette.neutral['500']};
    `}
`

export const StyledFooterWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: ${spacing * 5}px;
    `}
`

export const StyledEventTagWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: auto 0fr;
        align-items: center;
        gap: ${spacing * 2}px;
    `}
`

export const StyledDropdownWrapper = styled.div`
    margin-right: -10px;
`
