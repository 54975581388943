import {useMutation, useQueryClient} from '@tanstack/react-query'
import {httpRemoveMembers} from '../services/workspaces.http'
import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'

type UseRemoveMembersProps = {
    workspaceId: string
    onSuccess?: () => void
}

export const useRemoveMembers = ({workspaceId, onSuccess}: UseRemoveMembersProps) => {
    const client = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.REMOVE_MEMBERS],
        mutationFn: ({userIds}: {userIds: number[]}) => httpRemoveMembers({workspaceId, ids: userIds}),
        onSuccess: () => {
            typeof onSuccess === 'function' && onSuccess()
            client.invalidateQueries({queryKey: [QUERY_KEYS.MEMBERS, workspaceId]})
            client.invalidateQueries({queryKey: [QUERY_KEYS.WORKSPACES_LIST]})
        }
    })
}
