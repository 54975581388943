import styled, {DefaultTheme, css} from 'styled-components'

export const StyledRoot = styled.div`
    height: 100%;
    overflow: auto;
`

export const StyledWrapper = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        margin-top: ${spacing * 14}px;
        ${mediaQueries.l} {
            margin: ${spacing * 28}px 0 ${spacing * 14}px 0;
        }
    `}
`

export const StyledSection = styled.div`
    ${({theme: {spacing, typography, palette}, isStepSection}: {theme: DefaultTheme; isStepSection: boolean}) => css`
        margin-bottom: ${spacing * 16}px;
        color: ${palette.gray.middle};
        ${typography.textL}
        line-height: 27px;
        ${isStepSection &&
        css`
            margin-bottom: ${spacing * 12}px;
        `}
        & ul {
            margin-left: ${spacing * 4}px;
            padding-left: ${spacing * 2}px;
            list-style: disc;
            line-height: normal;
        }
        & h3 {
            color: ${palette.gray.main};
            ${typography.text1XL}
            font-weight: 700;
            margin-bottom: ${spacing * 6}px;
        }
    `}
`
export const StyledVideoTitle = styled.div`
    ${({theme: {spacing, palette}}) => css`
        color: ${palette.gray.main};
        font-weight: 700;
        margin: ${spacing * 11}px 0 ${spacing * 6}px 0;
    `}
`
