import styled, {DefaultTheme, css} from 'styled-components'

export const StyledTabSectionWrapper = styled.div`
    ${({theme: {palette, typography, spacing}}) => css`
        display: flex;
        align-items: center;
        color: ${palette.gray.main};
        text-align: center;
        ${typography.textL};
        background-color: ${palette.gray.xlight};
        border-radius: 48px;
        font-weight: 700;
        padding: ${spacing}px;
    `}
`
export const StyledTabItem = styled.div`
    ${({theme: {spacing, palette}, isActive}: {theme: DefaultTheme; isActive: boolean}) => css`
        padding: ${spacing * 2}px ${spacing * 6}px;

        cursor: pointer;

        ${isActive &&
        css`
            border-radius: 32px;
            background: ${palette.gray.main};
            color: ${palette.neutral.white};
            border-color: ${palette.gray.main};
        `}
    `}
`
