import React, {useEffect} from 'react'
import {StyledDescription, StyledDivider, StyledProfileVideosList, StyledRoot, StyledTitle} from './style'
import Spinner from '@/components/ui/Spinner'
import {useTranslation} from 'react-i18next'
import {ValidationError, handleApiError} from '@/utilities/helpers'
import ProfileVideoCard from '@/features/contributors/components/my-profile/profile-video-card/ProfileVideoCard'
import {VideoType} from '@/types/video'
import {useGetUserVideos} from '../../queries/useGetUserVideos'
import LoadMore from '@/components/ui/LoadMore'
import EmptySection from '@/components/commons/EmptySection/EmptySection'
import {VIDEO_STATUS} from '@/utilities/constants/video'

const UserVideosHistory = ({userId, userName}: {userId?: string; userName?: string}) => {
    const {t} = useTranslation()
    const {data, error, fetchNextPage, hasNextPage, isFetching, isLoading, isFetchingNextPage, isError} =
        useGetUserVideos({status: VIDEO_STATUS.Completed, userId: `${userId}`})
    const totalNumberOfVideos = data?.pages[0].meta.total

    useEffect(() => {
        if (isError) handleApiError({error: error as ValidationError<string>})
    }, [isError])

    return isLoading ? (
        <Spinner size={48} inline={true} overlay={false} />
    ) : (
        <StyledRoot>
            <StyledProfileVideosList>
                <StyledTitle>{t('user_profile:videos_list_title')}</StyledTitle>
                <StyledDescription>
                    {t('user_profile:videos_list_description', {
                        NUMBER_OF_VIDEOS: totalNumberOfVideos,
                        USER_NAME: userName
                    })}
                </StyledDescription>
                <StyledDivider />
                {data?.pages?.at(0)?.data.length ? (
                    <>
                        {data?.pages.map((page, index) => (
                            <React.Fragment key={index}>
                                {page?.data?.map((video: VideoType) => (
                                    <ProfileVideoCard key={video.id} video={video} isMine={false} />
                                ))}
                            </React.Fragment>
                        ))}

                        <LoadMore
                            hasNextPage={hasNextPage}
                            isFetchingNextPage={isFetchingNextPage}
                            isFetching={isFetching}
                            fetchNextPage={fetchNextPage}
                        />
                    </>
                ) : (
                    <EmptySection title={'contributor_my_profile:videos:no_videos'} />
                )}
            </StyledProfileVideosList>
        </StyledRoot>
    )
}

export default UserVideosHistory

UserVideosHistory.displayName = 'UserVideosHistory'
