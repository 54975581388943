import useWindowDimensions from './useWindowDimensions'
import {useMemo} from 'react'
import {SCREEN_SIZE} from '@/types/commons'
import {useTheme} from 'styled-components'

const useGetScreenSize = () => {
    const {width} = useWindowDimensions()
    const theme = useTheme()

    const screenSize = useMemo(() => {
        switch (true) {
            case width < theme.breakpoints.width.m:
                return SCREEN_SIZE.MOBILE
            case width >= theme.breakpoints.width.m && width < theme.desktopBreakpoints.width.xs:
                return SCREEN_SIZE.TABLET
            case width > theme.desktopBreakpoints.width.xs:
                return SCREEN_SIZE.DESKTOP
            default:
                return SCREEN_SIZE.DESKTOP
        }
    }, [width])

    return screenSize
}

export default useGetScreenSize
