import axios from '@/services/axiosInstance'
import {EventType, MemberType, VideoType, Workspace} from '../types'
import {HttpListResponse, Language} from '@/types/commons'
import {Event} from '@/types/event'
import {EVENT_STATUS} from '@/utilities/constants/event'

export const httpCreateWorkspace = ({name}: {name: string}) => {
    const url = '/workspaces'
    return axios.post<Workspace>(url, {name, isPublic: true})
}

export const httpRemoveWorkspace = ({workspaceId}: {workspaceId: number}) => {
    const url = `/workspaces/${workspaceId}`
    return axios.delete(url)
}

export const httpInviteMember = ({
    workspaceId,
    email,
    emailConfirmation
}: {
    workspaceId: number
    email: string
    emailConfirmation: string
}) => {
    const url = `/workspaces/${workspaceId}/invitations`
    return axios.post(url, {email, emailConfirmation})
}

export const httpRemoveMembers = ({workspaceId, ids}: {workspaceId: string; ids: number[]}) => {
    const data = {
        usersIds: ids
    }
    const url = `/workspaces/${workspaceId}/users`
    return axios.delete<Workspace>(url, {data})
}

export const httpGetWorkspaceDetails = (id: string) => {
    const url = `/workspaces/${id}`
    return axios.get<Workspace>(url).then(({data}) => data)
}

export const httpGetWorkspacesList = () => {
    const url = '/workspaces'
    return axios.get<HttpListResponse<Workspace>>(url).then(({data}) => data)
}

export const httpGetWorkspaceActiveEvents = (workspace: string, name?: string, page?: string) => {
    const url = `/workspaces/${workspace}/events`
    return axios
        .get<HttpListResponse<Event>>(url, {params: {name, page, status: [EVENT_STATUS.Open, EVENT_STATUS.Scheduled]}})
        .then(({data}) => data)
}

export const httpAcceptInvitation = ({
    workspaceId,
    invitationId,
    token
}: {
    workspaceId: string
    invitationId: string
    token: string
}) => {
    const url = `/workspaces/${workspaceId}/invitations/${invitationId}?token=${token}`
    return axios.post(url)
}

export const httpLeaveWorkspace = ({workspaceId, userId}: {workspaceId: string; userId: number}) => {
    const url = `/workspaces/${workspaceId}/users/${userId}`
    return axios.delete(url)
}

export const httpGetEvents = async ({
    workspaceId,
    name = undefined,
    pageParam,
    statuses = undefined,
    isEuropeana = undefined
}: {
    workspaceId: string
    name: string | undefined
    pageParam: string
    statuses?: string[]
    isEuropeana?: boolean
}) => {
    const url = `/workspaces/${workspaceId}/events${pageParam}`
    //TODO add isOpen, isEuropeana to body when api is implemented
    const response = await axios.get<HttpListResponse<EventType>>(url, {params: {name, status: statuses, isEuropeana}})
    return response.data
}

export const httpGetVideos = async (
    {
        workspaceId,
        name = undefined,
        isEuropeana = undefined,
        statuses = undefined,
        targets = undefined
    }: {
        workspaceId: string
        name: string | undefined
        isEuropeana?: boolean
        statuses?: string[]
        targets?: string[]
    },
    pageParam?: string
) => {
    const url = `/workspaces/${workspaceId}/projects${pageParam}`
    const response = await axios.get<HttpListResponse<VideoType>>(url, {
        params: {isEuropeana, statuses, targets, name}
    })
    return response.data
}

export const httpGetMembers = async ({workspaceId}: {workspaceId: string}) => {
    const url = `/workspaces/${workspaceId}/users`
    const response = await axios.get<HttpListResponse<MemberType>>(url)
    return response.data
}

export const httpGetVideoFilters = async () => {
    const {data: statuses} = await axios.get<string[]>('/statuses')
    const {
        data: {data: targets}
    } = await axios.get<HttpListResponse<Language>>('/languages')
    return {statuses, targets}
}

// accept a contributors request to join the event
export const httpAcceptEventRequest = ({
    workspaceId,
    eventId,
    userId
}: {
    workspaceId: string
    eventId: string
    userId: string
}) => {
    const url = `/workspaces/${workspaceId}/events/${eventId}/users/${userId}`
    return axios.post(url)
}

// reject a contributors request to join the event
export const httpRejectEventRequest = ({
    workspaceId,
    eventId,
    userId
}: {
    workspaceId: string
    eventId: string
    userId: string
}) => {
    const url = `/workspaces/${workspaceId}/events/${eventId}/users/${userId}/reject`
    return axios.delete(url)
}

export const httpDeleteVideo = ({workspaceId, videoId}: {workspaceId: string; videoId: string}) => {
    const url = `/workspaces/${workspaceId}/projects/${videoId}`
    return axios.delete(url)
}
