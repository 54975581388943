import styled, {DefaultTheme, css} from 'styled-components'

export const StyledRoot = styled.div`
    height: 100%;
    overflow: auto;
`

export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const StyledDescription = styled.div`
    ${({theme: {spacing, mediaQueries, typography}}) => css`
        margin-top: ${spacing * 8}px;
        margin-bottom: ${spacing * 16}px;
        max-width: 760px;
        word-break: break-all;
        ${mediaQueries.m} {
            ${typography.textXL};
        }
    `}
`

export const StyledTitleSection = styled.div`
    ${({theme: {palette, typography, mediaQueries, spacing}}) => css`
        color: ${palette.neutral.white};
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        & > h2 {
            ${typography.textXXL}
            font-weight: 700;
            margin-bottom: ${spacing * 4}px;
        }
        & span {
            margin-top: ${spacing * 4}px;
        }
        padding: 100px ${spacing * 4}px;

        ${mediaQueries.m} {
            padding: 0 ${spacing * 4}px;
            height: 400px;
            & > h2 {
                ${typography.text2XL}
            }
        }
        ${mediaQueries.desktopXs} {
            & > h2 {
                ${typography.text3XL}
            }
        }
        ${mediaQueries.l} {
            padding: 0 ${spacing * 4}px;
            height: 400px;
            & > h2 {
                ${typography.titleM}
            }
        }
    `}
`

export const StyledButton = styled.button`
    ${({theme: {typography, palette, spacing}, disabled = false}: {theme: DefaultTheme; disabled?: boolean}) => css`
        ${typography.textL};
        padding: ${spacing * 2}px ${spacing * 6}px;
        border-radius: 32px;
        background-color: ${palette.gray.lightBG};
        color: ${palette.gray.main};
        margin-top: ${spacing * 6}px;
        cursor: pointer;
        ${disabled &&
        css`
            cursor: not-allowed;
            opacity: 0.7;
        `}
    `}
`

export const StyledData = styled.p`
    ${({theme: {typography, palette}}) => css`
        ${typography.textXL}
        line-height: 27px;
        & span {
            color: ${palette.blue.link};
        }
    `}
`
export const StyledLoginModalWrapper = styled.div`
    ${({theme: {link, spacing}}) => css`
        & span {
            ${link}
        }
        & button {
            margin-left: auto;
            margin-top: ${spacing * 4}px;
        }
    `}
`
