import {FC, useEffect} from 'react'
import {
    StyledDate,
    StyledHistoryBullet,
    StyledHistoryItemWrapper,
    StyledLabel,
    StyledSectionWrapper,
    StyledStatus,
    StyledUrl,
    StyledValue,
    StyledValueLabelWrapper,
    StyledHistoryWrapper
} from './style'
import {useTranslation} from 'react-i18next'
import Modal from '@/components/commons/Modal'
import {Language} from '@/types/commons'
import {useGetVideoDetails} from '@/features/videos/queries/useGetVideoDetails'
import {formatLocalDate, handleApiError} from '@/utilities/helpers'
import Spinner from '@/components/ui/Spinner'
import {useGetJobStatusHistory} from '../../queries/useGetJobStatusHistory'
import {JobStatus} from '@/types/job'
import Tag from '@/components/ui/Tag'

// TODO: Missing button to download SRT file, check the mockup
// TODO: Missing "Assigned to" field, check the mockup

interface JobStatusHistoryModalProps {
    videoId: string
    workspaceId: string
    jobId: string
    onClose: () => void
    targetLanguage?: Language
    status: JobStatus
    videoName: string
}

const JobStatusHistoryModal: FC<JobStatusHistoryModalProps> = ({
    videoId,
    workspaceId,
    jobId,
    onClose,
    targetLanguage,
    status,
    videoName
}) => {
    const {t} = useTranslation()
    const {
        data: video,
        isLoading: isLoadingVideo,
        isError: isErrorVideo,
        error: errorVideo
    } = useGetVideoDetails({workspaceId: `${workspaceId}`, videoId: `${videoId}`})
    const {
        data: jobHistory,
        isLoading: isLoadingJobHistory,
        isError: isErrorJobHistory,
        error: errorJobHistory
    } = useGetJobStatusHistory({videoId: `${videoId}`, jobId: `${jobId}`})

    useEffect(() => {
        if (isErrorVideo) handleApiError({error: errorVideo})
    }, [isErrorVideo])

    useEffect(() => {
        if (isErrorJobHistory) handleApiError({error: errorJobHistory})
    }, [isErrorJobHistory])

    if (isLoadingVideo || isLoadingJobHistory) return <Spinner size={48} overlay />

    return (
        <Modal title={videoName} onClose={onClose}>
            <StyledSectionWrapper>
                <StyledUrl>{video?.originalUrl}</StyledUrl>
            </StyledSectionWrapper>
            <StyledSectionWrapper>
                <StyledValueLabelWrapper>
                    <StyledLabel>{t('video_ingestion:original_language')}</StyledLabel>
                    <StyledValue>{video?.sourceLanguage.name}</StyledValue>
                </StyledValueLabelWrapper>
                <StyledValueLabelWrapper>
                    <StyledLabel>{t('video_ingestion:target_language')}</StyledLabel>
                    <StyledValue>{targetLanguage?.name}</StyledValue>
                </StyledValueLabelWrapper>
            </StyledSectionWrapper>
            <StyledSectionWrapper>
                <Tag type={status}>{t(`job_status:${status}`)}</Tag>
            </StyledSectionWrapper>
            <StyledHistoryWrapper>
                {jobHistory?.map((item, index) => (
                    <StyledHistoryItemWrapper key={`video_history_item_${index}`}>
                        <StyledHistoryBullet />
                        <div>
                            <StyledStatus>{t(`job_status:${item.newStatus}`)}</StyledStatus>
                            <StyledDate>{formatLocalDate(item.createdAt)}</StyledDate>
                        </div>
                    </StyledHistoryItemWrapper>
                ))}
            </StyledHistoryWrapper>
        </Modal>
    )
}

JobStatusHistoryModal.displayName = 'JobStatusHistoryModal'
export default JobStatusHistoryModal
