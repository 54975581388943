import styled, {css} from 'styled-components'

export const StyledRoot = styled.div`
    ${({theme: {mediaQueries, spacing}}) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: ${spacing * 12}px;
        ${mediaQueries.m} {
            margin-top: ${spacing * 20}px;
        }
    `}
`

export const StyledTitle = styled.div`
    ${({theme: {palette, spacing, typography}}) => css`
        ${typography.title};
        color: ${palette.gray.main};
        margin-bottom: ${spacing * 6}px;
    `}
`

export const StyledSubTitle = styled.div`
    ${({theme: {typography, palette}}) => css`
        ${typography.subtitle};
        font-weight: 700;
        max-width: 100%;
        color: ${palette.gray.middle};
    `}
`
