import {NavLink} from 'react-router-dom'
import styled, {DefaultTheme, css} from 'styled-components'

export const StyledRoot = styled.div`
    ${({
        theme: {spacing, mediaQueries, palette, zIndex, headerHeightSizingSmall, headerHeightSizing},
        staticNav
    }: {
        theme: DefaultTheme
        staticNav: boolean
    }) => css`
        position: absolute;
        top: ${headerHeightSizingSmall}px;
        right: 0;
        left: 0;
        height: calc(100% - ${headerHeightSizingSmall}px);
        width: 100%;
        z-index: ${zIndex.navBar};
        border-right: 1px solid ${palette.gray.border};
        box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.05);
        padding: ${spacing * 6}px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: ${palette.neutral.white};
        overflow: auto;
        ${mediaQueries.m} {
            width: 250px;
            top: ${headerHeightSizing}px;
            height: calc(100% - ${headerHeightSizing}px);
            ${staticNav &&
            css`
                position: absolute;
                height: 100%;
                top: 0px;
            `}
        }
    `}
`

export const StyleLogoWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        margin-bottom: ${spacing * 10}px;
    `}
`

export const StyledLogo = styled.img`
    ${({theme: {mediaQueries}}) => css`
        margin: 0 auto;
        ${mediaQueries.m} {
            width: 130px;
        }
    `}
`
export const StyledMainSection = styled.div`
    display: flex;
    flex-direction: column;
`

export const StyledHover = styled.div`
    ${({theme: {palette}}) => css`
        & :hover {
            color: ${palette.blue.link};
            & svg path {
                fill: ${palette.blue.link};
            }
        }
    `}
`
export const StyledItemWrapper = styled(NavLink)`
    ${({theme: {spacing, palette}}) => css`
        display: flex;
        align-items: center;
        padding: ${spacing * 3}px;
        gap: ${spacing * 3}px;
        border-radius: 16px;
        background: inherit;

        color: ${palette.gray.main};
        margin-bottom: ${spacing * 2}px;
        cursor: pointer;
        &.active {
            background: ${palette.gray.inputBG};
        }
    `}
`

export const StyledFooter = styled.div`
    ${({theme: {spacing, palette}}) => css`
        display: grid;
        gap: ${spacing * 12}px;
        border-top: 1px solid ${palette.gray.border};
        padding-top: ${spacing * 6}px;
    `}
`
export const StyledFooterItem = styled.div`
    ${({theme: {spacing, palette, typography}}) => css`
        display: flex;
        gap: ${spacing * 6}px;
        cursor: pointer;
        & p {
            color: ${palette.gray.main};
            margin: 0;
            ${typography.textM}
            font-weight: 500;
            align-items: center;
        }
    `}
`
