import {useMutation, useQueryClient} from '@tanstack/react-query'
import {httpCreateWorkspace} from '../services/workspaces.http'
import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {Workspace} from '../types'

type UseCreateWorkspaceProps = {
    onSuccess: ({data}: {data: Workspace}) => void
}
export const useCreateWorkspace = ({onSuccess}: UseCreateWorkspaceProps) => {
    const client = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.CREATE_WORKSPACE],
        mutationFn: ({name}: {name: string}) => httpCreateWorkspace({name}),
        onSuccess: data => {
            onSuccess(data)
            client.invalidateQueries({queryKey: [QUERY_KEYS.WORKSPACES_LIST]})
        }
    })
}
