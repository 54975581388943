import styled, {css} from 'styled-components'

export const StyledDragStatusRoot = styled.div`
    display: flex;
    flex-direction: column;
`
export const StyledDragStatusWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing * 2}px;
        align-items: center;

        width: 100%;
        cursor: pointer;
        margin: ${spacing * 4}px 0px ${spacing * 2}px;
    `}
`

export const StyledStatus = styled.div`
    ${({theme: {palette, typography}}) => css`
        ${typography.textS};
        color: ${palette.gray.main};
        text-decoration: underline;
        font-weight: 500;
    `}
`

export const StyledError = styled.div`
    ${({theme: {palette, typography, spacing}}) => css`
        ${typography.textXS};
        color: ${palette.red.error};
        margin-bottom: ${spacing * 2}px;
    `}
`
