import {StyledAssignButtonRoot, StyledPoints} from './style'
import {Trans, useTranslation} from 'react-i18next'
import Button from '@/components/ui/Button'
import useAuthStore from '@/store/authStore'
import {ROUTE_LOGIN} from '@/utilities/constants'
import {useNavigate} from 'react-router-dom'
import {useVideoAssign} from '@/features/videos/queries/useVideoAssign'
import {ValidationError, handleApiError} from '@/utilities/helpers'
import {useEffect} from 'react'
import Spinner from '@/components/ui/Spinner'

interface AssignButtonProps {
    points: number
    projectId: number
    videoId: number
    alreadyHasAssignment?: boolean
}

const AssignButton: React.FC<AssignButtonProps> = ({points, projectId, videoId, alreadyHasAssignment = false}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {accessToken} = useAuthStore(store => store)

    const {mutate, isPending, isError, error} = useVideoAssign({
        projectId: `${projectId}`,
        videoId: `${videoId}`
    })

    const onAssignButtonClick = () => {
        if (!accessToken) {
            navigate(ROUTE_LOGIN)
        } else {
            mutate()
        }
    }

    useEffect(() => {
        if (isError) handleApiError({error: error as ValidationError<string>})
    }, [isError])

    return (
        <StyledAssignButtonRoot>
            {isPending && <Spinner size={48} overlay={true} />}
            <Button size="md" variant="warning" onClick={onAssignButtonClick} disabled={alreadyHasAssignment}>
                {t('video_details_page:details_section:assign_button')}
            </Button>
            {alreadyHasAssignment ? (
                <span>{t('video_details_page:details_section:already_assigned')}</span>
            ) : (
                <StyledPoints>
                    <Trans
                        t={t}
                        i18nKey="video_details_page:details_section:description"
                        values={{POINTS: points ?? 0}}
                        components={{
                            span: <span />
                        }}
                    />
                </StyledPoints>
            )}
        </StyledAssignButtonRoot>
    )
}

export default AssignButton

AssignButton.displayName = 'AssignButton'
