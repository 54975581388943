import styled, {css} from 'styled-components'

export const StyledRoot = styled.div`
    height: 100%;
    overflow: auto;
`

export const StyleLogoWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        margin-bottom: ${spacing * 16}px;
    `}
`

export const StyledLogo = styled.img`
    ${({theme: {mediaQueries}}) => css`
        display: flex;
        margin: 0 auto;
        max-width: 360px;
        ${mediaQueries.m} {
            max-width: 560px;
        }
    `}
`
export const StyledText = styled.div`
    ${({theme: {link}}) => css`
        line-height: 27px;
        & a {
            ${link}
        }
    `}
`
export const StyledSubtitle = styled.div`
    ${({theme: {typography, spacing, palette}}) => css`
        ${typography.text1XL}
        font-weight: 700;
        color: ${palette.gray.main};
        margin: ${spacing * 4}px 0;
    `}
`
export const StyledPartnersList = styled.div`
    ${({theme: {spacing}}) => css`
        & p {
            margin-bottom: ${spacing}px;
        }
    `}
`
