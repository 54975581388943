import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {httpDeleteEvent} from '../services/events.http'

export const useDeleteEvent = ({
    workspaceId,
    eventId,
    onSuccess
}: {
    workspaceId: string
    eventId: string
    onSuccess: () => void
}) => {
    const client = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.DELETE_EVENT, workspaceId, eventId],
        mutationFn: () => httpDeleteEvent(workspaceId, eventId),
        onSuccess: () => {
            onSuccess()
            client.invalidateQueries({queryKey: [QUERY_KEYS.EVENTS, workspaceId]})
            client.invalidateQueries({queryKey: [QUERY_KEYS.WORKSPACES_LIST]})
        }
    })
}
