import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {httpUpdateVideoAssignment} from '@/features/videos/services/video.http'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {VideoStatus} from '@/types/video'
import {VIDEO_STATUS} from '@/utilities/constants/video'

export const useUpdateVideoAssignment = ({
    projectId,
    videoId,
    onSuccess
}: {
    projectId: string
    videoId: string
    onSuccess: () => void
}) => {
    const client = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.UPDATE_VIDEO_ASSIGNMENT, videoId],
        mutationFn: (status: VideoStatus) => httpUpdateVideoAssignment(projectId, videoId, status),
        onSuccess: () => {
            onSuccess()
            client.invalidateQueries({queryKey: [QUERY_KEYS.WORKSPACE_VIDEO_DETAILS]})
            client.invalidateQueries({queryKey: [QUERY_KEYS.VIDEO_DETAILS, videoId]})
            client.invalidateQueries({queryKey: [QUERY_KEYS.VIDEOS]})
            client.invalidateQueries({queryKey: [QUERY_KEYS.ASSIGNED_VIDEOS, VIDEO_STATUS.ReadyForAudit]})
            client.invalidateQueries({queryKey: [QUERY_KEYS.ASSIGNED_VIDEOS, VIDEO_STATUS.Assigned]})
        }
    })
}
