import styled, {css} from 'styled-components'

export const StyledRoot = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 360px;
        margin-bottom: ${spacing * 10}px;
        align-self: center;

        ${mediaQueries.m} {
            align-self: self-start;
        }
    `}
`

export const StyledTitle = styled.div`
    ${({theme: {palette, mediaQueries, typography}}) => css`
        ${typography.textXXL}
        font-size: 24px;
        color: ${palette.gray.main};
        font-weight: 700;
        ${mediaQueries.m} {
            font-size: 32px;
        }
    `}
`

export const StyledAddWrapper = styled.div`
    ${({theme: {palette, spacing, typography}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing * 3}px;
        ${typography.textM};
        margin: ${spacing * 6}px 0px;
        padding: ${spacing * 3}px ${spacing * 5}px;
        color: ${palette.gray.main};
        font-weight: 700;
        text-decoration-line: underline;
        width: fit-content;

        & svg {
            cursor: pointer;
            & path {
                fill: ${palette.gray.main};
            }
        }

        & span {
            cursor: pointer;
        }
    `}
`

export const StyledRankingSection = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 6}px;
    `}
`

// select section
export const StyledSelectSection = styled.div`
    ${({theme: {spacing, palette, typography}}) => css`
        ${typography.textL}
        color: ${palette.gray.main};
        display: flex;
        align-items: center;
        font-weight: 500;
        min-height: 18px;
        margin-bottom: ${spacing * 6}px;
        & span {
            padding-right: ${spacing * 1.5}px;
            cursor: pointer;
            text-decoration-line: underline;
        }
    `}
`

export const StyledItem = styled.span`
    ${({theme: {spacing, palette}}) => css`
        padding-left: ${spacing * 1.5}px;
        border-left: 1px solid ${palette.gray.menuActive};
    `}
`
export const StyledDeleteIcon = styled.div`
    ${({theme: {spacing, palette}}) => css`
        margin-left: auto;
        display: flex;
        align-items: center;
        gap: ${spacing}px;

        cursor: pointer;
        & svg {
            cursor: pointer;
            & path {
                fill: ${palette.gray.main};
            }
        }
        & span {
            text-decoration-line: none;
        }
    `}
`
