import React, {useEffect} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {StyledFormRoot} from './style.ts'
import {useForm, SubmitHandler} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {z} from 'zod'
import Modal from '@components/commons/Modal'
import Input from '@components/ui/Input'
import Spinner from '@components/ui/Spinner'
import {handleApiError} from '@utilities/helpers.ts'
import {useInviteMember} from '../../queries/useInviteMember.ts'

const enum INVITE_MEMBER_MODEL {
    Email = 'email',
    EmailConfirmation = 'emailConfirmation'
}

const InviteMemberSchema = z
    .object({
        [INVITE_MEMBER_MODEL.Email]: z.string().min(1, {message: 'validation:email'}).email({
            message: 'validation:email_valid'
        }),
        [INVITE_MEMBER_MODEL.EmailConfirmation]: z.string().min(1, {message: 'validation:email'}).email({
            message: 'validation:email_valid'
        })
    })
    .refine(data => data[INVITE_MEMBER_MODEL.Email] === data[INVITE_MEMBER_MODEL.EmailConfirmation], {
        path: [INVITE_MEMBER_MODEL.EmailConfirmation],
        message: 'validation:confirm_email'
    })

type ValidationSchema = z.infer<typeof InviteMemberSchema>

export interface InviteMemberModalProps {
    onClose: () => void
    setSuccessModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    workspaceId?: number
}

const InviteMemberModal: React.FC<InviteMemberModalProps> = ({onClose, setSuccessModalOpen, workspaceId}) => {
    const {t} = useTranslation()
    const onSuccess = () => {
        onClose()
        setSuccessModalOpen(true)
    }
    const {mutate, isPending, isError, error} = useInviteMember({onSuccess})

    const {
        register,
        handleSubmit,
        setError,
        formState: {errors, isValid, isDirty}
    } = useForm<ValidationSchema>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(InviteMemberSchema)
    })

    useEffect(() => {
        if (isError && error instanceof Error) {
            handleApiError({setError, error})
        }
    }, [isError])

    const onSubmit: SubmitHandler<ValidationSchema> = values => {
        if (workspaceId) {
            const dataToSend = {
                ...values,
                workspaceId
            }

            mutate(dataToSend)
        }
    }

    return (
        <Modal
            overlay
            title={t('workspace:invite_member_modal:title')}
            onClose={onClose}
            cancelButtonCallback={onClose}
            isSubmitButtonDisabled={!isValid || !isDirty}
            submitButtonCallback={handleSubmit(onSubmit)}
            submitButtonText={'workspace:invite_member_modal:add_member'}
        >
            {isPending && <Spinner size={48} overlay={false} />}
            <StyledFormRoot onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <Trans
                        t={t}
                        i18nKey="workspace:invite_member_modal:description"
                        components={{
                            span: <span />
                        }}
                    />
                </div>
                <Input
                    {...register(INVITE_MEMBER_MODEL.Email)}
                    label={`${t('common:email')}*`}
                    errorMessage={`${t(errors.email?.message || '')}`}
                />
                <Input
                    {...register(INVITE_MEMBER_MODEL.EmailConfirmation)}
                    label={`${t('common:confirm_email')}*`}
                    errorMessage={`${t(errors.emailConfirmation?.message || '')}`}
                />
            </StyledFormRoot>
        </Modal>
    )
}

export default InviteMemberModal

InviteMemberModal.displayName = 'InviteMemberModal'
