import Modal from '@/components/commons/Modal'
import styled, {DefaultTheme, css} from 'styled-components'

export const StyledModal = styled(Modal)`
    ${({theme: {shadows}}: {theme: DefaultTheme; $withShadow?: boolean}) => css`
        box-shadow: ${shadows['3xl']};
    `}
`

export const StyledSubtitle = styled.span`
    ${({theme: {palette}}) => css`
        color: ${palette.gray};
        display: block;
    `}
`

export const StyledBold = styled.b`
    ${() => css`
        font-weight: bold;
    `}
`

export const StyledLabel = styled.span`
    ${({theme: {palette, spacing, typography}}) => css`
        ${typography.textS}
        color: ${palette.neutral[800]};
        font-weight: bold;
        display: block;
        margin-bottom: ${spacing}px;
        margin-top: ${spacing * 4}px;
    `}
`

export const StyledUrlWrapper = styled.div`
    ${({theme: {palette, spacing}}) => css`
        position: relative;
        background-color: ${palette.success[100]};
        border-radius: 16px;
        height: 45px;
        display: flex;
        align-items: center;
        margin-bottom: ${spacing * 2}px;
    `}
`

export const StyledUrlOverlay = styled.div`
    ${({theme: {palette}}) => css`
        height: 45px;
        position: absolute;
        background: linear-gradient(to right, ${palette.success[100]} 5%, transparent 35%);
        width: 100%;
        border-radius: 16px;
    `}
`

export const StyledUrlText = styled.span`
    ${({theme: {palette, spacing}}) => css`
        color: ${palette.neutral[700]};
        padding: 0 ${spacing * 4}px;
    `}
`

export const StyledActionsWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        margin-top ${spacing * 6}px;
        display: flex;
        justify-content: flex-end;
    `}
`
