import React, {useEffect} from 'react'
import {StyledSubtitle} from './style'
import {useTranslation} from 'react-i18next'
import Modal from '@/components/commons/Modal'
import {handleApiError} from '@/utilities/helpers'
import Spinner from '@/components/ui/Spinner'
import {useUpdateVideoAssignment} from '@/features/videos/queries/useUpdateVideoAssignment'
import {VIDEO_STATUS} from '@/utilities/constants/video'

interface UnassignModalProps {
    videoId: string
    workspaceId: string
    jobId: string
    onClose: () => void
}

const UnassignModal: React.FC<UnassignModalProps> = ({videoId, jobId, onClose}: UnassignModalProps) => {
    const {t} = useTranslation()
    const {mutate, isPending, isError, error} = useUpdateVideoAssignment({
        projectId: `${videoId}`,
        videoId: `${jobId}`,
        onSuccess: () => onClose()
    })

    useEffect(() => {
        if (isError) handleApiError({error})
    }, [isError])

    const onClickClose = () => {
        onClose()
    }

    const onSubmit = () => {
        mutate(VIDEO_STATUS.Ready)
    }

    if (isPending) return <Spinner size={48} overlay />

    return (
        <Modal
            overlay
            title={'unassign_modal:title'}
            onClose={onClickClose}
            cancelButtonCallback={onClickClose}
            submitButtonCallback={onSubmit}
            submitButtonText={'unassign_modal:submit_button'}
        >
            <StyledSubtitle>{t('unassign_modal:subtitle')}</StyledSubtitle>
        </Modal>
    )
}

export default UnassignModal

UnassignModal.displayName = 'UnassignModal'
