export const breakpoints = {
    width: {
        xxs: 320,
        xs: 365,
        s: 414,
        m: 768,
        l: 1200,
        xl: 1440,
        xxl: 1920
    }
}

export const desktopBreakpoints = {
    width: {
        xs: 1024
    }
}

const mediaQueries = {
    xxs: `@media only screen and (min-width: ${breakpoints.width.xxs}px)`,
    xs: `@media only screen and (min-width: ${breakpoints.width.xs}px)`,
    s: `@media only screen and (min-width: ${breakpoints.width.s}px)`,
    m: `@media only screen and (min-width: ${breakpoints.width.m}px)`,
    l: `@media only screen and (min-width: ${breakpoints.width.l}px)`,
    xl: `@media only screen and (min-width: ${breakpoints.width.xl}px)`,
    desktopXs: `@media only screen and (min-width: ${desktopBreakpoints.width.xs}px)`
}

export default mediaQueries
