import {useParams} from 'react-router-dom'
import {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import Spinner from '@/components/ui/Spinner'
import {StyledVideoSection, StyledSearchWrapper, StyledBox, StyledBoxItem, StyledChevronIcon} from './style'
import VideoCard from '@/features/workspaces/components/video-card/VideoCard'
import {getIsEuropeanaFilterValue, handleApiError} from '@/utilities/helpers'
import {useGetWorkspaceEventDetails} from '@/features/events/queries/useGetWorkspaceEventDetails'
import Input from '@/components/ui/Input'
import {ChevronUpIcon, DatabaseIcon, LanguageFilterIcon, SearchIcon, TagIcon} from '@/assets/icons/icons'
import VideoFilters from '@/features/workspaces/components/video-filters/VideoFilters'
import useFiltersParams from '@/hooks/useFiltersParams'
import {FILTERS_PARAMS} from '@/types/video'
import EmptySection from '@/components/commons/EmptySection/EmptySection'

const VideosTab = () => {
    const {t} = useTranslation()
    const {workspaceId, eventId} = useParams()
    const {data, isLoading, isError, error} = useGetWorkspaceEventDetails({
        workspaceId: `${workspaceId}`,
        eventId: `${eventId}`
    })

    const [isOpenFiltersSection, setIsOpenFiltersSection] = useState(false)
    const [filtersParams, setFiltersParams] = useFiltersParams()

    const isEuropeana = getIsEuropeanaFilterValue(filtersParams)

    const statuses = filtersParams.status ? filtersParams?.status.split(',') : undefined
    const targets = filtersParams.targets ? filtersParams?.targets.split(',') : undefined

    useEffect(() => {
        if (isError && error instanceof Error) {
            handleApiError({error})
        }
    }, [isError])

    const filteredVideos = useMemo(() => {
        return data?.projects?.filter(
            item =>
                item.name.toUpperCase().includes(filtersParams?.name?.toUpperCase() ?? '') &&
                (statuses !== undefined ? statuses.includes(item.status) : true) &&
                (targets !== undefined ? item.jobs.find(job => targets.includes(`${job.targetLanguage.id}`)) : true) &&
                (isEuropeana !== undefined ? item.isEuropeana === isEuropeana : true)
        )
    }, [filtersParams?.name, statuses?.length, targets?.length, isEuropeana, data?.projects?.length])

    return (
        <>
            <StyledSearchWrapper>
                <Input
                    value={filtersParams.name}
                    onChange={event => {
                        setFiltersParams(FILTERS_PARAMS.Name, event.target.value)
                    }}
                    placeholder={`${t('common:search')}`}
                    icon={<SearchIcon />}
                />
            </StyledSearchWrapper>

            <StyledBox>
                <StyledBoxItem style={{width: 115}}>
                    <TagIcon />
                    {t('workspace:status')}
                </StyledBoxItem>

                <StyledBoxItem>
                    <LanguageFilterIcon width={17} height={18} />
                    {t('workspace:targets')}
                </StyledBoxItem>

                <StyledBoxItem>
                    <DatabaseIcon />
                    {t('workspace:platform')}
                </StyledBoxItem>

                <StyledChevronIcon
                    isOpenFiltersSection={isOpenFiltersSection}
                    onClick={() => setIsOpenFiltersSection(value => !value)}
                >
                    <span>{t(isOpenFiltersSection ? 'common:close_filters' : 'common:open_filters')}</span>
                    <ChevronUpIcon />
                </StyledChevronIcon>
            </StyledBox>

            {isOpenFiltersSection && <VideoFilters />}

            {isLoading ? (
                <Spinner size={32} inline overlay={true} />
            ) : (
                <>
                    {filteredVideos?.length ? (
                        <StyledVideoSection>
                            {filteredVideos?.map(video => (
                                <VideoCard key={video?.id} video={video} />
                            ))}
                        </StyledVideoSection>
                    ) : (
                        <EmptySection title={'workspace:no_videos'} />
                    )}
                </>
            )}
        </>
    )
}

export default VideosTab

VideosTab.displayName = 'VideosTab'
