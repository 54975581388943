import Container from '@/components/ui/Container'
import {Trans, useTranslation} from 'react-i18next'
import {StyledRoot, StyledSection, StyledTitle} from './style'
import {useTheme} from 'styled-components'
import ContributorContainer from '@/components/ui/ContributorContainer/ContributorContainer'

const PrivacyPolicy: React.FC = () => {
    const {t} = useTranslation()
    const {infoContainerSize} = useTheme()

    const cookiesLinks = [
        {
            id: 1,
            name: t('privacy_policy:section_4:link_1'),
            link: 'https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-11'
        },
        {
            id: 2,
            name: t('privacy_policy:section_4:link_2'),
            link: 'https://support.microsoft.com/it-it/microsoft-edge/eliminare-i-cookie-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09'
        },
        {
            id: 3,
            name: t('privacy_policy:section_4:link_3'),
            link: 'https://support.mozilla.org/it/kb/protezione-antitracciamento-avanzata-firefox-desktop?redirectslug=Attivare+e+disattivare+i+cookie&redirectlocale=it'
        },
        {
            id: 4,
            name: t('privacy_policy:section_4:link_4'),
            link: 'https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?redirectslug=delete-cookies-remove-info-websites-stored&redirectlocale=en-US'
        },
        {
            id: 5,
            name: t('privacy_policy:section_4:link_5'),
            link: 'https://support.google.com/chrome/answer/95647?hl=en'
        },
        {
            id: 6,
            name: t('privacy_policy:section_4:link_6'),
            link: 'https://support.apple.com/ru-ru/guide/safari/sfri11471/mac'
        }
    ]

    const socialLinks = [
        {
            id: 1,
            name: t('privacy_policy:section_5:link_1'),
            link: 'https://www.facebook.com/privacy/policies/cookies/?entry_point=cookie_policy_redirect&entry=0'
        },
        {
            id: 2,
            name: t('privacy_policy:section_5:link_2'),
            link: 'https://help.twitter.com/en/rules-and-policies/x-cookies'
        },
        {
            id: 3,
            name: t('privacy_policy:section_5:link_3'),
            link: 'https://www.linkedin.com/legal/cookie-policy'
        },
        {
            id: 4,
            name: t('privacy_policy:section_5:link_4'),
            link: 'https://policies.google.com/technologies/cookies?hl=it'
        }
    ]

    return (
        <StyledRoot>
            <ContributorContainer>
                <Container maxWidth={infoContainerSize}>
                    <StyledTitle>{t('nav:privacy_policy')}</StyledTitle>
                    {/* section 1 */}
                    <StyledSection>
                        <h2>I. {t('privacy_policy:section_1:title')}</h2>
                        <p>
                            <Trans
                                t={t}
                                i18nKey="privacy_policy:section_1:text_1"
                                components={{
                                    span: <span />
                                }}
                            />
                        </p>
                        <p>{t('privacy_policy:section_1:text_2')}</p>
                        <p>
                            <Trans
                                t={t}
                                i18nKey="privacy_policy:section_1:text_3"
                                components={{
                                    span: <span />
                                }}
                            />
                        </p>
                        <p>
                            <Trans
                                t={t}
                                i18nKey="privacy_policy:section_1:text_4"
                                components={{
                                    span: <span />
                                }}
                            />
                        </p>
                        <p>
                            <Trans
                                t={t}
                                i18nKey="privacy_policy:section_1:text_5"
                                components={{
                                    span: <span />
                                }}
                            />
                        </p>
                    </StyledSection>

                    {/* section 2 */}
                    <StyledSection>
                        <h2>II. {t('privacy_policy:section_2:title')}</h2>
                        <p>{t('privacy_policy:section_2:text_1')}</p>
                        <h3>{t('privacy_policy:section_2:subtitle_1')}</h3>
                        <p>{t('privacy_policy:section_2:text_2')}</p>
                        <h3>{t('privacy_policy:section_2:subtitle_2')}</h3>
                        <p>{t('privacy_policy:section_2:text_3')}</p>
                    </StyledSection>

                    {/* section 3 */}
                    <StyledSection>
                        <h2>III. {t('privacy_policy:section_3:title')}</h2>
                        <p>{t('privacy_policy:section_3:text_1')}</p>
                        <ul>
                            <li>{t('privacy_policy:section_3:list_item_1')}</li>
                            <li>{t('privacy_policy:section_3:list_item_2')}</li>
                            <li>{t('privacy_policy:section_3:list_item_3')}</li>
                            <li>{t('privacy_policy:section_3:list_item_4')}</li>
                            <li>{t('privacy_policy:section_3:list_item_5')}</li>
                            <li>{t('privacy_policy:section_3:list_item_6')}</li>
                            <li>{t('privacy_policy:section_3:list_item_7')}</li>
                            <li>{t('privacy_policy:section_3:list_item_8')}</li>
                            <li>{t('privacy_policy:section_3:list_item_9')}</li>
                        </ul>
                        <p>{t('privacy_policy:section_3:text_2')}</p>
                        <p>{t('privacy_policy:section_3:text_3')}</p>
                    </StyledSection>

                    {/* section 4 */}
                    <StyledSection>
                        <h2>IV. {t('privacy_policy:section_4:title')}</h2>
                        <h3>{t('privacy_policy:section_4:subtitle_1')}</h3>
                        <p>{t('privacy_policy:section_4:text_1')}</p>
                        <p>{t('privacy_policy:section_4:text_2')}</p>
                        <h3>{t('privacy_policy:section_4:subtitle_2')}</h3>
                        <p>{t('privacy_policy:section_4:text_3')}</p>
                        <p>{t('privacy_policy:section_4:text_4')}</p>
                        <p>{t('privacy_policy:section_4:text_5')}</p>
                        <p>{t('privacy_policy:section_4:text_6')}</p>
                        <h3>{t('privacy_policy:section_4:subtitle_3')}</h3>
                        <p>{t('privacy_policy:section_4:text_7')}</p>
                        <p>{t('privacy_policy:section_4:text_8')}</p>
                        <ul>
                            {cookiesLinks.map(link => (
                                <li key={link.id}>
                                    <a href={link.link} target="_blank">
                                        {link.name}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </StyledSection>

                    {/* section 5 */}
                    <StyledSection>
                        <h2>V. {t('privacy_policy:section_5:title')}</h2>
                        <p>{t('privacy_policy:section_5:text_1')}</p>
                        <h3>{t('privacy_policy:section_5:subtitle_1')}</h3>
                        <p>{t('privacy_policy:section_5:text_2')}</p>
                        <p>{t('privacy_policy:section_5:text_3')}</p>
                        <ul>
                            {socialLinks.map(link => (
                                <li key={link.id}>
                                    <a href={link.link} target="_blank">
                                        {link.name}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </StyledSection>

                    {/* section 6 */}
                    <StyledSection>
                        <h2>VI. {t('privacy_policy:section_6:title')}</h2>
                        <p>{t('privacy_policy:section_6:text_1')}</p>
                    </StyledSection>

                    {/* section 7 */}
                    <StyledSection>
                        <h2>VII. {t('privacy_policy:section_7:title')}</h2>
                        <p>{t('privacy_policy:section_7:text_1')}</p>
                    </StyledSection>

                    {/* section 8 */}
                    <StyledSection>
                        <h2>VIII. {t('privacy_policy:section_8:title')}</h2>
                        <p>{t('privacy_policy:section_8:text_1')}</p>
                    </StyledSection>

                    {/* section 9 */}
                    <StyledSection>
                        <h2>IX. {t('privacy_policy:section_9:title')}</h2>
                        <p>{t('privacy_policy:section_9:text_1')}</p>
                        <p>{t('privacy_policy:section_9:text_2')}</p>
                        <p>{t('privacy_policy:section_9:text_3')}</p>
                    </StyledSection>

                    {/* section 10 */}
                    <StyledSection>
                        <h2>X. {t('privacy_policy:section_10:title')}</h2>
                        <p>{t('privacy_policy:section_10:text_1')}</p>
                        <ul>
                            <li>{t('privacy_policy:section_10:list_item_1')}</li>
                            <li>{t('privacy_policy:section_10:list_item_2')}</li>
                            <li>{t('privacy_policy:section_10:list_item_3')}</li>
                            <li>{t('privacy_policy:section_10:list_item_4')}</li>
                            <li>{t('privacy_policy:section_10:list_item_5')}</li>
                            <li>{t('privacy_policy:section_10:list_item_6')}</li>
                            <li>{t('privacy_policy:section_10:list_item_7')}</li>
                            <li>{t('privacy_policy:section_10:list_item_8')}</li>
                        </ul>
                        <p>{t('privacy_policy:section_3:text_2')}</p>
                    </StyledSection>

                    {/* section 11 */}
                    <StyledSection>
                        <h2>XI. {t('privacy_policy:section_11:title')}</h2>
                        <p>{t('privacy_policy:section_11:text_1')}</p>
                        <p>{t('privacy_policy:section_11:text_2')}</p>
                    </StyledSection>

                    {/* section 12 */}
                    <StyledSection>
                        <h2>XII. {t('privacy_policy:section_12:title')}</h2>
                        <p>
                            <Trans
                                t={t}
                                i18nKey="privacy_policy:section_12:text_1"
                                components={{
                                    a: <a href="mailto:info@translated.com" />
                                }}
                            />
                        </p>
                        <p>{t('privacy_policy:section_12:text_2')}</p>
                    </StyledSection>

                    {/* section 13 */}
                    <StyledSection>
                        <h2>XIII. {t('privacy_policy:section_13:title')}</h2>
                        <p>
                            <Trans
                                t={t}
                                i18nKey="privacy_policy:section_13:text_1"
                                components={{
                                    a: <a href="mailto:dpo@translated.com" />
                                }}
                            />
                        </p>
                    </StyledSection>

                    {/* section 14 */}
                    <StyledSection>
                        <h2>XIV. {t('privacy_policy:section_14:title')}</h2>
                        <p>{t('privacy_policy:section_14:text_1')}</p>
                    </StyledSection>
                </Container>
            </ContributorContainer>
        </StyledRoot>
    )
}
export default PrivacyPolicy

PrivacyPolicy.displayName = 'PrivacyPolicy'
