import {QUERY_KEYS} from '@/queryClient'
import {useInfiniteQuery} from '@tanstack/react-query'
import {httpGetAssignedVideos} from '../services/profile.http'

export const useGetAssignedVideos = ({status}: {status: string}) =>
    useInfiniteQuery({
        queryKey: [QUERY_KEYS.ASSIGNED_VIDEOS, status],
        queryFn: ({pageParam}) => httpGetAssignedVideos(status, pageParam),
        initialPageParam: '',
        getNextPageParam: lastPage => lastPage.meta.next_page_url
    })
