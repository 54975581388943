import {useTranslation} from 'react-i18next'
import {useForm, SubmitHandler} from 'react-hook-form'
import {FC, useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {
    PASSWORD_RESET_MODEL,
    PasswordResetValidationSchema,
    passwordResetFormSchema
} from '@/features/password-reset/services/passwordResetFormModel'
import {zodResolver} from '@hookform/resolvers/zod'
import Input from '@/components/ui/Input'
import {StyledButtonWrap, StyledContentWrapper} from './style'
import Button from '@/components/ui/Button'
import {EyeIcon, EyeInvisibleIcon} from '@/assets/icons/icons'
import {ValidationError, handleApiError} from '@/utilities/helpers'
import {usePasswordReset} from '../../queries/usePasswordReset'

export interface PasswordResetFormProps {
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
    setIsOpenErrorModal: React.Dispatch<React.SetStateAction<boolean>>
    setIsOpenSuccessModal: React.Dispatch<React.SetStateAction<boolean>>
}

const Form: FC<PasswordResetFormProps> = ({setIsLoading, setIsOpenErrorModal, setIsOpenSuccessModal}) => {
    const {t} = useTranslation()
    const {token} = useParams()
    const encodedToken = token && encodeURIComponent(token)
    const decodedToken = encodedToken && decodeURIComponent(encodedToken)
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const {mutate, isPending, isError, error} = usePasswordReset()

    const {
        register,
        handleSubmit,
        setError,
        formState: {errors, isValid}
    } = useForm<PasswordResetValidationSchema>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(passwordResetFormSchema),
        defaultValues: {
            [PASSWORD_RESET_MODEL.Token]: decodedToken
        }
    })

    useEffect(() => {
        if (isError) handleApiError({setError, error: error as ValidationError<'token'>})
    }, [isError])

    useEffect(() => {
        setIsLoading(isPending)
    }, [isPending])

    const onSuccess = () => {
        setIsOpenSuccessModal(true)
    }

    useEffect(() => {
        if (errors[PASSWORD_RESET_MODEL.Token]) {
            setIsOpenErrorModal(true)
        }
    }, [errors[PASSWORD_RESET_MODEL.Token]])

    const onSubmit: SubmitHandler<PasswordResetValidationSchema> = data => {
        mutate(data, {onSuccess})
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <StyledContentWrapper>
                <Input
                    {...register(PASSWORD_RESET_MODEL.Password)}
                    label={`${t('sign_up:form:password')}*`}
                    type={showPassword ? 'text' : 'password'}
                    icon={showPassword ? <EyeIcon /> : <EyeInvisibleIcon />}
                    iconCallback={() => setShowPassword(!showPassword)}
                    errorMessage={`${t(errors[PASSWORD_RESET_MODEL.Password]?.message || '')}`}
                />
                <Input
                    {...register(PASSWORD_RESET_MODEL.PasswordConfirmation)}
                    label={`${t('sign_up:form:confirm_password')}*`}
                    type={showConfirmPassword ? 'text' : 'password'}
                    icon={showConfirmPassword ? <EyeIcon /> : <EyeInvisibleIcon />}
                    iconCallback={() => setShowConfirmPassword(!showConfirmPassword)}
                    errorMessage={`${t(errors[PASSWORD_RESET_MODEL.PasswordConfirmation]?.message || '')}`}
                />
            </StyledContentWrapper>
            <StyledButtonWrap>
                <Button type={'submit'} disabled={!isValid}>
                    {t('common:confirm')}
                </Button>
            </StyledButtonWrap>
        </form>
    )
}

export default Form
Form.displayName = 'Form'
