import styled, {css} from 'styled-components'

export const StyledSectionWrapper = styled.div(
    ({theme: {spacing}}) => css`
        margin-bottom: ${spacing * 4}px;
    `
)

export const StyledUrl = styled.span(
    ({theme: {palette, typography}}) => css`
        ${typography.textM}
        font-weight: 600;
        color: ${palette.neutral['900']};
        word-break: break-all;
    `
)

export const StyledValueLabelWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing * 2}px;
    `}
`

export const StyledLabel = styled.span`
    ${({theme: {palette, spacing, typography}}) => css`
        ${typography.textM}
        display: block;
        margin-bottom: ${spacing * 2}px;
        color: ${palette.neutral['900']};
        font-weight: 500;
    `}
`

export const StyledValue = styled.span`
    ${({theme: {palette, spacing, typography}}) => css`
        ${typography.textM}
        display: block;
        margin-bottom: ${spacing * 2}px;
        color: ${palette.neutral['500']};
    `}
`

export const StyledHistoryItemWrapper = styled.div`
    ${({theme: {palette, spacing}}) => css`
        border-left: dashed 2px ${palette.blue.main};
        position: relative;
        display: flex;
        align-items: center;
        height: 60px;
        padding-left: ${spacing * 8}px;
    `}
`

export const StyledHistoryBullet = styled.div`
    ${({theme: {palette}}) => css`
        background-color: ${palette.blue.main};
        position: absolute;
        width: 6px;
        height: 6px;
        top: -3px;
        left: -4px;
        border-radius: 3px;
    `}
`

export const StyledStatus = styled.span`
    ${({theme: {palette, spacing, typography}}) => css`
        ${typography.textM}
        display: block;
        margin-bottom: ${spacing * 2}px;
        color: ${palette.neutral['900']};
        font-weight: 500;
        margin-bottom: ${spacing}px;
    `}
`

export const StyledDate = styled.span`
    ${({theme: {palette, spacing, typography}}) => css`
        ${typography.textS}
        display: block;
        margin-bottom: ${spacing * 2}px;
        color: ${palette.neutral['900']};
        font-weight: 400;
    `}
`

export const StyledHistoryWrapper = styled.div(
    ({theme: {spacing}}) => css`
        margin-top: ${spacing * 8}px;
        margin-left: ${spacing * 2}px;
        padding: 4px 2px;
        max-height: 40vh;
        overflow: auto;
    `
)
