import React from 'react'
import {StyledSubtitle} from './style'
import {
    VIDEO_MODEL,
    VideoEventSchema,
    VideoEventValidationSchema
} from '@features/videos/services/VideoCreationFormModel'
import {zodResolver} from '@hookform/resolvers/zod/src/zod.js'
import {FormProvider, useForm} from 'react-hook-form'
import ListSelect from '@/components/commons/ListSelect/ListSelect'
import {useTranslation} from 'react-i18next'
import Modal from '@/components/commons/Modal'
import {ValueLabel} from '@/types/commons'
import {httpGetWorkspaceActiveEvents} from '@/features/workspaces/services/workspaces.http'
import {Event} from '@/types/event'
import {useParams} from 'react-router-dom'
import {VideoEventForm} from '@/features/videos/types/video'

interface AddEventModalProps {
    isVisible: boolean
    onCancel: () => void
    onSubmit: (values: VideoEventForm) => void
}

const AddEventModal: React.FC<AddEventModalProps> = ({isVisible, onSubmit, onCancel}) => {
    const {t} = useTranslation()
    const {workspaceId} = useParams()

    const methods = useForm<VideoEventValidationSchema>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(VideoEventSchema)
    })

    const {
        handleSubmit,
        reset,
        formState: {isValid, isDirty, errors}
    } = methods

    const onClickClose = () => {
        reset()
        onCancel()
    }

    if (!isVisible) return null

    return (
        <FormProvider {...methods}>
            <Modal
                overlay
                title={'video_creation:add_video'}
                onClose={onClickClose}
                cancelButtonCallback={onClickClose}
                isSubmitButtonDisabled={!isValid || !isDirty}
                submitButtonCallback={handleSubmit(onSubmit)}
                submitButtonText={'video_creation:add_video'}
            >
                <StyledSubtitle>{t('video_creation:add_video')}</StyledSubtitle>
                <ListSelect
                    name={VIDEO_MODEL.Event}
                    label={t('video_creation:events')}
                    placeholder={t('video_creation:events_placeholder')}
                    errorMessage={`${t(errors[VIDEO_MODEL.Event]?.message || '')}`}
                    height={200}
                    fetchFunction={(name, page) => httpGetWorkspaceActiveEvents(`${workspaceId}`, name, page)}
                    adapterFunction={data =>
                        data?.data?.map((item: Event): ValueLabel => ({value: item.id, label: item.name}))
                    }
                />
            </Modal>
        </FormProvider>
    )
}

export default AddEventModal

AddEventModal.displayName = 'AddEventModal'
