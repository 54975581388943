import styled, {css} from 'styled-components'

export const StyledProfileVideosListRoot = styled.div`
    ${({theme: {spacing}}) => css`
        margin-bottom: ${spacing * 14}px;
    `}
`

export const StyledDivider = styled.div`
    ${({theme: {palette, spacing}}) => css`
        border-top: 1px solid ${palette.gray.xlight};
        margin-top: ${spacing * 8}px;
    `}
`

export const StyledTitle = styled.div`
    ${({theme: {palette, typography}}) => css`
        ${typography.subtitle};
        color: ${palette.gray.main};
    `}
`
