import {Language} from '@/types/commons'
import {PLATFORMS} from '../services/constants'

export const getVideoFiltersContent = ({statuses, targets}: {statuses?: string[]; targets?: Language[]}) => {
    return {
        statuses: statuses ?? [],
        targets: targets ?? [],
        platforms: [PLATFORMS.NonEuropeana, PLATFORMS.Europeana]
    }
}
