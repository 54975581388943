import styled, {css} from 'styled-components'

export const StyledUploader = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing * 6}px;
        width: 100%;
    `}
`

export const StyledInfoSection = styled.div`
    display: flex;
    flex-direction: column;
`

export const StyledTitle = styled.div`
    ${({theme: {palette, typography}}) => css`
        ${typography.textS};
        color: ${palette.gray.main};
        font-weight: 700;
        & span {
            color: ${palette.gray.light};
            font-weight: 400;
        }
    `}
`
