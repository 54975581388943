import styled, {css} from 'styled-components'

export const StyledFileInfo = styled.div`
    ${({theme: {palette, spacing}}) => css`
        display: grid;
        grid-template-columns: 0fr auto 0fr;
        align-items: center;
        background-color: ${palette.gray.inputBG};
        border-radius: ${spacing}px;
        padding: ${spacing}px;
        gap: ${spacing * 2}px;
        margin: ${spacing * 2}px 0px;
        cursor: pointer;
    `}
`

export const StyledFileName = styled.div`
    ${({theme: {typography, truncateText}}) => css`
        ${typography.textXS};
        ${truncateText}
        color: #1890ff;
    `}
`

export const StyledDeleteButton = styled.div`
    ${({theme: {palette}}) => css`
        cursor: pointer;
        height: 14px;
        width: 14px;
        & svg {
            & path {
                fill: ${palette.gray.light};
            }
        }
    `}
`

export const StyledHint = styled.div`
    ${({theme: {palette, typography}}) => css`
        ${typography.textXS};
        color: ${palette.gray.light};
    `}
`
