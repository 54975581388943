import {useParams} from 'react-router-dom'
import {useEffect} from 'react'
import {handleApiError} from '@/utilities/helpers'
import Spinner from '@/components/ui/Spinner'
import {useGetEventLeadersList} from '../../queries/useGetEventLeadersList'
import {StyledWrapper} from './style'
import LeadersList from '@/features/leader-board/components/leaders-list/LeadersList'
import LoadMore from '@/components/ui/LoadMore'
import EmptySection from '@/components/commons/EmptySection/EmptySection'

const LeaderboardTab = () => {
    const {eventId} = useParams()

    const {data, error, fetchNextPage, hasNextPage, isFetching, isLoading, isFetchingNextPage, isError} =
        useGetEventLeadersList({eventId: `${eventId}`})

    useEffect(() => {
        if (isError && error instanceof Error) {
            handleApiError({error})
        }
    }, [isError])

    return isLoading ? (
        <Spinner size={48} inline={true} overlay={false} />
    ) : data?.pages?.at(0)?.data.length ? (
        <StyledWrapper>
            <LeadersList data={data.pages} isNumbered isEvent />
            <LoadMore
                hasNextPage={hasNextPage}
                isFetchingNextPage={isFetchingNextPage}
                isFetching={isFetching}
                fetchNextPage={fetchNextPage}
            />
        </StyledWrapper>
    ) : (
        <EmptySection title={'event_page:no_leaders'} />
    )
}

export default LeaderboardTab

LeaderboardTab.displayName = 'LeaderboardTab'
