import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {z} from 'zod'
import {ValidationError, checkInputRemoveValue, handleApiError} from '@/utilities/helpers'
import WorkspaceActionModal from '@/components/commons/WorkspaceActionModal/WorkspaceActionModal'
import {useDeleteJob} from '@/features/videos/queries/useDeleteJob'

const enum CONFIRM_JOB_DELETION_MODEL {
    Type = 'type'
}

const ConfirmJobDeletionSchema = z.object({
    [CONFIRM_JOB_DELETION_MODEL.Type]: z
        .string()
        .min(1, {message: 'validation:required_field'})
        .refine(value => checkInputRemoveValue(value), {message: 'validation:invalid_value'})
        .default('')
})

type ValidationSchema = z.infer<typeof ConfirmJobDeletionSchema>

export interface JobDeletionModalProps {
    onClose: () => void
    workspaceId?: string
    videoId?: string
    jobId?: string
}

const JobDeletionModal: React.FC<JobDeletionModalProps> = ({onClose, workspaceId, videoId, jobId}) => {
    const {mutate, isPending, isError, error} = useDeleteJob({
        onSuccess: onClose,
        workspaceId: `${workspaceId}`,
        videoId: `${videoId}`
    })

    const {
        register,
        handleSubmit,
        formState: {errors, isValid, isDirty}
    } = useForm<ValidationSchema>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(ConfirmJobDeletionSchema)
    })

    useEffect(() => {
        if (isError) handleApiError({error: error as ValidationError<string>})
    }, [isError])

    const onSubmit = () => {
        mutate(`${jobId}`)
    }

    return (
        <WorkspaceActionModal
            onClose={onClose}
            title="video_ingestion:delete_target_language_title"
            description="video_ingestion:delete_target_language_description"
            cancelButtonName="common:cancel"
            errorMessage={errors.type?.message || ''}
            handleSubmit={handleSubmit(onSubmit)}
            isConfirmButtonDisabled={!isValid || !isDirty || isPending}
            isSubmitting={isPending}
            {...register(CONFIRM_JOB_DELETION_MODEL.Type)}
        />
    )
}

export default JobDeletionModal
