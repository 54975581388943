import React, {useEffect} from 'react'
import {StyledRoot, StyledWrapper, StyledMainSection} from './style'
import Container from '@/components/ui/Container'
import Info from '@/features/contributors/components/my-profile/info/Info'
import {useTheme} from 'styled-components'
import {useNavigate, useParams} from 'react-router-dom'
import {ValidationError, handleApiError} from '@/utilities/helpers'
import Spinner from '@/components/ui/Spinner'
import UserVideosHistory from '@/features/users/components/user-video-history/UserVideoHistory'
import {useGetUserProfile} from '@/features/users/queries/useGetUserProfile'
import {ROUTE_HOME} from '@/utilities/constants'

const UserProfile: React.FC = () => {
    const navigate = useNavigate()
    const {contributorContainerSize} = useTheme()
    const {userId} = useParams()
    const {data, isLoading, isError, error} = useGetUserProfile({userId: `${userId}`})

    useEffect(() => {
        if (isError && error) {
            if (error?.response?.status === 403) {
                navigate(ROUTE_HOME)
            } else {
                handleApiError({error: error as ValidationError<string>})
            }
        }
    }, [isError])

    if (isLoading) return <Spinner size={48} overlay={true} />

    return (
        <StyledRoot>
            <StyledMainSection>
                <Container maxWidth={contributorContainerSize}>
                    <StyledWrapper>
                        {data && <Info userData={data} isMine={false} />}
                        <UserVideosHistory userId={userId} userName={data?.name} />
                    </StyledWrapper>
                </Container>
            </StyledMainSection>
        </StyledRoot>
    )
}

export default UserProfile

UserProfile.displayName = 'UserProfile'
