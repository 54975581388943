import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {httpEditEvent} from '../services/events.http'
import {EventCreationRequest} from '@/types/event'

export const useEditEvent = ({
    workspaceId,
    eventId,
    onSuccess
}: {
    workspaceId: string
    eventId: string
    onSuccess: () => void
}) => {
    const client = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.EDIT_EVENT, workspaceId, eventId],
        mutationFn: (data: EventCreationRequest) => httpEditEvent(workspaceId, eventId, data),
        onSuccess: () => {
            onSuccess()
            client.invalidateQueries({queryKey: [QUERY_KEYS.EVENTS, workspaceId]})
            client.invalidateQueries({queryKey: [QUERY_KEYS.WORKSPACE_EVENT_DETAILS, workspaceId, eventId]})
        }
    })
}
