import styled, {css} from 'styled-components'

export const StyledRoot = styled.div`
    height: 100%;
    overflow: auto;
`

export const StyledTitle = styled.h1`
    ${({theme: {typography, palette, spacing}}) => css`
        color: ${palette.gray.main};
        ${typography.title};
        text-align: center;
        padding: ${spacing * 16}px 0;
    `}
`

export const StyledSection = styled.div`
    ${({theme: {link, typography, palette, spacing}}) => css`
        padding: ${spacing * 8}px 0px;
        line-height: 27px;
        & h2 {
            color: ${palette.gray.main};
            ${typography.text1XL};
            font-weight: 700;
            margin-bottom: ${spacing * 4}px;
        }
        & h3 {
            font-weight: 700;
        }
        & p {
            margin-bottom: ${spacing * 3}px;
        }
        & span {
            font-weight: 600;
        }
        & a {
            ${link}
        }
        & ul {
            margin-left: ${spacing * 2}px;
            padding-left: ${spacing * 2}px;
            list-style: disc;
            line-height: normal;
        }
    `}
`
