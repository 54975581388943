import styled, {css} from 'styled-components'

export const StyledAddWrapper = styled.div`
    ${({theme: {palette, spacing, typography}}) => css`
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-wrap: nowrap;
        gap: ${spacing * 3}px;
        ${typography.textM};
        padding: ${spacing * 3}px 0;
        color: ${palette.gray.main};
        font-weight: 700;
        text-decoration-line: underline;
    `}
`
export const StyledBox = styled.div`
    ${({theme: {palette, typography, spacing, mediaQueries}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing * 8}px;
        color: ${palette.gray.main};
        ${typography.textM};
        font-weight: 700;
        padding: ${spacing * 3}px ${spacing * 3}px;
        border-bottom: 1px solid ${palette.gray.disabled};
        ${mediaQueries.m} {
            gap: ${spacing * 15}px;
        }
        ${mediaQueries.l} {
            gap: ${spacing * 32}px;
        }
    `}
`
export const StyledBoxItem = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing * 2}px;
        padding: ${spacing * 2}px;
    `}
`

export const StyledChevronIcon = styled.div`
    ${({isOpenFiltersSection}: {isOpenFiltersSection: boolean}) => css`
        margin-left: auto;
        display: flex;
        align-items: center;
        height: 24px;
        cursor: pointer;
        font-weight: normal;

        & svg {
            margin-left: 4px;
            width: 24px;
            height: 24px;
            ${!isOpenFiltersSection &&
            css`
                transform: rotate(180deg);
            `};
        }
    `}
`

export const StyledEventSection = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: grid;
        grid-template-columns: 1fr;
        gap: ${spacing * 6}px;
        margin: ${spacing * 6}px 0;

        ${mediaQueries.m} {
            grid-template-columns: 1fr 1fr;
        }

        ${mediaQueries.desktopXs} {
            grid-template-columns: 1fr 1fr 1fr;
        }

        ${mediaQueries.l} {
            grid-template-columns: 1fr 1fr 1fr;
        }
    `}
`

export const StyledActionsWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 16px 0px;
        gap: ${spacing * 3}px;
    `}
`

export const StyledInputWrapper = styled.div`
    width: 100%;
    max-width: 460px;
`
