import React from 'react'
import {StyledBackgroundImageRoot} from './style'

interface BackgroundFullScreenImageProps {
    children: React.ReactNode
    backgroundImage: string
}

const BackgroundFullScreenImage = ({children, backgroundImage}: BackgroundFullScreenImageProps) => (
    <StyledBackgroundImageRoot backgroundImage={backgroundImage}>{children}</StyledBackgroundImageRoot>
)

export default BackgroundFullScreenImage

BackgroundFullScreenImage.displayName = 'BackgroundFullScreenImage'
