import {useTranslation} from 'react-i18next'
import {StyledTabItem, StyledTabSectionWrapper} from './style'

type TabSectionProps = {
    tabsList: {
        id: string
        name: string
    }[]
    activeTabId: string
    setActiveTabId: (id: string) => void
}

const TabSection: React.FC<TabSectionProps> = ({tabsList, setActiveTabId, activeTabId}) => {
    const {t} = useTranslation()

    return (
        <StyledTabSectionWrapper>
            {tabsList.map(({id, name}) => {
                return (
                    <StyledTabItem
                        key={id}
                        isActive={activeTabId === id}
                        onClick={() => {
                            setActiveTabId(id)
                        }}
                    >
                        {t(name)}
                    </StyledTabItem>
                )
            })}
        </StyledTabSectionWrapper>
    )
}

export default TabSection

TabSection.displayName = 'TabSection'
