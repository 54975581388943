import {QUERY_KEYS} from '@/queryClient'
import {useInfiniteQuery} from '@tanstack/react-query'
import {httpGetUserVideos} from '../services/users.http'

export const useGetUserVideos = ({status, userId}: {status: string; userId: string}) =>
    useInfiniteQuery({
        queryKey: [QUERY_KEYS.USER_VIDEOS, status, userId],
        queryFn: ({pageParam}) => httpGetUserVideos(status, userId, pageParam),
        initialPageParam: '',
        getNextPageParam: lastPage => lastPage.meta.next_page_url
    })
