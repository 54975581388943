import styled, {DefaultTheme, css} from 'styled-components'

export const StyledRoot = styled.div`
    ${({theme: {mediaQueries, spacing}}) => css`
        display: flex;
        width: 330px;
        padding: ${spacing * 6}px;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 16px;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);

        ${mediaQueries.m} {
            max-width: 370px;
        }
    `}
`
export const StyledTypeSection = styled.div`
    ${({theme: {palette, spacing, typography}}) => css`
        ${typography.textS};
        color: ${palette.blue.main};
        font-weight: 700;
        display: flex;
        align-items: center;
        gap: ${spacing * 2}px;
    `}
`

export const StyledActionSection = styled.div`
    ${({theme: {spacing}}) => css`
        position: relative;
        display: flex;
        justify-content: space-between;
        margin-bottom: ${spacing * 8}px;
        & svg {
            cursor: pointer;
        }
    `}
`

export const StyledTooltipWrapper = styled.div`
    ${({theme: {palette, spacing, zIndex}}) => css`
        position: absolute;
        border-radius: 8px;
        padding: ${spacing * 3}px;
        width: 150px;
        box-shadow: 0px 2px 15px -3px rgba(0, 0, 0, 0.07), 0px 10px 20px -2px rgba(0, 0, 0, 0.04);
        right: -20px;
        top: 30px;
        background: ${palette.neutral.white};
        cursor: pointer;
        z-index: ${zIndex.modal};
    `}
`

export const StyledTooltip = styled.div`
    ${({theme: {palette, spacing, typography, zIndex}}) => css`
        ${typography.textM};
        color: ${palette.gray.main};
        font-weight: 700;
        display: flex;
        align-items: center;
        gap: ${spacing * 2}px;
        z-index: ${zIndex.modal};
        & svg {
            width: 20px;
            height: 20px;
        }
    `}
`
export const StyledOverlay = styled.div(
    ({theme: {zIndex}}: {theme: DefaultTheme}) => css`
        position: fixed;
        inset: 0;
        z-index: ${zIndex.modalOverlay};
    `
)
export const StyledTitle = styled.div`
    ${({theme: {palette, spacing}}) => css`
        font-size: 20px;
        color: ${palette.gray.main};
        font-weight: 700;
        display: flex;
        align-items: center;
        gap: ${spacing * 3}px;
        margin-bottom: ${spacing * 5}px;
    `}
`

export const StyledDataSection = styled.div`
    ${({theme: {palette, typography, spacing}}) => css`
        ${typography.textS};
        color: ${palette.gray.secondary};
        font-weight: 700;
        display: flex;
        gap: ${spacing * 6}px;
        margin-bottom: ${spacing * 6}px;
    `}
`

export const StyledButtonsSection = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing * 3}px;
    `}
`

export const StyledMainWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing * 3}px;
        flex-direction: column;
    `}
`
