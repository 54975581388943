import React from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {InfoIcon} from '@/assets/icons/icons'
import {useTheme} from 'styled-components'
import {
    StyledActionsWrapper,
    StyledBold,
    StyledLabel,
    StyledModal,
    StyledSubtitle,
    StyledUrlOverlay,
    StyledUrlText,
    StyledUrlWrapper
} from './style'
import Button from '@/components/ui/Button'

interface VideoInfoModalProps {
    onClose: () => void
    withShadow?: boolean
}

const VideoInfoModal: React.FC<VideoInfoModalProps> = ({onClose, withShadow}) => {
    const {t} = useTranslation()
    const theme = useTheme()

    return (
        <StyledModal
            maxWidth={400}
            overlay={!withShadow}
            title={'video_info_modal:title'}
            onClose={onClose}
            cancelButtonCallback={onClose}
            submitButtonText={'video_info_modal:button'}
            submitButtonCallback={onClose}
            withFooter={false}
            icon={<InfoIcon width={21} height={20} fill={theme.palette.success[500]} />}
            $withShadow={withShadow}
        >
            <StyledSubtitle>
                <Trans
                    t={t}
                    i18nKey="video_info_modal:subtitle"
                    components={{
                        b: <StyledBold />
                    }}
                />
            </StyledSubtitle>
            <StyledLabel>{t('video_info_modal:label')}</StyledLabel>
            <StyledUrlWrapper>
                <StyledUrlOverlay />
                <StyledUrlText>example/exampletitle.mp4</StyledUrlText>
            </StyledUrlWrapper>
            <StyledUrlWrapper>
                <StyledUrlOverlay />
                <StyledUrlText>example/exampletitle.mov</StyledUrlText>
            </StyledUrlWrapper>
            <StyledUrlWrapper>
                <StyledUrlOverlay />
                <StyledUrlText>example/exampletitle.wmv</StyledUrlText>
            </StyledUrlWrapper>
            <StyledUrlWrapper>
                <StyledUrlOverlay />
                <StyledUrlText>example/exampletitle.avi</StyledUrlText>
            </StyledUrlWrapper>
            <StyledLabel>{t('video_info_modal:external_sources_title')}</StyledLabel>
            <StyledSubtitle>
                <Trans
                    t={t}
                    i18nKey="video_info_modal:external_sources_description"
                    components={{
                        b: <StyledBold />
                    }}
                />
            </StyledSubtitle>
            <StyledActionsWrapper>
                <Button size="sm" onClick={onClose}>
                    {t('video_info_modal:button')}
                </Button>
            </StyledActionsWrapper>
        </StyledModal>
    )
}

export default VideoInfoModal

VideoInfoModal.displayName = 'VideoInfoModal'
