import styled, {css} from 'styled-components'

export const StyledRoot = styled.div`
    ${({theme: {mediaQueries, spacing}}) => css`
        display: flex;
        justify-content: space-between;
        gap: ${spacing * 7}px;
        flex-direction: column;
        width: 100%;
        padding-right: 0px;

        ${mediaQueries.m} {
            flex-direction: row;
            padding: 0px ${spacing * 10}px;
            justify-content: center;
            gap: ${spacing * 15}px;
            margin-top: ${spacing * 30}px;
        }
        ${mediaQueries.xl} {
            justify-content: space-between;
        }
    `}
`

export const StyledInfoSection = styled.div`
    display: flex;
    flex-direction: column;
`

export const StyledTitleWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: 0fr auto;
        gap: ${spacing * 3}px;
        align-self: center;
        align-items: center;
        margin-bottom: ${spacing * 12.5}px;
    `}
`

export const StyledTitle = styled.div`
    ${({theme: {palette, typography}}) => css`
        ${typography.title};
        color: ${palette.gray.main};
    `}
`

export const StyledLeaderBoardWrapper = styled.div`
    ${({theme: {mediaQueries, spacing}}) => css`
        ${mediaQueries.m} {
            margin-top: ${spacing * 26}px;
        }
    `}
`
