import React, {useEffect, useRef, useState} from 'react'
import {
    StyledInputWrapper,
    StyledListWrapper,
    StyledMainWrapper,
    StyledHelperText,
    StyledErrorMessage,
    StyledListItem,
    StyledCheckboxEmpty,
    StyledCheckboxSelected,
    StyledItemLabel,
    StyledInput,
    InputRadioWrapper,
    StyledRadioSelected
} from './style'
import Label from '@/components/ui/Label'
import {CheckIcon, SearchIcon} from '@/assets/icons/icons'
import {DefaultTFuncReturn, t} from 'i18next'
import {useController, useFormContext} from 'react-hook-form'
import {HttpListResponse, ValueLabel} from '@/types/commons'
import {ValidationError, handleApiError} from '@/utilities/helpers'
import Button from '@/components/ui/Button'

export interface ListSelectProps<AdapterParamType> {
    name: string
    label?: string | DefaultTFuncReturn
    helpText?: string | DefaultTFuncReturn
    errorMessage?: string | DefaultTFuncReturn
    height?: number
    placeholder: string
    multiple?: boolean
    fetchFunction: (filter: string, page?: string) => Promise<HttpListResponse<AdapterParamType>>
    adapterFunction: (data: HttpListResponse<AdapterParamType>) => ValueLabel[]
    withFilter?: boolean
    suggestedItem?: number
    disabledValues?: number[]
}

function ListSelect<AdapterParamType>({
    name,
    label,
    helpText,
    errorMessage,
    height,
    fetchFunction,
    placeholder,
    multiple = false,
    adapterFunction,
    withFilter = true,
    suggestedItem,
    disabledValues
}: ListSelectProps<AdapterParamType>) {
    const {control} = useFormContext()
    const {
        field: {value, onChange}
    } = useController({
        name,
        control,
        rules: {required: true}
    })
    const [filterValue, setFilterValue] = useState('')
    const [filterParam, setFilterParam] = useState('')
    const debounceTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)
    const [data, setData] = useState<ValueLabel[]>([])
    const [nextPage, setNextPage] = useState<string | undefined>(undefined)

    const fetchData = async (pageParam?: string) => {
        try {
            const response = await fetchFunction(filterParam, pageParam)
            setNextPage(response?.meta?.next_page_url?.split('=')[1])
            if (pageParam) {
                setData(value => [...value, ...adapterFunction(response)])
            } else {
                setData(adapterFunction(response))
            }
        } catch (error) {
            handleApiError({error: error as ValidationError<string>})
        }
    }

    useEffect(() => {
        fetchData()
    }, [filterParam])

    const onClickItem = (item: ValueLabel): void => {
        if (multiple) {
            if (value?.find((current: ValueLabel) => current.value === item.value)) {
                onChange({
                    target: {value: value.filter((current: ValueLabel) => item.value !== current.value)}
                })
            } else {
                onChange({target: {value: [...(value ?? []), item]}})
            }
        } else {
            onChange({target: {value: item}})
        }
    }

    useEffect(() => {
        if (debounceTimeoutRef.current) clearTimeout(debounceTimeoutRef.current)
    }, [])

    const onChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        const eventValue = event.target.value
        setFilterValue(eventValue)
        if (debounceTimeoutRef.current) clearTimeout(debounceTimeoutRef.current)
        debounceTimeoutRef.current = setTimeout(() => {
            setFilterParam(eventValue)
        }, 500)
    }

    const onClickLoadMore = () => {
        fetchData(nextPage)
    }

    return (
        <StyledMainWrapper>
            {label && <Label>{label}</Label>}
            {withFilter && (
                <StyledInputWrapper>
                    <input placeholder={placeholder} value={filterValue} onChange={onChangeFilter} />
                    <SearchIcon />
                </StyledInputWrapper>
            )}
            <StyledListWrapper isTopBorderVisible={!withFilter} style={{height: `${height ?? 300}px`}}>
                {data?.map((item: ValueLabel, index: number) => {
                    const isDisabled =
                        ((item.isDisabled ?? false) ||
                            disabledValues?.find(disabledValue => item.value === disabledValue) !== undefined) ??
                        false
                    return (
                        <StyledListItem
                            disabled={isDisabled}
                            key={`list_select_${name}_${index}`}
                            onClick={!isDisabled ? () => onClickItem(item) : undefined}
                        >
                            {multiple ? (
                                <>
                                    {value?.find((current: ValueLabel) => current.value === item.value) ? (
                                        <StyledCheckboxSelected disabled={isDisabled}>
                                            <CheckIcon />
                                        </StyledCheckboxSelected>
                                    ) : (
                                        <StyledCheckboxEmpty disabled={isDisabled} />
                                    )}
                                </>
                            ) : (
                                <InputRadioWrapper key={`${item.value}`}>
                                    <StyledInput
                                        type="radio"
                                        id={`${item.value}`}
                                        value={item.value}
                                        name={name}
                                        disabled={isDisabled}
                                        checked={item.value === value?.value}
                                        readOnly={true}
                                    />
                                    <StyledRadioSelected htmlFor={`${item.value}`} disabled={isDisabled} />
                                </InputRadioWrapper>
                            )}
                            <StyledItemLabel disabled={isDisabled} isSuggested={item.value === suggestedItem}>
                                {item.label}
                                {item.value === suggestedItem ? ` (${t('common:suggested')})` : ''}
                            </StyledItemLabel>
                        </StyledListItem>
                    )
                })}
                {nextPage ? (
                    <Button variant="transparent" size="xs" onClick={onClickLoadMore}>
                        {t('load_more:load_more')}
                    </Button>
                ) : null}
            </StyledListWrapper>
            {helpText && <StyledHelperText>{helpText}</StyledHelperText>}
            {errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
        </StyledMainWrapper>
    )
}

export default ListSelect

ListSelect.displayName = 'ListSelect'
