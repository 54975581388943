import styled, {css} from 'styled-components'

export const StyledTopDivider = styled.div`
    ${({theme: {palette, spacing, mediaQueries}}) => css`
        border-bottom: 1px solid ${palette.gray.lightBG};
        margin-bottom: ${spacing * 20}px;

        ${mediaQueries.l} {
            margin-bottom: ${spacing * 23}px;
        }
    `}
`
export const StyledList = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: grid;
        gap: ${spacing * 8}px;
        padding: ${spacing * 6}px 0 ${spacing * 2}px 0;
        justify-content: center;
        ${mediaQueries.m} {
            row-gap: ${spacing * 12}px;
            column-gap: ${spacing * 5}px;
            grid-template-columns: 1fr 1fr 1fr;
            padding-top: ${spacing * 6}px 0 ${spacing * 2}px 0;
        }
        ${mediaQueries.l} {
            row-gap: ${spacing * 8}px;
            column-gap: ${spacing * 11}px;
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    `}
`

export const StyledViewAll = styled.div`
    ${({theme: {flexUtils, palette, typography}}) => css`
        ${flexUtils.columnSpaceBetween};
        width: 100%;
        & div {
            ${typography.textXL}
            font-weight: 500;
            color: ${palette.gray.main};
        }

        & span {
            ${typography.textL}
            color: ${palette.blue.link};
            cursor: pointer;
        }
    `}
`

export const StyledBottomDivider = styled.div`
    ${({theme: {palette, spacing, mediaQueries}}) => css`
        border-bottom: 1px solid ${palette.gray.lightBG};
        margin-bottom: ${spacing * 20}px;
        margin-top: ${spacing * 6}px;

        ${mediaQueries.m} {
            margin-bottom: ${spacing * 7}px;
        }
    `}
`
