import React, {useEffect} from 'react'
import {StyledSubtitle} from './style'
import {useTranslation} from 'react-i18next'
import Modal from '@/components/commons/Modal'
import {handleApiError} from '@/utilities/helpers'
import Spinner from '@/components/ui/Spinner'
import {useMarkAsDone} from '@/features/videos/queries/useMarkAsDone'

interface MarkAsDoneModalProps {
    videoId: string
    workspaceId: string
    jobId: string
    userId: string
    onClose: () => void
}

const MarkAsDoneModal: React.FC<MarkAsDoneModalProps> = ({
    videoId,
    workspaceId,
    jobId,
    userId,
    onClose
}: MarkAsDoneModalProps) => {
    const {t} = useTranslation()
    const {mutate, isPending, isError, error} = useMarkAsDone({
        projectId: `${videoId}`,
        jobId: `${jobId}`,
        userId: `${userId}`,
        workspaceId: `${workspaceId}`,
        onSuccess: onClose
    })

    useEffect(() => {
        if (isError) handleApiError({error})
    }, [isError])

    const onClickClose = () => {
        onClose()
    }

    const onSubmit = () => {
        if (userId) mutate()
    }

    if (isPending) return <Spinner size={48} overlay />

    return (
        <Modal
            overlay
            title={'mark_as_done_modal:title'}
            onClose={onClickClose}
            cancelButtonCallback={onClickClose}
            submitButtonCallback={onSubmit}
            submitButtonText={'mark_as_done_modal:submit_button'}
        >
            <StyledSubtitle>{t('mark_as_done_modal:subtitle')}</StyledSubtitle>
        </Modal>
    )
}

export default MarkAsDoneModal

MarkAsDoneModal.displayName = 'MarkAsDoneModal'
