import VideoCreationForm from '@/features/videos/components/video-creation-form/VideoCreationForm'
import PageHeader from '@/components/commons/PageHeader'
import {useTranslation} from 'react-i18next'

const VideoCreation: React.FC = () => {
    const {t} = useTranslation()
    return (
        <>
            <PageHeader title={t('video_creation:title')} />
            <VideoCreationForm />
        </>
    )
}

export default VideoCreation

VideoCreation.displayName = 'VideoCreation'
