import styled, {css} from 'styled-components'

export const StyledTopDivider = styled.div`
    ${({theme: {palette, spacing}}) => css`
        border-bottom: 1px solid ${palette.gray.disabled};
        margin-bottom: ${spacing * 10}px;
    `}
`
export const StyledList = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: grid;
        gap: ${spacing * 8}px;
        padding: ${spacing * 6}px 0 ${spacing * 14}px 0;
        ${mediaQueries.m} {
            row-gap: ${spacing * 12}px;
            column-gap: ${spacing * 5}px;
            grid-template-columns: 1fr 1fr 1fr;
            padding: ${spacing * 10}px 0 ${spacing * 20}px 0;
        }
        ${mediaQueries.l} {
            row-gap: ${spacing * 8}px;
            column-gap: ${spacing * 11}px;
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    `}
`
export const StyledViewAllRoot = styled.div`
    ${({theme: {flexUtils, palette, typography}}) => css`
        ${flexUtils.columnSpaceBetween};
        width: 100%;
        & div {
            ${typography.text1XL}
            font-weight: 700;
            color: ${palette.gray.main};
        }

        & span {
            ${typography.textL}
            color: ${palette.blue.link};
            cursor: pointer;
        }
    `}
`
