import {Outlet} from 'react-router-dom'
import {StyledContributorLayout} from './styles'
import React from 'react'
import TopNav from '@/components/commons/TopNav/TopNav'

const ContributorLayout: React.FC = () => {
    return (
        <StyledContributorLayout>
            <TopNav />
            <Outlet />
        </StyledContributorLayout>
    )
}

export default ContributorLayout
