import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {z} from 'zod'
import {checkInputRemoveValue, handleApiError} from '@/utilities/helpers'
import WorkspaceActionModal from '@/components/commons/WorkspaceActionModal/WorkspaceActionModal'
import {useDeleteEvent} from '@/features/events/queries/useDeleteEvent'
import Spinner from '@/components/ui/Spinner'
import {Trans, useTranslation} from 'react-i18next'

const enum CONFIRM_JOB_DELETION_MODEL {
    Type = 'type'
}

const EventDeletionSchema = z.object({
    [CONFIRM_JOB_DELETION_MODEL.Type]: z
        .string()
        .min(1, {message: 'validation:required_field'})
        .refine(value => checkInputRemoveValue(value), {message: 'validation:invalid_value'})
        .default('')
})

type ValidationSchema = z.infer<typeof EventDeletionSchema>

export interface JobDeletionModalProps {
    onClose: () => void
    workspaceId: string
    eventId: string
    eventName: string
}

const EventDeletionModal: React.FC<JobDeletionModalProps> = ({onClose, workspaceId, eventId, eventName}) => {
    const {t} = useTranslation()
    const {mutate, isError, error, isPending} = useDeleteEvent({
        workspaceId,
        eventId,
        onSuccess: () => onClose()
    })

    useEffect(() => {
        if (isError) {
            handleApiError({error})
        }
    }, [isError])

    const {
        register,
        handleSubmit,
        formState: {errors, isValid, isDirty}
    } = useForm<ValidationSchema>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(EventDeletionSchema)
    })

    const onSubmit = () => {
        mutate()
    }

    if (isPending) return <Spinner size={48} />

    return (
        <WorkspaceActionModal
            onClose={onClose}
            title="event_deletion_modal:title"
            description="event_deletion_modal:description"
            subDescription={
                <div>
                    <Trans
                        t={t}
                        i18nKey={'event_deletion_modal:sub_description'}
                        values={{EVENT_NAME: eventName.length > 50 ? `${eventName.slice(0, 50)}...` : eventName}}
                        components={{
                            span: <span />
                        }}
                    />
                </div>
            }
            cancelButtonName="common:cancel"
            errorMessage={errors.type?.message || ''}
            handleSubmit={handleSubmit(onSubmit)}
            isConfirmButtonDisabled={!isValid || !isDirty || isPending}
            isSubmitting={isPending}
            {...register(CONFIRM_JOB_DELETION_MODEL.Type)}
        />
    )
}

export default EventDeletionModal
