import styled, {DefaultTheme, css} from 'styled-components'

export const StyledFormRoot = styled.form(
    ({theme: {spacing}}: {theme: DefaultTheme}) => css`
        padding: ${spacing * 8}px ${spacing * 8}px ${spacing * 6}px;
        display: flex;
        flex-direction: column;
        gap: ${spacing * 6}px;
    `
)

export const StyledHeaderContainer = styled.div(
    ({theme: {spacing, typography}}: {theme: DefaultTheme}) => css`
        display: grid;
        grid-template-areas: 'icon title' '. description';
        grid-template-columns: 0fr auto;
        gap: ${spacing * 2}px ${spacing * 4}px;
        h4 {
            ${typography.subtitle};
            grid-area: title;
        }
        & svg {
            grid-area: icon;
        }
    `
)

export const StyledDescription = styled.div`
    ${({theme: {palette, typography, spacing}}) => css`
        ${typography.textS};
        color: ${palette.gray.middle};
        display: grid;
        gap: ${spacing * 7}px;
        grid-area: description;
        & span {
            font-weight: 500;
        }
    `}
`

export const StyledFooter = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        padding: ${spacing * 3}px ${spacing * 4}px;
        justify-content: flex-end;
        align-items: center;
        gap: ${spacing * 2}px;
    `}
`

export const StyledFooterContainer = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 6}px;
        & button {
            text-decoration: none;
        }
    `}
`

export const StyledInputWrapper = styled.div`
    ${({theme: {spacing, typography, palette}}) => css`
        ${typography.textS}
        color: ${palette.gray.middle};
        & span {
            font-weight: 700;
        }
        & p {
            margin-bottom: ${spacing * 2}px;
        }
    `}
`

export const StyledBox = styled.div`
    ${({theme: {spacing, typography}}) => css`
        ${typography.textS}
        line-height: normal;
        display: grid;
        align-items: start;
        grid-template-columns: 0fr auto;
        gap: ${spacing * 2}px;
        & span {
            font-weight: 500;
        }
        & p {
            margin-top: ${spacing * 2}px;
        }
    `}
`
