import styled, {css} from 'styled-components'

export const StyledRoot = styled.div`
    ${({theme: {mediaQueries}}) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: fit-content;
        margin-top: 48px;
        ${mediaQueries.m} {
            margin-top: 80px;
        }
    `}
`
export const StyledFormRoot = styled.form`
    ${({theme: {mediaQueries}}) => css`
        width: 100%;
        text-align: center;
        display: grid;
        gap: 16px;
        ${mediaQueries.m} {
            width: 360px;
            gap: 30px;
        }
    `}
`
export const StyledTitle = styled.div`
    ${({theme: {palette, spacing, mediaQueries, typography}}) => css`
        ${typography.title};
        color: ${palette.gray.main};
        margin-bottom: ${spacing * 3}px;
        ${mediaQueries.m} {
            margin-bottom: ${spacing * 6}px;
        }
    `}
`
export const StyledSubTitle = styled.div`
    ${({theme: {typography, palette}}) => css`
        ${typography.subtitle};
        font-weight: 700;
        max-width: 100%;
        color: ${palette.gray.middle};
    `}
`

export const StyledDivider = styled.div`
    ${({theme: {palette}}) => css`
        width: 100%;
        max-width: 360px;
        height: 1px;
        background-color: ${palette.gray.xlight};
    `}
`
export const StyledTextWrap = styled.div`
    ${({theme: {palette, mediaQueries, typography}}) => css`
        display: flex;
        align-items: center;
        justify-self: center;
        color: ${palette.gray.middle};
        ${typography.textM};
        ${mediaQueries.m} {
            ${typography.textL};
        }
    `}
`
export const StyledText = styled.div`
    ${({theme: {palette, mediaQueries, typography}}) => css`
        color: ${palette.gray.main};
        ${typography.textS};
        font-weight: 700;
        text-decoration-line: underline;
        padding: 12px;
        cursor: pointer;
        ${mediaQueries.m} {
            ${typography.textM};
        }
    `}
`

export const StyledPasswordLink = styled.div`
    ${({theme: {palette, typography}}) => css`
        color: ${palette.blue.link};
        ${typography.textXS}
        cursor: pointer;
    `}
`

export const StyledBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
`
export const StyledButtonWrap = styled.div`
    ${({theme: {mediaQueries}}) => css`
        width: 100%;
        & button {
            margin: 0 auto;
        }
        ${mediaQueries.m} {
            width: 360px;
        }
    `}
`

export const StyledTextDivider = styled.div`
    ${({theme: {palette, spacing, typography, flexUtils}}) => css`
        color: ${palette.gray.middle};
        ${typography.textS};
        font-weight: 500;
        ${flexUtils.columnSpaceBetween};

        margin-top: ${spacing * 3}px;
        ::before,
        ::after {
            content: '';
            width: 100%;
            max-width: 150px;
            height: 1px;
            background: ${palette.gray.xlight};
        }
    `}
`
