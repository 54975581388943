import axios from '@/services/axiosInstance'
import {HttpListResponse} from '@/types/commons'
import {LeaderType} from '../types'

export const httpGetLeadersList = async ({pageParam, limit = 10}: {pageParam: string; limit?: number}) => {
    const url = `/contributors${pageParam}`

    const response = await axios.get<HttpListResponse<LeaderType>>(url, {params: {limit: limit}})
    return response.data
}
