import React, {useEffect} from 'react'
import {StyledSubtitle} from './style'
import {useTranslation} from 'react-i18next'
import Modal from '@/components/commons/Modal'
import {ValidationError, handleApiError} from '@/utilities/helpers'
import Spinner from '@/components/ui/Spinner'
import {useDeliverSubtitles} from '@/features/videos/queries/useDeliverySubtitles'
import {AxiosError} from 'axios'
import toast from 'react-hot-toast'

interface DeliverSubtitlesModalProps {
    videoId: string
    workspaceId: string
    jobId: string
    onClose: () => void
}

const DeliverSubtitlesModal: React.FC<DeliverSubtitlesModalProps> = ({videoId, workspaceId, jobId, onClose}) => {
    const {t} = useTranslation()

    const onClickClose = () => {
        onClose()
    }

    const {mutate, isPending, isError, error} = useDeliverSubtitles({
        projectId: `${videoId}`,
        jobId: `${jobId}`,
        workspaceId: `${workspaceId}`,
        onSuccess: onClickClose
    })

    useEffect(() => {
        if (isError) {
            const errorDetails = error as AxiosError
            if (errorDetails?.response?.status === 409) {
                toast.error(t('deliver_subtitles_modal:duplicated_annotation'))
            } else {
                handleApiError({error: error as ValidationError<string>})
            }
        }
    }, [isError])

    const onSubmit = () => {
        mutate()
    }

    if (isPending) return <Spinner size={48} overlay />

    return (
        <Modal
            overlay
            title={'deliver_subtitles_modal:title'}
            onClose={onClickClose}
            cancelButtonCallback={onClickClose}
            submitButtonCallback={onSubmit}
            submitButtonText={'mark_as_done_modal:submit_button'}
        >
            <StyledSubtitle>{t('deliver_subtitles_modal:subtitle')}</StyledSubtitle>
        </Modal>
    )
}

export default DeliverSubtitlesModal

DeliverSubtitlesModal.displayName = 'DeliverSubtitlesModal'
