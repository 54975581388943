export const ROUTE_HOME = '/'
export const ROUTE_WORKSPACES = '/workspaces'
export const ROUTE_LOGIN = '/login'
export const ROUTE_SIGNUP = '/signup'
export const ROUTE_SIGNUP_CONTRIBUTOR = '/signup-contributor'
export const ROUTE_SIGNUP_PROVIDER = '/signup-provider'
export const ROUTE_PAGE_NOT_FOUND = '/page-not-found'
export const ROUTE_VIDEO_CREATION = '/workspaces/:workspaceId/video-creation'
export const ROUTE_VIDEO_INGESTION = '/workspaces/:workspaceId/videos/:videoId'
export const ROUTE_EVENT_INGESTION = '/workspaces/:workspaceId/events/:eventId'
export const ROUTE_CHANGE_PASSWORD = '/change-password'
export const ROUTE_WORKSPACE_DETAILS = '/workspaces/:workspaceId'
export const ROUTE_EMAIL_ACTIVATION = '/auth/activate'
export const ROUTE_EUROPEANA_CALLBACK = '/auth/sso'
export const ROUTE_PASSWORD_RESET = '/password-reset/:token'
export const ROUTE_VIDEOS = '/videos'
export const ROUTE_EVENTS = '/events'
export const ROUTE_LEADERBOARD = '/leaderboard'
export const ROUTE_HOW_IT_WORKS = '/how-it-works'
export const ROUTE_THE_PROJECT = '/the-project'
export const ROUTE_MY_PROFILE = '/my-profile'
export const ROUTE_VIDEO_DETAILS = '/videos/:videoId'
export const ROUTE_TERMS_AND_CONDITIONS = '/terms'
export const ROUTE_PRIVACY_POLICY = '/privacy-policy'
export const ROUTE_EVENT_DETAILS = '/events/:eventId'
export const ROUTE_WORKSPACE_EVENT_DETAILS = '/workspaces/:workspaceId/events/:eventId'
export const ROUTE_USER_PROFILE = '/user/:userId'
export const ROUTE_EVENTS_LIST = '/events/list/:status'
