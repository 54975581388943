import styled, {css} from 'styled-components'

export const StyledSectionWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        margin: ${spacing * 8}px 0;
        max-width: 600px;
    `}
`

export const StyledListsWrapper = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        margin: ${spacing * 8}px 0;
        max-width: 600px;
        display: flex;
        gap: ${spacing * 4}px;
        ${mediaQueries.s} {
            gap: ${spacing * 8}px;
        }
    `}
`

export const StyledActionsWrapper = styled.div`
    ${() => css`
        display: flex;
        align-items: center;
        justify-content: space-between;
    `}
`
