import React, {useEffect} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {useForm, SubmitHandler} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {z} from 'zod'
import Modal from '@components/commons/Modal'
import Input from '@components/ui/Input'
import Spinner from '@components/ui/Spinner'
import {StyledFormRoot} from './style'
import {ValidationError, handleApiError} from '@utilities/helpers.ts'
import useAuthStore from '@/store/authStore'
import {ROUTE_WORKSPACE_DETAILS, USER_SIGNUP_SOURCE} from '@/utilities/constants'
import {generatePath, useNavigate} from 'react-router-dom'
import {Workspace} from '../../types'
import {useCreateWorkspace} from '../../queries/useCreateWorkspace'

const enum CREATE_WORKSPACE_MODEL {
    Name = 'name'
}

const CreateWorkspaceSchema = z.object({
    [CREATE_WORKSPACE_MODEL.Name]: z.string().min(1, {message: 'validation:required_name'}).default('')
})

type ValidationSchema = z.infer<typeof CreateWorkspaceSchema>

export interface CreateWorkspaceModalProps {
    onClose: () => void
}

const CreateWorkspaceModal: React.FC<CreateWorkspaceModalProps> = ({onClose}) => {
    const {t} = useTranslation()
    const onSuccess = ({data}: {data: Workspace}) => {
        onClose()
        navigate(generatePath(ROUTE_WORKSPACE_DETAILS, {workspaceId: `${data?.id}`}))
    }
    const {mutate, isPending, isError, error} = useCreateWorkspace({onSuccess})
    const user = useAuthStore(store => store.user)
    const isEuropeanaUser = user?.signupSource === USER_SIGNUP_SOURCE.europeana
    const navigate = useNavigate()

    const {
        register,
        handleSubmit,
        setError,
        formState: {errors, isValid, isDirty}
    } = useForm<ValidationSchema>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(CreateWorkspaceSchema)
    })
    useEffect(() => {
        if (isError) handleApiError({setError, error: error as ValidationError<'name'>})
    }, [isError])

    const onSubmit: SubmitHandler<ValidationSchema> = values => {
        mutate(values)
    }

    return (
        <Modal
            overlay
            title={t('workspace:create_workspace:title')}
            onClose={onClose}
            cancelButtonCallback={onClose}
            isSubmitButtonDisabled={!isValid || !isDirty}
            submitButtonCallback={handleSubmit(onSubmit)}
            submitButtonText={`${t('workspace:create_workspace:create')}`}
        >
            {isPending && <Spinner size={48} overlay={false} />}
            <StyledFormRoot onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <Trans
                        t={t}
                        i18nKey="workspace:create_workspace:description"
                        values={{
                            context: isEuropeanaUser ? 'public' : 'private'
                        }}
                        components={{span: <span />}}
                    />
                </div>
                <Input
                    {...register(CREATE_WORKSPACE_MODEL.Name)}
                    label={`${t('common:name')}*`}
                    errorMessage={`${t(errors.name?.message || '')}`}
                    maxLength={40}
                />
            </StyledFormRoot>
        </Modal>
    )
}

export default CreateWorkspaceModal

CreateWorkspaceModal.displayName = 'CreateWorkspaceModal'
