import {useTranslation} from 'react-i18next'
import Logo from '@assets/images/logo.png'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {ROUTE_LOGIN, ROUTE_SIGNUP, ROUTE_VIDEOS, USER_ROLE_CONTRIBUTOR, routes} from '@utilities/constants'
import {DropdownArrowIcon, MenuIcon, SearchIcon} from '@/assets/icons/icons'
import Button from '@/components/ui/Button'
import {
    StyledLogo,
    StyledInputWrapper,
    HeaderGrid,
    StyledButtonWrapper,
    StyledAvatarWrapper,
    StyledLogoWrapper,
    StyledHeaderWrapper
} from './style'
import Input from '@/components/ui/Input'
import Avatar from '@/components/ui/Avatar'
import {useRef, useState} from 'react'
import DropDownMenu from '@/components/commons/DropDownMenu'
import Nav from '../Nav/Nav'
import useAuthStore from '@/store/authStore'
import useFiltersParams from '@/hooks/useFiltersParams'
import {FILTERS_PARAMS} from '@/types/video'
import {useClickOutside} from '@/hooks/useClickOutside'
import useLayoutStore from '@/store/layoutStore'

const Header = () => {
    const {t} = useTranslation()
    const accessToken = useAuthStore(store => store.accessToken)
    const user = useAuthStore(store => store.user)
    const navigate = useNavigate()
    const {pathname} = useLocation()
    const navWrapperRef = useRef<HTMLDivElement | null>(null)
    const [isOpenDropDownMenu, setIsOpenDropDownMenu] = useState(false)
    const [videoSearchValue, setVideoSearchValue] = useState('')
    const hasSearchInput = !user || (!!user && user?.type === USER_ROLE_CONTRIBUTOR)
    const [filtersParams] = useFiltersParams()
    const setIsNavOpen = useLayoutStore(store => store.setIsNavOpen)
    const isNavOpen = useLayoutStore(store => store.isNavOpen)

    const onClickOutSide = () => {
        setIsNavOpen(false)
    }

    const onChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        const eventValue = event.target.value
        setVideoSearchValue(eventValue)
    }

    const onSearchClick = () => {
        navigate(`${ROUTE_VIDEOS}?${FILTERS_PARAMS.Name}=${videoSearchValue}`)
    }

    const checkKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.code === 'Enter') {
            e.preventDefault()
            onSearchClick()
        }
    }

    useClickOutside({
        element: navWrapperRef?.current,
        callback: onClickOutSide,
        condition: isNavOpen && !!navWrapperRef?.current,
        dependencies: [navWrapperRef?.current, isNavOpen]
    })

    const handleOpen = () => {
        setIsNavOpen(!isNavOpen)
    }

    return (
        <StyledHeaderWrapper>
            <HeaderGrid>
                <StyledLogoWrapper>
                    {user?.type === USER_ROLE_CONTRIBUTOR && (
                        <div ref={navWrapperRef}>
                            <MenuIcon onClick={handleOpen} />
                            {isNavOpen && <Nav onCloseMenu={() => setIsNavOpen(false)} />}
                        </div>
                    )}
                    <Link to={user?.type === USER_ROLE_CONTRIBUTOR ? routes.HOME.path : routes.WORKSPACE.path}>
                        <StyledLogo src={Logo} alt={'logo'} />
                    </Link>
                </StyledLogoWrapper>

                <div>
                    {hasSearchInput && (
                        <StyledInputWrapper>
                            <Input
                                placeholder={`${t('header:search_input_placeholder')}`}
                                icon={<SearchIcon />}
                                iconCallback={onSearchClick}
                                onChange={onChangeFilter}
                                onKeyDown={e => checkKeyDown(e)}
                                defaultValue={filtersParams[FILTERS_PARAMS.Name] ?? ''}
                            />
                        </StyledInputWrapper>
                    )}
                </div>

                {accessToken ? (
                    <StyledAvatarWrapper>
                        <Avatar name={user?.name ?? ''} size={43} />
                        <DropdownArrowIcon onClick={() => setIsOpenDropDownMenu(!isOpenDropDownMenu)} />
                        <DropDownMenu isOpen={isOpenDropDownMenu} onClose={() => setIsOpenDropDownMenu(false)} />
                    </StyledAvatarWrapper>
                ) : (
                    <StyledButtonWrapper>
                        <Button
                            size="sm"
                            variant={pathname === ROUTE_LOGIN ? 'primary' : 'transparent'}
                            onClick={() => navigate(ROUTE_LOGIN)}
                        >
                            {t('common:log_in')}
                        </Button>
                        <Button
                            size="sm"
                            variant={pathname !== ROUTE_LOGIN ? 'primary' : 'transparent'}
                            onClick={() => navigate(ROUTE_SIGNUP)}
                        >
                            {t('common:sign_up')}
                        </Button>
                    </StyledButtonWrapper>
                )}
            </HeaderGrid>
        </StyledHeaderWrapper>
    )
}

export default Header
