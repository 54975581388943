import {useMutation} from '@tanstack/react-query'
import {httpInviteMember} from '../services/workspaces.http'
import {MUTATION_KEYS} from '@/queryClient'
type UseInviteMemberProps = {
    onSuccess: () => void
}
export const useInviteMember = ({onSuccess}: UseInviteMemberProps) =>
    useMutation({
        mutationKey: [MUTATION_KEYS.INVITE_MEMBER],
        mutationFn: ({
            workspaceId,
            email,
            emailConfirmation
        }: {
            workspaceId: number
            email: string
            emailConfirmation: string
        }) => httpInviteMember({workspaceId, email, emailConfirmation}),
        onSuccess
    })
