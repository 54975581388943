import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {StyledDropdownRoot, StyledMenuItem, StyledMenuWrapper} from './style'
import {DeleteIcon, DotsVerticalIcon, DownloadIcon, HistoryIcon} from '@/assets/icons/icons'
import DropDown from '@/components/ui/DropDown'
import {useTheme} from 'styled-components'
import {JobItem} from '@/types/job'
import {JOB_STATUS} from '@/utilities/constants/video'

interface DropdownMenuProps {
    item: JobItem
    onClickDeleteTargetLanguage: (item: JobItem) => void
    onClickDownloadSrt: (item: JobItem) => void
    onClickViewJobStatusHistory: (item: JobItem) => void
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
    item,
    onClickDeleteTargetLanguage,
    onClickDownloadSrt,
    onClickViewJobStatusHistory
}) => {
    const {t} = useTranslation()
    const theme = useTheme()
    const [openMenuId, setOpenMenuId] = useState<number | undefined>(undefined)
    const isReadyForAudit = item.status === JOB_STATUS.ReadyForAudit
    const isProcessing = item.status === JOB_STATUS.Processing
    const isDelivered = item.status === JOB_STATUS.Delivered
    const isRejected = item.status === JOB_STATUS.Rejected
    const isCompleted = item.status === JOB_STATUS.Completed

    const handleDelete = () => {
        if (!isDelivered) {
            onClickDeleteTargetLanguage(item)
            setOpenMenuId(undefined)
        }
    }

    return (
        <StyledDropdownRoot>
            <DotsVerticalIcon fill={theme.palette.neutral['900']} onClick={() => setOpenMenuId(item.id)} height={20} />
            <DropDown isOpen={openMenuId === item.id} onClose={() => setOpenMenuId(undefined)}>
                <StyledMenuWrapper>
                    {(isReadyForAudit || isRejected || isCompleted || isDelivered) && (
                        <>
                            <StyledMenuItem
                                onClick={() => {
                                    onClickDownloadSrt(item)
                                    setOpenMenuId(undefined)
                                }}
                            >
                                <div>
                                    <DownloadIcon height={18} width={18} fill={theme.palette.gray.dark} />
                                </div>
                                <span>{t('video_ingestion:download_srt')}</span>
                            </StyledMenuItem>
                        </>
                    )}

                    {!isProcessing && (
                        <>
                            {!!item.subtitlesUrl && (
                                <>
                                    <StyledMenuItem onClick={() => window.open(item.subtitlesUrl, '_blank')}>
                                        <div>
                                            <DownloadIcon height={18} width={18} fill={theme.palette.gray.dark} />
                                        </div>
                                        {t('video_ingestion:download_original_srt')}
                                    </StyledMenuItem>
                                </>
                            )}
                        </>
                    )}

                    <>
                        <StyledMenuItem
                            onClick={() => {
                                onClickViewJobStatusHistory(item)
                                setOpenMenuId(undefined)
                            }}
                        >
                            <div>
                                <HistoryIcon height={15} width={15} fill={theme.palette.gray.dark} />
                            </div>
                            <span>{t('video_ingestion:history')}</span>
                        </StyledMenuItem>
                    </>
                    <>
                        <StyledMenuItem onClick={handleDelete} disabled={isDelivered}>
                            <div>
                                <DeleteIcon
                                    height={18}
                                    width={18}
                                    fill={isDelivered ? theme.palette.gray.disabled : theme.palette.gray.dark}
                                />
                            </div>
                            <span>{t('video_ingestion:delete_job')}</span>
                        </StyledMenuItem>
                    </>
                </StyledMenuWrapper>
            </DropDown>
        </StyledDropdownRoot>
    )
}

export default DropdownMenu

DropdownMenu.displayName = 'DropdownMenu'
