import styled, {css} from 'styled-components'

export const StyledSubtitle = styled.span`
    ${({theme: {palette, spacing}}) => css`
        color: ${palette.gray};
        font-weight: normal;
        display: block;
        margin-bottom: ${spacing * 4}px;
    `}
`
