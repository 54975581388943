import {VideoCreationRequest, VideoDetails, VideoStatus, VideoType} from '@/types/video'
import axios from '@/services/axiosInstance'
import {JobCreationRequest, JobItem, JobStatusHistoryItem} from '@/types/job'
import {HttpListResponse} from '@/types/commons'
import {EuropeanaVideoType} from '../components/video-creation-form/add-video-modal/AddVideoModal'

export const httpCreateUrlVideo = (workspaceId: string, data: VideoCreationRequest) => {
    const url = `/workspaces/${workspaceId}/projects/url`
    return axios.post<VideoDetails>(url, data).then(({data}) => data)
}

export const httpCreateEuropeanaVideo = (workspaceId: string, data: VideoCreationRequest) => {
    const url = `/europeana/workspaces/${workspaceId}/projects`
    return axios.post<VideoDetails>(url, data).then(({data}) => data)
}

export const httpGetVideoDetails = (workspaceId: string, videoId: string) => {
    const url = `/workspaces/${workspaceId}/projects/${videoId}`
    return axios.get<VideoDetails>(url).then(({data}) => data)
}

export const httpGetJobStatusHistory = (videoId: string, jobId: string) => {
    const url = `/projects/${videoId}/jobs/${jobId}/jobsStatusHistory`
    return axios.get<JobStatusHistoryItem[]>(url).then(({data}) => data)
}

export const httpCreateJob = (videoId: string, data: JobCreationRequest) => {
    const url = `/projects/${videoId}/jobs`
    return axios.post<JobItem>(url, data).then(({data}) => data)
}

export const httpDeleteJob = (videoId: string, jobId: string) => {
    const url = `/projects/${videoId}/jobs/${jobId}`
    return axios.delete(url).then(({data}) => data)
}

export const httpGetVideosList = async ({
    name = undefined,
    page,
    per_page,
    sourceLanguageId,
    targetLanguageId,
    duration,
    availability,
    sortBy,
    direction = 'asc'
}: {
    name?: string
    page: number | string
    per_page: number
    sourceLanguageId?: number | string
    targetLanguageId?: number | string
    duration?: string
    availability?: string
    sortBy?: string
    direction?: string
}) => {
    const url = `/jobs`
    const response = await axios.get<HttpListResponse<VideoType>>(url, {
        params: {
            limit: per_page,
            page,
            name,
            sourceLanguageId,
            targetLanguageId,
            duration,
            status: availability,
            sortBy,
            direction
        }
    })
    return response.data
}

export const httpGetVideoInformation = async (videoId: string, hash?: string | null) => {
    const url = `/jobs/${videoId}`
    const response = await axios.get<VideoType>(url, {params: {t: hash}})
    return response.data
}

export const httpVideoAssign = async (projectId: string, videoId: string) => {
    const url = `/projects/${projectId}/jobs/${videoId}/assignments`
    const response = await axios.post(url)
    return response.data
}

export const httpUpdateVideoAssignment = async (projectId: string, videoId: string, status: VideoStatus) => {
    const url = `/projects/${projectId}/jobs/${videoId}/assignments`
    const response = await axios.patch(url, {status})
    return response.data
}

export const httpMarkAsDone = async (projectId: string, jobId: string, userId: string) => {
    const url = `/projects/${projectId}/jobs/${jobId}/assignments/${userId}`
    const response = await axios.post(url)
    return response.data
}

export const httpReject = async (projectId: string, jobId: string, userId: string) => {
    const url = `/projects/${projectId}/jobs/${jobId}/assignments/${userId}`
    const response = await axios.delete(url)
    return response.data
}

export const httpPublish = async (projectId: string, jobId: string) => {
    const url = `/projects/${projectId}/jobs/${jobId}`
    const response = await axios.patch(url, {status: 'ready'})
    return response.data
}

export const httpEditJobEvent = async (projectId: string, jobId: string, eventId: string | null) => {
    const url = `/projects/${projectId}/jobs/${jobId}/events`
    const response = await axios.patch(url, {eventId})
    return response.data
}

export const httpGetEuropeanaVideos = async (workspaceId: string, name?: string, page?: string) => {
    const url = `/europeana/workspaces/${workspaceId}/projects`
    const response = await axios.get<HttpListResponse<EuropeanaVideoType>>(url, {params: {name, page}})
    return response.data
}

export const httpRetryJob = async (projectId: string, jobId: string) => {
    const url = `/projects/${projectId}/jobs/${jobId}/retry`
    const response = await axios.patch(url)
    return response.data
}

export const httpDeliverySubtitles = async (workspaceId: string, projectId: string, jobId: string) => {
    const url = `/europeana/workspaces/${workspaceId}/projects/${projectId}/jobs/${jobId}/delivery`
    const response = await axios.post(url)
    return response.data
}
