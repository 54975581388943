import {AcceptIcon} from '@/assets/icons/icons'
import {useTranslation} from 'react-i18next'
import Button from '@/components/ui/Button'
import {useAcceptEventRequest} from '../../queries/useAcceptEventRequest'
import {useEffect} from 'react'
import {ValidationError, handleApiError} from '@/utilities/helpers'
import {useParams} from 'react-router-dom'
import Spinner from '@/components/ui/Spinner'

const AcceptEventRequest = ({userId}: {userId: string}) => {
    const {t} = useTranslation()
    const {workspaceId, eventId} = useParams()

    const {mutate, isPending, isError, error} = useAcceptEventRequest({
        workspaceId: `${workspaceId}`,
        eventId: `${eventId}`
    })

    useEffect(() => {
        if (isError) handleApiError({error: error as ValidationError<string>})
    }, [isError])

    const handleAccept = () => {
        mutate({userId: `${userId}`})
    }

    return (
        <div>
            {isPending && <Spinner size={48} />}
            <Button size="xs" onClick={handleAccept}>
                <AcceptIcon />
                {t('event_ingestion:accept_request')}
            </Button>
        </div>
    )
}

export default AcceptEventRequest

AcceptEventRequest.displayName = 'AcceptEventRequest'
