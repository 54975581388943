import {useTranslation} from 'react-i18next'
import {
    StyledCloseButton,
    StyledContainer,
    StyledDeleteIcon,
    StyledInput,
    StyledItem,
    StyledRoot,
    StyledSelectedMembersWrapper,
    StyledTitle
} from './style'
import {useEffect, useMemo, useState} from 'react'
import {ChevronUpIcon, DeleteIcon, InviteIcon, SearchIcon} from '@/assets/icons/icons'
import Input from '@/components/ui/Input'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import Member from '../member-component/Member'
import {useTheme} from 'styled-components'
import WorkspaceUnassignModal from '../workspace-unassign-modal/WorkspaceUnassignModal'
import {useGetMembers} from '../../queries/useGetMembers'
import {handleApiError} from '@/utilities/helpers'
import Spinner from '@/components/ui/Spinner'
import {useGetWorkspaceDetails} from '../../queries/useGetWorkspaceDetails'
import {useParams} from 'react-router-dom'
import InviteMemberModal from '../invite-member-modal/InviteMemberModal'
import useAuthStore from '@/store/authStore'
import Button from '@/components/ui/Button'
import StatusModal from '@/components/commons/StatusModal'

const MembersSidebar = () => {
    const {t} = useTranslation()
    const {width} = useWindowDimensions()
    const theme = useTheme()
    const {workspaceId} = useParams()
    const {
        isLoading: isLoadingMembers,
        data: membersData,
        isError: isErrorMembers,
        error: errorMembers
    } = useGetMembers({workspaceId: `${workspaceId}`})
    const {
        isLoading: isLoadingWorkspace,
        data: workspaceData,
        isError: isErrorWorkspace,
        error: errorWorkspace
    } = useGetWorkspaceDetails({id: `${workspaceId}`})

    const members = membersData?.data ?? []

    const [isSideNavOpen, setSideNavOpen] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [isWorkspaceUnassignModal, setIsWorkspaceUnassignModal] = useState(false)
    const [selectedMultipleMembers, setSelectedMultipleMembers] = useState<number[]>([])
    const [selectedSingleMember, setSelectedSingleMember] = useState<number[]>([])
    const [isInviteMembersModal, setInviteMembersModal] = useState(false)
    const [isSuccessModalOpen, setSuccessModalOpen] = useState(false)
    const user = useAuthStore(store => store.user)

    const imOwner = workspaceData?.user?.id === user?.id
    const hasFilterPanel = (!workspaceData?.isDefault || !!workspaceData?.isPublic) && !!members?.length

    useEffect(() => {
        if (isErrorMembers && errorMembers instanceof Error) {
            handleApiError({error: errorMembers})
        }
    }, [isErrorMembers])

    useEffect(() => {
        if (isErrorWorkspace && errorWorkspace instanceof Error) {
            handleApiError({error: errorWorkspace})
        }
    }, [isErrorWorkspace])

    useEffect(() => {
        if (width >= theme.breakpoints.width.xxl) {
            setSideNavOpen(true)
        }
    }, [width])

    const filteredMembers = useMemo(() => {
        setSelectedSingleMember([])
        setSelectedMultipleMembers([])
        return members?.filter(member => member?.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
    }, [searchValue, members?.length])

    const handleDeleteUser = (memberId: number) => {
        setSelectedSingleMember([memberId])
        setIsWorkspaceUnassignModal(true)
    }

    return isLoadingMembers || isLoadingWorkspace ? (
        <Spinner size={48} />
    ) : (
        <>
            <StyledContainer isSideNavOpen={isSideNavOpen}>
                <StyledCloseButton isSideNavOpen={isSideNavOpen} onClick={() => setSideNavOpen(!isSideNavOpen)}>
                    <ChevronUpIcon />
                </StyledCloseButton>

                <StyledRoot isSideNavOpen={isSideNavOpen}>
                    <StyledTitle>
                        <h4>{t('common:members')}</h4>
                        {!!workspaceData?.isPublic && imOwner && (
                            <Button variant={'primary'} size={'sm'} onClick={() => setInviteMembersModal(true)}>
                                <InviteIcon />
                                {t('common:invite')}
                            </Button>
                        )}
                    </StyledTitle>
                    {hasFilterPanel && (
                        <>
                            <StyledInput>
                                <Input
                                    placeholder={`${t('workspace:search_member')}`}
                                    icon={<SearchIcon />}
                                    onChange={e => setSearchValue(e.target.value)}
                                />
                            </StyledInput>
                            {/* TODO add filtering with member status if status is added */}
                            {/* <StyledFilterTabWrapper>
                            <StyledFilterTab
                                isActive={activeFilter === FILTER_TYPE.all}
                                onClick={() => setActiveFilter(FILTER_TYPE.all)}
                            >
                                {t('common:all')}
                            </StyledFilterTab>
                            <StyledFilterTab
                                isActive={activeFilter === FILTER_TYPE.active}
                                onClick={() => setActiveFilter(FILTER_TYPE.active)}
                            >
                                {t('common:active')}
                            </StyledFilterTab>
                            <StyledFilterTab
                                isActive={activeFilter === FILTER_TYPE.pending}
                                onClick={() => setActiveFilter(FILTER_TYPE.pending)}
                            >
                                {t('common:pending')}
                            </StyledFilterTab>
                        </StyledFilterTabWrapper> */}
                        </>
                    )}

                    {!!workspaceData?.user && (
                        <Member
                            member={workspaceData?.user}
                            setSelectedMembers={setSelectedMultipleMembers}
                            selectedMembers={selectedMultipleMembers}
                            ownerId={workspaceData?.user?.id}
                            memberId={workspaceData?.user?.id}
                        />
                    )}
                    <StyledSelectedMembersWrapper>
                        {!!selectedMultipleMembers.length && (
                            <>
                                <span
                                    onClick={() => setSelectedMultipleMembers(filteredMembers.map(member => member.id))}
                                >
                                    {t('workspace:select_all')}
                                </span>
                                <StyledItem onClick={() => setSelectedMultipleMembers([])}>
                                    {t('workspace:deselect_all')}
                                </StyledItem>

                                <StyledDeleteIcon onClick={() => setIsWorkspaceUnassignModal(true)}>
                                    <DeleteIcon fill={theme.palette.gray.dark} />
                                    <p>({selectedMultipleMembers.length})</p>
                                </StyledDeleteIcon>
                            </>
                        )}
                    </StyledSelectedMembersWrapper>

                    {filteredMembers?.map(member => {
                        return (
                            <Member
                                key={member.id}
                                member={member}
                                setSelectedMembers={setSelectedMultipleMembers}
                                selectedMembers={selectedMultipleMembers}
                                handleDeleteUser={handleDeleteUser}
                                ownerId={parseInt(`${workspaceData?.user?.id}`)}
                                memberId={member.id}
                            />
                        )
                    })}
                </StyledRoot>

                {isWorkspaceUnassignModal && (
                    <WorkspaceUnassignModal
                        onClose={() => {
                            setSelectedSingleMember([])
                            setIsWorkspaceUnassignModal(false)
                        }}
                        selectedMembers={selectedSingleMember.length ? selectedSingleMember : selectedMultipleMembers}
                        workspaceId={workspaceData?.id}
                        onSuccess={() => {
                            setSelectedSingleMember([])
                            setSelectedMultipleMembers([])
                            setIsWorkspaceUnassignModal(false)
                        }}
                    />
                )}
            </StyledContainer>
            {isInviteMembersModal && (
                <InviteMemberModal
                    onClose={() => setInviteMembersModal(false)}
                    setSuccessModalOpen={setSuccessModalOpen}
                    workspaceId={workspaceData?.id}
                />
            )}
            {isSuccessModalOpen && (
                <StatusModal
                    overlay
                    variant={'success'}
                    title={'workspace:success_modal:title'}
                    onClose={() => {
                        setSuccessModalOpen(false)
                    }}
                >
                    {t('workspace:success_modal:description')}
                </StatusModal>
            )}
        </>
    )
}

export default MembersSidebar

MembersSidebar.displayName = 'MembersSidebar'
