import {SubmitHandler, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import Container from '@/components/ui/Container'
import {
    StyledButtonWrap,
    StyledContentWrapper,
    StyledDescription,
    StyledFormRoot,
    StyledSubTitle,
    StyledText,
    StyledTextDivider,
    StyledTextWrap,
    StyledTitle
} from './style'
import Button from '@/components/ui/Button'
import {ROUTE_LOGIN} from '@/utilities/constants'
import {Trans, useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {EyeIcon, EyeInvisibleIcon} from '@/assets/icons/icons'
import {useEffect, useState} from 'react'
import Input from '@/components/ui/Input'
import Spinner from '@/components/ui/Spinner'
import {ValidationError, handleApiError} from '@/utilities/helpers'
import RegistrationCompleteModal from '@/features/authentication/components/registration-complete-modal/RegistrationCompleteModal'
import {
    PROVIDER_MODEL,
    ProviderValidationSchema,
    signupProviderFormSchema
} from '@/features/signup-provider/services/signupProviderFormModel'
import {useSignupProvider} from './queries/useSignupProvider'
import useAuthStore from '@/store/authStore'

const SignupProvider = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [isOpenRegistrationCompleteModal, setIsOpenRegistrationCompleteModal] = useState(false)
    const {mutate, isPending, isError, error} = useSignupProvider()
    const loginEuropeana = useAuthStore(store => store.loginEuropeana)

    const {
        register,
        handleSubmit,
        setError,
        formState: {errors, isValid}
    } = useForm<ProviderValidationSchema>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(signupProviderFormSchema)
    })

    useEffect(() => {
        if (isError) handleApiError({setError, error: error as ValidationError<'name'>})
    }, [isError])

    const onSuccess = () => {
        setIsOpenRegistrationCompleteModal(true)
    }

    const onSubmit: SubmitHandler<ProviderValidationSchema> = data => {
        mutate(data, {onSuccess})
    }

    const handleCloseRegistrationCompleteModal = () => {
        setIsOpenRegistrationCompleteModal(false)
        navigate(ROUTE_LOGIN)
    }

    return (
        <>
            {isPending && <Spinner size={48} />}
            <Container>
                <StyledFormRoot onSubmit={handleSubmit(onSubmit)}>
                    <StyledTitle>{t('common:sign_up')}</StyledTitle>
                    <StyledSubTitle>{t('sign_up:subtitle')}</StyledSubTitle>
                    <StyledDescription>
                        <Trans
                            t={t}
                            i18nKey="sign_up:description"
                            components={{
                                span: <span />
                            }}
                        />
                    </StyledDescription>
                    <StyledButtonWrap>
                        <Button variant="secondary" onClick={loginEuropeana}>
                            {t('common:signin_europeana')}
                        </Button>
                        <StyledTextDivider>{t('common:or')}</StyledTextDivider>
                    </StyledButtonWrap>
                    <StyledContentWrapper>
                        <Input
                            {...register(PROVIDER_MODEL.Name)}
                            label={`${t('sign_up:form:name')}*`}
                            errorMessage={`${t(errors[PROVIDER_MODEL.Name]?.message || '')}`}
                        />
                        <Input
                            {...register(PROVIDER_MODEL.Email)}
                            label={`${t('sign_up:form:email')}*`}
                            type={'email'}
                            errorMessage={`${t(errors[PROVIDER_MODEL.Email]?.message || '')}`}
                        />
                        <Input
                            {...register(PROVIDER_MODEL.Password)}
                            label={`${t('sign_up:form:password')}*`}
                            type={showPassword ? 'text' : 'password'}
                            icon={showPassword ? <EyeIcon /> : <EyeInvisibleIcon />}
                            iconCallback={() => setShowPassword(!showPassword)}
                            errorMessage={`${t(errors[PROVIDER_MODEL.Password]?.message || '')}`}
                        />
                        <Input
                            {...register(PROVIDER_MODEL.PasswordConfirmation)}
                            label={`${t('sign_up:form:confirm_password')}*`}
                            type={showConfirmPassword ? 'text' : 'password'}
                            icon={showConfirmPassword ? <EyeIcon /> : <EyeInvisibleIcon />}
                            iconCallback={() => setShowConfirmPassword(!showConfirmPassword)}
                            errorMessage={`${t(errors[PROVIDER_MODEL.Password]?.message || '')}`}
                        />
                    </StyledContentWrapper>
                    <StyledButtonWrap>
                        <Button type={'submit'} disabled={!isValid}>
                            {t('common:sign_up')}
                        </Button>
                    </StyledButtonWrap>
                    <StyledTextWrap>
                        <span>{t('sign_up:has_account')}</span>
                        <StyledText onClick={() => navigate(ROUTE_LOGIN)}>{t('common:log_in')}</StyledText>
                    </StyledTextWrap>
                </StyledFormRoot>

                {isOpenRegistrationCompleteModal && (
                    <RegistrationCompleteModal onClose={handleCloseRegistrationCompleteModal}>
                        <div>{t('sign_up:registration_complete_modal:text')}</div>
                    </RegistrationCompleteModal>
                )}
            </Container>
        </>
    )
}

export default SignupProvider

SignupProvider.displayName = 'SignupProvider'
