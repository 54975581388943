import StatusModal from '@/components/commons/StatusModal'
import {FC, ReactNode} from 'react'

interface ModalProps {
    children: ReactNode
    onClose?: () => void
}

const RegistrationCompleteModal: FC<ModalProps> = ({children, onClose}) => {
    return (
        <>
            <StatusModal overlay title={'sign_up:registration_complete_modal:title'} onClose={onClose}>
                <div>{children}</div>
            </StatusModal>
        </>
    )
}

RegistrationCompleteModal.displayName = 'RegistrationCompleteModal'
export default RegistrationCompleteModal
