import Container from '@/components/ui/Container'
import {StyledRoot, StyledTitle, StyledVideoSection} from './style'
import {useTranslation} from 'react-i18next'
import VideoFilters from '@/features/videos/components/video-filters/VideoFilters'
import {useTheme} from 'styled-components'
import PreviewVideosList from '@/features/videos/components/preview-videos-list/PreviewVideosList'
import React, {useEffect} from 'react'
import {useGetVideosList} from '@/features/videos/queries/useGetVideosList'
import {handleApiError} from '@/utilities/helpers'
import Spinner from '@/components/ui/Spinner'
import Pagination from '@/components/commons/Pagination/Pagination'
import ContributorContainer from '@/components/ui/ContributorContainer/ContributorContainer'
import useFiltersParams from '@/hooks/useFiltersParams'
import {FILTERS_PARAMS} from '@/types/video'
import EmptySection from '@/components/commons/EmptySection/EmptySection'

const VideosPage: React.FC = () => {
    const {t} = useTranslation()
    const {contributorContainerSize} = useTheme()
    const [filtersParams, setFiltersParams] = useFiltersParams()

    const per_page = 24
    const {data, error, isLoading, isError} = useGetVideosList({
        name: filtersParams.name,
        page: filtersParams.page ?? 1,
        sourceLanguageId: filtersParams.sourceLanguage,
        targetLanguageId: filtersParams.targetLanguage,
        duration: filtersParams.duration,
        availability: filtersParams.availability,
        sortBy: filtersParams.sortBy,
        per_page
    })

    useEffect(() => {
        if (isError && error instanceof Error) {
            handleApiError({error})
        }
    }, [isError])

    const addVideoSearchParams = (key: string, value: string) => {
        //reset page number when choose filter
        setFiltersParams('page', undefined)
        setFiltersParams(key, value)
    }

    return (
        <StyledRoot>
            <ContributorContainer>
                <Container maxWidth={contributorContainerSize}>
                    <StyledTitle>
                        {t('common:videos')}
                        <p>{t('videos_page:description')}</p>
                    </StyledTitle>
                    <VideoFilters handleFilterClickCallback={addVideoSearchParams} filters={filtersParams} />
                    <StyledVideoSection>
                        {!isLoading ? (
                            <>
                                {data && !!data?.data.length ? (
                                    <PreviewVideosList videos={data?.data} />
                                ) : (
                                    <EmptySection title={'videos_page:no_videos'} />
                                )}
                            </>
                        ) : (
                            <Spinner size={48} inline={true} overlay={false} />
                        )}
                        {data && (
                            <Pagination
                                paginationData={data.meta}
                                onPageChange={(page: number) => setFiltersParams(FILTERS_PARAMS.Page, `${page}`)}
                            />
                        )}
                    </StyledVideoSection>
                </Container>
            </ContributorContainer>
        </StyledRoot>
    )
}

export default VideosPage

VideosPage.displayName = 'VideosPage'
