import {create} from 'zustand'
import {persist} from 'zustand/middleware'

type LayoutStore = {
    isNavOpen: boolean
    setIsNavOpen: (isOpen: boolean) => void
}

const useLayoutStore = create<LayoutStore>()(
    persist(
        set => ({
            isNavOpen: false,
            setIsNavOpen: (isOpen: boolean) => set({isNavOpen: isOpen})
        }),
        {
            name: 'layout-storage'
        }
    )
)

export default useLayoutStore
