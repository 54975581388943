import axios from './axiosInstance'
import {
    AuthState,
    SignupContributorFormData,
    SignupProviderFormData,
    ResetPasswordData,
    ChangePasswordFormData,
    PasswordResetFormData,
    LoginFormData,
    EuropeanaLoginResponse,
    EuropeanaCallbackParams
} from '@/types/auth.ts'

export const httpLogin = (values: LoginFormData) => {
    return axios.post<AuthState>('/auth/login', values)
}

export const httpLogout = () => {
    const url = '/auth/logout'
    return axios.post(url)
}

export const httpUserMe = () => {
    const url = '/profile'
    return axios.get(url)
}

export const httpRecoveryPassword = (data: ResetPasswordData) => {
    const url = '/password-recovery'
    return axios.post(url, data)
}

export const httpResetPassword = (data: ResetPasswordData) => {
    const url = '/change-password'
    return axios.post(url, data)
}

export const httpSignupContributor = (values: SignupContributorFormData) => {
    const url = '/sign-up/contributor'
    return axios.post<AuthState>(url, values)
}

export const httpSignupProvider = (values: SignupProviderFormData) => {
    const url = '/sign-up/provider'
    return axios.post<AuthState>(url, values)
}

export const httpChangePassword = (values: ChangePasswordFormData) => {
    const url = '/change-password'
    return axios.post<AuthState>(url, values)
}

export const httpEmailActivation = (value: string | null) => {
    const url = '/auth/activate'
    return axios.post<AuthState>(url, {activationToken: value})
}

export const httpPasswordReset = (values: PasswordResetFormData) => {
    const url = '/password-reset'
    return axios.post<AuthState>(url, values)
}

export const httpEuropeanaLogin = () => {
    return axios.post<EuropeanaLoginResponse>('/auth-europeana/login')
}

export const httpEuropeanaCallback = (params: EuropeanaCallbackParams) => {
    return axios.post<AuthState>('/auth-europeana/callback', params)
}
