import styled, {DefaultTheme, css} from 'styled-components'
import {EventStatus} from '@/types/event'

const StatusLabelColors = ({theme: {palette}}: {theme: DefaultTheme}) => ({
    scheduled: css`
        color: ${palette.eventStatus.scheduled.text};
        background-color: ${palette.eventStatus.scheduled.background};
        border: 1px solid ${palette.eventStatus.scheduled.border};
    `,
    open: css`
        color: ${palette.eventStatus.open.text};
        background-color: ${palette.eventStatus.open.background};
        border: 1px solid ${palette.eventStatus.open.border};
    `,
    closed: css`
        color: ${palette.eventStatus.closed.text};
        background-color: ${palette.eventStatus.closed.background};
        border: 1px solid ${palette.eventStatus.closed.border};
    `
})

type StyledBtnProps = {
    status: EventStatus
}

export const StyledStatusLabel = styled.div<StyledBtnProps>`
    ${({theme: {spacing, typography}}) => css`
        ${typography.textXS}
        line-height: normal;
        padding: ${spacing * 2}px;
        border-radius: 16px;
        width: fit-content;
    `}
    ${({status, theme}) => StatusLabelColors({theme})[status]};
`
