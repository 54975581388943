import styled, {css} from 'styled-components'

export const StyledDeliveryButtonWrapper = styled.div`
    display: flex;
    align-items: center;
`

export const StyledDeliverIcon = styled.div`
    height: 24px;
    display: flex;
    align-items: center;

    & svg {
        height: 18px;
        width: 18px;
    }
`

export const StyledInfoIcon = styled.div`
    ${({theme: {palette}}) => css`
        height: 16px;
        display: flex;
        align-items: center;
        position: relative;

        & svg {
            height: 12px;
            width: 12px;
            & path {
                fill: ${palette.neutral.white};
            }
        }
    `}
`
