import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {httpPublish} from '@/features/videos/services/video.http'
import {useMutation, useQueryClient} from '@tanstack/react-query'

export interface PublishRequest {
    projectId: string
    jobId: string
    workspaceId?: string
    onSuccess: () => void
}

export const usePublish = ({projectId, jobId, workspaceId, onSuccess}: PublishRequest) => {
    const client = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.PUBLISH],
        mutationFn: () => httpPublish(projectId, jobId),
        onSuccess: () => {
            onSuccess()
            client.invalidateQueries({queryKey: [QUERY_KEYS.WORKSPACE_VIDEO_DETAILS, workspaceId, projectId]})
            client.invalidateQueries({queryKey: [QUERY_KEYS.JOB_STATUS_HISTORY, projectId]})
        }
    })
}
