import React, {useEffect, useRef, useState} from 'react'
import {StyledTooltip} from './style'
import {useClickOutside} from '@/hooks/useClickOutside'

interface TooltipProps {
    children: React.ReactNode
    onClose: () => void
    minWidth?: number
    isOpen: boolean
    tooltipElement?: HTMLDivElement | null
}

const Tooltip = ({children, isOpen, onClose, minWidth, tooltipElement}: TooltipProps) => {
    const divRef = useRef<HTMLDivElement | null>(null)
    const [isOutOfScreen, setIsOutOfScreen] = useState(false)
    const clickOutsideElement = tooltipElement ?? divRef?.current

    useClickOutside({
        element: clickOutsideElement,
        callback: onClose,
        condition: isOpen && !!clickOutsideElement,
        dependencies: [clickOutsideElement, isOpen]
    })

    const checkIfOutOfScreen = () => {
        const divRect = divRef?.current?.getBoundingClientRect()
        const isPartiallyBeyondRight = (divRect?.left ?? 0) + (divRect?.width ?? 0) > window.innerWidth

        return isPartiallyBeyondRight
    }

    const getDivOffset = () => {
        const divRect = divRef?.current?.getBoundingClientRect()
        const divWidth = divRect?.width ?? 0

        return divWidth
    }

    useEffect(() => {
        setIsOutOfScreen(checkIfOutOfScreen())
    }, [isOpen])

    return isOpen ? (
        <StyledTooltip ref={divRef} offset={isOutOfScreen ? getDivOffset() : 0} minWidth={minWidth}>
            {children}
        </StyledTooltip>
    ) : null
}

export default Tooltip

Tooltip.displayName = 'Tooltip'
