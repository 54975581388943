import styled, {css} from 'styled-components'

export const StyledRoot = styled.div`
    overflow: auto;
    height: 100%;
`

export const StyledTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const StyledTitle = styled.div`
    ${({theme: {palette, typography, spacing, mediaQueries}}) => css`
        ${typography.titleM};
        font-weight: 700;
        color: ${palette.gray.main};
        margin-top: ${spacing * 5}px;
        ${mediaQueries.m} {
            margin-top: ${spacing * 10}px;
        }
        ${mediaQueries.xl} {
            margin-top: ${spacing * 12}px;
        }
    `}
`

export const StyledSubTitle = styled.div`
    ${({theme: {typography, palette, mediaQueries}}) => css`
        line-height: 27px;
        max-width: 100%;
        color: ${palette.gray.middle};
        text-align: center;

        ${mediaQueries.m} {
            ${typography.textXL};
        }
    `}
`

export const StyledContentWrapper = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        margin-top: ${spacing * 14}px;
        ${mediaQueries.l} {
            margin-top: ${spacing * 16}px;
        }
    `}
`
