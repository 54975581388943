import {Navigate, Outlet, useLocation} from 'react-router-dom'
import {routes} from '@/utilities/constants'
import useAuthStore from '@/store/authStore'
import {useEffect} from 'react'

const PrivateRoute = () => {
    const {accessToken} = useAuthStore(store => store)
    const userMe = useAuthStore(store => store.userMe)
    const location = useLocation()

    useEffect(() => {
        if (accessToken) userMe()
    }, [location.pathname])

    return accessToken ? <Outlet /> : <Navigate to={routes.LOGIN.path} />
}

export default PrivateRoute
