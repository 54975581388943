import {Navigate, Outlet, useLocation} from 'react-router-dom'
import React, {useEffect} from 'react'
import TopNav from '@/components/commons/TopNav/TopNav'
import {StyledCommonLayout} from './style'
import Header from '@/components/commons/Header'
import {USER_ROLE_CONTRIBUTOR, routes} from '@/utilities/constants'
import useAuthStore from '@/store/authStore'

const CommonLayout: React.FC = () => {
    const {accessToken, user} = useAuthStore(store => store)
    const userRole = user?.type
    const {pathname} = useLocation()
    const redirectToCorrectHomePage = accessToken && pathname === routes.HOME.path && userRole !== USER_ROLE_CONTRIBUTOR

    const userMe = useAuthStore(store => store.userMe)

    useEffect(() => {
        if (accessToken) userMe()
    }, [pathname])

    return (
        <StyledCommonLayout>
            <div>
                <Header />
                <TopNav />
            </div>
            {redirectToCorrectHomePage ? <Navigate to={routes.WORKSPACE.path} /> : <Outlet />}
        </StyledCommonLayout>
    )
}

export default CommonLayout
