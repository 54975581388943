import VideoInformation from '@/features/videos/components/video-ingestion/video-information/VideoInformation'
import VideoJobs from '@/features/videos/components/video-ingestion/video-jobs/VideoJobs'

const VideoIngestion: React.FC = () => {
    return (
        <>
            <VideoInformation />
            <VideoJobs />
        </>
    )
}

export default VideoIngestion

VideoIngestion.displayName = 'VideoIngestion'
