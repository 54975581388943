import {FC, ReactNode, useEffect, useRef, useState} from 'react'
import {StyledDropDownMenu, StyledOverlay} from './style'

interface DropDown {
    children: ReactNode
    onClose?: () => void
    width?: number
    isOpen?: boolean
}

const DropDown: FC<DropDown> = ({children, onClose, isOpen}) => {
    const divRef = useRef<HTMLDivElement | null>(null)
    const [isOutOfScreen, setIsOutOfScreen] = useState(false)

    const checkIfOutOfScreen = () => {
        const divRect = divRef?.current?.getBoundingClientRect()
        const isPartiallyBelow = (divRect?.top ?? 0) + (divRect?.height ?? 0) + 20 > window.innerHeight
        return isPartiallyBelow
    }

    const getDivOffset = () => {
        const divRect = divRef?.current?.getBoundingClientRect()
        const divHeight = divRect?.height ?? 0
        return divHeight
    }

    useEffect(() => {
        setIsOutOfScreen(checkIfOutOfScreen())
    }, [isOpen])

    return (
        <>
            {isOpen && (
                <>
                    <StyledOverlay onClick={onClose} />
                    <StyledDropDownMenu ref={divRef} offset={isOutOfScreen ? getDivOffset() : 0}>
                        {children}
                    </StyledDropDownMenu>
                </>
            )}
        </>
    )
}

DropDown.displayName = 'DropDown'
export default DropDown
