import styled, {css} from 'styled-components'

export const StyledDetailsRoot = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 6}px;
    `}
`

export const StyledVideoCardWrapper = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        width: 100%;
        display: grid;
        gap: ${spacing * 5}px;
        width: 100%;
        margin-top: ${spacing * 13}px;

        ${mediaQueries.m} {
            gap: ${spacing * 5}px;
            grid-template-columns: 0fr 1fr;
            margin-top: ${spacing * 17}px;
        }
        ${mediaQueries.l} {
            margin-top: ${spacing * 20}px;
            gap: ${spacing * 12}px;
        }
    `}
`

export const StyledVideoInfo = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 4}px;
    `}
`

export const StyledName = styled.div`
    ${({theme: {palette, truncateText, typography}}) => css`
        ${typography.textXXL};
        font-weight: 700;
        line-height: 32px;
        color: ${palette.gray.main};
        margin-top: -10px;

        ${truncateText};
        max-width: 500px;
    `}
`

export const StyledLanguageWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        line-height: 27px;
        display: grid;
        gap: ${spacing * 2}px;
    `}
`

export const StyledPreviewLink = styled.a`
    ${({theme: {spacing, palette}}) => css`
        display: flex;
        gap: ${spacing * 2}px;
        align-items: center;
        color: ${palette.blue.link};
        width: fit-content;
        & span {
            cursor: pointer;
        }

        & svg {
            cursor: pointer;
        }
    `}
`

export const StyledDescription = styled.div`
    ${({theme: {typography}}) => css`
        ${typography.textXL};
        line-height: 27px;
        word-break: break-all;
    `}
`

export const StyledProjectDescription = styled.div`
    ${({theme: {typography}}) => css`
        ${typography.textL};
        word-break: break-all;
    `}
`

export const StyledDivider = styled.div`
    ${({theme: {palette}}) => css`
        width: 100%;
        border-top: 1px solid ${palette.gray.xlight};
    `}
`

export const StyledButtonSection = styled.div`
    ${({theme: {spacing, palette}}) => css`
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: ${spacing * 6}px;
        text-align: center;

        & span {
            color: ${palette.gray.main};
            line-height: 27px;
        }
    `}
`

export const StyledThumbnailWrapper = styled.div`
    ${({theme: {mediaQueries}}) => css`
        height: 260px;
        position: relative;
        ${mediaQueries.m} {
            height: 160px;
            width: 215px;
        }

        ${mediaQueries.l} {
            height: 240px;
            width: 330px;
        }
    `}
`

export const StyledImage = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
`

export const StyledLoginMessage = styled.div`
    ${({theme: {palette}}) => css`
        color: ${palette.neutral[900]};
    `}
`

export const StyledLink = styled.a`
    ${({theme: {palette}}) => css`
        text-decoration: none;
        cursor: pointer;
        color: ${palette.blue.link};
    `}
`

export const StyledEventLink = styled.b`
    ${({theme: {palette}}) => css`
        text-decoration: none;
        cursor: pointer;
        color: ${palette.blue.link};
    `}
`
