import Container from '@/components/ui/Container'
import {useTranslation} from 'react-i18next'
import {
    StyledButtonSection,
    StyledContentWrapper,
    StyledFormRoot,
    StyledSubTitle,
    StyledText,
    StyledTextDivider,
    StyledTextWrap,
    StyledTitle
} from './style'
import Button from '@/components/ui/Button'
import {useNavigate} from 'react-router-dom'
import {ROUTE_LOGIN, ROUTE_SIGNUP_CONTRIBUTOR, ROUTE_SIGNUP_PROVIDER} from '@/utilities/constants'

const SignUp = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    return (
        <Container>
            <StyledFormRoot>
                <StyledTitle>{t('common:sign_up')}</StyledTitle>
                <StyledSubTitle>{t('sign_up:subtitle')}</StyledSubTitle>
                <StyledContentWrapper>
                    <StyledButtonSection>
                        <Button onClick={() => navigate(ROUTE_SIGNUP_CONTRIBUTOR)}>{t('common:sign_up')}</Button>
                        <StyledTextDivider>{t('common:or')}</StyledTextDivider>
                        <Button onClick={() => navigate(ROUTE_SIGNUP_PROVIDER)} variant={'outline'}>
                            {t('sign_up:sign_up_provider')}
                        </Button>
                    </StyledButtonSection>
                </StyledContentWrapper>
                <StyledTextWrap>
                    <span>{t('sign_up:has_account')}</span>
                    <StyledText onClick={() => navigate(ROUTE_LOGIN)}>{t('common:log_in')}</StyledText>
                </StyledTextWrap>
            </StyledFormRoot>
        </Container>
    )
}

export default SignUp

SignUp.displayName = 'SignUp'
