import {InvitationType} from '@/features/workspaces/types'
import {LOCAL_STORAGE_EVENT_INVITATION_LINK, LOCAL_STORAGE_WORKSPACE_INVITATION} from './constants'

const workspaceInvitation = {
    set: (invitation: InvitationType | null) => {
        localStorage.setItem(LOCAL_STORAGE_WORKSPACE_INVITATION, JSON.stringify(invitation))
    },
    get: () => {
        const invitation = localStorage.getItem(LOCAL_STORAGE_WORKSPACE_INVITATION)
        return invitation ? JSON.parse(invitation) : null
    },
    remove: () => {
        localStorage.removeItem(LOCAL_STORAGE_WORKSPACE_INVITATION)
    }
}

const eventInvitationLink = {
    set: (invitation: string | null) => {
        localStorage.setItem(LOCAL_STORAGE_EVENT_INVITATION_LINK, JSON.stringify(invitation))
    },
    get: () => {
        const invitation = localStorage.getItem(LOCAL_STORAGE_EVENT_INVITATION_LINK)
        return invitation ? JSON.parse(invitation) : null
    },
    remove: () => {
        localStorage.removeItem(LOCAL_STORAGE_EVENT_INVITATION_LINK)
    }
}

export const LocalStorageManager = {
    workspaceInvitation,
    eventInvitationLink
}
