import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {httpAcceptInvitation} from '../services/workspaces.http'
import {InvitationType} from '../types'
import {GeneralError} from '@/utilities/helpers'
import {AxiosResponse} from 'axios'

type UseInviteMemberProps = {
    onSuccess: () => void
}

export const useAcceptInvitation = ({onSuccess}: UseInviteMemberProps) => {
    const client = useQueryClient()
    return useMutation<AxiosResponse, GeneralError, InvitationType>({
        mutationKey: [MUTATION_KEYS.ACCEPT_INVITATION],
        mutationFn: ({workspaceId, invitationId, token}: InvitationType) =>
            httpAcceptInvitation({workspaceId, invitationId, token}),
        onSuccess: () => {
            onSuccess()
            client.invalidateQueries({queryKey: [QUERY_KEYS.WORKSPACES_LIST]})
        }
    })
}
