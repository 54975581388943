import React from 'react'
import {StyledContainer} from '@/components/ui/Container/style'

interface ContainerProps {
    className?: string
    children: React.ReactNode
    fullWidth?: boolean
    fullHeight?: boolean
    maxWidth?: number
    isPositionRight?: boolean
}

const Container = ({
    children,
    className,
    fullWidth = false,
    fullHeight = true,
    maxWidth = 1920,
    isPositionRight = false
}: ContainerProps) => (
    <StyledContainer
        fullWidth={fullWidth}
        fullHeight={fullHeight}
        className={className}
        maxWidth={maxWidth}
        isPositionRight={isPositionRight}
    >
        {children}
    </StyledContainer>
)

export default Container

Container.displayName = 'Container'
