import React, {useEffect} from 'react'
import ProfileVideoCard from '../profile-video-card/ProfileVideoCard'
import {VideoType} from '@/types/video'
import {useGetAssignedVideos} from '@/features/contributors/queries/useGetAssignedVideos'
import {ValidationError, handleApiError} from '@/utilities/helpers'
import Spinner from '@/components/ui/Spinner'
import {StyledDivider, StyledProfileVideosListRoot, StyledTitle} from './style'
import {useTranslation} from 'react-i18next'
import LoadMore from '@/components/ui/LoadMore'
import EmptySection from '@/components/commons/EmptySection/EmptySection'

interface ProfileVideosListProps {
    title: string
    status: string
}

const ProfileVideosList: React.FC<ProfileVideosListProps> = ({title, status}) => {
    const {t} = useTranslation()
    const {data, error, fetchNextPage, hasNextPage, isFetching, isLoading, isFetchingNextPage, isError} =
        useGetAssignedVideos({status})
    const total = data?.pages[0]?.meta?.total

    useEffect(() => {
        if (isError) handleApiError({error: error as ValidationError<string>})
    }, [isError])

    return isLoading ? (
        <Spinner size={48} inline={true} overlay={false} />
    ) : (
        <StyledProfileVideosListRoot>
            <StyledTitle>{t(title)}</StyledTitle>
            <StyledDivider />
            {data?.pages?.at(0)?.data.length ? (
                <>
                    {data?.pages.map((page, index) => (
                        <React.Fragment key={index}>
                            {page?.data?.map((video: VideoType) => (
                                <ProfileVideoCard key={video.id} video={video} />
                            ))}
                        </React.Fragment>
                    ))}

                    {total > 1 && (
                        <LoadMore
                            hasNextPage={hasNextPage}
                            isFetchingNextPage={isFetchingNextPage}
                            isFetching={isFetching}
                            fetchNextPage={fetchNextPage}
                        />
                    )}
                </>
            ) : (
                <EmptySection title={'contributor_my_profile:videos:no_videos'} />
            )}
        </StyledProfileVideosListRoot>
    )
}

export default ProfileVideosList

ProfileVideosList.displayName = 'ProfileVideosList'
