import axios from '@/services/axiosInstance'
import {RankType} from '../types'

export const httpGetRankingList = () => {
    const url = '/leaderboard'
    return axios.get<RankType[]>(url)
}

export const httpCreateRank = (data: FormData) => {
    const url = '/leaderboard'
    return axios.post(url, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const httpEditRank = (data: FormData, rankId: string) => {
    const url = `/leaderboard/${rankId}`
    return axios.patch(url, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const httpDeleteRank = (rankId: number[]) => {
    const url = `/leaderboard`
    const data = {leaderboardIds: rankId}
    return axios.delete(url, {data})
}
