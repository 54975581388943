import React from 'react'
import {
    StyledAffiliationLink,
    StyledAffiliationWrapper,
    StyledDescription,
    StyledInfoRoot,
    StyledUserInfoWrapper,
    StyledUserName,
    StyledUserRole
} from './style'
import Avatar from '@/components/ui/Avatar'
import useAuthStore from '@/store/authStore'
import {Trans, useTranslation} from 'react-i18next'
import {getLocalDate} from '@/utilities/helpers'
import {USER_SIGNUP_SOURCE} from '@/utilities/constants'

const ProfileCard: React.FC = () => {
    const {t} = useTranslation()
    const user = useAuthStore(store => store.user)
    const localDate = user?.createdAt ? getLocalDate(user?.createdAt, 'MMM D, YYYY') : '--'

    return (
        <StyledInfoRoot>
            <StyledUserInfoWrapper>
                <Avatar name={user?.name ?? ''} size={80} />
                <StyledUserName>
                    {user?.name}
                    <small>{user?.username}</small>
                    <p>{t('my_profile:info:start_date', {DATE: localDate})}</p>
                </StyledUserName>
            </StyledUserInfoWrapper>
            <StyledUserRole>
                <Trans
                    t={t}
                    i18nKey="my_profile:info:user_role"
                    values={{
                        USER_ROLE: user?.type
                    }}
                    components={{
                        span: <span />
                    }}
                />
            </StyledUserRole>
            {user?.signupSource === USER_SIGNUP_SOURCE.europeana && (
                <StyledAffiliationWrapper>
                    <StyledAffiliationLink
                        target="_blank"
                        href={user?.affiliation ?? ''}
                        title={user?.affiliationName ?? ''}
                    >
                        {user?.affiliationName ?? ''}
                    </StyledAffiliationLink>
                </StyledAffiliationWrapper>
            )}
            <StyledDescription>
                <span>{t('my_profile:info:description')}</span>
            </StyledDescription>
        </StyledInfoRoot>
    )
}

export default ProfileCard

ProfileCard.displayName = 'ProfileCard'
