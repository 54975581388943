import styled, {css} from 'styled-components'

export const StyledRoot = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: grid;
        gap: ${spacing * 7}px;
        padding: ${spacing * 8}px 0;
        justify-content: center;
        ${mediaQueries.m} {
            row-gap: ${spacing * 8}px;
            column-gap: ${spacing * 5}px;
            grid-template-columns: 1fr 1fr 1fr;
        }
        ${mediaQueries.l} {
            gap: ${spacing * 8}px;
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    `}
`
