import {forwardRef, InputHTMLAttributes} from 'react'

import {StyledInput, StyledLabel, StyledInputRoot} from './style'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string
    name?: string
}

const Checkbox = forwardRef<HTMLInputElement, InputProps>(({label, name, disabled, id, ...rest}, ref) => {
    return (
        <StyledInputRoot>
            <StyledInput type="checkbox" id={id} name={name} disabled={disabled} ref={ref} {...rest} />
            <StyledLabel htmlFor={id} disabled={disabled}>
                {label}
            </StyledLabel>
        </StyledInputRoot>
    )
})

export default Checkbox
