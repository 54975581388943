import {useTranslation} from 'react-i18next'
import {StyledViewAllRoot} from './style'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import {useTheme} from 'styled-components'
import {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {SCREEN_SIZE} from '@/types/commons'

interface ViewAllSectionProps<initialArrayType> {
    title: string
    initialArray: initialArrayType[]
    onViewAllCallback: Dispatch<SetStateAction<initialArrayType[]>>
}

function ViewAllSection<initialArrayType>({
    title,
    initialArray,
    onViewAllCallback
}: ViewAllSectionProps<initialArrayType>) {
    const {t} = useTranslation()
    const {width} = useWindowDimensions()
    const theme = useTheme()
    const [screenSize, setScreenSize] = useState<SCREEN_SIZE>(SCREEN_SIZE.DESKTOP)
    const [viewAll, setViewAll] = useState(false)

    const NUMBERS_OF_ITEMS_TO_SHOW = {
        [SCREEN_SIZE.MOBILE]: 1,
        [SCREEN_SIZE.TABLET]: 3,
        [SCREEN_SIZE.DESKTOP]: 4
    }

    const isViewAllButtonVisible = initialArray.length > NUMBERS_OF_ITEMS_TO_SHOW[screenSize]

    useEffect(() => {
        switch (true) {
            case width < theme.breakpoints.width.m:
                return setScreenSize(SCREEN_SIZE.MOBILE)
            case width >= theme.breakpoints.width.m && width < theme.desktopBreakpoints.width.xs:
                return setScreenSize(SCREEN_SIZE.TABLET)
            case width > theme.desktopBreakpoints.width.xs:
                return setScreenSize(SCREEN_SIZE.DESKTOP)
            default:
                return setScreenSize(SCREEN_SIZE.DESKTOP)
        }
    }, [width])

    const handleViewAll = () => {
        setViewAll(value => !value)
    }

    useEffect(() => {
        const arrayShow = viewAll ? initialArray : initialArray.slice(0, NUMBERS_OF_ITEMS_TO_SHOW[screenSize])
        onViewAllCallback(arrayShow)
    }, [viewAll, screenSize])

    return (
        <StyledViewAllRoot>
            <div>{title}</div>
            {isViewAllButtonVisible && (
                <span onClick={handleViewAll}>{viewAll ? t('common:view_less') : t('common:view_all')}</span>
            )}
        </StyledViewAllRoot>
    )
}

export default ViewAllSection

ViewAllSection.displayName = 'ViewAllSection'
