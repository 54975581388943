import styled, {css} from 'styled-components'

export const StyledPageHeaderContainer = styled.div`
    ${() => css`
        display: grid;
        align-items: start;
        grid-template-columns: 1fr auto;
    `}
`

export const StyledPageHeaderText = styled.div`
    ${({theme: {spacing, palette, truncateText, typography}}) => css`
        & h1 {
            ${typography.text5XL};
            color: ${palette.neutral['900']};
            font-weight: 600;
            margin-bottom: ${spacing}px;
            word-break: break-word;

            ${truncateText};
            max-width: 600px;
        }
        & p {
            color: ${palette.neutral['600']};
            ${typography.subtitle}
        }
    `}
`
