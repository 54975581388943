import Container from '@/components/ui/Container'
import {StyledRoot, StyledSubTitle, StyledTitle} from './style'
import {useTranslation} from 'react-i18next'
import {FC, useState} from 'react'
import Spinner from '@/components/ui/Spinner'
import StatusModal from '@/components/commons/StatusModal'
import Form from '@/features/change-password/components/form/Form'
import {useNavigate} from 'react-router-dom'
import {ROUTE_MY_PROFILE} from '@/utilities/constants'

const ChangePassword: FC = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false)

    const handleCloseSuccessModal = () => {
        setIsOpenSuccessModal(false)
        navigate(ROUTE_MY_PROFILE)
    }

    return (
        <>
            {isLoading && <Spinner size={48} />}
            <Container>
                <StyledRoot>
                    <StyledTitle>{t('change_password:title')}</StyledTitle>
                    <StyledSubTitle>{t('change_password:subtitle')}</StyledSubTitle>
                    <Form setIsLoading={setIsLoading} setIsOpenSuccessModal={setIsOpenSuccessModal} />
                </StyledRoot>
                {isOpenSuccessModal && (
                    <StatusModal
                        overlay
                        title={t('change_password:success_modal_title')}
                        onClose={handleCloseSuccessModal}
                    >
                        <div>{t('change_password:success_modal_text')}</div>
                    </StatusModal>
                )}
            </Container>
        </>
    )
}

export default ChangePassword
ChangePassword.displayName = 'ChangePassword'
