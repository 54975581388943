import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {z} from 'zod'
import {checkInputRemoveValue, handleApiError} from '@/utilities/helpers'
import WorkspaceActionModal from '@/components/commons/WorkspaceActionModal/WorkspaceActionModal'
import Spinner from '@/components/ui/Spinner'
import {Trans, useTranslation} from 'react-i18next'
import {useDeleteVideo} from '../../queries/useDeleteVideo'

const enum CONFIRM_VIDEO_DELETION_MODEL {
    Type = 'type'
}

const VideoDeletionSchema = z.object({
    [CONFIRM_VIDEO_DELETION_MODEL.Type]: z
        .string()
        .min(1, {message: 'validation:required_field'})
        .refine(value => checkInputRemoveValue(value), {message: 'validation:invalid_value'})
        .default('')
})

type ValidationSchema = z.infer<typeof VideoDeletionSchema>

export interface VideoDeletionModalProps {
    onClose: () => void
    workspaceId: string
    videoId: string
    videoName: string
}

const VideoDeletionModal: React.FC<VideoDeletionModalProps> = ({onClose, workspaceId, videoId, videoName}) => {
    const {t} = useTranslation()
    const {mutate, isError, error, isPending} = useDeleteVideo({
        workspaceId,
        videoId,
        onSuccess: () => onClose()
    })

    useEffect(() => {
        if (isError) {
            handleApiError({error})
        }
    }, [isError])

    const {
        register,
        handleSubmit,
        formState: {errors, isValid, isDirty}
    } = useForm<ValidationSchema>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(VideoDeletionSchema)
    })

    const onSubmit = () => {
        mutate()
    }

    if (isPending) return <Spinner size={48} />

    return (
        <WorkspaceActionModal
            onClose={onClose}
            title="video_deletion_modal:title"
            description="video_deletion_modal:description"
            subDescription={
                <div>
                    <Trans
                        t={t}
                        i18nKey={'video_deletion_modal:sub_description'}
                        values={{NAME: videoName}}
                        components={{
                            span: <span />
                        }}
                    />
                </div>
            }
            cancelButtonName="common:cancel"
            errorMessage={errors.type?.message || ''}
            handleSubmit={handleSubmit(onSubmit)}
            isConfirmButtonDisabled={!isValid || !isDirty || isPending}
            isSubmitting={isPending}
            {...register(CONFIRM_VIDEO_DELETION_MODEL.Type)}
        />
    )
}

export default VideoDeletionModal
