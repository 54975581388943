import styled, {css} from 'styled-components'

export const StyledInfoRoot = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        margin: ${spacing * 14}px 0px ${spacing * 25}px;
        align-items: center;
    `}
`

export const StyledItemWrapper = styled.div`
    ${({theme: {spacing, palette, link}}) => css`
        display: grid;
        gap: ${spacing * 5}px;
        ${palette.gray.dark};
        line-height: 27px;
        & div {
            font-weight: 700;
        }
        & a {
            ${link}
        }
    `}
`

export const StyledTipsWrapper = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: flex;
        width: 100%;
        margin-top: ${spacing * 18}px;
        flex-direction: column;
        gap: ${spacing * 10}px;
        ${mediaQueries.m} {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            justify-content: space-between;
            gap: ${spacing * 4}px;
        }
    `}
`

export const StyledItem = styled.div`
    ${({theme: {mediaQueries, palette, spacing, typography}}) => css`
        display: flex;
        align-items: center;
        flex-direction: column;
        max-width: 260px;
        text-align: center;
        justify-self: center;
        margin: 0 auto;
        line-height: 23px;
        ${typography.textM};

        & h4 {
            color: ${palette.gray.main};
            font-weight: 700;
            margin: ${spacing * 7}px 0px ${spacing * 4}px;
        }

        & div {
            color: ${palette.gray.dark};
        }

        & span {
            color: ${palette.blue.link};
            cursor: pointer;
        }

        & a {
            color: ${palette.blue.link};
            cursor: pointer;
            text-decoration: none;
        }

        ${mediaQueries.m} {
            max-width: 240px;
            ${typography.textL};
        }
        ${mediaQueries.l} {
            max-width: 320px;
        }
    `}
`
