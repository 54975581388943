import {useNavigate} from 'react-router-dom'
import {
    StyledFooter,
    StyledFooterItem,
    StyledLogo,
    StyledMainSection,
    StyledRoot,
    StyledItemWrapper,
    StyleLogoWrapper,
    StyledHover
} from './style'
import {
    ROUTE_EVENTS,
    ROUTE_HOME,
    ROUTE_LEADERBOARD,
    ROUTE_TERMS_AND_CONDITIONS,
    ROUTE_PRIVACY_POLICY,
    ROUTE_THE_PROJECT,
    ROUTE_VIDEOS
} from '@/utilities/constants'
import Logo from '@assets/images/logo.png'
import {useTranslation} from 'react-i18next'
import {BrowseVideosIcon, EventsIcon, HomeIcon, LeaderboardIcon, LogoutIcon, TheProjectIcon} from '@/assets/icons/icons'
import {MouseEvent, useState} from 'react'
import useAuthStore from '@/store/authStore'
import LogoutModal from '@/features/authentication/components/logout-modal/LogoutModal'

type NavProps = {
    staticNav?: boolean
    onCloseMenu?: () => void
}

const Nav: React.FC<NavProps> = ({staticNav = false, onCloseMenu}) => {
    const {t} = useTranslation()
    const accessToken = useAuthStore(store => store.accessToken)
    const navigate = useNavigate()
    const [isLogoutModalOpen, setLogoutModalOpen] = useState(false)

    const handleCloseMenu = () => {
        typeof onCloseMenu === 'function' && onCloseMenu()
    }

    const handleCloseLogoutModal = (event?: MouseEvent) => {
        event && event.stopPropagation()
        setLogoutModalOpen(false)
    }

    const handleClickTerms = () => {
        handleCloseMenu()
        navigate(ROUTE_TERMS_AND_CONDITIONS)
    }
    const handleClickPrivacyPolicy = () => {
        navigate(ROUTE_PRIVACY_POLICY)
        handleCloseMenu()
    }

    return (
        <StyledRoot staticNav={staticNav}>
            <StyledMainSection>
                {staticNav && (
                    <StyleLogoWrapper>
                        <StyledLogo src={Logo} alt={'logo'} onClick={() => navigate(ROUTE_HOME)} />
                    </StyleLogoWrapper>
                )}
                <>
                    <div>
                        <StyledHover>
                            <StyledItemWrapper to={ROUTE_HOME} onClick={handleCloseMenu}>
                                <HomeIcon />
                                <span>{t('common:home')}</span>
                            </StyledItemWrapper>
                        </StyledHover>
                        <StyledHover>
                            <StyledItemWrapper to={ROUTE_VIDEOS} onClick={handleCloseMenu}>
                                <BrowseVideosIcon />
                                <span>{t('common:browse_videos')}</span>
                            </StyledItemWrapper>
                        </StyledHover>

                        <StyledHover>
                            <StyledItemWrapper to={ROUTE_EVENTS} onClick={handleCloseMenu}>
                                <EventsIcon />
                                <span>{t('common:events')}</span>
                            </StyledItemWrapper>
                        </StyledHover>

                        <StyledHover>
                            <StyledItemWrapper to={ROUTE_LEADERBOARD} onClick={handleCloseMenu}>
                                <LeaderboardIcon />
                                <span>{t('common:leaderboard')}</span>
                            </StyledItemWrapper>
                        </StyledHover>
                        <StyledHover>
                            <StyledItemWrapper to={ROUTE_THE_PROJECT} onClick={handleCloseMenu}>
                                <TheProjectIcon />
                                <span>{t('common:project')}</span>
                            </StyledItemWrapper>
                        </StyledHover>
                    </div>
                </>
            </StyledMainSection>

            <StyledFooter>
                {accessToken && (
                    <StyledFooterItem onClick={() => setLogoutModalOpen(true)}>
                        <LogoutIcon /> <p>{t('common:sign_out')}</p>
                    </StyledFooterItem>
                )}
                <StyledFooterItem>
                    <span onClick={handleClickPrivacyPolicy}>{t('nav:privacy_policy')}</span>{' '}
                    <span onClick={handleClickTerms}>{t('nav:terms')}</span>
                </StyledFooterItem>
            </StyledFooter>

            {isLogoutModalOpen && <LogoutModal onClose={handleCloseLogoutModal} />}
        </StyledRoot>
    )
}

export default Nav

Nav.displayName = 'Nav'
