import {useTranslation} from 'react-i18next'
import {
    StyledHeaderWrapper,
    StyledName,
    StyledDescription,
    StyledTitleWrapper,
    StyledInformationWrapper,
    StyledValueLabelWrapper,
    StyledValue,
    StyledLabel,
    StyledEditIconWrapper
} from './style'
import {generatePath, useParams} from 'react-router-dom'
import Spinner from '@/components/ui/Spinner'
import {useEffect, useState} from 'react'
import {ValidationError, formatLocalDate, handleApiError} from '@/utilities/helpers'
import {useGetWorkspaceEventDetails} from '@/features/events/queries/useGetWorkspaceEventDetails'
import {EarthIcon, EditIcon, LockIcon, ShareIcon} from '@/assets/icons/icons'
import Button from '@/components/ui/Button'
import {useTheme} from 'styled-components'
import {getEventStatus} from '@/utilities/event'
import EventTag from '@/components/ui/EventTag'
import toast from 'react-hot-toast'
import {ROUTE_EVENT_DETAILS} from '@/utilities/constants'
import EventModal from '@/features/workspaces/components/event-modal/EventModal'
import {EVENT_STATUS} from '@/utilities/constants/event'

const EventInformation: React.FC = () => {
    const {t} = useTranslation()
    const theme = useTheme()
    const {workspaceId, eventId} = useParams()
    const {data, isLoading, isError, error} = useGetWorkspaceEventDetails({
        workspaceId: `${workspaceId}`,
        eventId: `${eventId}`
    })
    const [isEventModalOpen, setIsEventModalOpen] = useState(false)

    useEffect(() => {
        if (isError) handleApiError({error: error as ValidationError<string>})
    }, [isError])

    if (isLoading) return <Spinner size={48} overlay />

    if (!data) return null

    const eventStatus = getEventStatus(data)

    const handleCopyEventLink = () => {
        const appURL = window.location.host
        const sharePublicEventURL = `${appURL}${generatePath(ROUTE_EVENT_DETAILS, {eventId: `${eventId}`})}`

        const sharePrivetEventURL = `${appURL}${generatePath(ROUTE_EVENT_DETAILS, {eventId: `${eventId}`})}?t=${
            data?.privateHash
        }`

        const shareURL = data?.isPublic ? sharePublicEventURL : sharePrivetEventURL
        navigator.clipboard.writeText(shareURL)
        toast.success(t('common:link_copied'))
    }

    const onClickEditEvent = () => {
        setIsEventModalOpen(true)
    }

    const onCloseEditEventModal = () => {
        setIsEventModalOpen(false)
    }

    return (
        <>
            <StyledHeaderWrapper>
                <StyledTitleWrapper>
                    <div>
                        {data?.isPublic ? (
                            <EarthIcon height={40} width={40} color={theme.palette.neutral['600']} />
                        ) : (
                            <LockIcon height={40} width={40} color={theme.palette.neutral['600']} />
                        )}
                    </div>
                    <div>
                        <StyledName>{data.name}</StyledName>
                        <StyledDescription>{data.description}</StyledDescription>
                    </div>
                </StyledTitleWrapper>
                {eventStatus !== EVENT_STATUS.Closed && (
                    <StyledEditIconWrapper>
                        <div>
                            <EditIcon
                                stroke={theme.palette.neutral[500]}
                                width={28}
                                height={28}
                                onClick={onClickEditEvent}
                            />
                        </div>
                    </StyledEditIconWrapper>
                )}
                <div>
                    <Button size="sm" onClick={handleCopyEventLink}>
                        <ShareIcon fill={theme.palette.neutral['100']} />
                        {t('common:share')}
                    </Button>
                </div>
            </StyledHeaderWrapper>
            <StyledInformationWrapper>
                <EventTag status={eventStatus} />
                <StyledValueLabelWrapper>
                    <StyledLabel>{t('event:start_date')}</StyledLabel>
                    <StyledValue>{formatLocalDate(data.startDate)}</StyledValue>
                </StyledValueLabelWrapper>
                <StyledValueLabelWrapper>
                    <StyledLabel>{t('event:end_date')}</StyledLabel>
                    <StyledValue>{formatLocalDate(data.endDate)}</StyledValue>
                </StyledValueLabelWrapper>
            </StyledInformationWrapper>
            {isEventModalOpen && data && (
                <EventModal eventToEdit={data} onCancel={onCloseEditEventModal} onSubmit={onCloseEditEventModal} />
            )}
        </>
    )
}

export default EventInformation

EventInformation.displayName = 'EventInformation'
