import {QueryClient} from '@tanstack/react-query'

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            networkMode: 'online',
            retry: 1,
            retryOnMount: true,
            retryDelay: 1000,
            staleTime: 600000, //10 min
            refetchOnMount: true,
            refetchOnWindowFocus: false,
            refetchOnReconnect: true
        }
    }
})

export const QUERY_KEYS = {
    LANGUAGES: 'languages',
    WORKSPACE_EVENTS: 'workspace-events',
    WORKSPACE_DETAILS: 'workspace-details',
    WORKSPACES_LIST: 'workspaces-list',
    WORKSPACE_VIDEOS: 'workspace-videos',
    WORKSPACE_VIDEO_DETAILS: 'workspace-video-details',
    WORKSPACE_EVENT_DETAILS: 'workspace-event-details',
    VIDEO_DETAILS: 'video-details',
    VIDEO_JOBS_LIST: 'video-jobs-list',
    JOB_STATUS_HISTORY: 'job-status-history',
    EVENTS: 'events',
    MEMBERS: 'members',
    RANKING_LIST: 'ranking-list',
    VIDEO_FILTERS: 'video-filters',
    LEADERS: 'leaders',
    EVENT_DETAILS: 'event-details',
    EVENT_VIDEOS: 'event-videos',
    EVENT_LEADERS: 'event-leaders',
    VIDEOS: 'videos',
    EVENT_FILTERS: 'event-filters',
    USER_PROFILE: 'user-profile',
    ASSIGNED_VIDEOS: 'assigned-videos',
    USER_VIDEOS: 'user-videos',
    WORKSPACE_EVENT_MEMBERS: 'workspace-event-members'
} as const

export const MUTATION_KEYS = {
    CHANGE_PASSWORD: 'change-password',
    RECOVERY_PASSWORD: 'recovery-password',
    PASSWORD_RESET: 'password-reset',
    CREATE_WORKSPACE: 'create-workspace',
    INVITE_MEMBER: 'invite-member',
    REMOVE_WORKSPACE: 'remove-workspace',
    REMOVE_MEMBERS: 'remove-members',
    EMAIL_ACTIVATION: 'email-activation',
    SIGNUP_CONTRIBUTOR: 'signup-contributor',
    SIGNUP_PROVIDER: 'signup-provider',
    CREATE_VIDEO: 'create-video',
    ACCEPT_INVITATION: 'accept_invitation',
    LEAVE_WORKSPACE: 'leave-workspace',
    CREATE_RANK: 'create-rank',
    CREATE_JOB: 'create-job',
    DELETE_JOB: 'delete-job',
    CREATE_EVENT: 'create-event',
    VIDEO_ASSIGN: 'video-assign',
    CHANGE_PROFILE_VISIBILITY: 'change-profile-visibility',
    MARK_AS_DONE: 'mark-as-done',
    JOIN_EVENT: 'join-event',
    EVENT_ADD_MEMBER: 'event_add_member',
    REJECT: 'reject',
    PUBLISH: 'PUBLISH',
    UPDATE_VIDEO_ASSIGNMENT: 'update-video-assignment',
    DELETE_RANK: 'delete-rank',
    EDIT_RANK: 'edit-rank',
    DELETE_EVENT: 'delete-event',
    ACCEPT_EVENT_INVITATION: 'accept-event-invitation',
    EDIT_EVENT: 'edit-event',
    ACCEPT_EVENT_REQUEST: 'accept-event-request',
    REJECT_EVENT_REQUEST: 'reject-event-request',
    EDIT_JOB_EVENT: 'edit-job-event',
    REMOVE_EVENT_MEMBER: 'remove-event-member',
    RETRY_JOB: 'retry-job',
    DELETE_VIDEO: 'delete-video',
    CREATE_EUROPEANA_VIDEO: 'create-europeana-video',
    DELIVERY_SUBTITLES: 'delivery-subtitles'
} as const
