import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import StatusModal from '@/components/commons/StatusModal'
import Button from '@/components/ui/Button'
import {StyledFooter} from './styles'
import {handleApiError} from '@/utilities/helpers'
import useAuthStore from '@/store/authStore'
import Spinner from '@/components/ui/Spinner'

export interface PasswordRecoverModalProps {
    onClose: () => void
}

const LogoutModal: React.FC<PasswordRecoverModalProps> = ({onClose}) => {
    const {t} = useTranslation()
    const logout = useAuthStore(store => store.logout)
    const error = useAuthStore(store => store.error)
    const isError = useAuthStore(store => store.isError)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (isError && error instanceof Error) handleApiError({error})
    }, [isError])

    const onClickLogout = async () => {
        setIsLoading(true)
        if (await logout()) {
            onClose()
        }
        setIsLoading(false)
    }

    return (
        <StatusModal overlay variant={'pending'} title={'log_out:title'} onClose={onClose} withoutPortal={true}>
            {isLoading && <Spinner size={48} overlay />}
            {t('log_out:subtitle')}
            <StyledFooter>
                <Button variant={'transparent'} size={'sm'} onClick={onClose}>
                    {t('common:cancel')}
                </Button>
                <Button variant={'primary'} size={'sm'} onClick={onClickLogout}>
                    {t('common:confirm')}
                </Button>
            </StyledFooter>
        </StatusModal>
    )
}

export default LogoutModal

LogoutModal.displayName = 'LogoutModal'
