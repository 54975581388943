import {z} from 'zod'

export const enum PROVIDER_MODEL {
    Name = 'name',
    Email = 'email',
    Password = 'password',
    PasswordConfirmation = 'passwordConfirmation'
}

export const signupProviderFormSchema = z
    .object({
        [PROVIDER_MODEL.Name]: z.string().min(1, {message: 'validation:required_name'}).default(''),
        [PROVIDER_MODEL.Email]: z
            .string()
            .min(1, {message: 'validation:email'})
            .email({
                message: 'validation:email_valid'
            })
            .default(''),
        [PROVIDER_MODEL.Password]: z
            .string()
            .min(6, {message: 'validation:password'})
            .regex(new RegExp(/^(?=.*[A-Z])(?=.*[0-9])(?=.*[^\w\s]).{6,32}/), {message: 'validation:password_complex'})
            .default(''),
        [PROVIDER_MODEL.PasswordConfirmation]: z.string().min(6, {message: 'validation:password'}).default('')
    })
    .refine(data => data[PROVIDER_MODEL.Password] === data[PROVIDER_MODEL.PasswordConfirmation], {
        path: [PROVIDER_MODEL.PasswordConfirmation],
        message: 'validation:confirm_password'
    })

export type ProviderValidationSchema = z.infer<typeof signupProviderFormSchema>
