import axios from '@/services/axiosInstance'
import {User} from '@/types/auth'

export const httpGetUserProfile = async (userId: string) => {
    const url = `/profile/${userId}`
    const response = await axios.get<User>(url)
    return response.data
}

export const httpGetUserVideos = async (status: string, userId: string, pageParam: string) => {
    const url = `/jobs/assignments/users/${userId}${pageParam}`
    const response = await axios.get(url, {params: {status}})
    return response.data
}
