import styled, {css} from 'styled-components'

export const StyledRoot = styled.div`
    ${({theme: {mediaQueries}}) => css`
        display: grid;
        height: 100%;
        overflow: hidden;
        grid-template-rows: auto;

        ${mediaQueries.m} {
            grid-template-rows: 0fr auto;
        }
    `}
`

export const StyledMainSection = styled.div`
    height: 100%;
    overflow: auto;
`

export const StyledWrapper = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: flex;
        flex-direction: column;
        margin-top: ${spacing * 11}px;

        ${mediaQueries.m} {
            align-self: self-start;
            flex-direction: row;
            margin-top: ${spacing * 20}px;
            gap: ${spacing * 10}px;
            justify-content: center;
        }

        ${mediaQueries.desktopXs} {
            gap: ${spacing * 35}px;
        }

        ${mediaQueries.l} {
            justify-content: flex-start;
        }
    `}
`
