import styled, {css} from 'styled-components'

export const StyledCardWrapper = styled.div`
    display: grid;
    grid-template-rows: 1fr auto auto;
    cursor: pointer;
`
export const StyledThumbnailWrapper = styled.div`
    ${({theme: {typography, spacing, palette, mediaQueries}}) => css`
        position: relative;
        height: 260px;
        & span {
            ${typography.textS}
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: ${palette.neutral['900']};
            border-top-left-radius: 10px;
            color: ${palette.neutral[100]};
            padding: ${spacing * 2}px ${spacing * 3}px;
            opacity: 0.75;
        }
        ${mediaQueries.m} {
            height: 186px;
        }
    `}
`

export const StyledImage = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
`

export const StyledName = styled.div`
    ${({theme: {spacing, typography, palette}}) => css`
        ${typography.textL};
        color: ${palette.gray.main};
        font-weight: 500;
        line-height: 22px;
        margin: ${spacing * 2}px 0 ${spacing * 3}px 0;
        overflow-wrap: anywhere;
        overflow: hidden;
        display: -webkit-box;
        box-orient: vertical;
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        -moz-line-clamp: 2;
    `}
`
export const StyledBox = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing * 2}px;
    `}
`

export const StyledLanguage = styled.div`
    ${({theme: {typography, palette}}) => css`
        ${typography.textS};
        color: ${palette.gray.light};
    `}
`
