import {z} from 'zod'

export const enum TARGET_LANGUAGE_MODEL {
    TargetLanguagesIds = 'targetLanguagesIds'
}

export const TargetLanguageSchema = z.object({
    [TARGET_LANGUAGE_MODEL.TargetLanguagesIds]: z
        .object({
            value: z.number(),
            label: z.string()
        })
        .array()
        .min(1)
        .default([])
})

export type TargetLanguageValidationSchema = z.infer<typeof TargetLanguageSchema>
