import styled, {css} from 'styled-components'

export const StyledLeadersListRoot = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const StyledDivider = styled.div`
    ${({theme: {palette}}) => css`
        border-top: 1px solid ${palette.gray.disabled};
        width: 100%;
    `}
`
