import styled, {css} from 'styled-components'

export const StyledRoot = styled.div`
    height: 100%;
    overflow: auto;
`

export const StyledContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const StyledButtonWrapper = styled.div`
    ${({theme: {spacing, typography, mediaQueries}}) => css`
        display: flex;
        justify-content: center;
        margin: ${spacing * 6}px 0;

        & button {
            ${typography.textL};
            padding: 1px ${spacing * 14}px;
            line-height: 38px;
        }

        ${mediaQueries.m} {
            margin: ${spacing * 10}px 0;
        }
    `}
`
