import {UploadIcon} from '@/assets/icons/icons'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {StyledDragStatusRoot, StyledDragStatusWrapper, StyledError, StyledStatus} from './style'

interface DragStatusProps {
    isDragActive: boolean
    isDragReject: boolean
    error: string
}

const DragStatus: React.FC<DragStatusProps> = ({isDragActive, isDragReject, error}) => {
    const {t} = useTranslation()

    const renderText = useMemo(() => {
        switch (true) {
            case isDragReject:
                return t('file_uploader:upload_area_drag_reject')
            case isDragActive:
                return t('file_uploader:upload_area_drag_active')
            default:
                return t('file_uploader:upload_area')
        }
    }, [isDragActive, isDragReject])

    return (
        <StyledDragStatusRoot>
            <StyledDragStatusWrapper>
                <UploadIcon />
                <StyledStatus>{renderText}</StyledStatus>
            </StyledDragStatusWrapper>
            {error && <StyledError>{t(`file_uploader:${error}`)}</StyledError>}
        </StyledDragStatusRoot>
    )
}

export default DragStatus

DragStatus.displayName = 'DragStatus'
