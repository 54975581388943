import {useTranslation} from 'react-i18next'
import {StyledEmptySection, StyledImage} from './style'
import Basic from '@assets/images/Basic.png'

const EmptySection = ({title}: {title: string}) => {
    const {t} = useTranslation()

    return (
        <StyledEmptySection>
            <StyledImage src={Basic} />
            {t(title)}
        </StyledEmptySection>
    )
}

export default EmptySection

EmptySection.displayName = 'EmptySection'
