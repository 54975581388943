import styled, {css} from 'styled-components'

export const StyledRoot = styled.div`
    height: 100%;
    overflow: auto;
`

export const StyledWrapper = styled.div`
    ${({theme: {mediaQueries, spacing, typography, palette}}) => css`
        margin-top: ${spacing * 4}px;

        & span {
            ${typography.text1XL}
            font-weight: 700;
            color: ${palette.gray.main};
        }

        ${mediaQueries.l} {
            margin-top: 0px;
        }
    `}
`

export const StyledList = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: grid;
        gap: ${spacing * 8}px;
        padding-top: ${spacing * 8}px;

        ${mediaQueries.m} {
            row-gap: ${spacing * 5}px;
            column-gap: ${spacing * 5}px;
            grid-template-columns: 1fr 1fr 1fr;
            padding-top: ${spacing * 12}px;
        }
        ${mediaQueries.l} {
            row-gap: ${spacing * 8}px;
            column-gap: ${spacing * 11}px;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            padding-top: ${spacing * 8}px;
        }
    `}
`

export const StyledLoadMoreWrapper = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        ${mediaQueries.m} {
            margin-top: ${spacing * 8}px;
        }
    `}
`
