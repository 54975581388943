import {useEffect} from 'react'
import {StyledSubtitle} from './style'
import {useTranslation} from 'react-i18next'
import Modal from '@/components/commons/Modal'
import {handleApiError} from '@/utilities/helpers'
import Spinner from '@/components/ui/Spinner'
import {useRetryJob} from '@/features/videos/queries/useRetryJob'

interface RetryJobModalProps {
    workspaceId: string
    videoId: string
    jobId: string
    onClose: () => void
}

const RetryJobModal = ({videoId, jobId, workspaceId, onClose}: RetryJobModalProps) => {
    const {t} = useTranslation()
    const {mutate, isPending, isError, error} = useRetryJob({
        projectId: `${videoId}`,
        workspaceId: `${workspaceId}`,
        onSuccess: onClose
    })

    useEffect(() => {
        if (isError) handleApiError({error})
    }, [isError])

    const onSubmit = () => {
        mutate({jobId})
    }

    if (isPending) return <Spinner size={48} overlay />

    return (
        <Modal
            overlay
            title={'retry_modal:title'}
            onClose={onClose}
            cancelButtonCallback={onClose}
            submitButtonCallback={onSubmit}
            submitButtonText={'retry_modal:submit_button'}
        >
            <StyledSubtitle>{t('retry_modal:subtitle')}</StyledSubtitle>
        </Modal>
    )
}

export default RetryJobModal

RetryJobModal.displayName = 'RetryJobModal'
