import {LoginFormData, User} from '@/types/auth'
import {ValidationError} from '@/utilities/helpers'
import {create} from 'zustand'
import {persist} from 'zustand/middleware'
import {queryClient} from '@/queryClient'

type AuthStore = {
    accessToken: string | null
    refreshToken: string | null
    europeanaCodeVerifier: string | null
    user: User | null
    error: ValidationError<'email'> | null
    isError: boolean
    isLoading: boolean
    login: (data: LoginFormData) => Promise<void>
    loginEuropeana: () => Promise<void>
    callbackEuropeana: (code: string) => Promise<void>
    logout: () => Promise<boolean>
    userMe: () => Promise<void>
    setAccessToken: (accessToken: string | null) => void
    setRefreshToken: (refreshToken: string | null) => void
}

type ErrorSetterParams = {
    error: ValidationError<'email'> | undefined
    isError: boolean
}

const setError = (set: (params: ErrorSetterParams) => void, error: Error): void => set({error, isError: true})

const resetError = (set: (params: ErrorSetterParams) => void): void => set({error: undefined, isError: false})

const useAuthStore = create<AuthStore>()(
    persist(
        (set, get) => ({
            accessToken: null,
            refreshToken: null,
            europeanaCodeVerifier: null,
            user: null,
            error: null,
            isError: false,
            isLoading: false,
            login: async (data: LoginFormData) => {
                resetError(set)
                try {
                    set({isLoading: true})
                    const {httpLogin} = await import('@services/auth.http')
                    const response = await httpLogin(data)
                    set({
                        user: response.data.user,
                        accessToken: response.data.accessToken,
                        refreshToken: response.data.refreshToken
                    })
                } catch (error) {
                    setError(set, error as ValidationError<'email'>)
                } finally {
                    set({isLoading: false})
                }
            },
            logout: async (): Promise<boolean> => {
                resetError(set)
                try {
                    set({isLoading: true})
                    const {httpLogout} = await import('@services/auth.http')
                    await httpLogout()
                    queryClient.clear()
                    set({user: null, accessToken: null, refreshToken: null})
                    set({isLoading: false})
                } catch (error) {
                    setError(set, error as ValidationError<'email'>)
                    set({isLoading: false})
                    return false
                }
                return true
            },
            loginEuropeana: async () => {
                resetError(set)
                try {
                    set({isLoading: true})
                    const {httpEuropeanaLogin} = await import('@services/auth.http')
                    const response = await httpEuropeanaLogin()
                    await set({
                        europeanaCodeVerifier: response.data.codeVerifier
                    })
                    window.location.assign(response.data.authUrl)
                } catch (error) {
                    setError(set, error as ValidationError<'email'>)
                } finally {
                    set({isLoading: false})
                }
            },
            callbackEuropeana: async (code: string) => {
                resetError(set)
                try {
                    set({isLoading: true})
                    const {httpEuropeanaCallback} = await import('@services/auth.http')
                    const response = await httpEuropeanaCallback({code, codeVerifier: `${get().europeanaCodeVerifier}`})
                    await set({
                        user: response.data.user,
                        accessToken: response.data.accessToken,
                        refreshToken: response.data.refreshToken,
                        europeanaCodeVerifier: null
                    })
                    window.location.assign('/')
                } catch (error) {
                    setError(set, error as ValidationError<'email'>)
                } finally {
                    set({isLoading: false})
                }
            },
            userMe: async () => {
                try {
                    resetError(set)
                    set({isLoading: true})
                    const {httpUserMe} = await import('@services/auth.http')
                    const response = await httpUserMe()
                    set({user: response.data})
                } catch (error) {
                    setError(set, error as ValidationError<'email'>)
                } finally {
                    set({isLoading: false})
                }
            },
            setAccessToken: (accessToken: string | null) => {
                set({accessToken})
            },
            setRefreshToken: (refreshToken: string | null) => {
                set({refreshToken})
            }
        }),
        {
            name: 'auth-storage'
        }
    )
)

export default useAuthStore
