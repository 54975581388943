import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {httpRetryJob} from '@/features/videos/services/video.http'
import {useMutation, useQueryClient} from '@tanstack/react-query'

export interface RetryJobRequest {
    projectId: string
    workspaceId: string
    onSuccess: () => void
}

export const useRetryJob = ({projectId, workspaceId, onSuccess}: RetryJobRequest) => {
    const client = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.RETRY_JOB],
        mutationFn: ({jobId}: {jobId: string}) => httpRetryJob(projectId, jobId),
        onSuccess: () => {
            onSuccess()
            client.invalidateQueries({queryKey: [QUERY_KEYS.WORKSPACE_VIDEO_DETAILS, workspaceId, projectId]})
            client.invalidateQueries({queryKey: [QUERY_KEYS.JOB_STATUS_HISTORY, projectId]})
        }
    })
}
