import {Event, EventCreationRequest} from '@/types/event'
import axios from '@/services/axiosInstance'
import {LeaderType} from '@/features/leader-board/types'
import {HttpListResponse} from '@/types/commons'
import {EventMember, EventType} from '@/features/workspaces/types'

export const httpGetEventsList = async ({
    limit = 10,
    status = undefined,
    pageParam
}: {
    limit?: number
    status?: string
    pageParam?: string
}) => {
    const url = `/events${pageParam}`
    const response = await axios.get<HttpListResponse<Event>>(url, {params: {limit, status: [status]}})
    return response.data
}

export const httpGetEventDetails = ({eventId, hash}: {eventId: string; hash?: string | null}) => {
    const url = `/events/${eventId}`
    return axios.get<Event>(url, {params: {t: hash}}).then(({data}) => data)
}

export const httpGetEventLeadersList = async ({
    eventId,
    limit = 10,
    pageParam
}: {
    eventId: string
    limit?: number
    pageParam: string
}) => {
    const url = `/events/${eventId}/leaderboard${pageParam}`
    const response = await axios.get<HttpListResponse<LeaderType>>(url, {params: {limit: limit}})
    return response.data
}

export const httpCreateEvent = (workspaceId: string, data: EventCreationRequest) => {
    const url = `/workspaces/${workspaceId}/events`
    return axios.post<Event>(url, data).then(({data}) => data)
}

export const httpGetWorkspaceEventDetails = (workspaceId: string, videoId: string) => {
    const url = `/workspaces/${workspaceId}/events/${videoId}`
    return axios.get<EventType>(url).then(({data}) => data)
}

export const httpJoinEvent = ({eventId}: {eventId: string}) => {
    const url = `/events/${eventId}/users`
    return axios.post(url)
}
export const httpAddMember = ({
    workspaceId,
    eventId,
    email,
    emailConfirmation
}: {
    workspaceId: string
    eventId: string
    email: string
    emailConfirmation: string
}) => {
    const url = `/workspaces/${workspaceId}/events/${eventId}/invitations/invite`
    return axios.post(url, {email, emailConfirmation})
}

export const httpDeleteEvent = (workspaceId: string, eventId: string) => {
    const url = `/workspaces/${workspaceId}/events/${eventId}`
    return axios.delete<EventType>(url)
}

export const httpAcceptEventInvitation = ({
    eventId,
    token,
    eventUserInvitationId
}: {
    eventId: string
    token: string
    eventUserInvitationId: string
}) => {
    const url = `events/${eventId}/invitations/accept`
    return axios.post(url, {token, eventUserInvitationId: +eventUserInvitationId})
}

export const httpEditEvent = (workspaceId: string, eventId: string, data: EventCreationRequest) => {
    const url = `/workspaces/${workspaceId}/events/${eventId}`
    return axios.patch<EventType>(url, data)
}

export const httpGetEventMembers = (workspaceId: string, eventId: string) => {
    const url = `/workspaces/${workspaceId}/events/${eventId}/users`
    return axios.get<EventMember[]>(url)
}

export const httpRemoveEventMember = ({
    workspaceId,
    eventId,
    ids
}: {
    workspaceId: string
    eventId: string
    ids: number[]
}) => {
    const data = {
        eventUserIds: ids
    }

    const url = `/workspaces/${workspaceId}/events/${eventId}/users`
    return axios.delete(url, {params: data})
}
