import styled, {css} from 'styled-components'

export const StyledRoot = styled.div`
    display: grid;
    height: 100%;
    overflow: hidden;
`

export const StyledMainSection = styled.div`
    height: 100%;
    overflow: auto;
`

export const StyledWrapper = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: flex;
        flex-direction: column;
        margin-top: ${spacing * 11}px;
        justify-content: center;
        gap: ${spacing * 14}px;

        ${mediaQueries.m} {
            align-self: self-start;
            flex-direction: row;
            margin-top: ${spacing * 20}px;
            gap: ${spacing * 6}px;
        }

        ${mediaQueries.l} {
            gap: ${spacing * 35}px;
        }
    `}
`
