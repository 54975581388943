import styled, {DefaultTheme, css} from 'styled-components'

export const StyledTabSectionWrapper = styled.div`
    ${({theme: {palette, typography}}) => css`
        display: flex;
        align-items: center;
        color: ${palette.gray.main};
        text-align: center;
        ${typography.textM};
    `}
`
export const StyledTabItem = styled.div`
    ${({
        theme: {spacing, palette},
        isActive,
        isRightTab
    }: {
        theme: DefaultTheme
        isActive: boolean
        isRightTab: boolean
    }) => css`
        padding: ${spacing * 3}px ${spacing * 5}px;
        border-radius: 0px 16px 0px 0px;
        border: 1.5px solid ${palette.gray.disabled};
        background: ${palette.neutral.white};
        cursor: pointer;
        ${isRightTab &&
        css`
            border-radius: 16px 0px 0px 0px;
        `}
        ${isActive &&
        css`
            background: ${palette.gray.main};
            color: ${palette.neutral.white};
            border-color: ${palette.gray.main};
        `}
    `}
`
