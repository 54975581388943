import React, {useState, useRef, useEffect} from 'react'
import {StyledMainWrapper} from './style'
import {PauseIcon, PlayIcon} from '@/assets/icons/icons'

interface VideoProps {
    source: string
    height?: number
    isPreview?: boolean
    withDuration?: boolean
    poster?: string
}

const Video: React.FC<VideoProps> = ({source, height, isPreview, withDuration = true, poster = ''}) => {
    const videoRef = useRef<HTMLVideoElement>(null)
    const [isPlaying, setPlaying] = useState(false)
    const [isMouseOver, setMouseOver] = useState(false)
    const [totalDuration, setTotalDuration] = useState<string>('0:00')
    const ASPECT_RATIO = 1.77 // to get video aspect ratio 16 : 9
    const currentWidth = videoRef?.current && videoRef?.current.offsetWidth
    const aspectRatioHeight = currentWidth && currentWidth / ASPECT_RATIO

    useEffect(() => {
        const video = videoRef.current

        const handleLoadedMetadata = () => {
            if (video) {
                const minutes = Math.floor(video.duration / 60)
                const seconds = Math.floor(video.duration % 60)
                const formattedTime = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
                setTotalDuration(formattedTime)
            }
        }

        if (video) {
            video.addEventListener('loadedmetadata', handleLoadedMetadata)

            return () => {
                video.removeEventListener('loadedmetadata', handleLoadedMetadata)
            }
        }
    }, [])

    const handleMouseOver = () => {
        setMouseOver(true)
    }

    const handleMouseLeave = () => {
        setMouseOver(false)
    }

    const togglePlay = () => {
        const video = videoRef.current

        if (video) {
            if (isPlaying) {
                video.pause()
            } else {
                video.play()
            }

            setPlaying(!isPlaying)
        }
    }

    return (
        <StyledMainWrapper height={height || aspectRatioHeight}>
            <video
                ref={videoRef}
                src={source}
                onMouseOver={handleMouseOver}
                onMouseLeave={handleMouseLeave}
                poster={poster}
            />
            {(isMouseOver || !isPlaying) && !isPreview && (
                <button onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave} onClick={togglePlay}>
                    {isPlaying ? <PauseIcon /> : <PlayIcon />}
                </button>
            )}
            {withDuration && <span>{totalDuration}</span>}
        </StyledMainWrapper>
    )
}

export default Video
