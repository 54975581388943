import {useMutation} from '@tanstack/react-query'
import {ChangePasswordFormData} from '@/types/auth.ts'
import {httpChangePassword} from '@/services/auth.http'
import {MUTATION_KEYS} from '@/queryClient'

export const useChangePassword = () =>
    useMutation({
        mutationKey: [MUTATION_KEYS.CHANGE_PASSWORD],
        mutationFn: (params: ChangePasswordFormData) => httpChangePassword(params)
    })
