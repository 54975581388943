import styled, {css} from 'styled-components'

export const StyledRankCard = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: 0fr auto 1fr 0fr;
        align-items: center;
        gap: ${spacing * 3}px;
        & img {
            object-fit: contain;
            height: 90px;
            width: 60px;
        }
    `}
`

export const StyledCheckbox = styled.div`
    & div {
        gap: 0px;
    }
`

export const StyledInfo = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing}px;
    `}
`

export const StyledDeleteButton = styled.div`
    ${({theme: {palette}}) => css`
        & svg {
            cursor: pointer;
            & path {
                fill: ${palette.gray.main};
            }
        }
    `}
`

export const StyledRankName = styled.div`
    ${({theme: {typography, palette}}) => css`
        ${typography.textXL};
        color: ${palette.gray.main};
        font-weight: 700;
    `}
`

export const StyledRankPoints = styled.div`
    ${({theme: {palette, typography}}) => css`
        ${typography.textL};
        color: ${palette.gray.dark};
    `}
`

export const StyledEditButton = styled.div`
    ${({theme: {typography, palette, spacing}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing}px;
        margin-top: ${spacing * 2}px;
        ${typography.textS};
        color: ${palette.gray.main};
        font-weight: 500;

        & svg {
            cursor: pointer;
        }
        & span {
            cursor: pointer;
            text-decoration: underline;
        }
    `}
`
