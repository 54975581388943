import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {httpRejectEventRequest} from '../services/workspaces.http'

type UseRejectEventProps = {
    workspaceId: string
    eventId: string
}

export const useRejectEventRequest = ({workspaceId, eventId}: UseRejectEventProps) => {
    const client = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.REJECT_EVENT_REQUEST, workspaceId, eventId],
        mutationFn: ({userId}: {userId: string}) => httpRejectEventRequest({workspaceId, eventId, userId}),
        onSuccess: () => {
            client.invalidateQueries({queryKey: [QUERY_KEYS.WORKSPACE_EVENT_MEMBERS, workspaceId, eventId]})
        }
    })
}
