import {StyledLeadersListRoot, StyledDivider} from './style'
import LeaderCard from '../leader-card/LeaderCard'
import {LeaderType} from '../../types'
import React from 'react'
import {getElementNumber} from '@/utilities/helpers'
import {HttpListResponse} from '@/types/commons'

export interface LeadersListProps {
    isNumbered?: boolean
    isEvent?: boolean
    data: HttpListResponse<LeaderType>[]
}

const LeadersList = ({data, isNumbered = false, isEvent = false}: LeadersListProps) => {
    return (
        <React.Fragment>
            {data?.map((page, index) => (
                <StyledLeadersListRoot key={index}>
                    {page?.data?.map((leader, index) => (
                        <React.Fragment key={leader.id}>
                            <LeaderCard
                                leader={leader}
                                isNumbered={isNumbered}
                                isEvent={isEvent}
                                itemNumber={getElementNumber({
                                    index,
                                    currentPage: page?.meta?.current_page,
                                    perPage: page?.meta?.per_page
                                })}
                            />
                            <StyledDivider />
                        </React.Fragment>
                    ))}
                </StyledLeadersListRoot>
            ))}
        </React.Fragment>
    )
}

export default LeadersList
