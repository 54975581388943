import styled, {css, DefaultTheme} from 'styled-components'

export const StyledContainer = styled.div(
    ({theme: {typography}}: {theme: DefaultTheme}) => css`
        h4 {
            ${typography.subtitle};

            display: -webkit-box;
            box-orient: vertical;
            -webkit-box-orient: vertical;
            -moz-box-orient: vertical;
            line-clamp: 2;
            -webkit-line-clamp: 2;
            -moz-line-clamp: 2;
            overflow-wrap: anywhere;
            overflow: hidden;
        }
    `
)

export const StyledHeader = styled.div(
    ({theme: {spacing}, $withIcon}: {theme: DefaultTheme; $withIcon: boolean}) => css`
        padding: ${spacing * 4}px ${spacing * 6}px;
        box-shadow: 0px -1px 0px 0px #f0f0f0 inset;
        display: grid;
        grid-template-columns: ${$withIcon ? '20px 1fr auto' : '1fr auto'};
        gap: ${spacing * 3}px;
        align-items: center;
    `
)
export const StyledFooter = styled.div(
    ({theme: {spacing}}: {theme: DefaultTheme}) => css`
        box-shadow: 0px 1px 0px 0px #f0f0f0 inset;
        display: flex;
        padding: 10px 16px;
        justify-content: flex-end;
        align-items: center;
        gap: ${spacing * 2}px;
    `
)

export const StyledCloseIcon = styled.div(
    () => css`
        margin-left: auto;
        cursor: pointer;
        width: 17px;
        height: 17px;
    `
)

export const StyledBody = styled.div(
    ({theme: {spacing, typography}}: {theme: DefaultTheme}) => css`
        padding: ${spacing * 8}px ${spacing * 5}px;
        ${typography.textM}
    `
)
