import styled, {DefaultTheme, css} from 'styled-components'

export const StyledSearchWrapper = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        margin: ${spacing * 6}px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        & > *:first-child {
            flex-grow: 1;
            max-width: 500px;
        }
        & > *:last-child {
            text-wrap: nowrap;
            margin-top: ${spacing * 2}px;
        }
        ${mediaQueries.m} {
            flex-direction: row;
            & > *:last-child {
                margin-top: 0;
            }
        }
    `}
`

export const StyledFilterTabWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing}px;
        margin-bottom: ${spacing * 5}px;
    `}
`

export const StyledFilterTab = styled.div`
    ${({theme: {spacing, palette, typography}, isActive}: {theme: DefaultTheme; isActive: boolean}) => css`
        ${typography.textXS}
        padding: ${spacing * 2}px ${spacing * 3}px;
        background: ${palette.neutral.white};
        color: ${palette.gray.main};
        border-radius: 16px;
        border: 1px solid ${palette.gray.disabled};
        cursor: pointer;
        ${isActive &&
        css`
            border: none;
            background: ${palette.gray.main};
            color: ${palette.neutral.white};
        `}
    `}
`
export const StyledSelectedMembersWrapper = styled.div`
    ${({theme: {spacing, palette, typography}}) => css`
        ${typography.textS}
        color: ${palette.gray.main};
        display: flex;
        align-items: center;
        min-height: 18px;
        font-weight: 500;
        & span {
            padding-right: ${spacing * 1.5}px;
            cursor: pointer;
            text-decoration-line: underline;
        }
    `}
`

export const StyledItem = styled.span`
    ${({theme: {spacing, palette}}) => css`
        padding-left: ${spacing * 1.5}px;
        border-left: 1px solid ${palette.gray.menuActive};
    `}
`
export const StyledDeleteIcon = styled.div`
    ${({theme: {spacing}}) => css`
        margin-left: auto;
        display: flex;
        align-items: center;
        gap: ${spacing}px;

        cursor: pointer;
        & span {
            text-decoration-line: none;
        }
    `}
`
