import React, {useEffect, useState} from 'react'
import Modal from '@/components/commons/Modal'
import {useTranslation} from 'react-i18next'
import {StyledChildrenRoot, StyledForm, StyledPointsWrapper} from './style'
import Input from '@/components/ui/Input'
import {RANK_MODEL, RankValidationSchema, rankFormSchema} from '../../services/rankFormModel'
import {zodResolver} from '@hookform/resolvers/zod'
import {useForm, SubmitHandler} from 'react-hook-form'
import {ValidationError, handleApiError} from '@/utilities/helpers'
import Spinner from '@/components/ui/Spinner'
import {useCreateRank} from '../../queries/useCreateRank'
import {FileUploader} from '@/components/commons/FileUploader'
import {FileWithPath} from 'react-dropzone'

export interface RankModalProps {
    handleClose: () => void
}

const CreateRankModal: React.FC<RankModalProps> = ({handleClose}) => {
    const {t} = useTranslation()

    const onSuccess = () => {
        handleClose()
    }

    const {mutate, isPending, isError, error} = useCreateRank({onSuccess})

    const [file, setFile] = useState<FileWithPath[]>([])

    const ACCEPT_BADGE_FILE_TYPES = {
        'image/png': ['.png'],
        'image/jpg': ['.jpg'],
        'image/jpeg': ['.jpeg']
    }

    const MAX_FILE_SIZE = 250000 // 250kb

    const defaultValues = {
        [RANK_MODEL.Name]: '',
        [RANK_MODEL.MinScore]: '',
        [RANK_MODEL.MaxScore]: ''
    }

    const {
        register,
        handleSubmit,
        setError,
        formState: {errors, isValid}
    } = useForm<RankValidationSchema>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(rankFormSchema),
        defaultValues: {...defaultValues}
    })

    const onSubmit: SubmitHandler<RankValidationSchema> = data => {
        const formData = new FormData()
        formData.append('file', file[0])
        formData.append('name', data[RANK_MODEL.Name])
        formData.append('minScore', data[RANK_MODEL.MinScore])
        formData.append('maxScore', data[RANK_MODEL.MaxScore])

        const dataToSend = formData
        mutate(dataToSend)
    }

    useEffect(() => {
        if (isError) handleApiError({setError, error: error as ValidationError<'name'>})
    }, [isError])

    return (
        <Modal
            submitButtonCallback={handleSubmit(onSubmit)}
            cancelButtonCallback={handleClose}
            onClose={handleClose}
            title={t('rank_modal:title')}
            isSubmitButtonDisabled={!isValid || !file.length}
        >
            <div>
                {isPending && <Spinner size={48} />}
                <StyledChildrenRoot>
                    <span>{t('rank_modal:description')}</span>
                </StyledChildrenRoot>
                <StyledForm onSubmit={handleSubmit(onSubmit)}>
                    <Input
                        {...register(RANK_MODEL.Name)}
                        label={`${t('rank_modal:form:name')}*`}
                        errorMessage={`${t(errors[RANK_MODEL.Name]?.message || '')}`}
                    />
                    <span>
                        <FileUploader onChange={setFile} maxSize={MAX_FILE_SIZE} accept={ACCEPT_BADGE_FILE_TYPES} />
                    </span>
                    <StyledPointsWrapper>
                        <Input
                            type={'number'}
                            {...register(RANK_MODEL.MinScore)}
                            label={`${t('rank_modal:form:minimum_score')}*`}
                            errorMessage={`${t(errors[RANK_MODEL.MinScore]?.message || '')}`}
                        />
                        <Input
                            type={'number'}
                            {...register(RANK_MODEL.MaxScore)}
                            label={`${t('rank_modal:form:highest_score')}*`}
                            errorMessage={`${t(errors[RANK_MODEL.MaxScore]?.message || '')}`}
                        />
                    </StyledPointsWrapper>
                </StyledForm>
            </div>
        </Modal>
    )
}

export default CreateRankModal

CreateRankModal.displayName = 'CreateRankModal'
