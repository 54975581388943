import {FC, ReactNode} from 'react'
import {StyledBody, StyledCloseIcon, StyledContainer, StyledFooter, StyledHeader} from './style'
import Popover from '../../ui/Popover'
import {CloseIcon} from '@/assets/icons/icons'
import Button from '../../ui/Button'
import {useTranslation} from 'react-i18next'

interface ModalProps {
    children: ReactNode
    overlay?: boolean
    className?: string
    onClose?: () => void
    title: string
    icon?: ReactNode
    cancelButtonText?: string
    submitButtonText?: string
    cancelButtonCallback?: () => void
    submitButtonCallback?: () => void
    cancelButtonType?: 'button' | 'submit' | 'reset' | undefined
    submitButtonType?: 'button' | 'submit' | 'reset' | undefined
    isSubmitButtonDisabled?: boolean
    maxWidth?: number
    withFooter?: boolean
}

const Modal: FC<ModalProps> = ({
    children,
    overlay = true,
    className,
    title,
    onClose,
    cancelButtonText = 'common:cancel',
    submitButtonText = 'common:submit',
    cancelButtonCallback,
    submitButtonCallback,
    cancelButtonType = 'button',
    submitButtonType = 'submit',
    isSubmitButtonDisabled,
    maxWidth = 400,
    withFooter = true,
    icon
}) => {
    const {t} = useTranslation()

    return (
        <Popover overlay={overlay} className={className} onClose={onClose} maxWidth={maxWidth}>
            <StyledContainer>
                <StyledHeader $withIcon={icon !== undefined}>
                    {icon}
                    <h4>{t(title)}</h4>
                    {typeof onClose === 'function' && (
                        <StyledCloseIcon>
                            <CloseIcon onClick={onClose} />
                        </StyledCloseIcon>
                    )}
                </StyledHeader>
                <StyledBody>{children}</StyledBody>
                {(cancelButtonCallback || submitButtonCallback) && withFooter && (
                    <StyledFooter>
                        {cancelButtonCallback && (
                            <Button
                                variant={'transparent'}
                                size={'sm'}
                                onClick={cancelButtonCallback}
                                type={cancelButtonType}
                            >
                                {t(cancelButtonText)}
                            </Button>
                        )}
                        {submitButtonCallback && (
                            <Button
                                variant={'primary'}
                                size={'sm'}
                                onClick={submitButtonCallback}
                                type={submitButtonType}
                                disabled={isSubmitButtonDisabled}
                            >
                                {t(submitButtonText)}
                            </Button>
                        )}
                    </StyledFooter>
                )}
            </StyledContainer>
        </Popover>
    )
}

Modal.displayName = 'Modal'
export default Modal
