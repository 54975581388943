import styled, {css, DefaultTheme} from 'styled-components'

export const StyledTitle = styled.div(
    ({theme: {spacing, typography, palette, mediaQueries}}: {theme: DefaultTheme}) => css`
        display: flex;
        justify-content: space-between;
        margin-bottom: ${spacing * 12}px;
        ${typography.textXL};
        color: ${palette.gray.main};
        font-weight: 500;
        & span {
            ${typography.textL};
            color: ${palette.blue.link};
            font-weight: 400;
            cursor: pointer;
        }
        ${mediaQueries.m} {
            margin-bottom: ${spacing * 6}px;
        }
    `
)
export const StyledItemsWrapper = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: ${spacing * 4}px;
    `
)

export const StyledItem = styled.div(
    ({theme: {typography, mediaQueries}}) => css`
        ${typography.textL};
        flex-grow: 1;
        flex-shrink: 0;
        width: 100%;
        ${mediaQueries.s} {
            max-width: 190px;
        }
    `
)

export const StyledFirstItem = styled(StyledItem)(
    ({theme: {mediaQueries}}) => css`
        flex-grow: 2;
        max-width: 100%;
        ${mediaQueries.l} {
            display: none;
        }
    `
)

export const StyledItemHeader = styled.div(
    ({theme: {spacing, typography, palette, mediaQueries}}: {theme: DefaultTheme}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing * 3}px;
        border-bottom: 1px solid ${palette.gray.lightBG};
        padding-bottom: ${spacing * 6}px;
        & svg {
            width: 16px;
            height: 16px;
        }
        & p {
            ${typography.textL};
            color: ${palette.gray.main};
        }
        ${mediaQueries.l} {
            padding-bottom: ${spacing * 7}px;
        }
    `
)

export const StyledChevronIcon = styled.div(
    ({theme: {mediaQueries}, showFilterItems}: {theme: DefaultTheme; showFilterItems: boolean}) => css`
        width: 24px;
        height: 24px;
        margin-left: auto;
        transform: ${showFilterItems ? 'rotate(0deg)' : 'rotate(180deg)'};
        cursor: pointer;
        & svg {
            width: 24px;
            height: 24px;
        }
        ${mediaQueries.l} {
            display: none;
        }
    `
)

export const StyledSortItemWrapper = styled.div(
    ({theme: {palette, spacing}}) => css`
        display: grid;
        gap: ${spacing * 2}px;
        color: ${palette.gray.dark};
        margin: ${spacing * 5}px 0;
        & p {
            cursor: pointer;
            &:hover {
                color: ${palette.blue.link};
            }
        }
    `
)

export const StyledSortItem = styled.div(
    ({theme: {palette}, isActive}: {theme: DefaultTheme; isActive: boolean}) => css`
        cursor: pointer;
        &:hover {
            color: ${palette.blue.link};
        }
        ${isActive &&
        css`
            color: ${palette.blue.link};
        `}
    `
)
