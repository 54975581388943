import styled, {css} from 'styled-components'

export const StyledLeaderCard = styled.div`
    ${({theme: {spacing, mediaQueries, typography}}) => css`
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: ${spacing * 4}px 0px;
        ${typography.textM};
        ${mediaQueries.m} {
            padding: ${spacing * 4}px ${spacing * 7.5}px;
            ${typography.textL};
        }
    `}
`

export const StyledInfoSection = styled.div`
    ${({theme: {spacing, typography, palette, mediaQueries, flexUtils}}) => css`
        ${flexUtils.start};
        width: 50%;
        gap: ${spacing * 2}px;
        color: ${palette.gray.main};
        padding: 0px;

        & span {
            ${typography.textXL};
        }

        ${mediaQueries.m} {
            gap: ${spacing * 9}px;
            padding: ${spacing * 3}px 0px;
        }
    `}
`

export const StyledAvatarWrapper = styled.div`
    ${({theme: {typography}}) => css`
        & span {
            ${typography.textXXL}
        }
    `}
`

export const StyledName = styled.div`
    font-weight: 700;
    cursor: pointer;
`

export const StyledUsername = styled.div`
    ${({theme: {typography, palette, truncateText}}) => css`
        ${typography.textM}
        display: block;
        color: ${palette.neutral[500]};
        ${truncateText}
    `}
`

export const StyledDate = styled.div`
    ${({theme: {palette, mediaQueries, typography}}) => css`
        color: ${palette.gray.middle};
        ${typography.textS}
        ${mediaQueries.m} {
            ${typography.textL}
        }
    `}
`

export const StyledBadgeSection = styled.div`
    ${({theme: {palette, spacing, mediaQueries, flexUtils}}) => css`
        ${flexUtils.end};
        width: 50%;
        gap: ${spacing * 2}px;
        color: ${palette.gray.main};
        text-align: end;

        & img {
            display: none;
        }

        & span {
            font-weight: 700;
        }

        ${mediaQueries.m} {
            gap: ${spacing * 9}px;

            & img {
                display: block;
                max-height: 90px;
            }
        }
    `}
`

export const StyledBadgeType = styled.div`
    ${({theme: {palette}}) => css`
        color: ${palette.gray.main};
    `}
`

export const StyledBadgeImage = styled.img`
    ${() => css`
        max-width: 60px;
    `}
`
