import PreviewEventCard from '@/features/events/components/preview-event-card/PreviewEventCard'
import {StyledList, StyledTopDivider, StyledViewAllRoot} from './style'
import {useGetEventsList} from '@/features/events/queries/useGetEventsList'
import {useEffect} from 'react'
import {handleApiError} from '@/utilities/helpers'
import Spinner from '@/components/ui/Spinner'
import useGetScreenSize from '@/hooks/useGetScreenSize'
import {SCREEN_SIZE} from '@/types/commons'
import {useTranslation} from 'react-i18next'
import {ROUTE_EVENTS_LIST} from '@/utilities/constants'
import {generatePath, useNavigate} from 'react-router-dom'

const EventsList = ({status, title}: {status?: string; title: string}) => {
    const navigate = useNavigate()
    const screenSize = useGetScreenSize()
    const {t} = useTranslation()
    const NUMBERS_OF_ITEMS_TO_SHOW = {
        [SCREEN_SIZE.MOBILE]: 1,
        [SCREEN_SIZE.TABLET]: 3,
        [SCREEN_SIZE.DESKTOP]: 4
    }

    const {data, error, isLoading, isError} = useGetEventsList({limit: NUMBERS_OF_ITEMS_TO_SHOW[screenSize], status})

    useEffect(() => {
        if (isError && error instanceof Error) {
            handleApiError({error})
        }
    }, [isError])
    const isViewAllButtonVisible = !!data && NUMBERS_OF_ITEMS_TO_SHOW[screenSize] < data?.pages[0]?.meta?.total

    if (!data?.pages?.at(0)?.data.length) return null

    return isLoading ? (
        <Spinner size={48} inline overlay={false} />
    ) : (
        <div>
            <StyledTopDivider />

            <StyledViewAllRoot>
                <div>{title}</div>
                {isViewAllButtonVisible && (
                    <span
                        onClick={() => {
                            navigate(
                                generatePath(ROUTE_EVENTS_LIST, {
                                    status: `${status}`
                                })
                            )
                        }}
                    >
                        {t('common:view_all')}
                    </span>
                )}
            </StyledViewAllRoot>

            <StyledList>
                {data?.pages?.at(0)?.data.length && (
                    <>
                        {data?.pages[0].data.map(event => (
                            <PreviewEventCard event={event} key={event.id} />
                        ))}
                    </>
                )}
            </StyledList>
        </div>
    )
}
export default EventsList

EventsList.displayName = 'EventsList'
