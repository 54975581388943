import styled, {css, DefaultTheme} from 'styled-components'

type StyledBtnProps = {
    size: 'xs' | 'sm' | 'lg' | 'md'
    variant: 'primary' | 'secondary' | 'outline' | 'transparent' | 'warning' | 'success'
}

export const StyledButton = styled.button<StyledBtnProps>`
    ${({theme: {palette, spacing, mediaQueries, typography, flexUtils}}) => css`
        ${typography.textS};
        line-height: normal;
        border-radius: 32px;
        cursor: pointer;
        padding: ${spacing * 3}px ${spacing * 5}px;
        color: ${palette.neutral.white};
        font-size: ${spacing * 3}px;

        //children
        ${flexUtils.centered};
        gap: 10px;
        &:disabled {
            cursor: not-allowed;
            opacity: 0.75;
        }
        ${mediaQueries.m} {
            ${typography.textM};
            line-height: normal;
        }
    `}
    ${({variant, theme}) => ColorStyles(theme)[variant]};
    ${({size, theme}) => SizeStyles(theme)[size]};
`

const ColorStyles = ({palette, mediaQueries}: DefaultTheme) => ({
    primary: css`
        background-color: ${palette.gray.main};
        border: none;
        &:disabled {
            background-color: ${palette.gray.disabled};
        }
    `,
    secondary: css`
        background-color: ${palette.blue.main};
        border: none;
        max-width: 216px;
        &:disabled {
            background-color: ${palette.blue.disabled};
        }
        ${mediaQueries.m} {
            max-width: 100%;
        }
    `,
    outline: css`
        color: ${palette.gray.main};
        background-color: ${palette.neutral.white};
        border: 1.5px solid ${palette.gray.main};
    `,
    transparent: css`
        color: ${palette.gray.main};
        background-color: transparent;
        border: none;
        font-weight: 700;
        text-decoration: underline;
        &:disabled {
            color: ${palette.gray.disabled};
        }
    `,
    warning: css`
        background-color: ${palette.red.labelText};
        border: none;
        &:disabled {
            background-color: ${palette.red.labelText};
        }
    `,
    success: css`
        background-color: ${palette.status.completed.text};
        border: none;
        &:disabled {
            background-color: ${palette.status.completed.text};
        }
    `
})

const SizeStyles = ({mediaQueries, spacing, typography}: DefaultTheme) => ({
    xs: css`
        width: fit-content;
        padding: ${spacing * 1.5}px ${spacing * 3}px;
        ${typography.textS}
        & svg {
            width: 24px;
            height: 24px;
        }
    `,
    sm: css`
        width: fit-content;
        & svg {
            width: 24px;
            height: 24px;
        }
    `,
    md: css`
        width: fit-content;
        padding: ${spacing * 2}px ${spacing * 3}px;
        ${typography.textS}
        font-weight: 500;
    `,
    lg: css`
        width: 100%;
        max-height: 39px;
        & svg {
            width: 24px;
            height: 24px;
        }
        ${mediaQueries.m} {
            width: 360px;
        }
    `
})
