import React, {useEffect} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {z} from 'zod'
import {ValidationError, checkInputRemoveValue, handleApiError} from '@/utilities/helpers'
import WorkspaceActionModal from '@/components/commons/WorkspaceActionModal/WorkspaceActionModal'
import {Workspace} from '../../types'
import {useRemoveWorkspace} from '../../queries/useRemoveWorkspace'

const enum CONFIRM_WORKSPACE_DELETION_MODEL {
    Type = 'type'
}

const ConfirmWorkspaceDeletionSchema = z.object({
    [CONFIRM_WORKSPACE_DELETION_MODEL.Type]: z
        .string()
        .min(1, {message: 'validation:required_field'})
        .refine(value => checkInputRemoveValue(value), {message: 'validation:invalid_value'})
        .default('')
})

type ValidationSchema = z.infer<typeof ConfirmWorkspaceDeletionSchema>

export interface DeletionModalProps {
    onClose: () => void
    workspaceData: Workspace
    hasOtherMembers: boolean
    hasPendingRequest: boolean
}

const WorkspaceDeletionModal: React.FC<DeletionModalProps> = ({
    onClose,
    workspaceData,
    hasOtherMembers,
    hasPendingRequest
}) => {
    const {t} = useTranslation()
    const {mutate, isPending, isError, error} = useRemoveWorkspace({onSuccess: onClose})

    const {
        register,
        handleSubmit,
        formState: {errors, isValid, isDirty}
    } = useForm<ValidationSchema>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(ConfirmWorkspaceDeletionSchema)
    })

    useEffect(() => {
        if (isError) handleApiError({error: error as ValidationError<string>})
    }, [isError])

    const onSubmit = () => {
        mutate(workspaceData?.id)
    }

    const renderCorrectInfo = () => {
        switch (true) {
            // Case 3 User delete a workspace that  is their own and in which there is only them. They aren’t working on a video.
            case !hasPendingRequest && !hasOtherMembers: {
                return (
                    <div>
                        <Trans
                            t={t}
                            i18nKey="workspace:confirm_workspace_abandonment_modal:sub_description_case_3"
                            values={{WORKSPACE_NAME: workspaceData?.name}}
                            components={{
                                span: <span />
                            }}
                        />
                    </div>
                )
            }
            // Case 4 User delete a workspace that  is their own and in which there is only them. They are working on a video.
            case hasPendingRequest && !hasOtherMembers: {
                return (
                    <div>
                        <Trans
                            t={t}
                            i18nKey="workspace:confirm_workspace_abandonment_modal:sub_description_case_3"
                            values={{WORKSPACE_NAME: workspaceData?.name}}
                            components={{
                                span: <span />
                            }}
                        />
                        <p>{t('workspace:confirm_workspace_abandonment_modal:sub_description_case_2')}</p>
                    </div>
                )
            }
            // Case 5 User delete a workspace that is their own and in which there are other users.
            case hasOtherMembers: {
                return (
                    <div>
                        <Trans
                            t={t}
                            i18nKey="workspace:confirm_workspace_abandonment_modal:sub_description_case_5"
                            values={{WORKSPACE_NAME: workspaceData?.name}}
                            components={{
                                span: <span />
                            }}
                        />
                    </div>
                )
            }
            default:
                return null
        }
    }

    return (
        <WorkspaceActionModal
            onClose={onClose}
            title="workspace:confirm_workspace_abandonment_modal:title_deletion"
            description="workspace:confirm_workspace_abandonment_modal:description_deletion"
            cancelButtonName="common:cancel"
            subDescription={renderCorrectInfo()}
            errorMessage={errors.type?.message || ''}
            handleSubmit={handleSubmit(onSubmit)}
            isConfirmButtonDisabled={!isValid || !isDirty || isPending}
            isSubmitting={isPending}
            {...register(CONFIRM_WORKSPACE_DELETION_MODEL.Type)}
        />
    )
}

export default WorkspaceDeletionModal

WorkspaceDeletionModal.displayName = 'WorkspaceDeletionModal'
