import {DeleteIcon} from '@/assets/icons/icons'
import Avatar from '@/components/ui/Avatar'
import Checkbox from '@/components/ui/Checkbox'
import {StyledButtonsWrapper, StyledContent, StyledEmail, StyledMemberItem, StyledName, StyledStatus} from './style'
import {MemberType} from '../../types'
import {useTranslation} from 'react-i18next'
import {Dispatch, SetStateAction} from 'react'
import useAuthStore from '@/store/authStore'
import {useTheme} from 'styled-components'
import AcceptEventRequest from '../accept-event-request/AcceptEventRequest'
import RejectEventRequest from '../reject-event-request/RejectEventRequest'

type MemberTypeProps = {
    member: MemberType
    selectedMembers: number[]
    setSelectedMembers: Dispatch<SetStateAction<number[]>>
    handleDeleteUser?: ((memberId: number) => void) | null
    ownerId: number
    memberId: number
    hasJoined?: boolean
}

const Member = ({
    member,
    selectedMembers,
    setSelectedMembers,
    handleDeleteUser = null,
    ownerId,
    memberId,
    hasJoined = true
}: MemberTypeProps) => {
    const {t} = useTranslation()
    const theme = useTheme()
    const {email, name} = member
    const isPendingStatus = !hasJoined
    const user = useAuthStore(store => store.user)

    const imOwner = ownerId === user?.id
    const isOwner = ownerId === memberId

    const handleCheckMembers = () => {
        if (!selectedMembers.find(item => item === memberId)) {
            setSelectedMembers([...selectedMembers, memberId])
        } else {
            setSelectedMembers(selectedMembers.filter(item => item !== memberId))
        }
    }

    const showDeleteButton = imOwner && !isOwner && !!handleDeleteUser && hasJoined

    return (
        <StyledMemberItem>
            {showDeleteButton && (
                <Checkbox
                    onChange={() => handleCheckMembers()}
                    checked={!!selectedMembers.find(item => item === memberId)}
                />
            )}
            <StyledContent>
                <Avatar name={name ? name : ''} size={49} />
                <div>
                    <StyledName>{name}</StyledName>
                    <StyledEmail>{email}</StyledEmail>
                    {(isPendingStatus || isOwner) && (
                        <StyledStatus isPendingStatus={isPendingStatus}>
                            {isOwner ? t('common:owner') : t(`common:pending`)}
                        </StyledStatus>
                    )}
                </div>
            </StyledContent>

            {!hasJoined && !isOwner && (
                <StyledButtonsWrapper>
                    <AcceptEventRequest userId={`${memberId}`} />
                    <RejectEventRequest userId={`${memberId}`} />
                </StyledButtonsWrapper>
            )}

            {showDeleteButton && (
                <DeleteIcon onClick={() => handleDeleteUser(memberId)} fill={theme.palette.gray.dark} />
            )}
        </StyledMemberItem>
    )
}

export default Member

Member.displayName = 'Member'
