import {useEffect} from 'react'

type Dependency = HTMLElement | null | boolean

export const useClickOutside = ({
    element,
    callback,
    dependencies = [],
    condition = true
}: {
    element: HTMLElement | null
    callback: () => void
    condition?: boolean
    dependencies?: Dependency[]
}) => {
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent | TouchEvent) => {
            if (element && event.target && event.target instanceof Node && !element.contains(event.target)) {
                callback()
            }
        }

        if (condition) {
            document.addEventListener('click', handleClickOutside)
            return () => {
                document.removeEventListener('click', handleClickOutside)
            }
        }
    }, [...dependencies])
}
