import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {httpAcceptEventInvitation} from '../services/events.http'

export const useAcceptEventInvitation = ({onSuccess, eventId}: {onSuccess: () => void; eventId: string}) => {
    const client = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.ACCEPT_EVENT_INVITATION],
        mutationFn: ({token, eventUserInvitationId}: {token: string; eventUserInvitationId: string}) =>
            httpAcceptEventInvitation({eventId, token, eventUserInvitationId}),
        onSuccess: () => {
            onSuccess()
            client.invalidateQueries({queryKey: [QUERY_KEYS.EVENT_DETAILS, eventId]})
        }
    })
}
