import styled, {css} from 'styled-components'

export const StyledRoot = styled.div`
    height: 100%;
    overflow: auto;
`

export const StyledVideoDetailsRoot = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 16}px;
    `}
`

export const StyledContentWrapper = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: grid;
        gap: ${spacing * 14}px;
        ${mediaQueries.l} {
            grid-template-columns: 1fr 1fr;
        }
    `}
`
