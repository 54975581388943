import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {httpLeaveWorkspace} from '../services/workspaces.http'

type UseLeaveWorkspaceProps = {
    onSuccess?: () => void
}

export const useLeaveWorkspace = ({onSuccess}: UseLeaveWorkspaceProps) => {
    const client = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.LEAVE_WORKSPACE],
        mutationFn: ({workspaceId, userId}: {workspaceId: string; userId: number}) =>
            httpLeaveWorkspace({workspaceId, userId}),
        onSuccess: () => {
            typeof onSuccess === 'function' && onSuccess()
            client.invalidateQueries({queryKey: [QUERY_KEYS.WORKSPACES_LIST]})
        }
    })
}
