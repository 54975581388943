import React from 'react'
import {
    StyledLeaderCard,
    StyledInfoSection,
    StyledName,
    StyledDate,
    StyledAvatarWrapper,
    StyledBadgeSection,
    StyledBadgeType,
    StyledBadgeImage,
    StyledUsername
} from './style'
import Avatar from '@/components/ui/Avatar'
import {getLocalDate} from '@/utilities/helpers'
import {useTranslation} from 'react-i18next'
import {LeaderType} from '../../types'
import {generatePath, useNavigate} from 'react-router'
import {ROUTE_USER_PROFILE} from '@/utilities/constants'

export interface LeaderCardProps {
    leader: LeaderType
    itemNumber: number
    isNumbered?: boolean
    isEvent?: boolean
}

const LeaderCard: React.FC<LeaderCardProps> = ({leader, itemNumber, isNumbered, isEvent}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const localDate = leader.createdAt ? getLocalDate(leader.createdAt, 'MMM D, YYYY') : '--'

    return (
        <StyledLeaderCard>
            <StyledInfoSection>
                {isNumbered && <span>{`${itemNumber}.`}</span>}
                <StyledAvatarWrapper>
                    <Avatar size={64} name={leader.name} />
                </StyledAvatarWrapper>

                <div>
                    <StyledName onClick={() => navigate(generatePath(ROUTE_USER_PROFILE, {userId: `${leader.id}`}))}>
                        {leader.name}
                    </StyledName>
                    <StyledUsername>{leader.username}</StyledUsername>
                    <StyledDate>{t('my_profile:info:start_date', {DATE: localDate})}</StyledDate>
                </div>
            </StyledInfoSection>
            <StyledBadgeSection>
                <div>
                    <span>
                        {t('leader_board:points', {
                            POINTS: isEvent ? leader.eventPoints : leader?.typeAttributes?.points
                        })}
                    </span>
                    <StyledBadgeType>{leader?.typeAttributes?.badgeName}</StyledBadgeType>
                </div>
                {leader?.typeAttributes?.badge && <StyledBadgeImage src={leader?.typeAttributes?.badge} />}
            </StyledBadgeSection>
        </StyledLeaderCard>
    )
}

export default LeaderCard

LeaderCard.displayName = 'LeaderCard'
