import {AttachmentIcon, DeleteIcon} from '@/assets/icons/icons'
import React from 'react'
import {StyledDeleteButton, StyledFileInfo, StyledFileName, StyledHint} from './style'
import {useTranslation} from 'react-i18next'

interface InfoProps {
    fileName?: string
    handleRemove: () => void
}

const Info: React.FC<InfoProps> = ({fileName, handleRemove}) => {
    const {t} = useTranslation()

    return (
        <>
            {!!fileName && (
                <StyledFileInfo>
                    <AttachmentIcon />
                    <StyledFileName>{fileName}</StyledFileName>
                    <StyledDeleteButton onClick={handleRemove}>
                        <DeleteIcon />
                    </StyledDeleteButton>
                </StyledFileInfo>
            )}
            <StyledHint>{t('file_uploader:uploader_hint')}</StyledHint>
        </>
    )
}

export default Info

Info.displayName = 'Info'
