import Container from '@/components/ui/Container'
import TitleSection from '@/features/contributors/components/title-section/TitleSection'
import {Trans, useTranslation} from 'react-i18next'
import {useTheme} from 'styled-components'
import {StyledDescription, StyledRoot, StyledWrapper} from './style'
import ContributorContainer from '@/components/ui/ContributorContainer/ContributorContainer'
import EventsList from '@/features/events/components/events-list/EventsList'
import {EVENT_STATUS} from '@/utilities/constants/event'

const EventsPage: React.FC = () => {
    const {t} = useTranslation()
    const {contributorContainerSize, infoContainerSize} = useTheme()

    return (
        <StyledRoot>
            <ContributorContainer>
                <TitleSection
                    title={t('event_page:title')}
                    subtitle={t('event_page:subtitle')}
                    description={
                        <Trans
                            t={t}
                            i18nKey="event_page:description"
                            components={{
                                span: <span />
                            }}
                        />
                    }
                />
                <StyledWrapper>
                    <Container maxWidth={infoContainerSize}>
                        <StyledDescription>
                            <Trans
                                t={t}
                                i18nKey="event_page:text"
                                components={{
                                    span: <span />,
                                    a: <a href="https://www.europeana.eu/" target="_blank" />,
                                    ul: <ul />,
                                    li: <li />
                                }}
                            />
                        </StyledDescription>
                    </Container>
                    <Container maxWidth={contributorContainerSize}>
                        <div>
                            {/* Ongoing events */}
                            <EventsList title={t('event_page:events_open')} status={EVENT_STATUS.Open} />

                            {/* Upcoming events*/}
                            <EventsList title={t('event_page:events_scheduled')} status={EVENT_STATUS.Scheduled} />

                            {/* Closed events */}
                            <EventsList title={t('event_page:events_closed')} status={EVENT_STATUS.Closed} />
                        </div>
                    </Container>
                </StyledWrapper>
            </ContributorContainer>
        </StyledRoot>
    )
}
export default EventsPage

EventsPage.displayName = 'EventsPage'
