import {InputHTMLAttributes, ReactNode, forwardRef} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import Spinner from '@/components/ui/Spinner'
import {
    StyledBox,
    StyledDescription,
    StyledFooter,
    StyledFooterContainer,
    StyledHeaderContainer,
    StyledInputWrapper,
    StyledFormRoot
} from './style'
import Input from '@/components/ui/Input'
import Button from '@/components/ui/Button'
import {ExclamationCircleIcon, WarningIcon} from '@/assets/icons/icons'
import Popover from '@/components/ui/Popover'

export interface WorkspaceActionModalProps extends InputHTMLAttributes<HTMLInputElement> {
    onClose: () => void
    title: string
    description: string
    subDescription?: string | ReactNode | null
    isSubmitting: boolean
    errorMessage: string
    handleSubmit: () => void
    isConfirmButtonDisabled: boolean
    cancelButtonName?: string
    inputHelpText?: string
}

const WorkspaceActionModal = forwardRef<HTMLInputElement, WorkspaceActionModalProps>(
    (
        {
            onClose,
            title,
            description,
            subDescription,
            isSubmitting,
            errorMessage,
            handleSubmit,
            isConfirmButtonDisabled,
            cancelButtonName = 'common:cancel',
            inputHelpText = 'common:input_remove_label',
            ...rest
        },
        ref
    ) => {
        const {t} = useTranslation()

        return (
            <Popover overlay={true} onClose={onClose}>
                <StyledFormRoot onSubmit={handleSubmit}>
                    <StyledHeaderContainer>
                        <ExclamationCircleIcon />
                        <h4>{t(title)}</h4>

                        {isSubmitting && <Spinner size={48} overlay={false} />}
                        <StyledDescription>
                            <div>
                                <Trans
                                    t={t}
                                    i18nKey={description}
                                    components={{
                                        span: <span />
                                    }}
                                />
                            </div>
                        </StyledDescription>
                    </StyledHeaderContainer>

                    <StyledFooterContainer>
                        {!!subDescription && (
                            <StyledBox>
                                <WarningIcon />
                                {subDescription}
                            </StyledBox>
                        )}
                        <StyledInputWrapper>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey={inputHelpText}
                                    components={{
                                        span: <span />
                                    }}
                                />
                            </p>
                            <Input {...rest} errorMessage={`${t(errorMessage || '')}`} ref={ref} />
                        </StyledInputWrapper>
                        <StyledFooter>
                            <Button variant={'primary'} size={'sm'} onClick={onClose}>
                                {t(cancelButtonName)}
                            </Button>
                            <Button
                                variant={'transparent'}
                                size={'sm'}
                                onClick={handleSubmit}
                                disabled={isConfirmButtonDisabled}
                            >
                                {t('common:confirm')}
                            </Button>
                        </StyledFooter>
                    </StyledFooterContainer>
                </StyledFormRoot>
            </Popover>
        )
    }
)
export default WorkspaceActionModal

WorkspaceActionModal.displayName = 'WorkspaceActionModal'
