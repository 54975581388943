import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {z} from 'zod'
import {ValidationError, checkInputRemoveValue, handleApiError} from '@/utilities/helpers'
import WorkspaceActionModal from '@/components/commons/WorkspaceActionModal/WorkspaceActionModal'
import {Workspace} from '../../types'
import {useLeaveWorkspace} from '../../queries/useLeaveWorkspace'

const enum CONFIRM_WORKSPACE_ABANDONMENT_MODEL {
    Type = 'type'
}

const ConfirmWorkspaceAbandonmentSchema = z.object({
    [CONFIRM_WORKSPACE_ABANDONMENT_MODEL.Type]: z
        .string()
        .min(1, {message: 'validation:required_field'})
        .refine(value => checkInputRemoveValue(value), {message: 'validation:invalid_value'})
        .default('')
})

type ValidationSchema = z.infer<typeof ConfirmWorkspaceAbandonmentSchema>

export interface AbandonmentModalProps {
    onClose: () => void
    workspaceData: Workspace
    imOwner: boolean
    hasOtherMembers: boolean
    hasPendingRequest: boolean
    userId: number
}

const WorkspaceAbandonmentModal: React.FC<AbandonmentModalProps> = ({
    onClose,
    workspaceData,
    imOwner,
    hasOtherMembers,
    hasPendingRequest,
    userId
}) => {
    const {t} = useTranslation()
    const {mutate, isPending, isError, error} = useLeaveWorkspace({onSuccess: onClose})

    const {
        register,
        handleSubmit,
        formState: {errors, isValid, isDirty}
    } = useForm<ValidationSchema>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(ConfirmWorkspaceAbandonmentSchema)
    })

    useEffect(() => {
        if (isError) handleApiError({error: error as ValidationError<string>})
    }, [isError])

    const onSubmit = () => {
        if (userId) {
            mutate({workspaceId: `${workspaceData?.id}`, userId})
        }
    }

    const renderCorrectInfo = () => {
        switch (true) {
            // Case 1 User is leaving a workspace that is not their own. They aren’t working on a video.
            case !imOwner && !hasPendingRequest: {
                return null
            }
            // Case 2 User is leaving a workspace that is not their own. They are working on a video.
            case !imOwner && hasPendingRequest: {
                return t('workspace:confirm_workspace_abandonment_modal:sub_description_case_2')
            }
            // Case 6 User is leaving a workspace that is their own and in which there are other users.
            case imOwner && hasOtherMembers: {
                return t('workspace:confirm_workspace_abandonment_modal:sub_description_case_6')
            }
            default:
                return null
        }
    }

    return (
        <WorkspaceActionModal
            onClose={onClose}
            title="workspace:confirm_workspace_abandonment_modal:title_abandonment"
            description="workspace:confirm_workspace_abandonment_modal:description_abandonment"
            cancelButtonName="common:cancel"
            subDescription={renderCorrectInfo()}
            errorMessage={errors.type?.message || ''}
            handleSubmit={handleSubmit(onSubmit)}
            isConfirmButtonDisabled={!isValid || !isDirty || isPending}
            isSubmitting={isPending}
            {...register(CONFIRM_WORKSPACE_ABANDONMENT_MODEL.Type)}
        />
    )
}

export default WorkspaceAbandonmentModal

WorkspaceAbandonmentModal.displayName = 'WorkspaceAbandonmentModal'
