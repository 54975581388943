import styled, {DefaultTheme, css} from 'styled-components'

export const StyledRoot = styled.div`
    ${({theme: {spacing}}) => css`
        margin-bottom: ${spacing * 6}px;
        border-radius: 16px;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    `}
`

export const StyledCoverVideo = styled.div`
    ${() => css`
        border-radius: 16px 16px 0 0;
        overflow: hidden;
    `}
`

export const StyledDescription = styled.div`
    ${({theme: {spacing}}) => css`
        padding: ${spacing * 5}px;
        display: flex;
        flex-direction: column;
    `}
`

export const StyledStatusLabelWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        margin-bottom: ${spacing * 6}px;
    `}
`

export const StyledDeleteButtonWrapper = styled.div`
    cursor: pointer;
    position: relative;
`

export const StyledDeleteButton = styled.div`
    & svg {
        margin-right: -10px;
    }
`

export const StyledTooltipWrapper = styled.div`
    ${({theme: {palette, spacing, zIndex}}) => css`
        position: absolute;
        border-radius: 8px;
        padding: ${spacing * 3}px;
        width: 150px;
        box-shadow: 0px 2px 15px -3px rgba(0, 0, 0, 0.07), 0px 10px 20px -2px rgba(0, 0, 0, 0.04);
        right: -20px;
        top: 30px;
        background: ${palette.neutral.white};
        cursor: pointer;
        z-index: ${zIndex.modal};
    `}
`

export const StyledTooltip = styled.div`
    ${({theme: {palette, spacing, typography, zIndex}}) => css`
        ${typography.textM};
        color: ${palette.gray.main};
        font-weight: 700;
        display: flex;
        align-items: center;
        gap: ${spacing * 2}px;
        z-index: ${zIndex.modal};
        & svg {
            width: 20px;
            height: 20px;
        }
    `}
`
export const StyledOverlay = styled.div(
    ({theme: {zIndex}}: {theme: DefaultTheme}) => css`
        position: fixed;
        inset: 0;
        z-index: ${zIndex.modalOverlay};
    `
)

export const StyledNameWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: auto 0fr;
        gap: ${spacing * 2}px;
    `}
`

export const StyledVideoName = styled.div`
    ${({theme: {spacing, palette, typography, truncateText}}) => css`
        ${typography.textXL};
        font-weight: 700;
        padding-bottom: ${spacing * 2}px;
        color: ${palette.blue.main};
        ${truncateText};
    `}
`
export const StyledLink = styled.div`
    ${({theme: {spacing, palette, typography}}) => css`
        ${typography.textXS};
        margin-bottom: ${spacing * 4}px;
        color: ${palette.gray.secondary};
        display: -webkit-box;
        box-orient: vertical;
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        -moz-line-clamp: 2;
        overflow-wrap: anywhere;
        overflow: hidden;
        max-width: 250px;
    `}
`

export const StyledTextItem = styled.div`
    ${({theme: {spacing, palette, typography}}) => css`
        ${typography.textS};
        font-weight: 700;
        padding-bottom: ${spacing * 2}px;
        color: ${palette.gray.secondary};
        & span {
            font-weight: 400;
        }
    `}
`

export const StyledFooterWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 ${spacing * 5}px ${spacing * 5}px;
    `}
`

export const StyledEuropeanaLogo = styled.a`
    ${({theme: {spacing, palette, typography}}) => css`
        ${typography.textXS};
        display: flex;
        align-items: center;
        gap: ${spacing}px;
        color: ${palette.gray.secondary};
        cursor: pointer;
        & img {
            width: 18px;
            height: 32px;
        }
    `}
`
