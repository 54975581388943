import styled, {css, DefaultTheme} from 'styled-components'

export const StyledPrivateLayout = styled.div`
    height: 100%;
    display: grid;
    overflow: hidden;
`

export const StyledContainer = styled.div(
    ({theme: {mediaQueries}}: {theme: DefaultTheme}) => css`
        height: 100%;
        display: grid;
        grid-template-rows: auto 1fr;
        width: 100vw;
        overflow: auto;
        ${mediaQueries.m} {
            width: 100%;
        }
    `
)

export const StyledOutletWrapper = styled.div(
    ({theme: {mediaQueries, navDesktopSizing}, isNavOpen}: {theme: DefaultTheme; isNavOpen: boolean}) => css`
        height: 100%;
        overflow: auto;
        ${mediaQueries.m} {
            ${isNavOpen &&
            css`
                padding-left: ${navDesktopSizing}px;
            `}
        }
    `
)
