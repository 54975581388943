import React, {useEffect, useMemo, useState} from 'react'
import {
    StyledAddWrapper,
    StyledDeleteIcon,
    StyledItem,
    StyledRankingSection,
    StyledRoot,
    StyledSelectSection,
    StyledTitle
} from './style'
import {DeleteIcon, PlusIcon} from '@/assets/icons/icons'
import {useTranslation} from 'react-i18next'
import RankCard from '../rank-card/RankCard'
import {RankType} from '../../types'
import {ValidationError, handleApiError} from '@/utilities/helpers'
import {useGetRankingList} from '../../queries/useGetRankingList'
import Spinner from '@/components/ui/Spinner'
import {useDeleteRank} from '../../queries/useDeleteRank'
import EmptySection from '@/components/commons/EmptySection/EmptySection'
import CreateRankModal from '../rank-modals/CreateRankModal'
import EditRankModal from '../rank-modals/EditRankModal'

const LeaderBoard: React.FC = () => {
    const {t} = useTranslation()
    const [selectedRanks, setSelectedRanks] = useState<RankType[]>([])
    const [rankToEdit, setRankToEdit] = useState<RankType | null>(null)
    const [isOpenCreateRankModal, setIsOpenCreateRankModal] = useState(false)
    const [isOpenEditRankModal, setIsOpenEditRankModal] = useState(false)
    const {data, isLoading, isError, error} = useGetRankingList()

    const onDeleteSuccess = () => {
        setSelectedRanks([])
    }

    const {mutate, isPending, isError: isDeleteError, error: deleteError} = useDeleteRank({onSuccess: onDeleteSuccess})

    const rankIds = useMemo(() => {
        return selectedRanks.map(rank => rank.id)
    }, [selectedRanks])

    useEffect(() => {
        if (isError || isDeleteError) handleApiError({error: error || (deleteError as ValidationError<string>)})
    }, [isError])

    const ranks = data?.data ?? []

    const onEditRankClick = (rank: RankType | null) => {
        setRankToEdit(rank)
        setIsOpenEditRankModal(true)
    }

    const handleCloseEditRankModal = () => {
        setRankToEdit(null)
        setIsOpenEditRankModal(false)
    }

    const handleDeleteRanks = ({singleRankId}: {singleRankId?: number[]}) => {
        const rankToDelete = singleRankId ? singleRankId : rankIds
        mutate(rankToDelete)
    }

    if (isLoading || isPending) return <Spinner size={48} overlay />

    return (
        <StyledRoot>
            <StyledTitle>{t('my_profile:manage_leaderboard:title')}</StyledTitle>
            <StyledAddWrapper onClick={() => setIsOpenCreateRankModal(true)}>
                <PlusIcon />
                <span>{t('my_profile:manage_leaderboard:add_button')}</span>
            </StyledAddWrapper>

            {/* SELECT SECTION */}

            {!!ranks?.length && (
                <StyledSelectSection>
                    {!!selectedRanks.length && (
                        <>
                            <span onClick={() => setSelectedRanks(ranks)}>{t('workspace:select_all')}</span>
                            <StyledItem onClick={() => setSelectedRanks([])}>{t('workspace:deselect_all')}</StyledItem>
                            <StyledDeleteIcon onClick={() => handleDeleteRanks({})}>
                                <DeleteIcon /> <span>({selectedRanks.length})</span>
                            </StyledDeleteIcon>
                        </>
                    )}
                </StyledSelectSection>
            )}

            {/* RANK LIST */}

            <StyledRankingSection>
                {ranks?.length ? (
                    ranks?.map(rank => (
                        <RankCard
                            key={rank?.id}
                            rank={rank}
                            setSelectedRanks={setSelectedRanks}
                            selectedRanks={selectedRanks}
                            onEditRankClick={onEditRankClick}
                            handleDeleteRanks={handleDeleteRanks}
                        />
                    ))
                ) : (
                    <EmptySection title={t('my_profile:manage_leaderboard:no_ranks')} />
                )}
            </StyledRankingSection>
            {/* Create rank modal */}
            {isOpenCreateRankModal && <CreateRankModal handleClose={() => setIsOpenCreateRankModal(false)} />}
            {/* Edit rank modal */}
            {isOpenEditRankModal && <EditRankModal rankToEdit={rankToEdit} handleClose={handleCloseEditRankModal} />}
        </StyledRoot>
    )
}

export default LeaderBoard

LeaderBoard.displayName = 'LeaderBoard'
