import styled, {css} from 'styled-components'

export const StyledRoot = styled.div`
    ${({theme: {mediaQueries}}) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: fit-content;
        margin-top: 48px;
        ${mediaQueries.m} {
            margin-top: 80px;
        }
    `}
`

export const StyledTitle = styled.div`
    ${({theme: {palette, spacing, mediaQueries, typography}}) => css`
        ${typography.title};
        color: ${palette.gray.main};
        margin-bottom: ${spacing * 3}px;
        ${mediaQueries.m} {
            margin-bottom: ${spacing * 6}px;
        }
    `}
`

export const StyledSubTitle = styled.div`
    ${({theme: {palette, typography}}) => css`
        ${typography.subtitle};
        font-weight: 700;
        max-width: 100%;
        color: ${palette.gray.middle};
        text-align: center;
    `}
`

export const StyledButtonWrapper = styled.div`
    ${({theme: {mediaQueries, spacing}}) => css`
        margin-top: ${spacing * 4}px;
        ${mediaQueries.m} {
            margin-top: ${spacing * 8}px;
        }
    `}
`
