import styled, {css} from 'styled-components'

export const StyledTitle = styled.div`
    ${({theme: {typography, palette, spacing}}) => css`
        color: ${palette.gray.main};
        ${typography.title};
        text-align: center;
        padding: ${spacing * 16}px 0;
    `}
`

export const StyledRoot = styled.div`
    height: 100%;
    overflow: auto;
`

export const StyledWrapper = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        margin-top: ${spacing * 14}px;
        ${mediaQueries.l} {
            margin: ${spacing * 28}px 0 ${spacing * 14}px 0;
        }
    `}
`

export const StyledSection = styled.div`
    ${({theme: {spacing, typography, palette, link}}) => css`
        padding: ${spacing * 8}px 0px;
        line-height: 27px;
        & h2 {
            color: ${palette.gray.main};
            ${typography.text1XL};
            font-weight: 700;
            margin-bottom: ${spacing * 4}px;
        }
        & h3 {
            margin-top: ${spacing * 2}px;
            font-weight: 700;
        }
        & p {
            margin-bottom: ${spacing * 3}px;
        }
        & span {
            ${link};
        }
        & ul {
            margin-left: ${spacing * 2}px;
            padding-left: ${spacing * 2}px;
            list-style: disc;
            line-height: normal;
        }
        & a {
            ${link};
        }
    `}
`
