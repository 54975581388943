import styled, {css} from 'styled-components'

export const StyledPreviewRoot = styled.div`
    min-width: 70px;
    & img {
        object-fit: contain;
        width: 70px;
        height: 90px;
    }
    img:not([src]) {
        opacity: 0;
    }
    img[src='*'] {
        opacity: 1;
    }
`

export const StyledNoFile = styled.div`
    ${({theme: {palette, typography, spacing}}) => css`
        width: 70px;
        height: 90px;
        border: 1px dashed ${palette.gray.middle};
        ${typography.textS};
        color: ${palette.gray.main};
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: ${spacing * 2}px;
    `}
`
