import React from 'react'
import {StyledTooltipWrapper} from './style'

interface HoverTooltipProps {
    children: React.ReactNode
    text: string
}

const HoverTooltip = ({children, text}: HoverTooltipProps) => {
    return (
        <StyledTooltipWrapper>
            {children}
            <span>{text}</span>
        </StyledTooltipWrapper>
    )
}

export default HoverTooltip

HoverTooltip.displayName = 'HoverTooltip'
