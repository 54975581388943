import {Trans, useTranslation} from 'react-i18next'
import {
    StyledBottomNavWrapper,
    StyledDivider,
    StyledFooterRoot,
    StyledFooterText,
    StyledLogoSection,
    StyledLogoLink
} from './style'
import Container from '@/components/ui/Container'
import {useTheme} from 'styled-components'
import {logosList} from '../../fixtures/footerLogosList'
import BottomNav from '@/components/commons/bottom-nav/BottomNav'

const Footer: React.FC = () => {
    const {t} = useTranslation()
    const {contributorContainerSize} = useTheme()

    return (
        <Container maxWidth={contributorContainerSize}>
            <StyledFooterRoot>
                <StyledFooterText>
                    <Trans
                        t={t}
                        i18nKey="footer:text"
                        components={{
                            a: (
                                <a
                                    href="https://digital-strategy.ec.europa.eu/en/activities/digital-programme"
                                    target="_blank"
                                />
                            )
                        }}
                    />
                </StyledFooterText>
                <StyledLogoSection>
                    {logosList.map(logo => (
                        <StyledLogoLink key={logo.id} target="_blank" href={logo.link}>
                            <img height={logo.height} alt={logo.alt} src={logo.image} />
                        </StyledLogoLink>
                    ))}
                </StyledLogoSection>
                <StyledBottomNavWrapper>
                    <StyledDivider />
                    <BottomNav />
                </StyledBottomNavWrapper>
            </StyledFooterRoot>
        </Container>
    )
}

export default Footer

Footer.displayName = 'Footer'
