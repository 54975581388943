import React, {useEffect} from 'react'
import {StyledSubtitle} from './style'
import {useTranslation} from 'react-i18next'
import Modal from '@/components/commons/Modal'
import {handleApiError} from '@/utilities/helpers'
import Spinner from '@/components/ui/Spinner'
import {usePublish} from '@/features/videos/queries/usePublish'

interface PublishModalProps {
    videoId: string
    workspaceId: string
    jobId: string
    onClose: () => void
}

const PublishModal: React.FC<PublishModalProps> = ({videoId, workspaceId, jobId, onClose}: PublishModalProps) => {
    const {t} = useTranslation()
    const {mutate, isPending, isError, error} = usePublish({
        projectId: `${videoId}`,
        jobId: `${jobId}`,
        workspaceId: `${workspaceId}`,
        onSuccess: onClose
    })

    useEffect(() => {
        if (isError) handleApiError({error})
    }, [isError])

    const onClickClose = () => {
        onClose()
    }

    const onSubmit = () => {
        mutate()
    }

    if (isPending) return <Spinner size={48} overlay />

    return (
        <Modal
            overlay
            title={'publish_modal:title'}
            onClose={onClickClose}
            cancelButtonCallback={onClickClose}
            submitButtonCallback={onSubmit}
            submitButtonText={'publish_modal:submit_button'}
        >
            <StyledSubtitle>{t('publish_modal:subtitle')}</StyledSubtitle>
        </Modal>
    )
}

export default PublishModal

PublishModal.displayName = 'PublishModal'
