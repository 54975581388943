import React from 'react'
import Footer from '@/features/contributors/components/footer/Footer'

interface ContainerProps {
    children: React.ReactNode
}

const ContributorContainer = ({children}: ContainerProps) => (
    <div>
        {children}
        <Footer />
    </div>
)

export default ContributorContainer

ContributorContainer.displayName = 'ContributorContainer'
