import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {httpDeleteVideo} from '../services/workspaces.http'

export const useDeleteVideo = ({
    workspaceId,
    videoId,
    onSuccess
}: {
    workspaceId: string
    videoId: string
    onSuccess: () => void
}) => {
    const client = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.DELETE_VIDEO, workspaceId, videoId],
        mutationFn: () => httpDeleteVideo({workspaceId, videoId}),
        onSuccess: () => {
            onSuccess()
            client.invalidateQueries({queryKey: [QUERY_KEYS.WORKSPACE_VIDEOS, workspaceId]})
            client.invalidateQueries({queryKey: [QUERY_KEYS.WORKSPACES_LIST]})
            client.invalidateQueries({queryKey: [QUERY_KEYS.WORKSPACE_EVENT_DETAILS, workspaceId]})
        }
    })
}
