import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {httpEditJobEvent} from '@/features/videos/services/video.http'
import {useMutation, useQueryClient} from '@tanstack/react-query'

export interface PublishRequest {
    projectId: string
    jobId: string
    workspaceId?: string
    onSuccess?: () => void
}

export const useEditJobEvent = ({projectId, jobId, workspaceId, onSuccess}: PublishRequest) => {
    const client = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.EDIT_JOB_EVENT],
        mutationFn: (eventId: string | null) => httpEditJobEvent(projectId, jobId, eventId),
        onSuccess: () => {
            onSuccess && onSuccess()
            client.invalidateQueries({queryKey: [QUERY_KEYS.WORKSPACE_VIDEO_DETAILS, workspaceId, projectId]})
            client.invalidateQueries({queryKey: [QUERY_KEYS.WORKSPACE_EVENT_DETAILS]})
            client.invalidateQueries({queryKey: [QUERY_KEYS.EVENTS]})
        }
    })
}
