import {ChangeEvent, InputHTMLAttributes, forwardRef} from 'react'
import {
    StyledErrorMessage,
    StyledInputWrapper,
    StyledTextarea,
    StyledLabel,
    StyledInputRoot,
    StyledCharsCount
} from './style'
import {useController, useFormContext} from 'react-hook-form'

export interface TextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
    className?: string
    label?: string
    placeholder?: string
    errorMessage?: string
    maxWidth?: string
    hasError?: boolean
    maxLength?: number
    name: string
}

const Textarea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
    ({label, placeholder, name, errorMessage, maxWidth, hasError, maxLength}, ref) => {
        const {control} = useFormContext()
        const {
            field: {value, onChange}
        } = useController({
            name,
            control,
            rules: {required: true}
        })

        const onChangeFiled = (event: ChangeEvent<HTMLTextAreaElement>) => {
            if (maxLength) {
                if (event.target.value.length <= maxLength) onChange(event)
            } else {
                onChange(event)
            }
        }

        return (
            <>
                <StyledInputRoot>
                    {!!label && <StyledLabel htmlFor={name}>{label}</StyledLabel>}
                    <StyledInputWrapper maxWidth={maxWidth} hasError={hasError ? hasError : !!errorMessage}>
                        <StyledTextarea
                            ref={ref}
                            value={value}
                            placeholder={placeholder}
                            onChange={onChangeFiled}
                            hasError={hasError ? hasError : !!errorMessage}
                        />
                        {maxLength && (
                            <StyledCharsCount hasError={hasError ? hasError : !!errorMessage}>{`${
                                value?.length ?? 0
                            }/${maxLength}`}</StyledCharsCount>
                        )}
                    </StyledInputWrapper>
                    {!!errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
                </StyledInputRoot>
            </>
        )
    }
)

export default Textarea
