import {StyledFiltersList, StyledEventFiltersRoot} from './style'
import Checkbox from '@/components/ui/Checkbox'
import {eventFiltersContent} from '../../fixtures/eventFiltersContent'
import {useTranslation} from 'react-i18next'
import useFiltersParams from '@/hooks/useFiltersParams'
import {FILTERS_PARAMS} from '@/types/video'

const EventFilters = () => {
    const {t} = useTranslation()

    const [filtersParams, setFiltersParams] = useFiltersParams()

    const statuses = filtersParams?.status ? filtersParams?.status.split(',') : []

    const getOldParams = (key: string) => {
        switch (key) {
            case FILTERS_PARAMS.Status: {
                return statuses
            }
            default:
                return []
        }
    }

    const handleCheckFilter = ({key, value}: {key: string; value: string}) => {
        const oldParam = getOldParams(key)
        if (!oldParam.find(item => item === value)) {
            const newParams = [...oldParam, value].join(',')
            setFiltersParams(key, newParams)
        } else {
            const newParams = oldParam.filter(item => item !== value).join(',')
            setFiltersParams(key, newParams)
        }
    }

    return (
        <StyledEventFiltersRoot>
            {/* Statuses */}
            <StyledFiltersList>
                {eventFiltersContent?.statuses.map(status => {
                    return (
                        <Checkbox
                            key={`${status}`}
                            id={`${status}`}
                            value={status}
                            label={`${t(`workspace:filters_event_statuses:${status}`)}`}
                            onChange={() => handleCheckFilter({key: FILTERS_PARAMS.Status, value: status})}
                            checked={!!statuses.find(item => item === status)}
                        />
                    )
                })}
            </StyledFiltersList>
        </StyledEventFiltersRoot>
    )
}

export default EventFilters

EventFilters.displayName = 'EventFilters'
