import styled from 'styled-components'

export const StyledPublicLayout = styled.div`
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
    overflow: hidden;
`

export const StyledOutletWrapper = styled.div`
    height: 100%;
    overflow: auto;
`
