import {StyledCompletedRoot} from './style'
import {Trans, useTranslation} from 'react-i18next'

interface CompletedProps {
    points: number
}

const Completed: React.FC<CompletedProps> = ({points}) => {
    const {t} = useTranslation()

    return (
        <StyledCompletedRoot>
            <div>{t('video_details_page:details_section:congratulations')}</div>
            <p>
                <Trans
                    t={t}
                    i18nKey="video_details_page:details_section:completed_description"
                    values={{POINTS: points ?? 0}}
                    components={{
                        span: <span />
                    }}
                />
            </p>
        </StyledCompletedRoot>
    )
}

export default Completed

Completed.displayName = 'Completed'
