import styled, {css} from 'styled-components'

export const StyledHeader = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: flex;
        justify-content: space-between;
        margin-bottom: ${spacing * 8}px;
        flex-direction: column;
        align-items: center;
        ${mediaQueries.m} {
            flex-direction: row;
            align-items: center;
        }
    `}
`

export const StyledTitle = styled.div`
    ${({theme: {typography, palette, spacing}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing * 3}px;
        & svg {
            width: 40px;
            height: 40px;
        }
        h4 {
            ${typography.title};
            color: ${palette.gray.main};
        }
    `}
`
