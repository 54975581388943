import {z} from 'zod'

export const enum PASSWORD_RESET_MODEL {
    Token = 'token',
    Password = 'password',
    PasswordConfirmation = 'passwordConfirmation'
}

export const passwordResetFormSchema = z
    .object({
        [PASSWORD_RESET_MODEL.Token]: z.string(),
        [PASSWORD_RESET_MODEL.Password]: z
            .string()
            .min(6, {message: 'validation:password'})
            .regex(new RegExp(/^(?=.*[A-Z])(?=.*[0-9])(?=.*[^\w\s]).{6,32}/), {message: 'validation:password_complex'})
            .default(''),
        [PASSWORD_RESET_MODEL.PasswordConfirmation]: z.string().min(6, {message: 'validation:password'}).default('')
    })
    .refine(data => data[PASSWORD_RESET_MODEL.Password] === data[PASSWORD_RESET_MODEL.PasswordConfirmation], {
        path: [PASSWORD_RESET_MODEL.PasswordConfirmation],
        message: 'validation:confirm_password'
    })

export type PasswordResetValidationSchema = z.infer<typeof passwordResetFormSchema>
