import styled, {css} from 'styled-components'

export const StyledEventFiltersRoot = styled.div`
    ${({theme: {palette, typography, spacing, mediaQueries}}) => css`
        display: flex;
        gap: ${spacing * 8}px;
        color: ${palette.gray.main};
        ${typography.textM};
        font-weight: 700;
        padding: ${spacing * 5}px ${spacing * 5}px;
        ${mediaQueries.m} {
            gap: ${spacing * 15}px;
        }
        ${mediaQueries.l} {
            gap: ${spacing * 32}px;
        }
    `}
`

export const StyledFiltersList = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 2}px;
        min-width: 90px;
    `}
`
