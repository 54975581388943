import {generatePath, useNavigate, useParams} from 'react-router-dom'
import {
    StyledActionsWrapper,
    StyledAddWrapper,
    StyledBox,
    StyledBoxItem,
    StyledChevronIcon,
    StyledInputWrapper,
    StyledVideoSection
} from './style'
import React, {useEffect, useRef, useState} from 'react'
import {ChevronUpIcon, DatabaseIcon, LanguageFilterIcon, PlusIcon, SearchIcon, TagIcon} from '@/assets/icons/icons'
import Button from '@/components/ui/Button'
import {useTranslation} from 'react-i18next'
import {getIsEuropeanaFilterValue, handleApiError} from '@/utilities/helpers'
import Spinner from '@/components/ui/Spinner'
import {ROUTE_VIDEO_CREATION} from '@/utilities/constants'
import {useTheme} from 'styled-components'
import {useGetVideos} from '@/features/workspaces/queries/useGetVideos'
import VideoCard from '../video-card/VideoCard'
import Input from '@/components/ui/Input'
import VideoFilters from '../video-filters/VideoFilters'
import LoadMore from '@/components/ui/LoadMore'
import useFiltersParams from '@/hooks/useFiltersParams'
import {FILTERS_PARAMS} from '@/types/video'
import EmptySection from '@/components/commons/EmptySection/EmptySection'

const VideosTab = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const theme = useTheme()
    const {workspaceId} = useParams()
    const [isOpenFiltersSection, setIsOpenFiltersSection] = useState(false)
    const [filtersParams, setFiltersParams] = useFiltersParams()

    const isEuropeana = getIsEuropeanaFilterValue(filtersParams)
    const statuses = filtersParams.status ? filtersParams?.status.split(',') : undefined
    const targets = filtersParams.targets ? filtersParams?.targets.split(',') : undefined

    const {data, error, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, isLoading, isError} = useGetVideos({
        workspaceId: `${workspaceId}`,
        name: filtersParams.name,
        isEuropeana,
        statuses,
        targets
    })
    const debounceTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)

    useEffect(() => {
        if (isError && error instanceof Error) {
            handleApiError({error})
        }
    }, [isError])

    useEffect(() => {
        if (debounceTimeoutRef.current) clearTimeout(debounceTimeoutRef.current)
    }, [])

    const onClickAddVideo = () => {
        navigate(generatePath(ROUTE_VIDEO_CREATION, {workspaceId: `${workspaceId}`}))
    }

    const toggleFiltersSection = () => {
        setIsOpenFiltersSection(value => !value)
    }

    const onChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        const eventValue = event.target.value
        if (debounceTimeoutRef.current) clearTimeout(debounceTimeoutRef.current)
        debounceTimeoutRef.current = setTimeout(() => {
            setFiltersParams(FILTERS_PARAMS.Name, eventValue)
        }, 500)
    }

    return (
        <>
            <StyledActionsWrapper>
                <StyledInputWrapper>
                    <Input
                        placeholder={`${t('header:search_input_placeholder')}`}
                        icon={<SearchIcon />}
                        onChange={onChangeFilter}
                        defaultValue={filtersParams.name}
                    />
                </StyledInputWrapper>

                <StyledAddWrapper>
                    <Button variant="primary" size="sm" onClick={onClickAddVideo}>
                        <PlusIcon fill={theme.palette.neutral.white} />
                        {t('workspace:add_video')}
                    </Button>
                </StyledAddWrapper>
            </StyledActionsWrapper>
            <StyledBox>
                <StyledBoxItem style={{width: 115}}>
                    <TagIcon />
                    {t('workspace:status')}
                </StyledBoxItem>

                <StyledBoxItem>
                    <LanguageFilterIcon width={17} height={18} />
                    {t('workspace:targets')}
                </StyledBoxItem>

                <StyledBoxItem>
                    <DatabaseIcon />
                    {t('workspace:platform')}
                </StyledBoxItem>

                <StyledChevronIcon isOpenFiltersSection={isOpenFiltersSection} onClick={toggleFiltersSection}>
                    <span>{t(isOpenFiltersSection ? 'common:close_filters' : 'common:open_filters')}</span>
                    <ChevronUpIcon />
                </StyledChevronIcon>
            </StyledBox>

            {/* Filters section */}
            {isOpenFiltersSection && <VideoFilters />}

            {isLoading ? (
                <Spinner size={32} inline overlay={true} />
            ) : (
                <>
                    {!!data?.pages && data?.pages?.at(0)?.data.length ? (
                        <>
                            <StyledVideoSection>
                                {data?.pages?.map((group, index) => (
                                    <React.Fragment key={index}>
                                        {group.data.map(video => (
                                            <VideoCard key={video?.id} video={video} />
                                        ))}
                                    </React.Fragment>
                                ))}
                            </StyledVideoSection>
                            <LoadMore
                                hasNextPage={hasNextPage}
                                isFetchingNextPage={isFetchingNextPage}
                                isFetching={isFetching}
                                fetchNextPage={fetchNextPage}
                            />
                        </>
                    ) : (
                        <EmptySection title={'workspace:no_videos'} />
                    )}
                </>
            )}
        </>
    )
}

export default VideosTab

VideosTab.displayName = 'VideosTab'
