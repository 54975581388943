import React from 'react'
import {StyledItem, StyledRoot} from './style'
import Button from '@/components/ui/Button'
import {useTranslation} from 'react-i18next'
import {ListResponseMeta} from '@/types/commons'

interface PaginationProps {
    paginationData: ListResponseMeta
    onPageChange: (page: number) => void
}

const Pagination: React.FC<PaginationProps> = ({paginationData, onPageChange}) => {
    const {t} = useTranslation()
    const {first_page, current_page, last_page} = paginationData

    if (last_page <= 1) return null

    const handlePageChange = (page: number) => {
        if (page >= 1 && page <= last_page) {
            onPageChange(page)
        }
    }

    const renderPageNumbers = () => {
        const pageNumbers: number[] = []
        let displayedPageNumbers = []

        for (let i = 1; i <= last_page; i++) {
            pageNumbers.push(i)
        }

        if (last_page <= 3) {
            displayedPageNumbers = pageNumbers
        } else {
            if (current_page <= 2) {
                displayedPageNumbers = pageNumbers.slice(0, 3)
            } else if (current_page >= last_page - 1) {
                displayedPageNumbers = pageNumbers.slice(last_page - 3, last_page)
            } else {
                displayedPageNumbers = [current_page - 1, current_page, current_page + 1]
            }
        }

        return displayedPageNumbers.map(page => (
            <StyledItem key={page} onClick={() => handlePageChange(page)} active={page === current_page}>
                {page}
            </StyledItem>
        ))
    }

    return (
        <StyledRoot>
            <Button size="sm" onClick={() => handlePageChange(current_page - 1)} disabled={current_page === first_page}>
                {t('common:prev')}
            </Button>

            {current_page > 2 && (
                <Button size="sm" onClick={() => handlePageChange(first_page)} disabled={current_page === first_page}>
                    {'<'}
                </Button>
            )}

            {renderPageNumbers()}

            {current_page < last_page - 1 && (
                <Button size="sm" onClick={() => handlePageChange(last_page)} disabled={current_page === last_page}>
                    {'>'}
                </Button>
            )}
            <Button size="sm" onClick={() => handlePageChange(current_page + 1)} disabled={current_page === last_page}>
                {t('common:next')}
            </Button>
        </StyledRoot>
    )
}

export default Pagination
