import Container from '@/components/ui/Container'
import {Trans, useTranslation} from 'react-i18next'
import {StyledRoot, StyledSection, StyledTitle, StyledWrapper} from './style'
import {useTheme} from 'styled-components'
import ContributorContainer from '@/components/ui/ContributorContainer/ContributorContainer'
import {ROUTE_PRIVACY_POLICY, ROUTE_THE_PROJECT} from '@/utilities/constants'
import {useNavigate} from 'react-router-dom'

const TermsAndConditions: React.FC = () => {
    const {t} = useTranslation()
    const {infoContainerSize} = useTheme()
    const navigate = useNavigate()
    const appURL = window.location.origin

    const content = [
        {
            id: 'section_1',
            title: 'terms:title_section_1',
            text: 'terms:text_section_1'
        },
        {
            id: 'section_2',
            title: 'terms:title_section_2',
            text: 'terms:text_section_2',
            route: ROUTE_THE_PROJECT,
            link: `${appURL}${ROUTE_THE_PROJECT}`
        },
        {
            id: 'section_3',
            title: 'terms:title_section_3',
            text: 'terms:text_section_3'
        },
        {
            id: 'section_4',
            title: 'terms:title_section_4',
            text: 'terms:text_section_4'
        },
        {
            id: 'section_5',
            title: 'terms:title_section_5',
            text: 'terms:text_section_5'
        },
        {
            id: 'section_6',
            title: 'terms:title_section_6',
            text: 'terms:text_section_6'
        },
        {
            id: 'section_7',
            title: 'terms:title_section_7',
            text: 'terms:text_section_7'
        },
        {
            id: 'section_8',
            title: 'terms:title_section_8',
            text: 'terms:text_section_8'
        },
        {
            id: 'section_9',
            title: 'terms:title_section_9',
            text: 'terms:text_section_9'
        },
        {
            id: 'section_10',
            title: 'terms:title_section_10',
            text: 'terms:text_section_10'
        },
        {
            id: 'section_11',
            title: 'terms:title_section_11',
            text: 'terms:text_section_11',
            route: ROUTE_PRIVACY_POLICY,
            link: `${appURL}${ROUTE_PRIVACY_POLICY}`
        },
        {
            id: 'section_12',
            title: 'terms:title_section_12',
            text: 'terms:text_section_12'
        },
        {
            id: 'section_13',
            title: 'terms:title_section_13',
            text: 'terms:text_section_13'
        },
        {
            id: 'section_14',
            title: 'terms:title_section_14',
            text: 'terms:text_section_14'
        },
        {
            id: 'section_15',
            title: 'terms:title_section_15',
            text: 'terms:text_section_15'
        },
        {
            id: 'section_16',
            title: 'terms:title_section_16',
            text: 'terms:text_section_16'
        },
        {
            id: 'section_17',
            title: 'terms:title_section_17',
            text: 'terms:text_section_17'
        },
        {
            id: 'section_18',
            title: 'terms:title_section_18',
            text: 'terms:text_section_18'
        },
        {
            id: 'section_19',
            title: 'terms:title_section_19',
            text: 'terms:text_section_19'
        },
        {
            id: 'section_20',
            title: 'terms:title_section_20',
            text: 'terms:text_section_20'
        },
        {
            id: 'section_21',
            title: 'terms:title_section_21',
            text: 'terms:text_section_21'
        },
        {
            id: 'section_22',
            title: 'terms:title_section_22',
            text: 'terms:text_section_22'
        },
        {
            id: 'section_23',
            title: 'terms:title_section_23',
            text: 'terms:text_section_23'
        },
        {
            id: 'section_24',
            title: 'terms:title_section_24',
            text: 'terms:text_section_24',
            link: 'info@subbit.eu'
        }
    ]
    return (
        <StyledRoot>
            <ContributorContainer>
                <Container maxWidth={infoContainerSize}>
                    <StyledTitle>{t('terms:title')}</StyledTitle>
                    <StyledWrapper>
                        {content.map(item => (
                            <StyledSection key={item.id}>
                                <h2>{t(item.title)}</h2>
                                <div>
                                    <Trans
                                        t={t}
                                        i18nKey={item.text}
                                        components={{
                                            ul: <ul />,
                                            li: <li />,
                                            a: <a href={`mailto:${item.link}`} />,
                                            span: <span onClick={() => item.route && navigate(item.route)} />,
                                            h3: <h3 />
                                        }}
                                        values={{LINK: item.link, SITE: 'subbit.eu'}}
                                    />
                                </div>
                            </StyledSection>
                        ))}
                    </StyledWrapper>
                </Container>
            </ContributorContainer>
        </StyledRoot>
    )
}
export default TermsAndConditions

TermsAndConditions.displayName = 'TermsAndConditions'
