import styled, {css} from 'styled-components'

export const StyledInformationWrapper = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 4}px;
        margin-top: ${spacing * 4}px;

        ${mediaQueries.m} {
            flex-direction: row;
            gap: ${spacing * 8}px;
        }
    `}
`
export const StyledVideoWrapper = styled.div`
    ${({theme: {mediaQueries, spacing}}) => css`
        width: 100%;
        border-radius: 16px;
        overflow: hidden;

        ${mediaQueries.m} {
            width: 400px;
            flex-direction: row;
            gap: ${spacing * 8}px;
        }
    `}
`

export const StyledDate = styled.div`
    ${({theme: {palette, typography, spacing}}) => css`
        ${typography.textM}
        display: block;
        margin-bottom: ${spacing * 4}px;
        color: ${palette.neutral['500']};
    `}
`

export const StyledUrl = styled.a`
    ${({theme: {palette, truncateText, spacing, typography}}) => css`
        ${typography.textM}
        display: block;
        margin-bottom: ${spacing * 4}px;
        color: ${palette.neutral['900']};
        cursor: pointer;

        ${truncateText};
        max-width: 400px;
    `}
`

export const StyledValueLabelWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing * 2}px;
    `}
`

export const StyledLabel = styled.span`
    ${({theme: {palette, spacing, typography}}) => css`
        ${typography.textM}
        display: block;
        margin-bottom: ${spacing * 2}px;
        color: ${palette.neutral['900']};
        font-weight: 500;
    `}
`

export const StyledValue = styled.span`
    ${({theme: {palette, spacing, typography}}) => css`
        ${typography.textM}
        display: block;
        margin-bottom: ${spacing * 2}px;
        color: ${palette.neutral['500']};
    `}
`

export const StyledStatusWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        margin-top: ${spacing * 2}px;
    `}
`

export const StyledEuropeanaLogo = styled.a`
    ${({theme: {spacing, palette, typography}}) => css`
        ${typography.textXS};
        display: flex;
        align-items: center;
        gap: ${spacing}px;
        color: ${palette.gray.secondary};
        margin-top: ${spacing * 4}px;
        cursor: pointer;
        & img {
            width: 18px;
            height: 32px;
        }
    `}
`
