import styled, {DefaultTheme, css, keyframes} from 'styled-components'

export const StyledInfoRoot = styled.div`
    ${({theme: {mediaQueries}}) => css`
        max-width: 100%;
        align-self: self-start;
        width: 100%;

        ${mediaQueries.m} {
            max-width: 250px;
        }
    `}
`

export const StyledUserInfoWrapper = styled.div`
    ${({theme: {spacing, typography, palette}}) => css`
        display: grid;
        grid-template-columns: 0fr auto;
        gap: ${spacing * 4}px;
        align-items: center;
        color: ${palette.gray.middle};

        & span {
            ${typography.textXXL}
        }
    `}
`

export const StyledUserName = styled.div`
    ${({theme: {spacing, palette, typography, mediaQueries, truncateText}}) => css`
        ${typography.textM};
        color: ${palette.gray.main};
        font-weight: 500;
        ${truncateText}
        & p {
            font-weight: 400;
            color: ${palette.gray.dark};
            margin-top: ${spacing}px;
        }
        & small {
            ${typography.textS}
            display: block;
            color: ${palette.neutral[500]};
            ${truncateText}
        }
        ${mediaQueries.m} {
            ${typography.textL};
        }
    `}
`

export const StyledRankSection = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        gap: ${spacing * 3}px;
        margin: ${spacing * 4}px 0px;

        & img {
            height: 90px;
            width: 60px;
            object-fit: contain;
        }
    `}
`

export const StyledPointsWrapper = styled.div`
    ${({theme: {typography, palette}}) => css`
        ${typography.textL}

        & p {
            color: ${palette.gray.main};
        }
    `}
`

export const StyledPoints = styled.div`
    ${({theme: {palette}}) => css`
        font-weight: 400;
        color: ${palette.gray.middle};
    `}
`

export const StyledRankName = styled.div`
    ${({theme: {palette}}) => css`
        font-weight: 500;
        color: ${palette.gray.main};
    `}
`

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const StyledProfileVisibility = styled.div`
    ${({theme: {spacing, palette, typography}, isIconRotating}: {isIconRotating: boolean; theme: DefaultTheme}) => css`
        ${typography.textL}
        font-weight: 700;
        color: ${palette.gray.main};
        margin-bottom: ${spacing * 2}px;
        display: grid;
        grid-template-columns: auto auto 1fr;
        align-items: center;
        gap: ${spacing * 3}px;

        & span {
            text-transform: capitalize;
            font-weight: 400;
            color: ${palette.blue.link};
        }

        & svg {
            cursor: pointer;
            margin: 0;
            max-width: 15px;
            ${isIconRotating &&
            css`
                animation: ${rotate360} 0.6s ease-out forwards;
            `}
        }
    `}
`

export const StyledDescription = styled.div`
    ${({theme: {spacing, palette, typography}}) => css`
        background-color: ${palette.gray.lightBG};
        padding: ${spacing * 2}px;
        border-radius: ${spacing}px;

        & span {
            ${typography.textS};
            color: ${palette.gray.main};
        }
    `}
`

export const StyledShareIcon = styled.div`
    ${({theme: {spacing}}) => css`
        cursor: pointer;
        margin-top: ${spacing * 4}px;
    `}
`

export const StyledNoBadgePoints = styled.div`
    ${({theme: {palette}}) => css`
        color: ${palette.gray.main};
    `}
`

export const StyledNoBadgeDescription = styled.div`
    ${({theme: {palette, typography, spacing}}) => css`
        color: ${palette.gray.light};
        ${typography.textM};
        margin-top: ${spacing * 2}px;
    `}
`
