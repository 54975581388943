import {z} from 'zod'

export const enum RANK_MODEL {
    Name = 'name',
    File = 'file',
    MinScore = 'minScore',
    MaxScore = 'maxScore'
}

export const rankFormSchema = z
    .object({
        [RANK_MODEL.Name]: z.string().min(1, {message: 'validation:required_name'}),
        [RANK_MODEL.MinScore]: z.string().regex(new RegExp(/^(?!0)\d+$/), {message: 'validation:required_rank_points'}),
        [RANK_MODEL.MaxScore]: z.string().regex(new RegExp(/^(?!0)\d+$/), {message: 'validation:required_rank_points'})
    })
    .refine(data => +data[RANK_MODEL.MinScore] < +data[RANK_MODEL.MaxScore], {
        path: [RANK_MODEL.MaxScore],
        message: 'validation:value_comparison_rank_points'
    })

export type RankValidationSchema = z.infer<typeof rankFormSchema>
