import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {httpVideoAssign} from '@/features/videos/services/video.http'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {VIDEO_STATUS} from '@/utilities/constants/video'

export const useVideoAssign = ({projectId, videoId}: {projectId: string; videoId: string}) => {
    const client = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.VIDEO_ASSIGN, videoId],
        mutationFn: () => httpVideoAssign(projectId, videoId),
        onSuccess: () => {
            client.invalidateQueries({queryKey: [QUERY_KEYS.VIDEO_DETAILS, videoId]})
            client.invalidateQueries({queryKey: [QUERY_KEYS.VIDEOS]})
            client.invalidateQueries({queryKey: [QUERY_KEYS.ASSIGNED_VIDEOS, VIDEO_STATUS.Assigned]})
        }
    })
}
