import {StyledButton} from './style'
import {ButtonHTMLAttributes, forwardRef, ReactNode} from 'react'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string
    children: ReactNode
    size?: 'xs' | 'sm' | 'lg' | 'md'
    variant?: 'primary' | 'secondary' | 'outline' | 'transparent' | 'warning' | 'success'
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    ({children, className, type = 'button', disabled, size = 'lg', variant = 'primary', ...rest}, ref) => (
        <StyledButton
            ref={ref}
            className={className}
            type={type}
            disabled={disabled}
            size={size}
            variant={variant}
            {...rest}
        >
            {children}
        </StyledButton>
    )
)

export default Button

Button.displayName = 'Button'
