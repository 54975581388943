import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {httpChangeProfileVisibility} from '../services/profile.http'

type UseChangeProfileVisibility = {
    onSuccess?: () => void
}

export const useChangeProfileVisibility = ({onSuccess}: UseChangeProfileVisibility) => {
    const client = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.CHANGE_PROFILE_VISIBILITY],
        mutationFn: () => httpChangeProfileVisibility(),
        onSuccess: () => {
            if (onSuccess) onSuccess()
            client.invalidateQueries({queryKey: [QUERY_KEYS.LEADERS]})
        }
    })
}
