import styled, {css} from 'styled-components'

export const StyledVideoSection = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: grid;
        grid-template-columns: 1fr;
        gap: ${spacing * 6}px;
        margin: ${spacing * 6}px 0;

        ${mediaQueries.m} {
            grid-template-columns: 1fr 1fr;
        }

        ${mediaQueries.desktopXs} {
            grid-template-columns: 1fr 1fr 1fr;
        }

        ${mediaQueries.l} {
            grid-template-columns: 1fr 1fr 1fr;
        }
    `}
`

export const StyledSearchWrapper = styled.div`
    ${({theme: {mediaQueries, spacing}}) => css`
        margin: ${spacing * 6}px 0;
        ${mediaQueries.m} {
            max-width: 500px;
        }
    `}
`

export const StyledBox = styled.div`
    ${({theme: {palette, typography, spacing, mediaQueries}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing * 8}px;
        color: ${palette.gray.main};
        ${typography.textM};
        font-weight: 700;
        padding: ${spacing * 3}px ${spacing * 3}px;
        border-bottom: 1px solid ${palette.gray.disabled};
        ${mediaQueries.m} {
            gap: ${spacing * 15}px;
        }
        ${mediaQueries.l} {
            gap: ${spacing * 32}px;
        }
    `}
`
export const StyledBoxItem = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing * 2}px;
        padding: ${spacing * 2}px;
        white-space: nowrap;
    `}
`

export const StyledChevronIcon = styled.div`
    ${({isOpenFiltersSection}: {isOpenFiltersSection: boolean}) => css`
        margin-left: auto;
        display: flex;
        align-items: center;
        height: 24px;
        cursor: pointer;
        font-weight: normal;
        white-space: nowrap;

        & svg {
            margin-left: 4px;
            width: 24px;
            height: 24px;
            ${!isOpenFiltersSection &&
            css`
                transform: rotate(180deg);
            `};
        }
    `}
`
