import {Trans, useTranslation} from 'react-i18next'
import Container from '@/components/ui/Container'
import {StyledInfoRoot, StyledItem, StyledItemWrapper, StyledTipsWrapper} from './style'
import {useTheme} from 'styled-components'
import {ChooseVideoIcon, PeopleIcon, ReviewIcon} from '@/assets/icons/icons'
import {useNavigate} from 'react-router-dom'
import {ROUTE_LEADERBOARD} from '@/utilities/constants'

const InfoSection: React.FC = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {infoContainerSize, contributorContainerSize} = useTheme()

    return (
        <StyledInfoRoot>
            <Container maxWidth={infoContainerSize}>
                <StyledItemWrapper>
                    <div>{t('home_page:info:title1')}</div>
                    <span>
                        <Trans
                            t={t}
                            i18nKey={'home_page:info:text1'}
                            components={{
                                a: <a href="https://www.europeana.eu/" target="_blank" />
                            }}
                        />
                    </span>
                    <span>{t('home_page:info:text2')}</span>
                    <div>{t('home_page:info:title2')}</div>
                    <span>{t('home_page:info:text3')}</span>
                    <div>{t('home_page:info:title3')}</div>
                </StyledItemWrapper>
            </Container>

            <Container maxWidth={contributorContainerSize}>
                <StyledTipsWrapper>
                    <StyledItem>
                        <ChooseVideoIcon />
                        <h4>{t('home_page:info:tips:title1')}</h4>
                        <div>{t('home_page:info:tips:text1')}</div>
                    </StyledItem>
                    <StyledItem>
                        <ReviewIcon />
                        <h4>{t('home_page:info:tips:title2')}</h4>
                        <div>{t('home_page:info:tips:text2')}</div>
                    </StyledItem>
                    <StyledItem>
                        <PeopleIcon />
                        <h4>{t('home_page:info:tips:title3')}</h4>
                        <div>
                            <Trans
                                t={t}
                                i18nKey="home_page:info:tips:text3"
                                components={{
                                    span: <span onClick={() => navigate(ROUTE_LEADERBOARD)} />
                                }}
                            />
                        </div>
                    </StyledItem>
                </StyledTipsWrapper>
            </Container>
        </StyledInfoRoot>
    )
}
export default InfoSection

InfoSection.displayName = 'InfoSection'
