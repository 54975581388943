import {User} from './auth'
import {Language} from './commons'
import {Event} from './event'
import {JobItem} from './job'

export interface VideoCreationRequest {
    eventId?: number
    name: string
    url: string
    sourceLanguageId: number
    targetLanguagesIds: number[]
    thumbnailUrl?: string
    europeanaRightsUrl?: string
    europeanaProjectId?: string
    europeanaPlatformSourceUrl?: string
    description?: string
}

export type VideoStatus = 'ready' | 'completed' | 'ready_for_audit' | 'assigned' | 'rejected' | 'delivered' | 'mixed'

export type ProjectInfos = {
    name: string
    audioUrl: string
    duration: string
    videoUrl: string
    points: number
    originalUrl: string
    europeanaUrl?: string
    thumbnailUrl: string
    sourceLanguage?: Language
    description?: string
}

export type EventInfos = {
    id: number
    name: string
    startDate: string
    endDate: string
    privateHash?: string
}

export interface VideoDetails {
    id: number
    event?: Event
    name: string
    videoUrl: string
    audioUrl: string
    mimeType: string
    source?: string
    sourceLanguage: Language
    targetLanguages: Language[]
    user: User
    status: VideoStatus
    createdAt: string
    jobs: JobItem[]
    isEuropeana?: boolean
    originalUrl?: string
    eventId: number | null
    thumbnailUrl: string
    europeanaPlatformUrl?: string
}

export interface VideoType {
    contributorSubtitlesUrl?: string
    id: number
    projectId: number
    projectInfos: ProjectInfos
    audioUrl: string
    status: VideoStatus
    subtitlesPathLocation: string | null
    targetLanguage: Language
    userId: number | null
    createdAt: string
    videoDescription: string
    points: number
    year: string
    providingInstitution: string
    providingInstitutionUrl: string
    publisher: string
    providingCountry: string
    assignedAt: string | null
    subPlatformUrl: string | null
    eventInfos: EventInfos
    relatedJobs?: VideoType[]
    privateHash?: string | null
}

export const enum FILTERS_PARAMS {
    Platform = 'platform',
    Status = 'status',
    Targets = 'targets',
    Name = 'name',
    Page = 'page'
}
