import {SubmitHandler, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {StyledButtonWrap, StyledContentWrapper, StyledFormRoot} from './style'
import Button from '@/components/ui/Button'
import {useTranslation} from 'react-i18next'
import {EyeIcon, EyeInvisibleIcon} from '@/assets/icons/icons'
import {FC, useEffect, useState} from 'react'
import Input from '@/components/ui/Input'
import {
    CHANGE_PASSWORD_MODEL,
    ChangePasswordValidationSchema,
    changePasswordFormSchema
} from '@features/change-password/services/changePasswordFormModel'
import {ValidationError, handleApiError} from '@/utilities/helpers'
import {useChangePassword} from '../../queries/useChangePassword'

export interface ChangePasswordFormProps {
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
    setIsOpenSuccessModal: React.Dispatch<React.SetStateAction<boolean>>
}

const Form: FC<ChangePasswordFormProps> = ({setIsLoading, setIsOpenSuccessModal}) => {
    const {t} = useTranslation()
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const {mutate, isPending, isError, error} = useChangePassword()

    const {
        register,
        handleSubmit,
        setError,
        formState: {errors, isValid}
    } = useForm<ChangePasswordValidationSchema>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(changePasswordFormSchema)
    })

    useEffect(() => {
        if (isError) handleApiError({setError, error: error as ValidationError<'password'>})
    }, [isError])

    useEffect(() => {
        setIsLoading(isPending)
    }, [isPending])

    const onSuccess = () => {
        setIsOpenSuccessModal(true)
    }

    const onSubmit: SubmitHandler<ChangePasswordValidationSchema> = data => {
        mutate(data, {onSuccess})
    }

    return (
        <StyledFormRoot>
            <form onSubmit={handleSubmit(onSubmit)}>
                <StyledContentWrapper>
                    <Input
                        {...register(CHANGE_PASSWORD_MODEL.Password)}
                        label={`${t('change_password:form:new_password')}*`}
                        type={showPassword ? 'text' : 'password'}
                        icon={showPassword ? <EyeIcon /> : <EyeInvisibleIcon />}
                        iconCallback={() => setShowPassword(!showPassword)}
                        errorMessage={`${t(errors[CHANGE_PASSWORD_MODEL.Password]?.message || '')}`}
                    />
                    <Input
                        {...register(CHANGE_PASSWORD_MODEL.PasswordConfirmation)}
                        label={`${t('change_password:form:confirm_new_password')}*`}
                        type={showConfirmPassword ? 'text' : 'password'}
                        icon={showConfirmPassword ? <EyeIcon /> : <EyeInvisibleIcon />}
                        iconCallback={() => setShowConfirmPassword(!showConfirmPassword)}
                        errorMessage={`${t(errors[CHANGE_PASSWORD_MODEL.PasswordConfirmation]?.message || '')}`}
                    />
                </StyledContentWrapper>
                <StyledButtonWrap>
                    <Button type={'submit'} disabled={!isValid}>
                        {t('change_password:title')}
                    </Button>
                </StyledButtonWrap>
            </form>
        </StyledFormRoot>
    )
}

export default Form
Form.displayName = 'Form'
