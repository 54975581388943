import styled, {css} from 'styled-components'

export const StyledSectionWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        margin: ${spacing * 4}px 0;
        max-width: 600px;
    `}
`

export const StyledEventWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing * 4}px;
    `}
`

export const StyledEventLabel = styled.span`
    ${({theme: {palette}}) => css`
        color: ${palette.gray};
        font-weight: normal;
    `}
`

export const StyledActionsWrapper = styled.div`
    ${() => css`
        display: flex;
        align-items: center;
        justify-content: flex-end;
    `}
`

export const StyledChooseVideoSourceWrapper = styled.div`
    & button {
        padding: 0px;
    }
`

export const StyledTitle = styled.div`
    ${({theme: {typography, palette}}) => css`
        color: ${palette.gray.main};
        ${typography.subtitle};
    `}
`

export const StyledDescription = styled.div`
    ${({theme: {spacing, typography, palette}}) => css`
        ${typography.textM};
        color: ${palette.gray.main};
        margin: ${spacing * 3}px 0px ${spacing * 6}px;
    `}
`

export const StyledVideoSourceCardsWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing * 4}px;
    `}
`

export const StyledVideoSourceCard = styled.div`
    ${({theme: {shadows, spacing}}) => css`
        box-shadow: ${shadows.md};
        width: 300px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        gap: ${spacing * 4}px;
        border-radius: 10px;
        padding: ${spacing * 8}px ${spacing * 4}px;
    `}
`

export const StyledVideoSourceTitle = styled.span`
    ${({theme: {typography, palette}}) => css`
        ${typography.textL};
        font-weight: 600;
        color: ${palette.neutral[900]};
    `}
`

export const StyledVideoSourceDescription = styled.span`
    ${({theme: {palette}}) => css`
        color: ${palette.neutral[900]};
        text-align: center;
    `}
`

export const StyledVideoSourceAction = styled.div`
    ${({theme: {spacing}}) => css`
        margin-top: ${spacing * 4}px;
        display: flex;
        & > button {
            padding: ${spacing * 2}px ${spacing * 4}px;
        }
    `}
`

export const StyledErrorMessage = styled.span`
    ${({theme: {palette, spacing}}) => css`
        color: ${palette.red.error};
        margin-top: ${spacing * 4}px;
        display: block;
    `}
`
