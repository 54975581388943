import React, {useEffect, useState} from 'react'
import {StyledActionsWrapper, StyledListsWrapper, StyledSectionWrapper} from './style'
import Button from '@/components/ui/Button'
import {
    VIDEO_MODEL,
    VideoLanguagesSchema,
    VideoLanguagesValidationSchema
} from '@features/videos/services/VideoCreationFormModel'
import {zodResolver} from '@hookform/resolvers/zod/src/zod.js'
import {FormProvider, useForm} from 'react-hook-form'
import ListSelect from '@/components/commons/ListSelect/ListSelect'
import {useTranslation} from 'react-i18next'
import {VideoLanguagesForm} from '@/features/videos/types/video'
import {Language, ValueLabel} from '@/types/commons'
import {httpGetLanguages} from '@/services/languages.http'
import Spinner from '@/components/ui/Spinner'
import {ValidationError, handleApiError} from '@/utilities/helpers'
import {useGetLanguages} from '@/queries/useGetLanguages'
import {getDisabledTargetLanguages} from '@/utilities/video'

interface LanguagesStepProps {
    onClickPreviousStep: () => void
    onSubmit: (values: VideoLanguagesForm) => void
    isLoading: boolean
    isError: boolean
    error: Error | null
    europeanaLanguage: Language | null
}

const LanguagesStep: React.FC<LanguagesStepProps> = ({
    onClickPreviousStep,
    onSubmit,
    isLoading,
    isError,
    error,
    europeanaLanguage
}: LanguagesStepProps) => {
    const {t} = useTranslation()
    const {data: languages} = useGetLanguages({name: undefined})
    const [disabledTargetLanguages, setDisabledTargetLanguages] = useState<number[]>([])

    useEffect(() => {
        if (isError) handleApiError({setError, error: error as ValidationError<'sourceLanguageId'>})
    }, [isError])

    const methods = useForm<VideoLanguagesValidationSchema>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(VideoLanguagesSchema)
    })

    const {
        handleSubmit,
        formState: {isValid, errors},
        setError,
        setValue,
        watch
    } = methods

    useEffect(() => {
        if (europeanaLanguage) {
            setValue(VIDEO_MODEL.SourceLanguageId, {value: europeanaLanguage.id, label: europeanaLanguage.name})
        }
    }, [europeanaLanguage])

    const sourceLanguage = watch(VIDEO_MODEL.SourceLanguageId)

    useEffect(() => {
        setValue(VIDEO_MODEL.TargetLanguagesIds, [])
    }, [sourceLanguage])

    useEffect(() => {
        setDisabledTargetLanguages(getDisabledTargetLanguages(languages?.data, parseInt(String(sourceLanguage?.value))))
    }, [sourceLanguage, languages])

    return (
        <FormProvider {...methods}>
            {isLoading && <Spinner size={48} overlay={true} />}
            <form onSubmit={handleSubmit(onSubmit)}>
                <StyledListsWrapper>
                    <ListSelect
                        withFilter={false}
                        name={VIDEO_MODEL.SourceLanguageId}
                        label={`${t('video_creation:source_language')}*`}
                        placeholder={t('video_creation:source_language_placeholder')}
                        errorMessage={`${t(errors[VIDEO_MODEL.SourceLanguageId]?.message || '')}`}
                        helpText={t('video_creation:source_language_help')}
                        fetchFunction={(name, page) => httpGetLanguages(name, page)}
                        suggestedItem={europeanaLanguage?.id}
                        adapterFunction={response =>
                            response?.data?.map(
                                (item: Language): ValueLabel => ({
                                    value: item.id,
                                    label: item.name,
                                    code: item.code
                                })
                            )
                        }
                    />
                    <ListSelect
                        withFilter={false}
                        name={VIDEO_MODEL.TargetLanguagesIds}
                        label={`${t('video_creation:destination_languages')}*`}
                        placeholder={t('video_creation:destination_language_placeholder')}
                        errorMessage={`${t(errors[VIDEO_MODEL.TargetLanguagesIds]?.message || '')}`}
                        helpText={t('video_creation:destination_languages_help')}
                        fetchFunction={(name, page) => httpGetLanguages(name, page)}
                        multiple
                        disabledValues={disabledTargetLanguages}
                        adapterFunction={response =>
                            response?.data?.map(
                                (item: Language): ValueLabel => ({
                                    value: item.id,
                                    label: item.name
                                })
                            )
                        }
                    />
                </StyledListsWrapper>
                <StyledSectionWrapper>
                    <StyledActionsWrapper>
                        <Button size="sm" onClick={onClickPreviousStep} disabled={isLoading}>
                            {t('video_creation:back')}
                        </Button>
                        <Button type="submit" size="sm" disabled={!isValid || isLoading}>
                            {t('video_creation:done')}
                        </Button>
                    </StyledActionsWrapper>
                </StyledSectionWrapper>
            </form>
        </FormProvider>
    )
}

export default LanguagesStep

LanguagesStep.displayName = 'LanguagesStep'
