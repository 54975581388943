import {ROUTE_EVENTS, ROUTE_LEADERBOARD, ROUTE_THE_PROJECT, ROUTE_VIDEOS} from '@/utilities/constants'
import {StyledItem, StyledItemWrapper, StyledTopNavRoot} from './style'
import {useTranslation} from 'react-i18next'

const TopNav: React.FC = () => {
    const {t} = useTranslation()

    return (
        <StyledTopNavRoot>
            <StyledItemWrapper>
                <StyledItem to={ROUTE_VIDEOS}>
                    <span>{t('common:browse_videos')}</span>
                </StyledItem>
                <StyledItem to={ROUTE_EVENTS}>
                    <span>{t('common:events')}</span>
                </StyledItem>
                <StyledItem to={ROUTE_LEADERBOARD}>
                    <span>{t('common:leaderboard')}</span>
                </StyledItem>
                <StyledItem to={ROUTE_THE_PROJECT}>
                    <span>{t('common:project')}</span>
                </StyledItem>
            </StyledItemWrapper>
        </StyledTopNavRoot>
    )
}

export default TopNav

TopNav.displayName = 'TopNav'
