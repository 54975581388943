import {VideoType} from '@/types/video'
import PreviewVideoCard from '../preview-video-card/PreviewVideoCard'
import {StyledRoot} from './styles'

const PreviewVideosList = ({videos}: {videos: VideoType[]}) => {
    return (
        <StyledRoot>
            {videos.map(video => (
                <PreviewVideoCard key={video.id} video={video} />
            ))}
        </StyledRoot>
    )
}

export default PreviewVideosList

PreviewVideosList.displayName = 'PreviewVideosList'
