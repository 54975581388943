import {QUERY_KEYS} from '@/queryClient'
import {httpGetEvents} from '../services/workspaces.http'
import {useInfiniteQuery} from '@tanstack/react-query'

export const useGetEvents = ({
    workspaceId,
    name,
    statuses,
    isEuropeana
}: {
    workspaceId: string
    name: string
    statuses?: string[]
    isEuropeana?: boolean
}) =>
    useInfiniteQuery({
        queryKey: [QUERY_KEYS.EVENTS, workspaceId, name, statuses, isEuropeana],
        queryFn: ({pageParam}) => httpGetEvents({workspaceId, name, pageParam, statuses, isEuropeana}),
        initialPageParam: '',
        getNextPageParam: lastPage => lastPage.meta.next_page_url
    })
