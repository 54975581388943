import styled, {css} from 'styled-components'

export const StyledRoot = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: flex;
        flex-direction: column;
        margin-top: ${spacing * 10}px;
        width: 100%;
        max-width: 540px;
        margin-bottom: ${spacing * 14}px;

        ${mediaQueries.m} {
            margin-top: 0px;
        }

        ${mediaQueries.l} {
            width: 100%;
            max-width: 100%;
            margin-bottom: 0px;
        }
    `}
`

export const StyledTabSection = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        width: fit-content;
        display: flex;
        justify-content: center;
        margin-bottom: ${spacing * 8}px;
        width: 100%;

        & div {
            & div {
                padding: ${spacing * 2}px ${spacing * 4}px;
            }
        }

        ${mediaQueries.m} {
            justify-content: start;
            & div {
                & div {
                    padding: ${spacing * 2}px ${spacing * 6}px;
                }
            }
        }
    `}
`
