import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {httpCreateEuropeanaVideo} from '@/features/videos/services/video.http'
import {VideoCreationRequest, VideoDetails} from '@/types/video'
import {useMutation, useQueryClient} from '@tanstack/react-query'

export const useCreateEuropeanaVideo = ({
    workspaceId,
    onSuccess
}: {
    workspaceId: string
    onSuccess: (data: VideoDetails) => void
}) => {
    const client = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.CREATE_EUROPEANA_VIDEO, workspaceId],
        mutationFn: (data: VideoCreationRequest) => httpCreateEuropeanaVideo(workspaceId, data),
        onSuccess: (data: VideoDetails) => {
            onSuccess(data)
            client.invalidateQueries({queryKey: [QUERY_KEYS.WORKSPACE_VIDEOS, workspaceId]})
            client.invalidateQueries({queryKey: [QUERY_KEYS.WORKSPACES_LIST]})
            if (data?.eventId) {
                client.invalidateQueries({
                    queryKey: [QUERY_KEYS.WORKSPACE_EVENT_DETAILS, workspaceId, `${data.eventId}`]
                })
                client.invalidateQueries({
                    queryKey: [QUERY_KEYS.EVENTS, workspaceId]
                })
            }
        }
    })
}
