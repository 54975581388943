import styled, {css} from 'styled-components'

export const StyledEmptySection = styled.div`
    ${({theme: {spacing, palette, typography}}) => css`
        ${typography.textS}
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: ${spacing * 2}px;
        margin-top: ${spacing * 6}px;
        color: ${palette.gray.main};
    `}
`

export const StyledImage = styled.img`
    width: 120px;
    height: 100px;
`
