import styled, {css} from 'styled-components'

export const StyledCompleteButtonRoot = styled.div`
    ${({theme: {spacing, palette}}) => css`
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: ${spacing * 6}px;

        & p {
            color: ${palette.gray.main};
            text-align: center;
        }
    `}
`

export const StyledButtonWrapper = styled.div`
    ${({theme: {spacing, mediaQueries, typography}}) => css`
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: ${spacing * 3}px;

        & button {
            padding: ${spacing * 2}px ${spacing * 6}px;
            ${spacing * 6}px;
        }

        ${mediaQueries.m} {
            flex-direction: row;
            gap: ${spacing * 10}px;
            & button {
                ${typography.textL};
            }
        }
    `}
`

export const StyledUnassignButton = styled.div`
    text-decoration: underline;
    cursor: pointer;
`
