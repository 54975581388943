import {getInitials} from '@/utilities/helpers'
import {AvatarRoot, StyledAvatar} from './style'

interface AvatarProps {
    name: string
    size?: number
}

const Avatar = ({name, size = 40}: AvatarProps) => {
    return (
        <AvatarRoot size={size}>
            <StyledAvatar>{getInitials(name)}</StyledAvatar>
        </AvatarRoot>
    )
}

export default Avatar

Avatar.displayName = 'Avatar'
