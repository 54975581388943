import styled, {css} from 'styled-components'

export const StyledRoot = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: flex;
        flex-direction: column;
        margin-bottom: ${spacing * 10}px;
        width: 100%;
        max-width: 540px;
        margin-bottom: ${spacing * 14}px;

        ${mediaQueries.l} {
            width: 100%;
            max-width: 100%;
            margin-bottom: 0px;
        }
    `}
`

export const StyledProfileVideosList = styled.div`
    ${({theme: {spacing}}) => css`
        margin-bottom: ${spacing * 2}px;
    `}
`

export const StyledDivider = styled.div`
    ${({theme: {palette, spacing}}) => css`
        border-top: 1px solid ${palette.gray.xlight};
        margin-top: ${spacing * 8}px;
    `}
`

export const StyledTitle = styled.div`
    ${({theme: {palette, typography}}) => css`
        ${typography.subtitle};
        color: ${palette.gray.main};
    `}
`

export const StyledDescription = styled.div`
    ${({theme: {palette, typography, spacing}}) => css`
        ${typography.textM};
        color: ${palette.gray.main};
        margin-top: ${spacing * 3}px;
    `}
`
