import styled, {DefaultTheme, css, keyframes} from 'styled-components'

export const StyledOverlay = styled.div(
    ({theme: {zIndex}}: {theme: DefaultTheme}) => css`
        position: fixed;
        inset: 0;
        background-color: rgba(0, 0, 0, 0.17);
        display: grid;
        align-content: center;
        justify-content: center;
        z-index: ${zIndex.modalOverlay};
    `
)

type StyledSpinnerProps = {
    color?: string
    inline: boolean
}

const pulse = (color?: string) => keyframes`
  50% { fill: ${color} }
  to { fill: transparent }
`

export const StyledSpinner = styled.svg<StyledSpinnerProps>`
    ${({color, inline}) => css`
        transform: ${inline ? 'none' : 'translate(-50%, -50%)'};
        width: ${inline ? '100%' : 'auto'};
        margin: ${inline ? '50px' : '0'} auto;
        position: ${inline ? 'static' : 'fixed'};
        left: 50%;
        top: 50%;
        z-index: 14;
        display: flex;
        align-items: center;
        path:nth-of-type(1) {
            animation: ${pulse(color)} 1s infinite linear;
        }

        path:nth-of-type(2) {
            animation: ${pulse(color)} 1s -0.083s infinite linear;
        }

        path:nth-of-type(3) {
            animation: ${pulse(color)} 1s -0.166s infinite linear;
        }

        path:nth-of-type(4) {
            animation: ${pulse(color)} 1s -0.249s infinite linear;
        }

        path:nth-of-type(5) {
            animation: ${pulse(color)} 1s -0.332s infinite linear;
        }

        path:nth-of-type(6) {
            animation: ${pulse(color)} 1s -0.415s infinite linear;
        }

        path:nth-of-type(7) {
            animation: ${pulse(color)} 1s -0.498s infinite linear;
        }

        path:nth-of-type(8) {
            animation: ${pulse(color)} 1s -0.581s infinite linear;
        }

        path:nth-of-type(9) {
            animation: ${pulse(color)} 1s -0.664s infinite linear;
        }

        path:nth-of-type(10) {
            animation: ${pulse(color)} 1s -0.747s infinite linear;
        }

        path:nth-of-type(11) {
            animation: ${pulse(color)} 1s -0.83s infinite linear;
        }

        path:nth-of-type(12) {
            animation: ${pulse(color)} 1s -0.913s infinite linear;
        }
    `}
`
