import {z} from 'zod'

export const enum LOGIN_MODEL {
    Email = 'email',
    Password = 'password',
    Remember_me = 'remember_me'
}

export const LoginSchema = z.object({
    [LOGIN_MODEL.Email]: z
        .string()
        .min(1, {message: 'validation:email'})
        .email({
            message: 'validation:email_valid'
        })
        .default(''),
    [LOGIN_MODEL.Password]: z.string().min(6, {message: 'validation:password'}).default(''),
    [LOGIN_MODEL.Remember_me]: z.boolean().optional().default(false)
})

export type ValidationSchema = z.infer<typeof LoginSchema>
