import {css} from 'styled-components'
import mediaQueries from './mediaQueries'

export const typography = {
    title: css`
        font-size: 40px;
        font-weight: 700;
        ${mediaQueries.m} {
            font-size: 48px;
        }
    `,
    titleS: css`
        font-size: 24px;
        font-weight: 700;

        ${mediaQueries.m} {
            font-size: 32px;
        }
    `,
    titleM: css`
        font-size: 55px;
    `,
    subtitle: css`
        font-size: 20px;
        font-weight: 700;
        ${mediaQueries.m} {
            font-size: 24px;
        }
    `,
    textXXS: css`
        font-size: 8px;
    `,
    textXS: css`
        font-size: 10px;
    `,
    textS: css`
        font-size: 12px;
    `,
    textM: css`
        font-size: 14px;
    `,
    textL: css`
        font-size: 16px;
    `,
    textXL: css`
        font-size: 18px;
    `,
    text1XL: css`
        font-size: 20px;
    `,
    textXXL: css`
        font-size: 24px;
    `,
    text2XL: css`
        font-size: 26px;
    `,
    textXXXL: css`
        font-size: 28px;
    `,
    text3XL: css`
        font-size: 30px;
    `,
    text4XL: css`
        font-size: 32px;
    `,
    text5XL: css`
        font-size: 40px;
    `
}
