export type Percentage = `${number}%`

export type Language = {
    id: number
    name: string
    code: string
}

export interface ListResponseMeta {
    total: number
    per_page: number
    current_page: number
    last_page: number
    first_page: number
    first_page_url: string
    last_page_url: string
    next_page_url: string
    previous_page_url: string | null
}

export interface HttpListResponse<T> {
    data: T[]
    meta: ListResponseMeta
}

export interface ValueLabel {
    value: number | string
    label: string
    isDisabled?: boolean
    url?: string
    thumbnailUrl?: string
    europeanaRightsUrl?: string
    europeanaProjectId?: string
    europeanaPlatformSourceUrl?: string
    description?: string
    language?: Language
    code?: string
}

export enum QueryStatus {
    Pending = 'pending',
    Error = 'error',
    Success = 'success'
}

export enum SCREEN_SIZE {
    MOBILE = 'mobile',
    TABLET = 'tablet',
    DESKTOP = 'desktop'
}
