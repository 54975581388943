import EventInformation from '@/features/events/components/event-ingestion/event-information/EventInformation'
import TabSection from '@/features/workspaces/components/tab-section/TabSection'
import {useEffect, useState} from 'react'
import {useParams, useSearchParams} from 'react-router-dom'
import {StyledTabsWrapper} from './style'
import VideosTab from '@/features/events/components/event-ingestion/videos-tab/VideosTab.tsx'
import MembersTab from '@/features/events/components/event-ingestion/members-tab/MembersTab'
import useFiltersParams from '@/hooks/useFiltersParams'
import {useGetWorkspaceEventDetails} from '@/features/events/queries/useGetWorkspaceEventDetails'
import {ValidationError, handleApiError} from '@/utilities/helpers'
import Spinner from '@/components/ui/Spinner'

export const EVENT_TABS = [
    {
        id: 'videos',
        name: 'common:videos'
    },
    {
        id: 'members',
        name: 'common:members'
    }
]

const EventIngestion: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [activeTabId, setActiveTabId] = useState(searchParams.get('tab') ?? EVENT_TABS[0].id)
    const [filtersParams] = useFiltersParams()
    const {workspaceId, eventId} = useParams()
    const [videoFiltersParams, setVideoFiltersParams] = useState<{[key: string]: string}>({})
    const [membersFiltersParams, setMembersFiltersParams] = useState<{[key: string]: string}>({})
    const isActiveTabVideo = activeTabId === EVENT_TABS[0].id

    const {data, isLoading, isError, error} = useGetWorkspaceEventDetails({
        workspaceId: `${workspaceId}`,
        eventId: `${eventId}`
    })

    useEffect(() => {
        if (isError) handleApiError({error: error as ValidationError<string>})
    }, [isError])

    const handleChangeTab = (id: string) => {
        if (isActiveTabVideo) {
            setVideoFiltersParams({...filtersParams})
        } else {
            setMembersFiltersParams({...filtersParams})
        }
        setSearchParams({tab: id})
        setActiveTabId(id)
    }

    //added to ensure that the selected filters are saved when changing tabs
    useEffect(() => {
        if (!isActiveTabVideo && !!Object.keys(membersFiltersParams).length) {
            setSearchParams({...membersFiltersParams})
        } else if (isActiveTabVideo && !!Object.keys(videoFiltersParams).length) {
            setSearchParams({...videoFiltersParams})
        }
    }, [activeTabId, eventId])

    const EventTabContent = {
        [EVENT_TABS[0].id]: <VideosTab />,
        [EVENT_TABS[1].id]: <MembersTab owner={data?.user} projectData={data} />
    }

    if (isLoading) return <Spinner size={48} overlay />

    return (
        <>
            <EventInformation />
            <StyledTabsWrapper>
                <TabSection
                    tabsList={EVENT_TABS}
                    activeTabId={activeTabId}
                    setActiveTabId={id => handleChangeTab(id)}
                />
            </StyledTabsWrapper>
            <div>{EventTabContent[activeTabId]}</div>
        </>
    )
}

export default EventIngestion

EventIngestion.displayName = 'EventIngestion'
