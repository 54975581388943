import {useMutation, useQueryClient} from '@tanstack/react-query'
import {httpRemoveWorkspace} from '../services/workspaces.http'
import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'

type UseRemoveWorkspaceProps = {
    onSuccess: () => void
}

export const useRemoveWorkspace = ({onSuccess}: UseRemoveWorkspaceProps) => {
    const client = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.REMOVE_WORKSPACE],
        mutationFn: (workspaceId: number) => httpRemoveWorkspace({workspaceId}),
        onSuccess: () => {
            onSuccess()
            client.invalidateQueries({queryKey: [QUERY_KEYS.WORKSPACES_LIST]})
        }
    })
}
