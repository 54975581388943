import {FC, ReactNode} from 'react'
import {StyledBody, StyledCloseIcon, StyledContainer} from './style'
import Popover from '../../ui/Popover'
import {CheckCircleIcon, CloseCircleIcon, CloseIcon, ExclamationCircleIcon} from '@/assets/icons/icons'
import {useTranslation} from 'react-i18next'

interface StatusModalProps {
    children: ReactNode
    additionalFooter?: ReactNode
    overlay?: boolean
    className?: string
    onClose?: () => void
    title: string
    variant?: 'success' | 'pending' | 'error'
    withoutPortal?: boolean
}

const StatusModal: FC<StatusModalProps> = ({
    children,
    overlay,
    className,
    title,
    onClose,
    variant = 'success',
    additionalFooter,
    withoutPortal = false
}) => {
    const {t} = useTranslation()

    const getCorrectVariantIcon = () => {
        switch (variant) {
            case 'success': {
                return <CheckCircleIcon />
            }
            case 'pending': {
                return <ExclamationCircleIcon />
            }
            case 'error': {
                return <CloseCircleIcon />
            }
            default:
                return <CheckCircleIcon />
        }
    }

    return (
        <Popover overlay={overlay} className={className} onClose={onClose} withoutPortal={withoutPortal}>
            <StyledContainer>
                {variant && getCorrectVariantIcon()}
                <h4>{t(title)}</h4>
                {typeof onClose === 'function' && (
                    <StyledCloseIcon>
                        <CloseIcon onClick={onClose} />
                    </StyledCloseIcon>
                )}
                <StyledBody>{children}</StyledBody>
            </StyledContainer>
            {additionalFooter}
        </Popover>
    )
}

StatusModal.displayName = 'StatusModal'
export default StatusModal
