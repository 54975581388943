import React, {useEffect, useState} from 'react'
import {
    StyledDescription,
    StyledInfoRoot,
    StyledNoBadgeDescription,
    StyledNoBadgePoints,
    StyledPoints,
    StyledPointsWrapper,
    StyledProfileVisibility,
    StyledRankName,
    StyledRankSection,
    StyledShareIcon,
    StyledUserInfoWrapper,
    StyledUserName
} from './style'
import Avatar from '@/components/ui/Avatar'
import {Trans, useTranslation} from 'react-i18next'
import {RefreshPrivateIcon, ShareProfileIcon} from '@/assets/icons/icons'
import {ValidationError, getLocalDate, handleApiError} from '@/utilities/helpers'
import {User} from '@/types/auth'
import {ACCOUNT_VISIBILITY, ROUTE_USER_PROFILE} from '@/utilities/constants'
import {useChangeProfileVisibility} from '@/features/contributors/queries/useChangeProfileVisibility'
import useAuthStore from '@/store/authStore'
import {generatePath} from 'react-router'
import toast from 'react-hot-toast'

interface InfoProps {
    userData: User | null
    isMine?: boolean
}

const Info: React.FC<InfoProps> = ({userData, isMine = true}) => {
    const {t} = useTranslation()
    const userMe = useAuthStore(store => store.userMe)
    const user = userData
    const badgeImage = user?.typeAttributes?.badge
    const localDate = user?.createdAt ? getLocalDate(`${user?.createdAt}`, 'MMM D, YYYY') : '--'
    const isPublicAccount = user?.typeAttributes?.isPublic
    const profileVisibility = isPublicAccount ? ACCOUNT_VISIBILITY.public : ACCOUNT_VISIBILITY.private
    const [isIconRotating, setIsIconRotating] = useState(false)

    const {mutate, isPending, isError, error} = useChangeProfileVisibility({onSuccess: userMe})

    useEffect(() => {
        if (isError) handleApiError({error: error as ValidationError<string>})
    }, [isError])

    const handleChangeProfileVisibility = () => {
        if (!isPending) {
            mutate()
        }
    }

    useEffect(() => {
        if (isPending) {
            setIsIconRotating(true)
            setTimeout(() => {
                setIsIconRotating(false)
            }, 600)
        }
    }, [isPending])

    const handleCopyProfileLink = () => {
        const appURL = window.location.host
        const shareURL = `${appURL}${generatePath(ROUTE_USER_PROFILE, {userId: `${user?.id}`})}`
        navigator.clipboard.writeText(shareURL)
        toast.success(t('common:link_copied'))
    }

    return (
        <>
            <StyledInfoRoot>
                <StyledUserInfoWrapper>
                    <Avatar name={user?.name ?? ''} size={60} />
                    <StyledUserName>
                        {user?.name}
                        <small>{user?.username}</small>
                        <p>{t('my_profile:info:start_date', {DATE: localDate})}</p>
                    </StyledUserName>
                </StyledUserInfoWrapper>
                <StyledRankSection>
                    {badgeImage ? (
                        <>
                            {badgeImage && <img src={badgeImage} />}
                            <StyledPointsWrapper>
                                <StyledPoints>
                                    {t('contributor_my_profile:info:points', {POINTS: user?.typeAttributes?.points})}
                                </StyledPoints>
                                <StyledRankName>{user?.typeAttributes?.badgeName}</StyledRankName>
                            </StyledPointsWrapper>
                        </>
                    ) : (
                        <StyledPointsWrapper>
                            <StyledNoBadgePoints>
                                {t('contributor_my_profile:info:points', {POINTS: user?.typeAttributes?.points})}
                            </StyledNoBadgePoints>
                            {isMine && (
                                <StyledNoBadgeDescription>
                                    {t('contributor_my_profile:info:no_badge', {
                                        MIN_RANK_POINTS: user?.typeAttributes?.minRankPoints
                                    })}
                                </StyledNoBadgeDescription>
                            )}
                        </StyledPointsWrapper>
                    )}
                </StyledRankSection>

                {isMine && (
                    <>
                        <StyledProfileVisibility isIconRotating={isIconRotating}>
                            <Trans
                                t={t}
                                i18nKey="contributor_my_profile:info:visibility"
                                values={{
                                    STATUS: profileVisibility
                                }}
                                components={{
                                    span: <span />
                                }}
                            />

                            <RefreshPrivateIcon onClick={handleChangeProfileVisibility} />
                        </StyledProfileVisibility>

                        <StyledDescription>
                            <span>{t('contributor_my_profile:info:description', {context: profileVisibility})}</span>
                        </StyledDescription>
                    </>
                )}

                {isPublicAccount && (
                    <StyledShareIcon onClick={handleCopyProfileLink}>
                        <ShareProfileIcon />
                    </StyledShareIcon>
                )}
            </StyledInfoRoot>
        </>
    )
}

export default Info

Info.displayName = 'Info'
