import {useSearchParams} from 'react-router-dom'

export type VideoSearchParams = {
    [key: string]: string
}

const useFiltersParams = (): [VideoSearchParams, (key: string, value?: string) => void] => {
    const [searchParams, setSearchParams] = useSearchParams()

    const parseFiltersParams = () => {
        let parameters = {}
        searchParams.forEach((value, key) => {
            parameters = {
                ...parameters,
                [key]: value
            }
        })
        return parameters
    }

    const setFiltersParams = (key: string, value?: string): void => {
        setSearchParams(params => {
            if (value) {
                if (params.get(key) === value) {
                    params.delete(key)
                } else {
                    params.set(key, value)
                }
            } else {
                params.delete(key)
            }
            return params
        })
    }

    return [parseFiltersParams(), setFiltersParams]
}

export default useFiltersParams
