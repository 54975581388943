import styled, {css} from 'styled-components'

export const StyledCardWrapper = styled.div`
    display: grid;
    grid-template-rows: 1fr auto;
    cursor: pointer;
`
export const StyledImage = styled.img`
    ${({theme: {mediaQueries, spacing}}) => css`
        height: 320px;
        object-fit: cover;
        margin-bottom: ${spacing * 2}px;
        ${mediaQueries.m} {
            height: 230px;
        }
    `}
`
export const StyledName = styled.div`
    ${({theme: {spacing, palette, truncateText}}) => css`
        color: ${palette.gray.main};
        font-weight: 500;
        margin-bottom: ${spacing}px;
        ${truncateText}
    `}
`
export const StyledVideosCount = styled.div`
    ${({theme: {spacing, typography, palette}}) => css`
        ${typography.textM};
        color: ${palette.gray.dark};
        display: flex;
        align-items: center;
        gap: ${spacing * 2}px;
    `}
`
