import styled, {css} from 'styled-components'

export const StyledInputRoot = styled.div`
    ${({theme: {spacing}}) => css`
        position: relative;
        display: flex;
        gap: ${spacing * 2}px;
        align-items: center;
    `}
`

type StyledStyledInputProps = {
    disabled?: boolean
}

export const StyledInput = styled.input<StyledStyledInputProps>`
    ${({theme: {palette, shadows}}) => css`
        appearance: none;
        cursor: pointer;
        position: relative;
        width: 26px;
        height: 16px;
        background-color: ${palette.neutral['200']};
        border-radius: 10px;
        transition: all 0.3s ease;

        &:not(:checked):before {
            content: '';
            position: absolute;
            top: 2px;
            left: 2px;
            width: 12px;
            height: 12px;
            background-color: ${palette.neutral['50']};
            border-radius: 10px;
            box-shadow: ${shadows.sm};
        }

        &:checked {
            background-color: ${palette.gray.main};
        }

        &:checked::before {
            content: '';
            position: absolute;
            top: 2px;
            right: 2px;
            width: 12px;
            height: 12px;
            background-color: ${palette.neutral['50']};
            border-radius: 10px;
            box-shadow: ${shadows.sm};
        }

        &:disabled {
            border-color: ${palette.gray.main};
            cursor: not-allowed;
        }
    `}
`

type StyledLabelProps = {
    disabled?: boolean
}

export const StyledLabel = styled.label<StyledLabelProps>`
    ${({theme: {palette, typography, spacing}, disabled}) => css`
        ${typography.textS}
        font-weight: 500;
        line-height: 120%; /* 14.4px */
        display: flex;
        gap: ${spacing * 2}px;
        margin-bottom: ${spacing * 2}px;
        color: ${disabled ? palette.gray.disabled : palette.gray.main};
    `}
`

export const StyledErrorMessage = styled.small`
    ${({theme: {palette, typography}}) => css`
        ${typography.textXS}
        color: ${palette.red.error};
    `}
`
