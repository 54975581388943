import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import Container from '@/components/ui/Container'
import {StyledContentWrapper, StyledRoot, StyledSubTitle, StyledTitle, StyledTitleWrapper} from './style'
import {useTheme} from 'styled-components'
import LeadersList from '@/features/leader-board/components/leaders-list/LeadersList'
import {useGetLeadersList} from '@/features/leader-board/queries/useGetLeadersList'
import {ValidationError, handleApiError} from '@/utilities/helpers'
import Spinner from '@/components/ui/Spinner'
import LoadMore from '@/components/ui/LoadMore'
import ContributorContainer from '@/components/ui/ContributorContainer/ContributorContainer'
import EmptySection from '@/components/commons/EmptySection/EmptySection'

const LeaderBoard: React.FC = () => {
    const {t} = useTranslation()
    const {contributorContainerSize} = useTheme()
    const per_page = 20
    const {data, error, fetchNextPage, hasNextPage, isFetching, isLoading, isFetchingNextPage, isError} =
        useGetLeadersList({per_page})

    useEffect(() => {
        if (isError) handleApiError({error: error as ValidationError<string>})
    }, [isError])

    if (isLoading) return <Spinner size={48} overlay />

    return (
        <StyledRoot>
            <ContributorContainer>
                <Container maxWidth={contributorContainerSize}>
                    <StyledTitleWrapper>
                        <StyledTitle>{t('leader_board:title')}</StyledTitle>
                        <StyledSubTitle>{t('leader_board:subtitle')}</StyledSubTitle>
                    </StyledTitleWrapper>
                    {data?.pages?.at(0)?.data.length ? (
                        <StyledContentWrapper>
                            <LeadersList data={data.pages} isNumbered />

                            <LoadMore
                                hasNextPage={hasNextPage}
                                isFetchingNextPage={isFetchingNextPage}
                                isFetching={isFetching}
                                fetchNextPage={fetchNextPage}
                            />
                        </StyledContentWrapper>
                    ) : (
                        <EmptySection title={'videos_page:no_videos'} />
                    )}
                </Container>
            </ContributorContainer>
        </StyledRoot>
    )
}

export default LeaderBoard

LeaderBoard.displayName = 'LeaderBoard'
