import {NavLink} from 'react-router-dom'
import styled, {DefaultTheme, css} from 'styled-components'

export const StyledNavRoot = styled.div`
    ${({theme: {spacing, mediaQueries, palette}}: {theme: DefaultTheme}) => css`
        background: ${palette.neutral.white};
        width: 100%;
        padding: ${spacing * 4}px;
        ${mediaQueries.m} {
            padding: ${spacing * 6}px;
        }
    `}
`

export const StyledItemWrapper = styled.div`
    ${({theme: {spacing, flexUtils, mediaQueries}}) => css`
        ${flexUtils.centered};
        gap: ${spacing * 5}px;
        flex-direction: column;
        ${mediaQueries.m} {
            flex-direction: row;
        }
    `}
`

export const StyledItem = styled(NavLink)`
    ${({theme: {palette, typography}}) => css`
        ${typography.textL};
        cursor: pointer;
        color: ${palette.gray.darkNav};

        &.active {
            color: ${palette.blue.link};
        }
    `}
`

export const StyledLink = styled.a`
    ${({theme: {palette, typography}}) => css`
        ${typography.textL};
        text-decoration: none;
        cursor: pointer;
        color: ${palette.gray.darkNav};
    `}
`
