import {QUERY_KEYS} from '@/queryClient'
import {useQuery} from '@tanstack/react-query'
import {httpGetVideosList} from '../services/video.http'

export const useGetVideosList = ({
    name = undefined,
    page = 1,
    per_page = 10,
    sourceLanguageId = undefined,
    targetLanguageId = undefined,
    duration = undefined,
    availability = undefined,
    sortBy = undefined
}: {
    name?: string
    page?: number | string
    per_page?: number
    sourceLanguageId?: number | string
    targetLanguageId?: number | string
    duration?: string
    availability?: string
    sortBy?: string
}) =>
    useQuery({
        queryKey: [
            QUERY_KEYS.VIDEOS,
            name,
            page,
            per_page,
            sourceLanguageId,
            targetLanguageId,
            duration,
            availability,
            sortBy
        ],
        queryFn: () =>
            httpGetVideosList({
                name,
                page,
                per_page,
                sourceLanguageId,
                targetLanguageId,
                duration,
                availability,
                sortBy
            })
    })
