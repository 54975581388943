import {ReactNode} from 'react'

interface ShowHideProps {
    isVisible: boolean
    children: ReactNode
}

const ShowHide = ({isVisible, children}: ShowHideProps) => {
    return <div style={{display: isVisible ? 'block' : 'none'}}>{children}</div>
}

export default ShowHide

ShowHide.displayName = 'ShowHide'
