import styled, {DefaultTheme, css} from 'styled-components'

export const StyledRoot = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        justify-content: center;
        gap: ${spacing * 4}px;
        font-weight: 700;
    `}
`

export const StyledItem = styled.div`
    ${({theme: {spacing, palette, typography, flexUtils}, active}: {active: boolean; theme: DefaultTheme}) => css`
        ${typography.textL}
        ${flexUtils.centered}
        width: 48px;
        border-radius: 32px;
        padding: ${spacing * 2}px 0;
        color: ${palette.neutral.white};
        background-color: ${palette.gray.main};
        cursor: pointer;
        ${active &&
        css`
            color: ${palette.gray.main};
            background-color: ${palette.gray.xlight};
            cursor: not-allowed;
        `}
    `}
`
