import {useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {StyledHeader, StyledTitle} from './style'
import {useEffect, useState} from 'react'
import {EarthIcon, LockIcon} from '@/assets/icons/icons'
import {handleApiError} from '@/utilities/helpers'
import TabSection from '@/features/workspaces/components/tab-section/TabSection'
import Spinner from '@/components/ui/Spinner'
import {ROUTE_WORKSPACES} from '@/utilities/constants'
import {useGetWorkspaceDetails} from '@/features/workspaces/queries/useGetWorkspaceDetails'
import {useTheme} from 'styled-components'
import EventsTab from '@/features/workspaces/components/events-tab-component/EventsTab'
import VideosTab from '@/features/workspaces/components/videos-tab-component/VideosTab'
import useFiltersParams from '@/hooks/useFiltersParams'

export const WORKSPACE_TABS = [
    {
        id: 'videos',
        name: 'common:videos'
    },
    {
        id: 'events',
        name: 'common:events'
    }
]

const WorkspaceDetails = () => {
    const navigate = useNavigate()
    const theme = useTheme()
    const {workspaceId} = useParams()
    const {isPending, data: workspaceData, isError, error} = useGetWorkspaceDetails({id: `${workspaceId}`})

    const [searchParams, setSearchParams] = useSearchParams()
    const [activeTabId, setActiveTabId] = useState(searchParams.get('tab') ?? WORKSPACE_TABS[0].id)
    const [filtersParams] = useFiltersParams()

    const [videoFiltersParams, setVideoFiltersParams] = useState<{[key: string]: string}>({})
    const [eventFiltersParams, setEventFiltersParams] = useState<{[key: string]: string}>({})
    const isActiveTabVideo = activeTabId === WORKSPACE_TABS[0].id

    const handleChangeTab = (id: string) => {
        if (isActiveTabVideo) {
            setVideoFiltersParams({...filtersParams})
        } else {
            setEventFiltersParams({...filtersParams})
        }
        setSearchParams({tab: id})
        setActiveTabId(id)
    }

    //added to ensure that the selected filters are saved when changing tabs
    useEffect(() => {
        if (!isActiveTabVideo && !!Object.keys(eventFiltersParams).length) {
            setSearchParams({...eventFiltersParams})
        } else if (isActiveTabVideo && !!Object.keys(videoFiltersParams).length) {
            setSearchParams({...videoFiltersParams})
        }
    }, [activeTabId, workspaceId])

    useEffect(() => {
        if (isError && error instanceof Error) {
            handleApiError({error})
            navigate(ROUTE_WORKSPACES)
        }
    }, [isError])

    const WorkspaceTabContent = {
        [WORKSPACE_TABS[0].id]: <VideosTab />,
        [WORKSPACE_TABS[1].id]: <EventsTab />
    }

    return isPending && !workspaceData ? (
        <Spinner size={48} />
    ) : (
        <>
            <StyledHeader>
                <StyledTitle>
                    {workspaceData?.isPublic ? (
                        <EarthIcon color={theme.palette.gray.dark} />
                    ) : (
                        <LockIcon color={theme.palette.gray.dark} />
                    )}
                    <h4>
                        {workspaceData && workspaceData.name.length > 30
                            ? `${workspaceData?.name.substring(0, 30)}...`
                            : workspaceData?.name}
                    </h4>
                </StyledTitle>
            </StyledHeader>
            <TabSection
                tabsList={WORKSPACE_TABS}
                activeTabId={activeTabId}
                setActiveTabId={id => handleChangeTab(id)}
            />

            <div>{WorkspaceTabContent[activeTabId]}</div>
        </>
    )
}

export default WorkspaceDetails

WorkspaceDetails.displayName = 'WorkspaceDetails'
