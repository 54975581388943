import {useParams} from 'react-router-dom'
import {
    StyledActionsWrapper,
    StyledAddWrapper,
    StyledBox,
    StyledBoxItem,
    StyledChevronIcon,
    StyledInputWrapper,
    StyledEventSection
} from './style'
import React, {useEffect, useRef, useState} from 'react'
import {ChevronUpIcon, PlusIcon, SearchIcon, TagIcon} from '@/assets/icons/icons'
import Button from '@/components/ui/Button'
import {useTranslation} from 'react-i18next'
import {getIsEuropeanaFilterValue, handleApiError} from '@/utilities/helpers'
import Spinner from '@/components/ui/Spinner'
import {useTheme} from 'styled-components'
import {useGetEvents} from '@/features/workspaces/queries/useGetEvents'
import EventCard from '../event-card/EventCard'
import Input from '@/components/ui/Input'
import EventFilters from '../event-filters/EventFilters'
import LoadMore from '@/components/ui/LoadMore'
import EventModal from '../event-modal/EventModal'
import useFiltersParams from '@/hooks/useFiltersParams'
import {FILTERS_PARAMS} from '@/types/video'
import EmptySection from '@/components/commons/EmptySection/EmptySection'
import {EventType} from '../../types'

const EventsTab = () => {
    const {t} = useTranslation()
    const theme = useTheme()
    const {workspaceId} = useParams()
    const [isOpenFiltersSection, setIsOpenFiltersSection] = useState(false)
    const [isEventModalOpen, setIsEventModalOpen] = useState(false)
    const [filtersParams, setFiltersParams] = useFiltersParams()
    const [eventToEdit, setEventToEdit] = useState<EventType | null>(null)

    const isEuropeana = getIsEuropeanaFilterValue(filtersParams)
    const statuses = filtersParams.status ? filtersParams?.status.split(',') : undefined

    const {data, error, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, isLoading, isError} = useGetEvents({
        workspaceId: `${workspaceId}`,
        name: filtersParams.name,
        statuses,
        isEuropeana
    })

    const eventDebounceTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)

    useEffect(() => {
        if (eventDebounceTimeoutRef.current) clearTimeout(eventDebounceTimeoutRef.current)
    }, [])

    useEffect(() => {
        if (isError && error instanceof Error) {
            handleApiError({error})
        }
    }, [isError])

    const onClickAddEvent = () => {
        setIsEventModalOpen(true)
    }

    const onCloseAddEventModal = () => {
        eventToEdit && setEventToEdit(null)
        setIsEventModalOpen(false)
    }

    const toggleFiltersSection = () => {
        setIsOpenFiltersSection(value => !value)
    }

    const onChangeEventFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        const eventValue = event.target.value

        if (eventDebounceTimeoutRef.current) clearTimeout(eventDebounceTimeoutRef.current)
        eventDebounceTimeoutRef.current = setTimeout(() => {
            setFiltersParams(FILTERS_PARAMS.Name, eventValue)
        }, 500)
    }

    useEffect(() => {
        if (eventToEdit) {
            setIsEventModalOpen(true)
        }
    }, [eventToEdit])

    return (
        <>
            <StyledActionsWrapper>
                <StyledInputWrapper>
                    <Input
                        placeholder={`${t('common:search')}`}
                        icon={<SearchIcon />}
                        onChange={onChangeEventFilter}
                        defaultValue={filtersParams.name}
                    />
                </StyledInputWrapper>

                <StyledAddWrapper>
                    <Button variant="primary" size="sm" onClick={onClickAddEvent}>
                        <PlusIcon fill={theme.palette.neutral.white} />
                        {t('workspace:add_event')}
                    </Button>
                </StyledAddWrapper>
            </StyledActionsWrapper>
            <StyledBox>
                <StyledBoxItem>
                    <TagIcon />
                    {t('workspace:status')}
                </StyledBoxItem>

                <StyledChevronIcon isOpenFiltersSection={isOpenFiltersSection} onClick={toggleFiltersSection}>
                    <span>{t(isOpenFiltersSection ? 'common:close_filters' : 'common:open_filters')}</span>
                    <ChevronUpIcon />
                </StyledChevronIcon>
            </StyledBox>

            {/* Filters section */}
            {isOpenFiltersSection && <EventFilters />}

            {isLoading ? (
                <Spinner size={32} inline overlay={true} />
            ) : (
                <>
                    {!!data?.pages && data?.pages?.at(0)?.data.length ? (
                        <>
                            <StyledEventSection>
                                {data?.pages?.map((group, index) => (
                                    <React.Fragment key={index}>
                                        {group.data.map(event => (
                                            <EventCard key={event?.id} event={event} setEventToEdit={setEventToEdit} />
                                        ))}
                                    </React.Fragment>
                                ))}
                            </StyledEventSection>
                            <LoadMore
                                hasNextPage={hasNextPage}
                                isFetchingNextPage={isFetchingNextPage}
                                isFetching={isFetching}
                                fetchNextPage={fetchNextPage}
                            />
                        </>
                    ) : (
                        <EmptySection title={'workspace:no_events'} />
                    )}
                </>
            )}
            {isEventModalOpen && (
                <EventModal eventToEdit={eventToEdit} onCancel={onCloseAddEventModal} onSubmit={onCloseAddEventModal} />
            )}
        </>
    )
}

export default EventsTab

EventsTab.displayName = 'EventsTab'
