import styled, {css} from 'styled-components'

export const StyledFormRoot = styled.div`
    ${({theme: {flexUtils, mediaQueries}}) => css`
        max-width: 360px;
        width: 100%;

        ${mediaQueries.m} {
            ${flexUtils.centered};
        }
    `}
`

export const StyledContentWrapper = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        margin: ${spacing * 4}px 0px;
        display: grid;
        gap: ${spacing * 4}px;
        align-items: center;
        width: 100%;

        ${mediaQueries.m} {
            margin: ${spacing * 8}px 0px;
            gap: ${spacing * 8}px;
        }
    `}
`

export const StyledButtonWrap = styled.div`
    ${({theme: {mediaQueries}}) => css`
        width: 100%;
        & button {
            margin: 0 auto;
        }

        ${mediaQueries.m} {
            width: 360px;
        }
    `}
`
