import {useTranslation} from 'react-i18next'
import LeadersList from '@/features/leader-board/components/leaders-list/LeadersList'
import {StyledTitle} from './style'
import {useGetLeadersList} from '@/features/leader-board/queries/useGetLeadersList'
import {ValidationError, handleApiError} from '@/utilities/helpers'
import Spinner from '@/components/ui/Spinner'
import {useEffect} from 'react'

const TopContributorSection: React.FC = () => {
    const {t} = useTranslation()
    const per_page = 10
    const {data, error, isLoading, isError} = useGetLeadersList({per_page})

    useEffect(() => {
        if (isError) handleApiError({error: error as ValidationError<string>})
    }, [isError])

    if (isLoading) return <Spinner size={48} overlay={false} inline />

    return data?.pages?.at(0)?.data.length ? (
        <>
            <StyledTitle>{t('home_page:top_contributors_section')}</StyledTitle>
            <LeadersList data={data.pages} isNumbered />
        </>
    ) : null
}
export default TopContributorSection

TopContributorSection.displayName = 'TopContributorSection'
