import React from 'react'
import useAuthStore from '@/store/authStore'
import {USER_ROLE_CONTRIBUTOR} from '@/utilities/constants'
import ContributorProfile from './contributor/ContributorProfile'
import ProviderProfile from './provider/ProviderProfile'

const MyProfile: React.FC = () => {
    const user = useAuthStore(store => store.user)
    const isContributor = user?.type === USER_ROLE_CONTRIBUTOR

    return isContributor ? <ContributorProfile /> : <ProviderProfile />
}

export default MyProfile

MyProfile.displayName = 'MyProfile'
