import axios from 'axios'
import {ROUTE_LOGIN} from '@utilities/constants/routeNames'
import {envVars} from '@/envVars.ts'
import useAuthStore from '@/store/authStore'

const axiosConfig = {
    baseURL: envVars.VITE_API_ENDPOINT,
    headers: {
        common: {
            Authorization: useAuthStore.getState().accessToken
                ? `Bearer ${useAuthStore.getState().accessToken}`
                : false,
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        },
        post: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        patch: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    }
}

const axiosInstance = axios.create(axiosConfig)

const attemptRefresh = async (refreshToken: string) => {
    const url = '/auth/refresh'
    try {
        const {data} = await axiosInstance.post(url, {token: refreshToken})
        useAuthStore.getState().setAccessToken(data['accessToken'])
        useAuthStore.getState().setRefreshToken(data['refreshToken'])
        return data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log(error.status)
            console.error(error.response)
            console.log(error.response?.data)
        } else {
            console.error(error)
        }
    }
}

axiosInstance.interceptors.request.use(request => {
    const accessToken = useAuthStore.getState().accessToken
    if (accessToken) request.headers['Authorization'] = `Bearer ${accessToken}`
    return request
})

const endpointToIgnore = [ROUTE_LOGIN]

axiosInstance.interceptors.response.use(
    response => {
        // pass response without change
        return response
    },
    async error => {
        // get error info
        const statusCode = error?.response?.status
        const originalRequest = error.config
        switch (statusCode) {
            case 401: {
                const refreshToken = useAuthStore.getState().refreshToken
                useAuthStore.getState().setRefreshToken(null)
                if (refreshToken && !originalRequest._retry && !endpointToIgnore.includes(error.config.url)) {
                    originalRequest._retry = true // prevent infinite retry loop
                    await attemptRefresh(refreshToken)

                    return axiosInstance.request(originalRequest)
                }
                useAuthStore.getState().setAccessToken(null)
                useAuthStore.getState().setRefreshToken(null)
                delete axiosInstance.defaults.headers.common.Authorization
                return Promise.reject(error)
            }
            default:
                return Promise.reject(error)
        }
    }
)

export default axiosInstance
