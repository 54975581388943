import styled, {css} from 'styled-components'

type StyledContainerProps = {
    fullWidth: boolean
    fullHeight: boolean
    maxWidth: number
    isPositionRight: boolean
}

export const StyledContainer = styled.div<StyledContainerProps>`
    ${({fullWidth, maxWidth, theme: {spacing, mediaQueries}, isPositionRight}) => css`
        width: 100%;
        padding: ${spacing * 4}px;
        max-width: ${fullWidth ? '100%' : maxWidth + 'px'};
        margin: 0 auto;
        ${mediaQueries.l} {
            padding: ${spacing * 6}px;
            margin: ${isPositionRight ? '0 0 0 auto' : '0 auto'};
        }
        ${mediaQueries.xl} {
            padding: ${spacing * 9}px;
            margin: 0 auto;
        }
    `}
`
