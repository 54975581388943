import {QUERY_KEYS} from '@/queryClient'
import {useQuery} from '@tanstack/react-query'
import {httpGetWorkspaceEventDetails} from '../services/events.http'

export const useGetWorkspaceEventDetails = ({
    workspaceId,
    eventId,
    enabled = true
}: {
    workspaceId: string
    eventId: string
    enabled?: boolean
}) =>
    useQuery({
        queryKey: [QUERY_KEYS.WORKSPACE_EVENT_DETAILS, workspaceId, eventId],
        queryFn: () => httpGetWorkspaceEventDetails(workspaceId, eventId),
        enabled
    })
