import {useParams} from 'react-router-dom'
import {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import Spinner from '@/components/ui/Spinner'
import {
    StyledDeleteIcon,
    StyledItem,
    StyledSearchWrapper,
    StyledSelectedMembersWrapper,
    StyledFilterTab,
    StyledFilterTabWrapper
} from './style'
import {handleApiError} from '@/utilities/helpers'
import Input from '@/components/ui/Input'
import {DeleteIcon, PlusIcon, SearchIcon} from '@/assets/icons/icons'
import useFiltersParams from '@/hooks/useFiltersParams'
import {FILTERS_PARAMS} from '@/types/video'
import Member from '@/features/workspaces/components/member-component/Member'
import {EventType, MemberType} from '@/features/workspaces/types'
import Button from '@/components/ui/Button'
import {useTheme} from 'styled-components'
import {useGetEventMembers} from '@/features/events/queries/useGetEventMembers'
import AddMemberModal from '../add-member-modal/AddMemberModal'
import useAuthStore from '@/store/authStore'
import {FILTER_TYPE} from '@/utilities/constants/workspace'
import DeleteEventMemberModal from '../delete-event-member-modal/DeleteEventMemberModal'

const MembersTab = ({owner, projectData}: {owner?: MemberType; projectData: EventType | undefined}) => {
    const {t} = useTranslation()
    const theme = useTheme()
    const {workspaceId, eventId} = useParams()
    const {data, isLoading, isError, error} = useGetEventMembers({
        workspaceId: `${workspaceId}`,
        eventId: `${eventId}`
    })

    const members = data?.data ?? []
    const user = useAuthStore(store => store.user)

    const imOwner = owner?.id === user?.id
    const [selectedMultipleMembers, setSelectedMultipleMembers] = useState<number[]>([])
    const [selectedSingleMember, setSelectedSingleMember] = useState<number[]>([])
    const [filtersParams, setFiltersParams] = useFiltersParams()
    const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false)
    const [activeFilter, setActiveFilter] = useState(FILTER_TYPE.all)
    const [isDeleteEventMemberModal, setIsDeleteEventMemberModal] = useState(false)

    useEffect(() => {
        if (isError && error instanceof Error) {
            handleApiError({error})
        }
    }, [isError])

    const filteredMembers = useMemo(() => {
        setSelectedSingleMember([])
        setSelectedMultipleMembers([])
        return members.filter(item => {
            let valid = item.user.name.toUpperCase().includes(filtersParams?.name?.toUpperCase() ?? '')
            if (activeFilter === FILTER_TYPE.pending) {
                valid = valid && !item.hasJoined
            } else if (activeFilter === FILTER_TYPE.active) {
                valid = valid && item.hasJoined
            }
            return valid
        })
    }, [activeFilter, filtersParams?.name, data])

    const handleDeleteUser = (memberId: number) => {
        setSelectedSingleMember([memberId])
        setIsDeleteEventMemberModal(true)
    }

    const mustRequestToJoin = projectData?.mustRequestToJoin

    return (
        <>
            <StyledSearchWrapper>
                <Input
                    value={filtersParams.name}
                    onChange={event => {
                        setFiltersParams(FILTERS_PARAMS.Name, event.target.value)
                    }}
                    placeholder={`${t('common:search')}`}
                    icon={<SearchIcon />}
                />
                {imOwner && (
                    <Button variant="transparent" size="sm" onClick={() => setIsAddMemberModalOpen(true)}>
                        <PlusIcon fill={theme.palette.neutral['900']} />
                        {t('event_ingestion:add_member')}
                    </Button>
                )}
            </StyledSearchWrapper>

            {isLoading ? (
                <Spinner size={32} inline overlay={true} />
            ) : (
                <>
                    {!!members?.length && mustRequestToJoin && (
                        <>
                            <StyledFilterTabWrapper>
                                <StyledFilterTab
                                    isActive={activeFilter === FILTER_TYPE.all}
                                    onClick={() => setActiveFilter(FILTER_TYPE.all)}
                                >
                                    {t('common:all')}
                                </StyledFilterTab>
                                <StyledFilterTab
                                    isActive={activeFilter === FILTER_TYPE.active}
                                    onClick={() => setActiveFilter(FILTER_TYPE.active)}
                                >
                                    {t('common:active')}
                                </StyledFilterTab>
                                <StyledFilterTab
                                    isActive={activeFilter === FILTER_TYPE.pending}
                                    onClick={() => setActiveFilter(FILTER_TYPE.pending)}
                                >
                                    {t('common:pending')}
                                </StyledFilterTab>
                            </StyledFilterTabWrapper>
                        </>
                    )}

                    {owner && (
                        <Member
                            key={owner.id}
                            member={owner}
                            setSelectedMembers={setSelectedMultipleMembers}
                            selectedMembers={selectedMultipleMembers}
                            ownerId={parseInt(`${owner?.id}`)}
                            memberId={owner.id}
                        />
                    )}
                    <StyledSelectedMembersWrapper>
                        {!!selectedMultipleMembers.length && (
                            <>
                                <span
                                    onClick={() =>
                                        filteredMembers &&
                                        setSelectedMultipleMembers(filteredMembers?.map(member => member.id))
                                    }
                                >
                                    {t('event_ingestion:delete_member_modal:select_all')}
                                </span>
                                <StyledItem onClick={() => setSelectedMultipleMembers([])}>
                                    {t('event_ingestion:delete_member_modal:deselect_all')}
                                </StyledItem>

                                <StyledDeleteIcon onClick={() => setIsDeleteEventMemberModal(true)}>
                                    <DeleteIcon fill={theme.palette.gray.dark} />
                                    <p>({selectedMultipleMembers.length})</p>
                                </StyledDeleteIcon>
                            </>
                        )}
                    </StyledSelectedMembersWrapper>

                    {filteredMembers?.length
                        ? filteredMembers?.map(member => {
                              return (
                                  <Member
                                      key={member.id}
                                      member={member?.user}
                                      setSelectedMembers={setSelectedMultipleMembers}
                                      selectedMembers={selectedMultipleMembers}
                                      handleDeleteUser={handleDeleteUser}
                                      ownerId={parseInt(`${owner?.id}`)}
                                      memberId={member.id}
                                      hasJoined={member.hasJoined}
                                  />
                              )
                          })
                        : null}
                </>
            )}

            {isAddMemberModalOpen && (
                <AddMemberModal
                    onClose={() => setIsAddMemberModalOpen(false)}
                    workspaceId={workspaceId}
                    eventId={eventId}
                />
            )}

            {isDeleteEventMemberModal && (
                <DeleteEventMemberModal
                    onClose={() => {
                        setSelectedSingleMember([])
                        setIsDeleteEventMemberModal(false)
                    }}
                    selectedMembers={selectedSingleMember.length ? selectedSingleMember : selectedMultipleMembers}
                    workspaceId={`${workspaceId}`}
                    eventId={`${eventId}`}
                    onSuccess={() => {
                        setSelectedSingleMember([])
                        setSelectedMultipleMembers([])
                        setIsDeleteEventMemberModal(false)
                    }}
                />
            )}
        </>
    )
}

export default MembersTab

MembersTab.displayName = 'MembersTab'
