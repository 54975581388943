import * as pages from '@/pages'
import {
    ROUTE_CHANGE_PASSWORD,
    ROUTE_EMAIL_ACTIVATION,
    ROUTE_HOME,
    ROUTE_LOGIN,
    ROUTE_MY_PROFILE,
    ROUTE_PAGE_NOT_FOUND,
    ROUTE_PASSWORD_RESET,
    ROUTE_SIGNUP,
    ROUTE_SIGNUP_CONTRIBUTOR,
    ROUTE_SIGNUP_PROVIDER,
    ROUTE_VIDEO_CREATION,
    ROUTE_VIDEO_INGESTION,
    ROUTE_WORKSPACES,
    ROUTE_WORKSPACE_DETAILS,
    ROUTE_EUROPEANA_CALLBACK,
    ROUTE_LEADERBOARD,
    ROUTE_VIDEOS,
    ROUTE_VIDEO_DETAILS,
    ROUTE_EVENTS,
    ROUTE_EVENT_DETAILS,
    ROUTE_THE_PROJECT,
    ROUTE_USER_PROFILE,
    ROUTE_EVENT_INGESTION,
    ROUTE_EVENTS_LIST,
    ROUTE_TERMS_AND_CONDITIONS,
    ROUTE_PRIVACY_POLICY
} from './routeNames'

export const routes = {
    LOGIN: {
        path: ROUTE_LOGIN,
        element: <pages.Login />,
        breadcrumb: 'login'
    },
    SIGNUP: {
        path: ROUTE_SIGNUP,
        element: <pages.Signup />,
        breadcrumb: 'signup'
    },
    SIGNUP_CONTRIBUTOR: {
        path: ROUTE_SIGNUP_CONTRIBUTOR,
        element: <pages.SignupContributor />,
        breadcrumb: 'signup-contributor'
    },
    SIGNUP_PROVIDER: {
        path: ROUTE_SIGNUP_PROVIDER,
        element: <pages.SignupProvider />,
        breadcrumb: 'signup-provider'
    },
    EUROPEANA_CALLBACK: {
        path: ROUTE_EUROPEANA_CALLBACK,
        element: <pages.EuropeanaCallback />,
        breadcrumb: 'europeana-callback'
    },
    HOME: {
        path: ROUTE_HOME,
        element: <pages.Home />,
        breadcrumb: 'home'
    },
    WORKSPACE: {
        path: ROUTE_WORKSPACES,
        element: <pages.Workspaces />,
        breadcrumb: 'workspace'
    },
    WORKSPACE_DETAILS: {
        path: ROUTE_WORKSPACE_DETAILS,
        element: <pages.WorkspaceDetails />
    },
    VIDEO_CREATION: {
        path: ROUTE_VIDEO_CREATION,
        element: <pages.VideoCreation />,
        breadcrumb: 'video-creation'
    },
    VIDEO_INGESTION: {
        path: ROUTE_VIDEO_INGESTION,
        element: <pages.VideoIngestion />,
        breadcrumb: 'videos'
    },
    EVENT_INGESTION: {
        path: ROUTE_EVENT_INGESTION,
        element: <pages.EventIngestion />,
        breadcrumb: 'events'
    },
    CHANGE_PASSWORD: {
        path: ROUTE_CHANGE_PASSWORD,
        element: <pages.ChangePassword />,
        breadcrumb: 'change-password'
    },
    EMAIL_ACTIVATION: {
        path: ROUTE_EMAIL_ACTIVATION,
        element: <pages.EmailActivation />
    },
    PASSWORD_RESET: {
        path: ROUTE_PASSWORD_RESET,
        element: <pages.PasswordReset />
    },
    MY_PROFILE: {
        path: ROUTE_MY_PROFILE,
        element: <pages.MyProfile />
    },
    LEADER_BOARD: {
        path: ROUTE_LEADERBOARD,
        element: <pages.LeaderBoard />
    },
    VIDEOS_PAGE: {
        path: ROUTE_VIDEOS,
        element: <pages.VideosPage />
    },
    VIDEOS_DETAILS: {
        path: ROUTE_VIDEO_DETAILS,
        element: <pages.VideoDetails />
    },
    EVENTS_PAGE: {
        path: ROUTE_EVENTS,
        element: <pages.EventsPage />
    },
    EVENT_DETAILS: {
        path: ROUTE_EVENT_DETAILS,
        element: <pages.EventDetails />
    },
    THE_PROJECT: {
        path: ROUTE_THE_PROJECT,
        element: <pages.TheProject />
    },
    USER_PROFILE: {
        path: ROUTE_USER_PROFILE,
        element: <pages.UserProfile />
    },
    EVENTS_LIST: {
        path: ROUTE_EVENTS_LIST,
        element: <pages.EventsListPage />
    },
    TERMS_AND_CONDITIONS: {
        path: ROUTE_TERMS_AND_CONDITIONS,
        element: <pages.TermsAndConditions />
    },
    PRIVACY_POLICY: {
        path: ROUTE_PRIVACY_POLICY,
        element: <pages.PrivacyPolicy />
    },
    PAGE_NOT_FOUND: {
        path: ROUTE_PAGE_NOT_FOUND,
        element: <pages.PageNotFound />,
        breadcrumb: 'page-not-found'
    }
}
