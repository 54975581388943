import styled, {css} from 'styled-components'

export const StyledWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        width: 100%;
        margin-top: ${spacing * 12}px;
    `}
`
export const StyledVideoSection = styled.div`
    ${({theme: {spacing}}) => css`
        margin-top: ${spacing * 8}px;
    `}
`
