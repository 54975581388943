import PreviewEventCard from '@/features/events/components/preview-event-card/PreviewEventCard'
import {StyledBottomDivider, StyledList, StyledTopDivider, StyledViewAll} from './style'
import {useTranslation} from 'react-i18next'
import {ROUTE_EVENTS} from '@/utilities/constants'
import {useNavigate} from 'react-router-dom'
import {useGetEventsList} from '@/features/events/queries/useGetEventsList'
import {useEffect} from 'react'
import {handleApiError} from '@/utilities/helpers'
import Spinner from '@/components/ui/Spinner'
import useGetScreenSize from '@/hooks/useGetScreenSize'
import {SCREEN_SIZE} from '@/types/commons'

const EventsSection = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const screenSize = useGetScreenSize()
    const NUMBERS_OF_ITEMS_TO_SHOW = {
        [SCREEN_SIZE.MOBILE]: 1,
        [SCREEN_SIZE.TABLET]: 3,
        [SCREEN_SIZE.DESKTOP]: 4
    }
    const limit = NUMBERS_OF_ITEMS_TO_SHOW[screenSize]
    const {data, error, isError, isLoading} = useGetEventsList({limit})

    useEffect(() => {
        if (isError && error instanceof Error) {
            handleApiError({error})
        }
    }, [isError])

    return isLoading ? (
        <Spinner size={48} inline overlay={false} />
    ) : data?.pages?.at(0)?.data.length ? (
        <div>
            <StyledTopDivider />
            <StyledViewAll>
                <div>{t('home_page:events_section')}</div>
                <span onClick={() => navigate(ROUTE_EVENTS)}> {t('common:view_all')}</span>
            </StyledViewAll>
            <StyledList>
                <>
                    {data?.pages[0].data.map(event => (
                        <PreviewEventCard event={event} key={event.id} />
                    ))}
                </>
            </StyledList>
            <StyledBottomDivider />
        </div>
    ) : null
}
export default EventsSection

EventsSection.displayName = 'EventsSection'
