import styled, {css} from 'styled-components'

export const StyledHeaderWrapper = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 4}px;
        margin-top: ${spacing * 4}px;

        ${mediaQueries.m} {
            flex-direction: row;
            gap: ${spacing * 4}px;
        }
    `}
`

export const StyledTitleWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing * 2}px;
        flex-grow: 1;
    `}
`

export const StyledName = styled.span`
    ${({theme: {palette, truncateText, spacing, typography}}) => css`
        ${typography.textXXL}
        display: block;
        margin-bottom: ${spacing * 2}px;
        color: ${palette.neutral['900']};
        font-weight: 500;
        word-break: break-all;

        ${truncateText};
        max-width: 500px;
    `}
`

export const StyledDescription = styled.span`
    ${({theme: {palette, typography, spacing}}) => css`
        ${typography.textM}
        display: block;
        color: ${palette.neutral['900']};
        margin-bottom: ${spacing * 2}px;
        word-break: break-all;
    `}
`

export const StyledInformationWrapper = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 4}px;
        margin-top: ${spacing * 8}px;

        ${mediaQueries.m} {
            align-items: center;
            flex-direction: row;
            gap: ${spacing * 4}px;
        }
    `}
`

export const StyledValueLabelWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing * 2}px;
    `}
`

export const StyledLabel = styled.span`
    ${({theme: {palette, typography}}) => css`
        ${typography.textM}
        display: block;
        color: ${palette.neutral['900']};
        font-weight: 500;
    `}
`

export const StyledValue = styled.span`
    ${({theme: {palette, typography}}) => css`
        ${typography.textM}
        display: block;
        color: ${palette.neutral['500']};
    `}
`

export const StyledStatusWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        margin-top: ${spacing * 2}px;
    `}
`

export const StyledEditIconWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        & > div {
            cursor: pointer;
            margin-right: ${spacing * 2}px;
            padding: ${spacing * 2}px;
        }
    `}
`
