import styled, {css} from 'styled-components'

export const StyledRoot = styled.div`
    ${() => css`
        height: 70vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    `}
`
export const StyledWorkspaceContainer = styled.div`
    ${({theme: {mediaQueries, spacing}}) => css`
        display: flex;
        flex-direction: row;
        gap: ${spacing * 8}px;
        ${mediaQueries.l} {
            justify-content: flex-start;
        }
    `}
`
export const StyledHeader = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: flex;
        justify-content: space-between;
        margin-bottom: ${spacing * 8}px;
        flex-direction: column;
        align-items: initial;
        gap: ${spacing * 3}px;

        & button {
            padding: ${spacing * 2}px ${spacing * 3}px;
            gap: ${spacing}px;
            align-self: end;
        }

        ${mediaQueries.s} {
            flex-direction: row;
            align-items: center;
        }

        ${mediaQueries.m} {
            margin-bottom: ${spacing * 18}px;
            & button {
                padding: ${spacing * 3}px ${spacing * 5}px;
                gap: ${spacing * 2}px;
            }
        }
    `}
`

export const StyledTitle = styled.div`
    ${({theme: {typography, palette, spacing, mediaQueries}}) => css`
        display: flex;
        align-items: center;

        & svg {
            width: 40px;
            height: 40px;
        }
        h4 {
            ${typography.title};
            color: ${palette.gray.main};
        }

        ${mediaQueries.m} {
            gap: ${spacing * 3}px;
        }
    `}
`
export const StyledAddWorkspaceCard = styled.div`
    ${({theme: {mediaQueries, spacing, typography, palette}}) => css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 330px;
        padding: ${spacing * 6}px;
        gap: ${spacing * 6}px;
        flex-direction: column;
        border-radius: 16px;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
        background-color: #ecf2ff;

        & > span {
            ${typography.textL}
            font-weight: 500;
            color: ${palette.neutral[900]};
            text-align: center;
            padding-top: ${spacing * 12}px;
        }

        ${mediaQueries.m} {
            max-width: 370px;
        }
    `}
`
