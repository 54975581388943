import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {httpDeleteJob} from '@/features/videos/services/video.http'
import {useMutation, useQueryClient} from '@tanstack/react-query'

export const useDeleteJob = ({
    workspaceId,
    videoId,
    onSuccess
}: {
    workspaceId: string
    videoId: string
    onSuccess: () => void
}) => {
    const client = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.DELETE_JOB, videoId],
        mutationFn: (jobId: string) => httpDeleteJob(videoId, jobId),
        onSuccess: () => {
            onSuccess()
            client.invalidateQueries({queryKey: [QUERY_KEYS.WORKSPACE_VIDEO_DETAILS, workspaceId, videoId]})
            client.invalidateQueries({queryKey: [QUERY_KEYS.WORKSPACE_VIDEOS, workspaceId]})
        }
    })
}
