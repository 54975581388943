import {Navigate, Outlet} from 'react-router-dom'
import {USER_ROLE_CONTRIBUTOR, routes} from '@/utilities/constants'
import React from 'react'
import useAuthStore from '@/store/authStore'

type RoleRouteProps = {
    grantedRoles: string[]
}

const RoleBasedRoute: React.FC<RoleRouteProps> = ({grantedRoles}) => {
    const user = useAuthStore(store => store.user)
    const userRole = user?.type
    const isGranted = !!userRole && grantedRoles.includes(userRole)

    const applyCorrectRedirect = () => {
        if (userRole === USER_ROLE_CONTRIBUTOR) {
            return <Navigate to={routes.HOME.path} />
        } else {
            return <Navigate to={routes.WORKSPACE.path} />
        }
    }

    return isGranted ? <Outlet /> : applyCorrectRedirect()
}

export default RoleBasedRoute
