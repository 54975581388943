import styled, {css} from 'styled-components'

export const StyledJobsWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        margin-top: ${spacing * 6}px;
    `}
`

export const StyledJobsTitle = styled.span`
    ${({theme: {spacing, typography, palette}}) => css`
        ${typography.subtitle};
        margin-bottom: ${spacing * 6}px;
        display: block;
        color: ${palette.neutral['900']};
    `}
`

export const StyledButtonWrapper = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        margin-bottom: ${spacing * 6}px;
        display: flex;
        justify-content: flex-end;
        & > *:last-child {
            text-wrap: nowrap;
            order -1;
        }
        ${mediaQueries.m} {
            flex-direction: row;
            & > *:last-child {
                margin-right: ${spacing * 4}px;
                order 1;
            }
        }
    `}
`

export const StyledJobsTable = styled.table`
    ${({theme: {spacing, palette, mediaQueries}}) => css`
        width: 100%;
        table-layout: fixed;

        & > tbody > tr > td {
            text-align: center;
            vertical-align: middle;
        }
        & > tbody > tr > td {
            padding: ${spacing}px;
            ${mediaQueries.m} {
                padding: ${spacing * 4}px ${spacing * 6}px;
            }
        }
        & > tbody > tr + tr {
            border-top: solid 1px ${palette.gray.border};
        }
    `}
`

export const StyledJobRowLanguageWrapper = styled.div`
    ${({theme: {spacing, palette}}) => css`
        display: grid;
        grid-template-columns: auto 1fr;
        gap: ${spacing * 2}px;
        align-items: center;
        font-weight: 500;
        color: ${palette.neutral['900']};
    `}
`

export const StyledJobRowEventWrapper = styled.div`
    ${({theme: {typography, spacing}}) => css`
        ${typography.textM}
        display: flex;
        align-items: flex-start;
        gap: ${spacing * 2}px;
    `}
`

export const StyledActionsWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: ${spacing * 4}px;
    `}
`

export const StyledActionWrapper = styled.div`
    ${() => css`
        cursor: pointer;
        display: inline;
    `}
`

export const StyledDropdownWrapper = styled.div`
    ${() => css`
        position: relative;
    `}
`

export const StyledMenuWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: ${spacing * 3}px;
        padding: ${spacing * 3}px 0px;
    `}
`

export const StyledMenuItem = styled.div`
    ${({theme: {palette, typography, spacing}}) => css`
        ${typography.textS}
        display: grid;
        grid-template-columns: ${spacing * 6}px auto;
        gap: ${spacing * 2}px;
        cursor: pointer;
        color: ${palette.gray.main};
        font-weight: 500;
        transition: color 0.3s ease;
        padding: 0px ${spacing * 3}px;

        &:hover {
            color: ${palette.blue.main};
        }
    `}
`

export const StyledDivider = styled.div`
    ${({theme: {palette}}) => css`
        border-top: 1px solid ${palette.gray.disabled};
        width: 100%;
    `}
`

export const StyledStatusWrapper = styled.div`
    ${() => css`
        display: flex;
        align-items: center;
    `}
`

export const StyledProcessingLabel = styled.span`
    ${({theme: {spacing, typography, palette}}) => css`
        ${typography.textS};
        margin-left: ${spacing * 2}px;
        color: ${palette.neutral['400']};
        font-style: italic;
        white-space: nowrap;
    `}
`

export const StyledJobActionsWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing}px;
    `}
`

export const StyledJobActionWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        margin: ${spacing}px;
        & > * {
            white-space: nowrap;
        }
    `}
`
