import {useRef, useState} from 'react'
import {StyledDeliverIcon, StyledInfoIcon} from './style'
import {useTranslation} from 'react-i18next'
import {DeliverIcon, InfoIcon} from '@/assets/icons/icons'
import Button from '@/components/ui/Button'
import Tooltip from '@/components/ui/Tooltip'

const DeliverButton = ({onClick}: {onClick: () => void}) => {
    const {t} = useTranslation()
    const [isStatusTooltipVisible, setIsStatusTooltipVisible] = useState(false)
    const tooltipRef = useRef<HTMLDivElement | null>(null)

    const handleShowTooltip = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation()
        setIsStatusTooltipVisible(!isStatusTooltipVisible)
    }

    return (
        <div>
            <Button size="xs" onClick={onClick}>
                <StyledDeliverIcon>
                    <DeliverIcon />
                </StyledDeliverIcon>
                {t('video_ingestion:deliver')}
                <StyledInfoIcon ref={tooltipRef} onClick={e => handleShowTooltip(e)}>
                    <InfoIcon />
                    {isStatusTooltipVisible && (
                        <Tooltip
                            isOpen={isStatusTooltipVisible}
                            onClose={() => setIsStatusTooltipVisible(false)}
                            minWidth={265}
                            tooltipElement={tooltipRef?.current}
                        >
                            {t('video_ingestion:deliver_tooltip')}
                        </Tooltip>
                    )}
                </StyledInfoIcon>
            </Button>
        </div>
    )
}

export default DeliverButton

DeliverButton.displayName = 'DeliverButton'
