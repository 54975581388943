import {palette as mainPalette} from './palette.ts'
import mediaQueries, {breakpoints, desktopBreakpoints} from './mediaQueries.ts'
import ResetStyles from './reset.ts'
import {shadows} from './shadows.ts'
import {css, DefaultTheme} from 'styled-components'
import {typography} from './typography.ts'
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'

const GlobalStyles = ResetStyles
export const spacing = 4

interface ThemeInterface {
    name: 'light'
    // TODO: we will support the dark mode decommenting this one and adding the dark types to our style.d.ts
    /*name: 'light' | 'dark'*/
    direction?: 'ltr' | 'rtl'
}

const getAppTheme = ({name = 'light'}: ThemeInterface): DefaultTheme => {
    const palette = mainPalette[name]
    const headerHeightSizingSmall = 76
    const headerHeightSizing = 91.8
    const navDesktopSizing = 250
    const topNavHeightSizing = 67
    const contributorContainerSize = 1180
    const providerContainerSize = 950
    const infoContainerSize = 745

    const zIndex = {
        modalOverlay: 10,
        modal: 11,
        navBar: 9,
        navBarOverlay: 8,
        sideBar: 8
    }

    const truncateText = css`
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    `
    const flexUtils = {
        centered: css`
            display: flex;
            align-items: center;
            justify-content: center;
        `,
        start: css`
            display: flex;
            align-items: center;
            justify-content: flex-start;
        `,
        end: css`
            display: flex;
            align-items: center;
            justify-content: flex-end;
        `,
        columnSpaceBetween: css`
            display: flex;
            align-items: center;
            justify-content: space-between;
        `
    }

    const link = css`
        cursor: pointer;
        text-decoration: none;
        color: ${palette.blue.link};
        &:hover {
            color: ${palette.gray.middle};
            text-decoration: none;
        }
        &:focus {
            color: ${palette.gray.middle};
        }
    `

    return {
        spacing,
        zIndex,
        mediaQueries,
        palette,
        typography,
        shadows,
        truncateText,
        flexUtils,
        link,
        headerHeightSizing,
        headerHeightSizingSmall,
        navDesktopSizing,
        topNavHeightSizing,
        contributorContainerSize,
        providerContainerSize,
        infoContainerSize,
        breakpoints,
        desktopBreakpoints
    }
}

export {GlobalStyles, getAppTheme}
