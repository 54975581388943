import styled, {DefaultTheme, css} from 'styled-components'

export const StyledInputRoot = styled.div`
    position: relative;
    text-align: start;
`

export const StyledInputWrapper = styled.div`
    ${({
        theme: {palette, mediaQueries, typography, spacing},
        maxWidth,
        hasError
    }: {
        theme: DefaultTheme
        maxWidth?: string
        hasError: boolean
    }) => css`
        position: relative;
        width: 100%;
        max-width: ${maxWidth ? maxWidth : '100%'};
        border: 1px solid ${palette.gray.disabled};
        border-radius: 16px;
        color: ${palette.gray.main};
        padding: ${spacing * 3}px ${spacing * 4}px;

        ${hasError &&
        css`
            color: ${palette.red.error};
            border: 1px solid ${palette.red.error};
        `};
        ::placeholder {
            ${hasError &&
            css`
                color: ${palette.red.error};
            `}
        }
        &:focus {
            outline: none;
        }
        ${mediaQueries.m} {
            ${typography.textL}
        }
    `}
`

export const StyledTextarea = styled.textarea`
    ${({theme: {palette, mediaQueries, typography}, hasError}: {theme: DefaultTheme; hasError: boolean}) => css`
        ${typography.textS}
        color: ${palette.gray.main};
        width: 100%;

        ${hasError &&
        css`
            color: ${palette.red.error};
        `};
        ::placeholder {
            ${hasError &&
            css`
                color: ${palette.red.error};
            `}
        }
        &:focus {
            outline: none;
        }
        ${mediaQueries.m} {
            ${typography.textL}
        }
    `}
`

export const StyledErrorMessage = styled.small`
    ${({theme: {palette, typography}}) => css`
        ${typography.textXS}
        color: ${palette.red.error};
    `}
`

export const StyledLabel = styled.label`
    ${({theme: {palette, typography, spacing}}) => css`
        ${typography.textS}
        font-weight: 500;
        line-height: 120%; /* 14.4px */
        display: block;
        margin-bottom: ${spacing * 2}px;
        color: ${palette.gray.main};
    `}
`

export const StyledCharsCount = styled.small`
    ${({theme: {palette, typography, spacing}, hasError}: {theme: DefaultTheme; hasError: boolean}) => css`
        ${typography.textXS}
        color: ${hasError ? palette.red.error : palette.neutral['400']};
        text-align: right;
        display: block;
        margin-top: ${spacing * 4}px;
    `}
`
