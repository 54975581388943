import {useMutation, useQueryClient} from '@tanstack/react-query'
import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {httpRemoveEventMember} from '../services/events.http'

type UseDeleteEventMemberProps = {
    workspaceId: string
    eventId: string
    onSuccess?: () => void
}

export const useDeleteEventMember = ({workspaceId, eventId, onSuccess}: UseDeleteEventMemberProps) => {
    const client = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.REMOVE_EVENT_MEMBER],
        mutationFn: ({userIds}: {userIds: number[]}) => httpRemoveEventMember({workspaceId, eventId, ids: userIds}),
        onSuccess: () => {
            typeof onSuccess === 'function' && onSuccess()
            client.invalidateQueries({queryKey: [QUERY_KEYS.WORKSPACE_EVENT_MEMBERS, workspaceId, eventId]})
        }
    })
}
