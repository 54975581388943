import styled, {css} from 'styled-components'

export const StyledSectionWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        margin: ${spacing * 6}px 0px;

        &:first-child {
            margin-top: 0px;
        }

        &:last-child {
            margin-bottom: 0px;
        }
    `}
`

export const StyledDatesWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing * 4}px;
        & > * {
            width: 50%;
        }
    `}
`

export const StyledPrivateEventLabel = styled.div`
    ${({theme: {palette}}) => css`
        color: ${palette.neutral['900']};
    `}
`

export const StyledName = styled.div`
    ${({theme: {palette, truncateText, typography}}) => css`
        ${typography.textL};
        color: ${palette.gray.main};
        font-weight: 500;

        ${truncateText};
    `}
`
