import {useMemo} from 'react'
import {StyledVideoSection, StyledWrapper} from './style'
import VideoFilters from '@/features/videos/components/video-filters/VideoFilters'
import PreviewVideosList from '@/features/videos/components/preview-videos-list/PreviewVideosList'
import useFiltersParams from '@/hooks/useFiltersParams'
import EmptySection from '@/components/commons/EmptySection/EmptySection'
import {VideoType} from '@/types/video'
import {convertDurationToMilliseconds} from '@/utilities/helpers'
import {DURATION, SORT_BY} from '@/utilities/constants/video'

const VideosTab = ({videos}: {videos: VideoType[]}) => {
    const [filtersParams, setFiltersParams] = useFiltersParams()
    const addVideoSearchParams = (key: string, value: string) => {
        setFiltersParams(key, value)
    }

    const filterByTargetLanguage = (videos: VideoType[], language: string) => {
        return videos.filter(video => `${video.targetLanguage.id}` === language)
    }
    const filterBySourceLanguage = (videos: VideoType[], language: string) => {
        return videos.filter(video => `${video?.projectInfos?.sourceLanguage?.id}` === language)
    }
    const filterByDuration = (videos: VideoType[], duration: string) => {
        if (!duration) return videos

        return videos.filter(video => {
            const videoDuration = convertDurationToMilliseconds({videoDuration: `${video.projectInfos.duration}`})

            if (duration === DURATION.short.name) {
                return videoDuration < DURATION.short.value
            } else if (duration === DURATION.medium.name) {
                return videoDuration > DURATION.medium.value && videoDuration < DURATION.long.value
            } else if (duration === DURATION.long.name) {
                return videoDuration > DURATION.long.value
            }
            return true
        })
    }

    const filterByAvailability = (videos: VideoType[], availability: string) => {
        return videos.filter(video => `${video.status}` === availability)
    }

    const sortByDuration = (videos: VideoType[]) => {
        return videos.sort((a, b) => {
            const durationA = convertDurationToMilliseconds({videoDuration: `${a.projectInfos.duration}`})
            const durationB = convertDurationToMilliseconds({videoDuration: `${b.projectInfos.duration}`})
            return durationA - durationB
        })
    }

    const sortByName = (videos: VideoType[]) => {
        return videos.sort((a, b) => {
            const nameA = a.projectInfos.name.toLowerCase()
            const nameB = b.projectInfos.name.toLowerCase()
            if (nameA < nameB) {
                return -1
            }
            if (nameA > nameB) {
                return 1
            }
            return 0
        })
    }

    const filteredVideos = useMemo(() => {
        let filteredData = [...videos]
        // source language
        if (filtersParams.sourceLanguage) {
            filteredData = filterBySourceLanguage(filteredData, filtersParams.sourceLanguage)
        }
        // target language
        if (filtersParams.targetLanguage) {
            filteredData = filterByTargetLanguage(filteredData, filtersParams.targetLanguage)
        }
        // duration
        if (filtersParams.duration) {
            filteredData = filterByDuration(filteredData, filtersParams.duration)
        }
        // availability
        if (filtersParams.availability) {
            filteredData = filterByAvailability(filteredData, filtersParams.availability)
        }
        // sortBy
        if (filtersParams.sortBy === SORT_BY.duration) {
            // duration
            filteredData = sortByDuration(filteredData)
        } else if (filtersParams.sortBy === SORT_BY.name) {
            // name
            filteredData = sortByName(filteredData)
        }

        return filteredData
    }, [filtersParams])

    return (
        <StyledWrapper>
            <VideoFilters filters={filtersParams} handleFilterClickCallback={addVideoSearchParams} />
            {filteredVideos?.length ? (
                <StyledVideoSection>
                    <PreviewVideosList videos={filteredVideos} />
                </StyledVideoSection>
            ) : (
                <EmptySection title={'event_page:no_videos'} />
            )}
        </StyledWrapper>
    )
}

export default VideosTab

VideosTab.displayName = 'VideosTab'
