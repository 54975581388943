import styled, {DefaultTheme, css} from 'styled-components'

export const StyledContainer = styled.div`
    ${({
        theme: {mediaQueries, headerHeightSizingSmall, headerHeightSizing, zIndex, palette},
        isSideNavOpen
    }: {
        theme: DefaultTheme
        isSideNavOpen: boolean
    }) => css`
        position: absolute;
        right: 0;
        top: ${headerHeightSizingSmall}px;
        height: calc(100% - ${headerHeightSizingSmall}px);
        width: ${isSideNavOpen ? '100%' : '0px'};
        z-index: ${zIndex.sideBar};
        background: ${isSideNavOpen ? palette.neutral.white : 'transparent'};
        ${mediaQueries.m} {
            width: ${isSideNavOpen ? '310px' : '0px'};
            top: ${headerHeightSizing}px;
            height: calc(100% - ${headerHeightSizing}px);
        }
    `}
`
export const StyledCloseButton = styled.div`
    ${({theme: {palette, zIndex, mediaQueries}, isSideNavOpen}: {theme: DefaultTheme; isSideNavOpen: boolean}) => css`
        position: absolute;
        height: 30px;
        width: 30px;
        border-radius: ${isSideNavOpen ? '50%' : '50% 50% 0 0'};
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.07);
        border: 1px solid ${palette.gray.inputBG};
        background: ${palette.neutral.white};
        transform: ${isSideNavOpen ? 'rotate(90deg)' : 'rotate(270deg)'};
        display: flex;
        align-items: center;
        justify-content: center;
        left: ${isSideNavOpen ? '15px;' : '-30px'};
        top: 28px;
        cursor: pointer;
        z-index: ${zIndex.sideBar};
        ${mediaQueries.m} {
            left: ${isSideNavOpen ? '-15px;' : '-30px'};
        }
    `}
`

export const StyledRoot = styled.div`
    ${({theme: {spacing, palette}, isSideNavOpen}: {theme: DefaultTheme; isSideNavOpen: boolean}) => css`
        display: none;
        ${isSideNavOpen &&
        css`
            height: 100%;
            overflow: auto;
            display: block;
            border-right: 1px solid ${palette.gray.border};
            box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.05);
            padding: ${spacing * 14}px ${spacing * 6}px ${spacing * 6}px;
            background: ${palette.neutral.white};
        `}
    `}
`

export const StyledTitle = styled.div`
    ${({theme: {typography, palette, spacing}}) => css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: ${spacing * 3}px;
        margin-bottom: ${spacing * 6}px;
        h4 {
            ${typography.subtitle};
            color: ${palette.gray.main};
        }
    `}
`

export const StyledInput = styled.div`
    ${({theme: {spacing}}) => css`
        width: 100%;
        margin-bottom: ${spacing * 4}px;
    `}
`

export const StyledFilterTabWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing}px;
        margin-bottom: ${spacing * 5}px;
    `}
`

export const StyledFilterTab = styled.div`
    ${({theme: {spacing, palette, typography}, isActive}: {theme: DefaultTheme; isActive: boolean}) => css`
        ${typography.textXS}
        padding: ${spacing * 2}px ${spacing * 3}px;
        background: ${palette.neutral.white};
        color: ${palette.gray.main};
        border-radius: 16px;
        border: 1px solid ${palette.gray.disabled};
        cursor: pointer;
        ${isActive &&
        css`
            border: none;
            background: ${palette.gray.main};
            color: ${palette.neutral.white};
        `}
    `}
`

export const StyledSelectedMembersWrapper = styled.div`
    ${({theme: {spacing, palette, typography}}) => css`
        ${typography.textS}
        color: ${palette.gray.main};
        display: flex;
        align-items: center;
        min-height: 18px;
        font-weight: 500;
        & span {
            padding-right: ${spacing * 1.5}px;
            cursor: pointer;
            text-decoration-line: underline;
        }
    `}
`

export const StyledItem = styled.span`
    ${({theme: {spacing, palette}}) => css`
        padding-left: ${spacing * 1.5}px;
        border-left: 1px solid ${palette.gray.menuActive};
    `}
`
export const StyledDeleteIcon = styled.div`
    ${({theme: {spacing}}) => css`
        margin-left: auto;
        display: flex;
        align-items: center;
        gap: ${spacing}px;

        cursor: pointer;
        & span {
            text-decoration-line: none;
        }
    `}
`
