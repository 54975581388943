import {ClockIcon, LanguageIcon, SortByIcon, StarIcon} from '@/assets/icons/icons'
import {Language} from '@/types/commons'

export const getFullVideoFiltersContent = (language: Language[]) => {
    return [
        {
            id: 'sourceLanguage',
            icon: <LanguageIcon />,
            name: 'filter_video_panel:name_source_languageId',
            label: 'sourceLanguage',
            sortItem: language
        },
        {
            id: 'targetLanguage',
            icon: <LanguageIcon />,
            name: 'filter_video_panel:name_target_languageId',
            label: 'targetLanguage',
            sortItem: language
        },
        {
            id: 'duration',
            icon: <ClockIcon />,
            name: 'filter_video_panel:name_duration',
            label: 'duration',
            sortItem: [
                {
                    id: 'short',
                    name: 'filter_video_panel:item_short',
                    code: ''
                },
                {
                    id: 'medium',
                    name: 'filter_video_panel:item_medium',
                    code: ''
                },
                {
                    id: 'long',
                    name: 'filter_video_panel:item_large',
                    code: ''
                }
            ]
        },
        {
            id: 'availability',
            icon: <StarIcon />,
            name: 'filter_video_panel:name_availability',
            label: 'availability',
            sortItem: [
                {
                    id: 'ready',
                    name: 'filter_video_panel:item_ready',
                    code: ''
                },
                {
                    id: 'completed',
                    name: 'filter_video_panel:item_completed',
                    code: ''
                }
            ]
        },
        {
            id: 'sortBy',
            icon: <SortByIcon />,
            name: 'filter_video_panel:name_sortBy',
            label: 'sortBy',
            sortItem: [
                {
                    id: 'duration_in_seconds',
                    name: 'filter_video_panel:item_duration',
                    code: ''
                },
                {
                    id: 'name',
                    name: 'filter_video_panel:item_name',
                    code: ''
                }
            ]
        }
    ]
}
