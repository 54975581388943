import styled, {css} from 'styled-components'

export const StyledRoot = styled.div`
    height: 100%;
    overflow: auto;
`

export const StyledTitle = styled.div`
    ${({theme: {palette, typography, flexUtils, spacing, mediaQueries}}) => css`
        ${typography.titleM}
        font-weight: 700;
        ${flexUtils.centered};
        flex-direction: column;
        color: ${palette.gray.main};
        margin: ${spacing * 5}px 0 ${spacing * 14}px 0;
        & p {
            ${typography.textL};
            font-weight: 400;
            line-height: 27px;
            margin: 0;
            color: ${palette.gray.middle};

            text-align: center;
        }
        ${mediaQueries.m} {
            margin: ${spacing * 10}px 0 ${spacing * 14}px 0;
        }
        ${mediaQueries.xl} {
            margin: ${spacing * 12}px 0 ${spacing * 16}px 0;
        }
    `}
`

export const StyledVideoSection = styled.div`
    ${({theme: {spacing}}) => css`
        margin-top: ${spacing * 8}px;
    `}
`
