import styled, {css} from 'styled-components'

export const StyledRoot = styled.div`
    height: 100%;
    overflow: auto;
`

export const StyledWrapper = styled.div`
    ${({theme: {mediaQueries, spacing}}) => css`
        margin-top: ${spacing * 13}px;
        ${mediaQueries.m} {
            margin-top: ${spacing * 8}px;
        }
    `}
`

export const StyledDescription = styled.div`
    ${({theme: {palette, link, spacing}}) => css`
        margin-bottom: ${spacing * 17}px;
        line-height: 27px;
        & span {
            color: ${palette.gray.main};
            font-weight: 700;
        }
        & a {
            ${link}
        }
        & ul {
            margin-left: ${spacing * 4}px;
            padding-left: ${spacing * 2}px;
            list-style: disc;
            line-height: normal;
        }
    `}
`
