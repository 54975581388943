import axios from '@/services/axiosInstance'

export const httpChangeProfileVisibility = async () => {
    const url = `/contributors`
    const response = await axios.patch(url)
    return response.data
}

export const httpGetAssignedVideos = async (status: string, pageParam: string) => {
    const url = `/jobs/assignments/users${pageParam}`
    const response = await axios.get(url, {params: {status}})
    return response.data
}
