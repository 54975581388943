import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient'
import {httpGetUserProfile} from '../services/users.http'
import {GeneralError} from '@/utilities/helpers'
import {User} from '@/types/auth'

export const useGetUserProfile = ({userId}: {userId: string}) =>
    useQuery<User, GeneralError, User, string[]>({
        queryKey: [QUERY_KEYS.USER_PROFILE, userId],
        queryFn: () => httpGetUserProfile(userId)
    })
