import styled, {DefaultTheme, css} from 'styled-components'
export const StyledInputRoot = styled.div`
    position: relative;
    text-align: start;
`
type StyledStyledInputWrapperProps = {
    maxWidth?: string
}
export const StyledInputWrapper = styled.div<StyledStyledInputWrapperProps>`
    ${({maxWidth}) => css`
        position: relative;
        width: 100%;
        max-width: ${maxWidth ? maxWidth : '100%'};
    `}
`
type StyledStyledInputProps = {
    hasError?: boolean
    hasIcon?: boolean
    disabled: boolean
}
export const StyledInput = styled.input<StyledStyledInputProps>`
    ${({theme: {palette, mediaQueries, typography, spacing}, hasError, disabled, hasIcon}) => css`
        ${typography.textS}
        border: 1px solid ${palette.gray.disabled};
        border-radius: 16px;
        width: 100%;
        padding: ${hasIcon
            ? `${spacing * 3}px ${spacing * 12}px ${spacing * 3}px ${spacing * 4}px`
            : `${spacing * 3}px ${spacing * 4}px`};
        color: ${disabled ? palette.gray.disabled : palette.gray.main};

        ${hasError &&
        css`
            color: ${palette.red.error};
            border: 1px solid ${palette.red.error};
        `};
        ::placeholder {
            ${hasError &&
            css`
                color: ${palette.red.error};
            `}
        }
        &:focus {
            outline: none;
        }
        ${mediaQueries.m} {
            ${typography.textL}
        }
    `}
`
type StyledStyledIconsProps = {
    iconPosition?: string
    isError?: boolean
}
export const StyledIcons = styled.span<StyledStyledIconsProps>`
    ${({iconPosition}) => css`
        position: absolute;
        align-items: center;
        top: 50%;
        ${iconPosition === 'left'
            ? css`
                  left: 16px;
              `
            : css`
                  right: 16px;
              `};
        transform: translateY(10%);
        display: flex;
        z-index: 1;
    `}
`
export const StyledIcon = styled.span<StyledStyledIconsProps>`
    ${({theme: {mediaQueries, palette}, isError}) => css`
        display: flex;
        cursor: pointer;
        width: 14px;
        height: 14px;
        & svg {
            width: 14px;
            height: 14px;
        }
        ${mediaQueries.m} {
            width: 24px;
            height: 24px;
            & svg {
                & path {
                    ${isError &&
                    css`
                        fill: ${palette.red.error};
                        fill-opacity: 1;
                    `};
                }
                width: 24px;
                height: 24px;
            }
        }
    `}
`
export const StyledIconWrapper = styled.span`
    ${() => css`
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        z-index: 1;
        left: unset;
        right: 16px;
    `}
`
export const StyledErrorMessage = styled.small`
    ${({theme: {palette, typography}}) => css`
        ${typography.textXS}
        color: ${palette.red.error};
    `}
`
export const StyledLabel = styled.label`
    ${({theme: {palette, typography, spacing}, disabled}: {theme: DefaultTheme; disabled: boolean}) => css`
        ${typography.textS}
        font-weight: 500;
        line-height: 120%; /* 14.4px */
        display: block;
        margin-bottom: ${spacing * 2}px;
        color: ${disabled ? palette.gray.disabled : palette.gray.main};
    `}
`
