import React from 'react'
import {StyledRoot, StyledWrapper, StyledMainSection} from './style'
import Container from '@/components/ui/Container'
import useAuthStore from '@/store/authStore'
import Info from '@/features/contributors/components/my-profile/info/Info'
import {useTheme} from 'styled-components'
import TopNav from '@/components/commons/TopNav/TopNav'
import ProfileVideosHistory from '@/features/contributors/components/my-profile/profile-video-history/ProfileVideoHistory'

const ContributorProfile: React.FC = () => {
    const {contributorContainerSize} = useTheme()
    const user = useAuthStore(store => store.user)

    return (
        <StyledRoot>
            <TopNav />
            <StyledMainSection>
                <Container maxWidth={contributorContainerSize}>
                    <StyledWrapper>
                        <Info userData={user} />
                        <ProfileVideosHistory />
                    </StyledWrapper>
                </Container>
            </StyledMainSection>
        </StyledRoot>
    )
}

export default ContributorProfile

ContributorProfile.displayName = 'ContributorMyProfile'
