export const palette = {
    light: {
        gray: {
            main: '#2D2926',
            middle: '#6E6F70',
            light: '#888887',
            xlight: '#E2EBEF',
            disabled: '#D9D9D9',
            menuActive: '#F0F0F0',
            inputBG: '#F5F5F5',
            avatarBG: '#D4DDF4',
            secondary: '#2F475E',
            border: '#eee',
            dark: '#57534F',
            darkNav: '#1C1B1B',
            lightBG: '#ecf2ff'
        },
        blue: {
            main: '#3D63AE',
            disabled: '#AFC9FD',
            link: '#4878F2',
            light: '#EAECF0'
        },
        // TODO: to review after design provides full colors hierarchy (label colors)
        green: {
            main: '#52C41A',
            middle: '#B7EB8F',
            light: '#F6FFED',
            labelBg: 'rgba(140, 203, 72, 0.10)',
            labelText: '#8CCB48'
        },
        orange: {
            main: '#FA8C16',
            middle: '#FFD591',
            light: '#FFF7E6'
        },
        red: {
            main: '#F5222D',
            middle: '#FFA39E',
            light: '#FFF1F0',
            error: '#CF1322',
            labelBg: 'rgba(236, 123, 95, 0.10)',
            labelText: '#EC7B5F'
        },

        neutral: {
            white: '#FFFFFF',
            25: '#FCFCFD',
            50: '#F9FAFB',
            100: '#F2F4F7',
            200: '#EAECF0',
            300: '#D0D5DD',
            400: '#98A2B3',
            500: '#667085',
            600: '#475467',
            700: '#344054',
            800: '#1D2939',
            900: '#101828',
            950: '#0C111D',
            black: '#000000'
        },
        primary: {
            25: '#F2F6FD',
            50: '#EFF8FF',
            100: '#D1E9FF',
            200: '#B2DDFF',
            300: '#84CAFF',
            400: '#53B1FD',
            500: '#2E90FA',
            600: '#1570EF',
            700: '#175CD3',
            800: '#1849A4',
            900: '#194185',
            950: '#102A56'
        },
        secondary: {
            25: '#F2F6FD',
            50: '#B2B1B7',
            100: '#8A909E',
            200: '#6A707E',
            300: '#464A53',
            400: '#8A909E',
            500: '#6A707E',
            600: '#464A53',
            700: '#8A909E',
            800: '#6A707E',
            900: '#464A53',
            950: '#102A56'
        },
        success: {
            25: '#F6FEF9',
            50: '#ECFDF3',
            100: '#DCFAE6',
            200: '#ABEFC6',
            300: '#75E0A7',
            400: '#47CD89',
            500: '#17B26A',
            600: '#079455',
            700: '#067647',
            800: '#085D3A',
            900: '#074D31',
            950: '#053321'
        },
        danger: {
            25: '#FFFBFA',
            50: '#FEF3F2',
            100: '#FEE4E2',
            200: '#FECDCA',
            300: '#FDA29B',
            400: '#F97066',
            500: '#F04438',
            600: '#D92D20',
            700: '#B42318',
            800: '#912018',
            900: '#7A271A',
            950: '#55160C'
        },
        warning: {
            25: '#FFFCF5',
            50: '#FFFAEB',
            100: '#FEF0C7',
            200: '#FEDF89',
            300: '#FEC84B',
            400: '#FDB022',
            500: '#F79009',
            600: '#DC6803',
            700: '#B54708',
            800: '#93370D',
            900: '#7A2E0E',
            950: '#4E1D09'
        },
        status: {
            processing: {
                text: '#5981CF',
                border: '#AFC9FD',
                background: '#ECF2FF'
            },
            ready: {
                text: '#FAAD14',
                border: '#FED88C',
                background: '#FFF8EA'
            },
            assigned: {
                text: '#722ED1',
                border: '#722ED1',
                background: '#F9F0FF'
            },
            ready_for_audit: {
                text: '#FAAD14',
                border: '#FAAD14',
                background: '#FFF8EA'
            },
            completed: {
                text: '#389E0D',
                border: '#A3E289',
                background: '#DCFFCD'
            },
            delivered: {
                text: '#389E0D',
                border: '#A3E289',
                background: '#DCFFCD'
            },
            rejected: {
                text: '#CF1322',
                border: '#F27A84',
                background: '#FFDDE0'
            },
            default: {
                text: '#5981CF',
                border: '#AFC9FD',
                background: '#ECF2FF'
            },
            fail: {
                text: '#2D2926',
                border: '#2D2926',
                background: '#eee'
            }
        },
        eventStatus: {
            scheduled: {
                text: '#5981CF',
                border: '#AFC9FD',
                background: '#ECF2FF'
            },
            open: {
                text: '#389E0D',
                border: '#A3E289',
                background: '#DCFFCD'
            },
            closed: {
                text: '#CF1322',
                border: '#F27A84',
                background: '#FFDDE0'
            }
        },
        gradient: {
            backgroundGradient: 'linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.00) 100%)'
        }
    }
    // TODO: we will support the dark mode documenting this one and adding the dark types to our style.d.ts
    /*dark: null*/
}
