import styled, {css, DefaultTheme} from 'styled-components'

export const StyledContainer = styled.div(
    ({theme: {spacing, typography}}: {theme: DefaultTheme}) => css`
        padding: ${spacing * 8}px ${spacing * 8}px ${spacing * 6}px;
        display: grid;
        grid-template-areas: 'icons title close' '. description .';
        grid-template-columns: 0fr auto 0fr;
        h4 {
            ${typography.subtitle};
            grid-area: title;
            margin: 0 ${spacing * 5}px ${spacing * 2}px ${spacing * 4}px;
        }
    `
)

export const StyledStatusIcon = styled.div(
    () => css`
        grid-area: icons;
    `
)

export const StyledCloseIcon = styled.div(
    () => css`
        grid-area: close;
        margin-left: auto;
        cursor: pointer;
    `
)

export const StyledBody = styled.div(
    ({theme: {spacing, typography}}: {theme: DefaultTheme}) => css`
        ${typography.textS}
        margin-left: ${spacing * 4}px;
        grid-area: description;
    `
)

interface StyledModalProps {
    width?: number
    theme: DefaultTheme
}

export const StyledModal = styled.div(
    ({theme: {palette, zIndex}}: StyledModalProps) => css`
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: ${zIndex.modal};
        border-radius: 16px;
        background: ${palette.neutral.white};
    `
)
