import React, {useEffect} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {StyledFormRoot} from './style'
import {useForm, SubmitHandler} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {z} from 'zod'
import Spinner from '@/components/ui/Spinner'
import {useRecoveryPassword} from './queries/useRecoveryPassword'
import Modal from '@/components/commons/Modal'
import Input from '@/components/ui/Input'
import {ValidationError, handleApiError} from '@/utilities/helpers'

const enum PASSWORD_RECOVER_MODEL {
    Email = 'email'
}

const PasswordRecoverSchema = z.object({
    [PASSWORD_RECOVER_MODEL.Email]: z
        .string()
        .min(1, {message: 'validation:email'})
        .email({
            message: 'validation:email_valid'
        })
        .default('')
})

type ValidationSchema = z.infer<typeof PasswordRecoverSchema>

export interface PasswordRecoverModalProps {
    onClose: () => void
    setSuccessModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const PasswordRecoverModal: React.FC<PasswordRecoverModalProps> = ({onClose, setSuccessModalOpen}) => {
    const {t} = useTranslation()
    const {mutate, isPending, isError, error} = useRecoveryPassword()

    const {
        register,
        handleSubmit,
        setError,
        formState: {errors, isValid, isDirty}
    } = useForm<ValidationSchema>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(PasswordRecoverSchema)
    })

    useEffect(() => {
        if (isError) handleApiError({setError, error: error as ValidationError<'email'>})
    }, [isError])

    const onSuccess = () => {
        onClose()
        setSuccessModalOpen(true)
    }

    const onSubmit: SubmitHandler<ValidationSchema> = values => {
        mutate(values, {onSuccess})
    }

    return (
        <Modal
            overlay
            title={'log_in_page:password_recover_modal:title'}
            onClose={onClose}
            cancelButtonCallback={onClose}
            isSubmitButtonDisabled={!isValid || !isDirty}
            submitButtonCallback={handleSubmit(onSubmit)}
        >
            {isPending && <Spinner size={48} overlay={false} />}
            <StyledFormRoot onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <Trans
                        t={t}
                        i18nKey="log_in_page:password_recover_modal:description"
                        components={{
                            span: <span />
                        }}
                    />
                </div>
                <Input
                    {...register(PASSWORD_RECOVER_MODEL.Email)}
                    label={`${t('common:email')}*`}
                    errorMessage={`${t(errors[PASSWORD_RECOVER_MODEL.Email]?.message || '')}`}
                />
            </StyledFormRoot>
        </Modal>
    )
}

export default PasswordRecoverModal

PasswordRecoverModal.displayName = 'PasswordRecoverModal'
