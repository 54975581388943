import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {httpCreateRank} from '../services/rank.http'

export const useCreateRank = ({onSuccess}: {onSuccess: () => void}) => {
    const client = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.CREATE_RANK],
        mutationFn: (data: FormData) => httpCreateRank(data),
        onSuccess: () => {
            onSuccess()
            client.invalidateQueries({queryKey: [QUERY_KEYS.RANKING_LIST]})
        }
    })
}
