import {QUERY_KEYS} from '@/queryClient'
import {httpGetVideoInformation} from '@/features/videos/services/video.http'
import {useQuery} from '@tanstack/react-query'

export const useGetVideoInformation = ({videoId, hash = undefined}: {videoId: string; hash?: string | null}) =>
    useQuery({
        queryKey: [QUERY_KEYS.VIDEO_DETAILS, videoId],
        queryFn: () => httpGetVideoInformation(videoId, hash),
        refetchOnWindowFocus: 'always'
    })
