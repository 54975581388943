import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {httpCreateJob} from '@/features/videos/services/video.http'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {JobCreationRequest} from '@/types/job'

export const useCreateJob = ({
    workspaceId,
    videoId,
    onSuccess
}: {
    workspaceId: string
    videoId: string
    onSuccess: () => void
}) => {
    const client = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.CREATE_JOB, videoId],
        mutationFn: (data: JobCreationRequest) => httpCreateJob(videoId, data),
        onSuccess: () => {
            onSuccess()
            client.invalidateQueries({queryKey: [QUERY_KEYS.WORKSPACE_VIDEO_DETAILS, workspaceId, videoId]})
            client.invalidateQueries({queryKey: [QUERY_KEYS.WORKSPACE_VIDEOS, workspaceId]})
        }
    })
}
