import React, {useState} from 'react'
import {StyledInputsWrapper, StyledSubtitle} from './style'
import {useTranslation} from 'react-i18next'
import Modal from '@/components/commons/Modal'
import {VideoUrlForm} from '@/features/videos/types/video'
import {FormProvider, useForm} from 'react-hook-form'
import {
    EuropeanaVideoSchema,
    VIDEO_MODEL,
    VideoUrlSchema,
    VideoUrlValidationSchema
} from '@/features/videos/services/VideoCreationFormModel'
import {zodResolver} from '@hookform/resolvers/zod'
import Input from '@/components/ui/Input'
import {USER_SIGNUP_SOURCE} from '@/utilities/constants'
import {httpGetEuropeanaVideos} from '@/features/videos/services/video.http'
import {Language, ValueLabel} from '@/types/commons'
import VideoSelect from '@/components/commons/VideoSelect/VideoSelect'
import {useParams} from 'react-router-dom'
import {InfoIcon} from '@/assets/icons/icons'
import {useTheme} from 'styled-components'
import VideoInfoModal from '../video-info-modal/VideoInfoModal'

interface AddVideoModalProps {
    onClose: () => void
    onSubmit: (data: VideoUrlForm) => void
    source?: string
}

export type EuropeanaVideoType = {
    id: number
    thumbnailUrl: string
    name: string
    videoUrl: string[]
    europeanaProjectId: string
    europeanaPlatformSourceUrl: string[]
    europeanaRightsUrl: string[]
    description: string
    language: Language
    usable: boolean
}

const AddVideoModal: React.FC<AddVideoModalProps> = ({onClose, onSubmit, source}) => {
    const {t} = useTranslation()
    const theme = useTheme()
    const {workspaceId} = useParams()
    const isEuropeanaSource = source === USER_SIGNUP_SOURCE.europeana
    const methods = useForm<VideoUrlValidationSchema>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: isEuropeanaSource ? zodResolver(EuropeanaVideoSchema) : zodResolver(VideoUrlSchema)
    })
    const [isVideoInfoModalVisible, setIsVideoInfoModalVisible] = useState(false)

    const {
        handleSubmit,
        register,
        formState: {isValid, errors}
    } = methods

    return (
        <>
            <FormProvider {...methods}>
                <Modal
                    maxWidth={900}
                    overlay
                    title={'add_video_modal:title'}
                    onClose={onClose}
                    cancelButtonCallback={onClose}
                    isSubmitButtonDisabled={!isValid}
                    submitButtonText={'common:next'}
                    submitButtonCallback={handleSubmit(onSubmit)}
                    withFooter={!isEuropeanaSource}
                >
                    <StyledSubtitle>
                        {isEuropeanaSource
                            ? t('add_video_modal:europeana_subtitle')
                            : t('add_video_modal:non-europeana_subtitle')}
                    </StyledSubtitle>
                    {isEuropeanaSource ? (
                        <>
                            <VideoSelect
                                name={VIDEO_MODEL.EuropeanaVideo}
                                placeholder={t('common:search')}
                                fetchFunction={(name, page) => httpGetEuropeanaVideos(`${workspaceId}`, name, page)}
                                onSelect={() => {
                                    handleSubmit(onSubmit)()
                                    onClose()
                                }}
                                adapterFunction={data =>
                                    data?.data?.map(
                                        (item: EuropeanaVideoType): ValueLabel => ({
                                            value: item.videoUrl[0],
                                            label: item.name,
                                            url: item.videoUrl[0],
                                            thumbnailUrl: item.thumbnailUrl,
                                            europeanaRightsUrl: item.europeanaRightsUrl[0],
                                            europeanaProjectId: item.europeanaProjectId,
                                            europeanaPlatformSourceUrl: item.europeanaPlatformSourceUrl[0],
                                            description: item?.description ?? '',
                                            language: item.language,
                                            isDisabled: !item.usable
                                        })
                                    )
                                }
                            />
                        </>
                    ) : (
                        <StyledInputsWrapper>
                            <Input
                                {...register(VIDEO_MODEL.Name)}
                                label={`${t('video_creation:name')}*`}
                                type="text"
                                errorMessage={`${t(errors[VIDEO_MODEL.Name]?.message || '')}`}
                            />

                            <Input
                                {...register(VIDEO_MODEL.Description)}
                                label={`${t('video_creation:description')}*`}
                                type="text"
                                errorMessage={`${t(errors[VIDEO_MODEL.Description]?.message || '')}`}
                            />

                            <Input
                                {...register(VIDEO_MODEL.Url)}
                                icon={<InfoIcon fill={theme.palette.neutral[800]} />}
                                iconCallback={() => setIsVideoInfoModalVisible(true)}
                                label={`${t('video_creation:url')}*`}
                                type="text"
                                errorMessage={`${t(errors[VIDEO_MODEL.Url]?.message || '')}`}
                            />
                        </StyledInputsWrapper>
                    )}
                </Modal>
            </FormProvider>
            {isVideoInfoModalVisible && <VideoInfoModal withShadow onClose={() => setIsVideoInfoModalVisible(false)} />}
        </>
    )
}

export default AddVideoModal

AddVideoModal.displayName = 'AddVideoModal'
