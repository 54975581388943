import {forwardRef, InputHTMLAttributes} from 'react'

import {StyledInput, StyledLabel, StyledInputRoot, StyledErrorMessage} from './style'
import HoverTooltip from '../HoverTooltip'
import {InfoCircleIcon} from '@/assets/icons/icons'
import {useTheme} from 'styled-components'
import {DefaultTFuncReturn} from 'i18next'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string
    name?: string
    errorMessage?: string
    helperText?: DefaultTFuncReturn
}

const Switch = forwardRef<HTMLInputElement, InputProps>(
    ({label, name, errorMessage, disabled, id, helperText, ...rest}, ref) => {
        const theme = useTheme()
        return (
            <>
                <StyledLabel disabled={disabled} htmlFor={id}>
                    <span>{label}</span>
                    {helperText && (
                        <HoverTooltip text={helperText}>
                            <InfoCircleIcon width={15} height={15} stroke={theme.palette.neutral[900]} />
                        </HoverTooltip>
                    )}
                </StyledLabel>
                <StyledInputRoot>
                    <StyledInput type="checkbox" id={id} name={name} disabled={disabled} ref={ref} {...rest} />
                </StyledInputRoot>
                {!!errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
            </>
        )
    }
)

export default Switch
