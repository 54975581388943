import {StyledBox, StyledCardWrapper, StyledImage, StyledLanguage, StyledName, StyledThumbnailWrapper} from './style'
import {VideoType} from '@/types/video'
import {ROUTE_VIDEO_DETAILS} from '@/utilities/constants'
import {generatePath, useNavigate} from 'react-router-dom'
import Tag from '@/components/ui/Tag'
import {useTranslation} from 'react-i18next'
import {formatDuration} from '@/utilities/helpers'

const PreviewVideoCard = ({video}: {video: VideoType}) => {
    const {id, status, projectInfos, targetLanguage, privateHash} = video
    const navigate = useNavigate()
    const {t} = useTranslation()

    const sharePublicVideoURL = generatePath(ROUTE_VIDEO_DETAILS, {videoId: `${id}`})
    const sharePrivetVideoURL = `${generatePath(ROUTE_VIDEO_DETAILS, {videoId: `${id}`})}?t=${privateHash}`

    return (
        <StyledCardWrapper
            onClick={() => {
                navigate(privateHash ? sharePrivetVideoURL : sharePublicVideoURL)
            }}
        >
            <StyledThumbnailWrapper>
                <StyledImage src={projectInfos?.thumbnailUrl ?? ''} alt={projectInfos?.name} />
                {!!projectInfos?.duration && <span>{formatDuration(projectInfos.duration)}</span>}
            </StyledThumbnailWrapper>
            {!!projectInfos?.name && <StyledName>{projectInfos.name}</StyledName>}
            <StyledBox>
                <Tag type={status}>{t(`contributor_video_status_label:${status}`)}</Tag>
                <StyledLanguage>
                    {projectInfos.sourceLanguage?.name} &gt; {targetLanguage?.name}
                </StyledLanguage>
            </StyledBox>
        </StyledCardWrapper>
    )
}

export default PreviewVideoCard

PreviewVideoCard.displayName = 'PreviewVideoCard'
