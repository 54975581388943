import BackgroundFullScreenImage from '@/components/ui/BackgroundFullScreenImage'
import {StyledDescription, StyledTitleSection} from './style'
import {FC, ReactNode} from 'react'
import Subbit_Header from '@assets/images/background/Subbit_Header.jpg'

interface TitleSectionProps {
    backgroundImage?: string
    title: string
    subtitle?: string | ReactNode
    description?: string | ReactNode
}
const TitleSection: FC<TitleSectionProps> = ({
    backgroundImage = Subbit_Header,
    title,
    subtitle = '',
    description = ''
}) => {
    return (
        <BackgroundFullScreenImage backgroundImage={backgroundImage}>
            <StyledTitleSection>
                <h2>{title}</h2>
                <h3>{!!subtitle && subtitle}</h3>
                <StyledDescription>{!!description && description}</StyledDescription>
            </StyledTitleSection>
        </BackgroundFullScreenImage>
    )
}

export default TitleSection

TitleSection.displayName = 'TitleSection'
