import styled, {css} from 'styled-components'

export const StyledInputRoot = styled.div`
    ${({theme: {spacing}}) => css`
        position: relative;
        display: flex;
        gap: ${spacing * 2}px;
        align-items: center;
    `}
`

type StyledStyledInputProps = {
    disabled?: boolean
}

export const StyledInput = styled.input<StyledStyledInputProps>`
    ${({theme: {palette, spacing}}) => css`
        appearance: none;
        cursor: pointer;
        position: relative;
        width: ${spacing * 3}px;
        height: ${spacing * 3}px;
        border: 0.75px solid ${palette.neutral['300']};
        border-radius: 3px;
        transition: all 0.3s ease;

        &:checked {
            border-color: ${palette.gray.main};
            background-color: ${palette.gray.main};
            position: relative;
        }

        &:checked::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 9px;
            height: 9px;
            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTggMy4xMTkxNEwzLjg3NSA3LjI0NDE0TDIgNS4zNjkxNCIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIxLjI0OTk1IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }

        &:disabled {
            border-color: ${palette.gray.main};
            cursor: not-allowed;
        }
    `}
`

export const StyledLabel = styled.label<StyledStyledInputProps>`
    ${({disabled, theme: {palette}}) => css`
        font-weight: 400;
        line-height: normal;
        color: ${palette.gray.main};
        cursor: pointer;
        ${disabled &&
        css`
            color: rgba(0, 0, 0, 0.25);
        `};
    `}
`
