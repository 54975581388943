import {ChevronUpIcon, FiltersIcon} from '@/assets/icons/icons'
import {useTranslation} from 'react-i18next'
import {
    StyledChevronIcon,
    StyledFirstItem,
    StyledItem,
    StyledItemHeader,
    StyledItemsWrapper,
    StyledSortItem,
    StyledSortItemWrapper,
    StyledTitle
} from './style'
import {useEffect, useState} from 'react'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import {useTheme} from 'styled-components'
import {useNavigate} from 'react-router-dom'
import {ROUTE_VIDEOS} from '@/utilities/constants'
import {getFullVideoFiltersContent} from '../../fixtures/videoFiltersContent'
import {useGetLanguages} from '@/queries/useGetLanguages'
import {handleApiError} from '@/utilities/helpers'

type Filters = {
    [key: string]: string
}

const VideoFilters = ({
    withViewAll = false,
    handleFilterClickCallback,
    filters = {}
}: {
    withViewAll?: boolean
    handleFilterClickCallback: (key: string, value: string) => void
    filters?: Filters
}) => {
    const {t} = useTranslation()
    const {width} = useWindowDimensions()
    const theme = useTheme()
    const navigate = useNavigate()
    const [showFilterItems, setShowFilterItems] = useState(false)
    const {data, isError, error} = useGetLanguages({name: undefined})
    const videoFiltersContent = getFullVideoFiltersContent(data?.data ?? [])

    useEffect(() => {
        if (width >= theme.breakpoints.width.l) {
            setShowFilterItems(true)
        }
    }, [width])

    useEffect(() => {
        if (isError) handleApiError({error})
    }, [isError])

    return (
        <div>
            <StyledTitle>
                <p>{t('filter_video_panel:title')}</p>

                {withViewAll && <span onClick={() => navigate(ROUTE_VIDEOS)}> {t('filter_video_panel:view_all')}</span>}
            </StyledTitle>

            <StyledItemsWrapper>
                <StyledFirstItem>
                    <StyledItemHeader>
                        <FiltersIcon />
                        <p>{t('filter_video_panel:filter')}</p>

                        <StyledChevronIcon
                            showFilterItems={showFilterItems}
                            onClick={() => setShowFilterItems(prevState => !prevState)}
                        >
                            <ChevronUpIcon />
                        </StyledChevronIcon>
                    </StyledItemHeader>
                </StyledFirstItem>

                {showFilterItems &&
                    videoFiltersContent?.map(item => (
                        <StyledItem key={item.id}>
                            <StyledItemHeader>
                                {item.icon}
                                <p>{t(item.name)}</p>
                            </StyledItemHeader>

                            <StyledSortItemWrapper>
                                {item.sortItem.map(sortItem => (
                                    <StyledSortItem
                                        key={sortItem.id}
                                        onClick={() => handleFilterClickCallback(item.label, `${sortItem.id}`)}
                                        isActive={filters[item.label] === `${sortItem.id}`}
                                    >
                                        {t(sortItem.name)}
                                    </StyledSortItem>
                                ))}
                            </StyledSortItemWrapper>
                        </StyledItem>
                    ))}
            </StyledItemsWrapper>
        </div>
    )
}

export default VideoFilters

VideoFilters.displayName = 'VideoFilters'
