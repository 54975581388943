import styled, {css} from 'styled-components'

export const StyledFormRoot = styled.form`
    ${({theme: {mediaQueries, spacing}}) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: ${spacing * 12}px;
        ${mediaQueries.m} {
            margin-top: ${spacing * 20}px;
        }
    `}
`

export const StyledTitle = styled.div`
    ${({theme: {palette, spacing, typography}}) => css`
        ${typography.title};
        color: ${palette.gray.main};
        margin-bottom: ${spacing * 6}px;
    `}
`

export const StyledSubTitle = styled.div`
    ${({theme: {typography}}) => css`
        ${typography.subtitle};
        max-width: 100%;
    `}
`

export const StyledContentWrapper = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        margin-bottom: ${spacing * 3}px;
        display: grid;
        gap: ${spacing * 4}px;
        align-items: center;
        width: 100%;

        ${mediaQueries.m} {
            margin-bottom: ${spacing * 8}px;
            gap: ${spacing * 8}px;
            width: 360px;
        }
    `}
`

export const StyledButtonSection = styled.div`
    ${({theme: {mediaQueries, spacing}}) => css`
        margin-top: ${spacing * 3}px;
        width: 100%;
        & button {
            margin: 0 auto;
        }

        ${mediaQueries.m} {
            margin-top: ${spacing * 8}px;
            width: 360px;
        }
    `}
`

export const StyledTextDivider = styled.div`
    ${({theme: {palette, spacing, typography, flexUtils}}) => css`
        ${typography.textS}
        color: ${palette.gray.middle};
        font-weight: 500;
        ${flexUtils.columnSpaceBetween};
        margin-top: ${spacing * 3}px;
        margin-bottom: ${spacing * 3}px;
        ::before,
        ::after {
            content: '';
            width: 100%;
            max-width: 150px;
            height: 1px;
            background: ${palette.gray.xlight};
        }
    `}
`

export const StyledTextWrap = styled.div`
    ${({theme: {palette, mediaQueries, typography, spacing}}) => css`
        ${typography.textM}
        display: flex;
        align-items: center;
        color: ${palette.gray.middle};
        font-weight: 400;
        margin: ${spacing * 2}px auto;
        ${mediaQueries.m} {
            ${typography.textL}
            color: ${palette.gray.middle};
            font-weight: 500;
            margin: 30px auto;
        }
    `}
`
export const StyledText = styled.div`
    ${({theme: {palette, mediaQueries, typography, spacing}}) => css`
        color: ${palette.gray.main};
        ${typography.textS}
        font-weight: 700;
        padding: ${spacing * 3}px;
        cursor: pointer;

        ${mediaQueries.m} {
            ${typography.textM}
            text-decoration: underline;
        }
    `}
`
