import styled, {css} from 'styled-components'

export const StyledVideoInfoRoot = styled.div`
    display: flex;
    flex-direction: column;
`

export const StyledTitle = styled.div`
    ${({theme: {typography, palette, spacing}}) => css`
        ${typography.textXL}
        font-weight: 700;
        color: ${palette.gray.main};
        margin-bottom: ${spacing * 11}px;
    `}
`

export const StyledItemWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 7}px;
    `}
`

export const StyledDivider = styled.div`
    ${({theme: {palette}}) => css`
        border-top: 1px solid ${palette.gray.xlight};
    `}
`

export const StyledItem = styled.div`
    ${({theme: {palette}}) => css`
        & div {
            color: ${palette.gray.main};
            font-weight: 500;
            line-height: 23px;
        }
    `}
`

export const StyledLink = styled.a`
    ${({theme: {palette, spacing}}) => css`
        display: flex;
        gap: ${spacing * 2}px;
        align-items: center;

        & span {
            color: ${palette.blue.link};
            cursor: pointer;
        }

        & svg {
            cursor: pointer;
        }
    `}
`
