import {z} from 'zod'

export const enum CHANGE_PASSWORD_MODEL {
    Password = 'password',
    PasswordConfirmation = 'passwordConfirmation'
}

export const changePasswordFormSchema = z
    .object({
        [CHANGE_PASSWORD_MODEL.Password]: z
            .string()
            .min(6, {message: 'validation:password'})
            .regex(new RegExp(/^(?=.*[A-Z])(?=.*[0-9])(?=.*[^\w\s]).{6,32}/), {message: 'validation:password_complex'})
            .default(''),
        [CHANGE_PASSWORD_MODEL.PasswordConfirmation]: z.string().min(6, {message: 'validation:password'}).default('')
    })
    .refine(data => data[CHANGE_PASSWORD_MODEL.Password] === data[CHANGE_PASSWORD_MODEL.PasswordConfirmation], {
        path: [CHANGE_PASSWORD_MODEL.PasswordConfirmation],
        message: 'validation:confirm_password'
    })

export type ChangePasswordValidationSchema = z.infer<typeof changePasswordFormSchema>
