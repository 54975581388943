import {QUERY_KEYS} from '@/queryClient'
import {useInfiniteQuery} from '@tanstack/react-query'
import {httpGetVideos} from '../services/workspaces.http'

export const useGetVideos = ({
    workspaceId,
    name,
    isEuropeana,
    statuses,
    targets
}: {
    workspaceId: string
    name: string
    isEuropeana?: boolean
    statuses?: string[]
    targets?: string[]
}) =>
    useInfiniteQuery({
        queryKey: [QUERY_KEYS.WORKSPACE_VIDEOS, workspaceId, name, isEuropeana, statuses, targets],
        queryFn: ({pageParam}) => httpGetVideos({workspaceId, name, isEuropeana, statuses, targets}, pageParam),
        initialPageParam: '',
        getNextPageParam: lastPage => lastPage.meta.next_page_url
    })
