import {Outlet, generatePath, matchRoutes, useLocation, useNavigate} from 'react-router-dom'
import {StyledContainer, StyledOutletWrapper, StyledPrivateLayout} from './styles'
import React, {useEffect, useState} from 'react'
import Header from '@/components/commons/Header'
import {
    ROUTE_EVENT_INGESTION,
    ROUTE_MY_PROFILE,
    ROUTE_VIDEO_CREATION,
    ROUTE_VIDEO_INGESTION,
    ROUTE_WORKSPACES,
    ROUTE_WORKSPACE_DETAILS,
    USER_ROLE_CONTRIBUTOR
} from '@/utilities/constants'
import {LocalStorageManager} from '@/utilities/localStorage'
import WorkspaceLimitReachedModal from '@/features/workspaces/components/workspace-limit-reached-modal/WorkspaceLimitReachedModal'
import {ValidationError, handleApiError} from '@/utilities/helpers'
import {useAcceptInvitation} from '@/features/workspaces/queries/useAcceptInvitation'
import useAuthStore from '@/store/authStore'
import useLayoutStore from '@/store/layoutStore'

type PrivateLayoutProps = {
    withHeader?: boolean
}
const PrivateLayout: React.FC<PrivateLayoutProps> = ({withHeader}) => {
    const navigate = useNavigate()
    const [isWorkspaceLimitReachedModalOpen, setIsWorkspaceLimitReachedModalOpen] = useState(false)
    const user = useAuthStore(store => store.user)
    const isContributor = user?.type === USER_ROLE_CONTRIBUTOR
    const location = useLocation()
    const routeWithStaticNav = [
        {path: ROUTE_WORKSPACES},
        {path: ROUTE_WORKSPACE_DETAILS},
        {path: ROUTE_MY_PROFILE},
        {path: ROUTE_VIDEO_INGESTION},
        {path: ROUTE_VIDEO_CREATION},
        {path: ROUTE_EVENT_INGESTION}
    ]
    const matches = matchRoutes(routeWithStaticNav, location)
    const isNavOpen = useLayoutStore(store => store.isNavOpen)
    const [invitation, setInvitation] = useState(LocalStorageManager.workspaceInvitation.get() ?? null)

    const onSuccessAcceptInvitation = () => {
        if (invitation) {
            navigate(generatePath(ROUTE_WORKSPACE_DETAILS, {workspaceId: `${invitation.workspaceId}`}))
            setInvitation(null)
            LocalStorageManager.workspaceInvitation.remove()
        }
    }

    const {mutate, isError, error} = useAcceptInvitation({onSuccess: onSuccessAcceptInvitation})

    useEffect(() => {
        if (isError && error) {
            if (error?.response?.status === 409) {
                // This error conflict: already has two workspaces
                setIsWorkspaceLimitReachedModalOpen(true)
            } else {
                setInvitation(null)
                LocalStorageManager.workspaceInvitation.remove()
                handleApiError({error: error as ValidationError<string>})
            }
        }
    }, [isError])

    useEffect(() => {
        if (invitation) {
            const timeout = setTimeout(() => mutate(invitation), 100)
            return () => clearTimeout(timeout)
        }
    }, [invitation])

    return (
        <StyledPrivateLayout>
            <StyledContainer>
                {withHeader && <Header />}
                <StyledOutletWrapper isNavOpen={isNavOpen && !!matches?.length && !isContributor}>
                    <Outlet />
                </StyledOutletWrapper>
            </StyledContainer>

            {isWorkspaceLimitReachedModalOpen && (
                <WorkspaceLimitReachedModal
                    onClose={() => {
                        setIsWorkspaceLimitReachedModalOpen(false)
                        setInvitation(null)
                        LocalStorageManager.workspaceInvitation.remove()
                    }}
                />
            )}
        </StyledPrivateLayout>
    )
}

export default PrivateLayout
