import styled, {DefaultTheme, css} from 'styled-components'
import * as AvatarPrimitive from '@radix-ui/react-avatar'

export const AvatarRoot = styled(AvatarPrimitive.Root)`
    ${({theme: {palette}, size}: {theme: DefaultTheme; size: number}) => css`
        display: inline-flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        user-select: none;
        width: ${size}px;
        height: ${size}px;
        border-radius: 100%;
        background: ${palette.gray.avatarBG};
    `}
`

export const StyledAvatar = styled(AvatarPrimitive.Root)`
    ${({theme: {palette, typography}}: {theme: DefaultTheme}) => css`
        color: ${palette.blue.main};
        ${typography.textXL}
        font-weight: 700;
        text-transform: uppercase;
    `}
`
