import {useQuery} from '@tanstack/react-query'
import {httpGetWorkspacesList} from '../services/workspaces.http'
import {QUERY_KEYS} from '@/queryClient'

export const useGetWorkspacesList = ({enabled = true}: {enabled?: boolean}) =>
    useQuery({
        queryKey: [QUERY_KEYS.WORKSPACES_LIST],
        queryFn: () => httpGetWorkspacesList(),
        enabled
    })
