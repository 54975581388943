import {QUERY_KEYS} from '@/queryClient'
import {useInfiniteQuery} from '@tanstack/react-query'
import {httpGetLeadersList} from '../services/leader.http'

export const useGetLeadersList = ({per_page = 10}: {per_page: number}) =>
    useInfiniteQuery({
        queryKey: [QUERY_KEYS.LEADERS, per_page],
        queryFn: ({pageParam}) => httpGetLeadersList({pageParam, limit: per_page}),
        initialPageParam: '',
        getNextPageParam: lastPage => lastPage.meta.next_page_url
    })
