import styled, {css} from 'styled-components'

export const StyledChildrenRoot = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`
export const StyledForm = styled.form`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 12}px;
        width: 100%;
        margin: ${spacing * 6}px 0 ${spacing * 8}px 0px;
    `}
`

export const StyledPointsWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing * 3}px;

        input[type='number']::-webkit-inner-spin-button,
        input[type='number']::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type='number'] {
            -moz-appearance: textfield;
            appearance: textfield;
        }
    `}
`
