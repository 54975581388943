import {FC, ReactNode, useEffect} from 'react'
import {StyledModal, StyledOverlay} from './style'
import {createPortal} from 'react-dom'

interface PopoverProps {
    children: ReactNode
    overlay?: boolean
    className?: string
    onClose?: () => void
    lockBodyScroll?: boolean
    maxWidth?: number
    withoutPortal?: boolean
}

const Popover: FC<PopoverProps> = ({
    children,
    overlay,
    className,
    onClose,
    lockBodyScroll = true,
    maxWidth = 416,
    withoutPortal = false
}) => {
    useEffect(() => {
        if (lockBodyScroll) {
            const bodyStyle = window.document.body.style
            bodyStyle.overflowY = 'hidden' // Lock body scroll
            return () => {
                bodyStyle.overflowY = 'auto' // Unlock body scroll
            }
        }
    }, [])

    const render = (
        <>
            {overlay && <StyledOverlay onClick={onClose} />}
            <StyledModal className={className} maxWidth={maxWidth}>
                {children}
            </StyledModal>
        </>
    )
    if (withoutPortal) return render

    return createPortal(render, document.body)
}

Popover.displayName = 'Popover'
export default Popover
