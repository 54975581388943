import {forwardRef, InputHTMLAttributes, ReactNode} from 'react'
import {
    StyledErrorMessage,
    StyledIcon,
    StyledInputWrapper,
    StyledIconWrapper,
    StyledInput,
    StyledLabel,
    StyledInputRoot
} from './style'
export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    className?: string
    label?: string
    name?: string
    placeholder?: string
    icon?: ReactNode
    iconCallback?: () => void
    errorMessage?: string
    maxWidth?: string
    hasError?: boolean
    maxLength?: number
}
const Input = forwardRef<HTMLInputElement, InputProps>(
    (
        {
            label,
            name,
            placeholder,
            onChange,
            onBlur,
            icon,
            iconCallback,
            errorMessage,
            maxWidth,
            hasError,
            disabled = false,
            maxLength,
            ...rest
        },
        ref
    ) => {
        return (
            <>
                <StyledInputRoot>
                    {!!label && (
                        <StyledLabel htmlFor={name} disabled={disabled}>
                            {label}
                        </StyledLabel>
                    )}
                    <StyledInputWrapper maxWidth={maxWidth}>
                        <StyledInput
                            name={name}
                            placeholder={placeholder}
                            onChange={onChange}
                            onBlur={onBlur}
                            hasError={hasError ? hasError : !!errorMessage}
                            hasIcon={icon !== undefined}
                            ref={ref}
                            disabled={disabled}
                            maxLength={maxLength}
                            {...rest}
                        />
                        {icon && (
                            <StyledIconWrapper>
                                <StyledIcon
                                    {...(iconCallback && {
                                        onClick: iconCallback
                                    })}
                                    isError={!!errorMessage || hasError}
                                >
                                    {icon}
                                </StyledIcon>
                            </StyledIconWrapper>
                        )}
                    </StyledInputWrapper>
                    {!!errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
                </StyledInputRoot>
            </>
        )
    }
)
export default Input
