import styled, {css} from 'styled-components'

export const StyledRoot = styled.div`
    ${({theme: {mediaQueries, spacing, headerHeightSizingSmall, headerHeightSizing}}) => css`
        display: flex;
        align-items: center;
        height: calc(100% - ${headerHeightSizingSmall}px);
        ${mediaQueries.m} {
            padding-left: ${spacing * 10}px;
            height: calc(100% - ${headerHeightSizing}px);
        }
        ${mediaQueries.l} {
            padding-left: 0px;
        }
    `}
`

export const StyledError = styled.div`
    ${({theme: {palette, typography}}) => css`
        ${typography.textL};
        color: ${palette.red.labelText};
        font-weight: 500;
    `}
`

export const StyledTitle = styled.div`
    ${({theme: {palette, spacing, typography}}) => css`
        ${typography.title};
        color: ${palette.gray.main};
        margin: ${spacing * 3}px 0px ${spacing * 6}px;
    `}
`

export const StyledDescription = styled.div`
    ${({theme: {typography, palette}}) => css`
        ${typography.textXL};
        color: ${palette.neutral[600]};
    `}
`

export const StyledButtonSection = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing * 3}px;
        margin-top: ${spacing * 12}px;
    `}
`
