import React from 'react'
import {Trans, useTranslation} from 'react-i18next'
import StatusModal from '@/components/commons/StatusModal'
import {StyledDescription} from './style'

export interface WorkspaceLimitReachedModalProps {
    onClose: () => void
}

const WorkspaceLimitReachedModal: React.FC<WorkspaceLimitReachedModalProps> = ({onClose}) => {
    const {t} = useTranslation()

    return (
        <StatusModal
            overlay
            title={t('workspace:workspace_limit_reached_modal:title')}
            onClose={onClose}
            variant="error"
        >
            <StyledDescription>
                <Trans
                    t={t}
                    i18nKey="workspace:workspace_limit_reached_modal:description"
                    components={{
                        span: <span />
                    }}
                />
            </StyledDescription>
        </StatusModal>
    )
}

export default WorkspaceLimitReachedModal

WorkspaceLimitReachedModal.displayName = 'WorkspaceLimitReachedModal'
