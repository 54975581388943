import {ROUTE_PRIVACY_POLICY, ROUTE_TERMS_AND_CONDITIONS} from '@/utilities/constants'
import {useTranslation} from 'react-i18next'
import {StyledItem, StyledItemWrapper, StyledLink, StyledNavRoot} from './style'

const BottomNav: React.FC = () => {
    const {t} = useTranslation()

    return (
        <StyledNavRoot>
            <StyledItemWrapper>
                <StyledItem to={ROUTE_TERMS_AND_CONDITIONS}>
                    <span>{t('nav:terms_and_conditions')}</span>
                </StyledItem>
                <StyledItem to={ROUTE_PRIVACY_POLICY}>
                    <span>{t('nav:privacy_policy')}</span>
                </StyledItem>
                {/* TODO: add correct href */}
                <StyledLink>
                    <span>{t('nav:contact_us')}</span>
                </StyledLink>
            </StyledItemWrapper>
        </StyledNavRoot>
    )
}

export default BottomNav

BottomNav.displayName = 'BottomNav'
