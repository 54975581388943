import Button from '../Button'
import {StyledLoadMoreWrapper} from './style'
import {useTranslation} from 'react-i18next'

interface LoadMoreProps {
    hasNextPage: boolean
    isFetchingNextPage: boolean
    isFetching: boolean
    fetchNextPage: () => void
}

const LoadMore = ({hasNextPage, isFetchingNextPage, isFetching, fetchNextPage}: LoadMoreProps) => {
    const {t} = useTranslation()
    return (
        <>
            <StyledLoadMoreWrapper>
                <Button size="sm" onClick={() => fetchNextPage()} disabled={!hasNextPage || isFetchingNextPage}>
                    {isFetchingNextPage
                        ? t('load_more:loading_more')
                        : hasNextPage
                        ? t('load_more:load_more')
                        : t('load_more:no_more_data')}
                </Button>
            </StyledLoadMoreWrapper>
            <div>{isFetching && !isFetchingNextPage ? t('load_more:fetching') : null}</div>
        </>
    )
}

export default LoadMore

LoadMore.displayName = 'LoadMore'
