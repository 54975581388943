import {FC, useState} from 'react'
import DropDown from '@/components/ui/DropDown'
import {StyledDivider, StyledMenuItem, StyledMenuWrapper} from './style'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {ROUTE_CHANGE_PASSWORD, ROUTE_MY_PROFILE, USER_ROLE_PROVIDER, routes} from '@/utilities/constants'
import LogoutModal from '@/features/authentication/components/logout-modal/LogoutModal'
import {
    LogoutIcon,
    PrivacyPolicyIcon,
    ScaleIcon,
    SettingsIcon,
    TermsAndConditionsIcon,
    UserIcon
} from '@/assets/icons/icons'
import useAuthStore from '@/store/authStore'

interface DropDownMenuProps {
    onClose?: () => void
    width?: number
    isOpen?: boolean
}

const DropDownMenu: FC<DropDownMenuProps> = ({isOpen, onClose}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [isLogoutModalOpen, setLogoutModalOpen] = useState(false)
    const user = useAuthStore(store => store.user)

    const handleMyProfileClick = () => {
        if (onClose) onClose()
        navigate(ROUTE_MY_PROFILE)
    }

    const handleChangePasswordClick = () => {
        if (onClose) onClose()
        navigate(ROUTE_CHANGE_PASSWORD)
    }

    const handleOpenLogoutModal = () => {
        setLogoutModalOpen(true)
    }

    return (
        <DropDown isOpen={isOpen} onClose={onClose}>
            <StyledMenuWrapper>
                <StyledMenuItem onClick={handleMyProfileClick}>
                    <UserIcon />
                    <span>{t('drop_down_menu:my_profile')}</span>
                </StyledMenuItem>
                {user?.signupSource !== 'europeana' && (
                    <>
                        <StyledDivider />
                        <StyledMenuItem onClick={handleChangePasswordClick}>
                            <SettingsIcon />
                            <span>{t('drop_down_menu:change_password')}</span>
                        </StyledMenuItem>
                    </>
                )}
                {user?.type === USER_ROLE_PROVIDER && (
                    <>
                        <StyledDivider />
                        <StyledMenuItem
                            onClick={() => {
                                navigate(routes.PRIVACY_POLICY.path)
                                if (onClose) onClose()
                            }}
                        >
                            <PrivacyPolicyIcon />
                            <span>{t('nav:privacy_policy')}</span>
                        </StyledMenuItem>
                        <StyledDivider />
                        <StyledMenuItem
                            onClick={() => {
                                navigate(routes.TERMS_AND_CONDITIONS.path)
                                if (onClose) onClose()
                            }}
                        >
                            <TermsAndConditionsIcon />
                            <span>{t('nav:terms_and_conditions')}</span>
                        </StyledMenuItem>
                        <StyledDivider />
                        <StyledMenuItem
                            onClick={() => {
                                navigate(routes.VIDEOS_PAGE.path)
                                if (onClose) onClose()
                            }}
                        >
                            <ScaleIcon fill="#57534F" width={15} height={15} />
                            <span>{t('common:switch_to_contributor')}</span>
                        </StyledMenuItem>
                    </>
                )}
                <StyledDivider />
                <StyledMenuItem onClick={handleOpenLogoutModal}>
                    <LogoutIcon />
                    <span>{t('common:sign_out')}</span>
                </StyledMenuItem>
            </StyledMenuWrapper>
            {isLogoutModalOpen && <LogoutModal onClose={() => setLogoutModalOpen(false)} />}
        </DropDown>
    )
}

export default DropDownMenu
