import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {httpJoinEvent} from '../services/events.http'

export const useJoinEvent = ({eventId, onSuccess}: {eventId: string; onSuccess: () => void}) => {
    const client = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.JOIN_EVENT],
        mutationFn: () => httpJoinEvent({eventId}),
        onSuccess: () => {
            onSuccess()
            client.invalidateQueries({queryKey: [QUERY_KEYS.EVENT_DETAILS, eventId]})
        }
    })
}
