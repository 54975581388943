import React from 'react'
import {
    StyledVideoCardRoot,
    StyledCardWrapper,
    StyledName,
    StyledLanguage,
    StyledInfoWrapper,
    StyledBottomDivider,
    StyledImage,
    StyledThumbnailWrapper
} from './style'
import {generatePath, useNavigate} from 'react-router-dom'
import {ROUTE_VIDEO_DETAILS} from '@/utilities/constants'
import {VideoType} from '@/types/video'
import Tag from '@/components/ui/Tag'
import {useTranslation} from 'react-i18next'
import {formatDuration} from '@/utilities/helpers'

export interface ProfileVideoCardProps {
    video: VideoType
    isMine?: boolean
}

const ProfileVideoCard: React.FC<ProfileVideoCardProps> = ({video, isMine = true}) => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const {status, targetLanguage, id, projectInfos} = video

    return (
        <StyledVideoCardRoot>
            <StyledCardWrapper onClick={() => navigate(generatePath(ROUTE_VIDEO_DETAILS, {videoId: `${id}`}))}>
                <StyledThumbnailWrapper>
                    <StyledImage src={projectInfos.thumbnailUrl} alt={projectInfos.name} />
                    {projectInfos.duration && <span>{formatDuration(projectInfos.duration)}</span>}
                </StyledThumbnailWrapper>

                <StyledInfoWrapper>
                    <StyledName>{projectInfos.name}</StyledName>
                    <StyledLanguage>
                        {projectInfos.sourceLanguage?.name} &gt; {targetLanguage.name}
                    </StyledLanguage>
                    {isMine && <Tag type={status}>{t(`contributor_video_status_label:${status}`)}</Tag>}
                </StyledInfoWrapper>
            </StyledCardWrapper>
            <StyledBottomDivider />
        </StyledVideoCardRoot>
    )
}

export default ProfileVideoCard

ProfileVideoCard.displayName = 'ProfileVideoCard'
