import {useMutation, useQueryClient} from '@tanstack/react-query'
import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {httpAddMember} from '../services/events.http'
type UseEventAddMemberProps = {
    onSuccess: () => void
    workspaceId: string
    eventId: string
}
export const useEventAddMember = ({onSuccess, workspaceId, eventId}: UseEventAddMemberProps) => {
    const client = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.EVENT_ADD_MEMBER],
        mutationFn: ({email, emailConfirmation}: {email: string; emailConfirmation: string}) =>
            httpAddMember({workspaceId, eventId, email, emailConfirmation}),
        onSuccess: () => {
            onSuccess()
            client.invalidateQueries({queryKey: [QUERY_KEYS.WORKSPACE_EVENT_DETAILS, workspaceId, eventId]})
        }
    })
}
