import {Language} from '@/types/commons'
import {MESSAGE_LANGUAGES_CODES_A, MESSAGE_LANGUAGES_CODES_B} from './constants/video'

export const isVideoPlaying = (video: HTMLVideoElement | null) =>
    video ? !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2) : false

export const getDisabledTargetLanguages = (languages: Language[] | undefined, sourceLanguage: number) => {
    const messagesLanguagesIdsA = languages
        ?.filter(item => MESSAGE_LANGUAGES_CODES_A.from.find(element => element === item.code))
        .map(item => item.id)
    const messagesLanguagesIdsB = languages
        ?.filter(item => MESSAGE_LANGUAGES_CODES_B.to.find(element => element === item.code))
        .map(item => item.id)

    const languageIdA = languages?.find(item => item.code === MESSAGE_LANGUAGES_CODES_A.to)?.id
    const languageIdB = languages?.find(item => item.code === MESSAGE_LANGUAGES_CODES_B.from)?.id

    if (messagesLanguagesIdsA?.includes(sourceLanguage)) {
        const disabledItems = languages?.map(item => item.id).filter(item => item !== languageIdA) ?? []
        return disabledItems
    } else if (sourceLanguage === languageIdB) {
        const disabledItems =
            languages?.map(item => item.id).filter(item => !messagesLanguagesIdsB?.find(element => item === element)) ??
            []
        return disabledItems
    } else {
        return []
    }
}
