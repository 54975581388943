import Container from '@/components/ui/Container'
import TitleSection from '@/features/contributors/components/title-section/TitleSection'
import {Trans, useTranslation} from 'react-i18next'
import {StyledButtonWrapper, StyledContentWrapper, StyledRoot} from './style'
import VideoFilters from '@/features/videos/components/video-filters/VideoFilters'
import PreviewVideosList from '@/features/videos/components/preview-videos-list/PreviewVideosList'
import Button from '@/components/ui/Button'
import {ROUTE_VIDEOS} from '@/utilities/constants'
import {Navigate, useNavigate} from 'react-router-dom'
import EventsSection from '@/features/home/components/events-section/EventsSection'
import {useTheme} from 'styled-components'
import TopContributorSection from '@/features/home/components/top-contributors-section/TopContributorsSection'
import InfoSection from '@/features/home/components/info-section/InfoSection'
import ContributorContainer from '@/components/ui/ContributorContainer/ContributorContainer'
import {useGetVideosList} from '@/features/videos/queries/useGetVideosList'
import {useEffect} from 'react'
import {handleApiError} from '@/utilities/helpers'
import {LocalStorageManager} from '@/utilities/localStorage'

const Home = () => {
    const {t} = useTranslation()
    const {contributorContainerSize} = useTheme()
    const navigate = useNavigate()
    const per_page = 12
    const {data, error, isError} = useGetVideosList({
        per_page
    })

    useEffect(() => {
        if (isError && error instanceof Error) {
            handleApiError({error})
        }
    }, [isError])

    const handleFilterClick = (key: string, value: string) => {
        navigate(`${ROUTE_VIDEOS}?${key}=${value}`)
    }

    if (LocalStorageManager.eventInvitationLink.get()) {
        return <Navigate to={LocalStorageManager.eventInvitationLink.get()} />
    }

    return (
        <StyledRoot>
            <ContributorContainer>
                <TitleSection
                    title={t('home_page:title')}
                    subtitle={t('home_page:subtitle')}
                    description={
                        <Trans
                            t={t}
                            i18nKey={'home_page:description'}
                            components={{
                                span: <span />
                            }}
                        />
                    }
                />
                <InfoSection />

                <Container maxWidth={contributorContainerSize}>
                    <StyledContentWrapper>
                        <VideoFilters handleFilterClickCallback={handleFilterClick} withViewAll />
                        {data && <PreviewVideosList videos={data?.data} />}
                        <StyledButtonWrapper>
                            <Button size="xs" onClick={() => navigate(ROUTE_VIDEOS)}>
                                {t('home_page:button')}
                            </Button>
                        </StyledButtonWrapper>
                        <EventsSection />
                        <TopContributorSection />
                    </StyledContentWrapper>
                </Container>
            </ContributorContainer>
        </StyledRoot>
    )
}

export default Home

Home.displayName = 'Home'
