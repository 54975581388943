import styled, {DefaultTheme, css} from 'styled-components'

export const StyledTooltip = styled.div(
    ({
        theme: {zIndex, palette, spacing, typography},
        offset,
        minWidth
    }: {
        theme: DefaultTheme
        offset: number
        minWidth?: number
    }) => css`
        ${typography.textS}
        z-index: ${zIndex.modalOverlay};
        position: absolute;
        background: ${palette.neutral.white};
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
        border-radius: ${spacing * 2}px;
        padding: ${spacing * 2}px;
        color: ${palette.neutral[700]};
        text-align: start;

        left: ${offset ? -offset : spacing * 3}px;
        bottom: ${spacing * 3}px;
        width: fit-content;
        min-width: ${minWidth ? `${minWidth}px` : '100%'};
        & span {
            text-wrap: nowrap;
        }
    `
)
