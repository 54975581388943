import styled, {css} from 'styled-components'

type StyledBackgroundProps = {
    backgroundImage: string
}

export const StyledBackgroundImageRoot = styled.div<StyledBackgroundProps>`
    ${({theme: {palette}, backgroundImage}) => css`
        background-image: ${palette.gradient.backgroundGradient}, url(${backgroundImage});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    `}
`
