import PageHeader from '@/components/commons/PageHeader'
import {useTranslation} from 'react-i18next'
import {
    StyledDate,
    StyledInformationWrapper,
    StyledLabel,
    StyledUrl,
    StyledValueLabelWrapper,
    StyledVideoWrapper,
    StyledValue,
    StyledStatusWrapper,
    StyledEuropeanaLogo
} from './style'
import Tag from '@/components/ui/Tag'
import {useGetVideoDetails} from '../../../queries/useGetVideoDetails'
import {useParams} from 'react-router-dom'
import Spinner from '@/components/ui/Spinner'
import {useEffect} from 'react'
import {ValidationError, formatLocalDate, handleApiError} from '@/utilities/helpers'
import Video from '@/components/ui/Video'
import Europeana_logo from '@assets/images/Europeana_logo.png'

const VideoInformation: React.FC = () => {
    const {t} = useTranslation()
    const {workspaceId, videoId} = useParams()
    const {data, isLoading, isError, error} = useGetVideoDetails({
        workspaceId: `${workspaceId}`,
        videoId: `${videoId}`
    })

    useEffect(() => {
        if (isError) handleApiError({error: error as ValidationError<string>})
    }, [isError])

    if (isLoading) return <Spinner size={48} overlay />

    return (
        <>
            <PageHeader title={data?.name ?? '--'} />
            <StyledInformationWrapper>
                <div>
                    <StyledVideoWrapper>
                        <Video source={data?.videoUrl ?? ''} poster={data?.thumbnailUrl} />
                    </StyledVideoWrapper>
                </div>
                <div>
                    {data?.createdAt && <StyledDate>{formatLocalDate(data.createdAt)}</StyledDate>}
                    <StyledUrl target="_blank" href={data?.originalUrl}>
                        {data?.originalUrl}
                    </StyledUrl>
                    <StyledValueLabelWrapper>
                        <StyledLabel>{t('video_ingestion:original_language')}</StyledLabel>
                        <StyledValue>{data?.sourceLanguage?.name}</StyledValue>
                    </StyledValueLabelWrapper>
                    <StyledValueLabelWrapper>
                        <StyledLabel>{t('video_ingestion:upload_by')}</StyledLabel>
                        <StyledValue>{data?.user?.name}</StyledValue>
                    </StyledValueLabelWrapper>
                    <StyledStatusWrapper>
                        {data?.status && <Tag type={data?.status}>{t(`job_status:${data?.status}`)}</Tag>}
                    </StyledStatusWrapper>
                    {data?.isEuropeana && (
                        <StyledEuropeanaLogo href={data.europeanaPlatformUrl} target="_blank">
                            <img src={Europeana_logo} alt={'Europeana logo'} />
                            <span> {t('workspace:video_card:on_europeana')}</span>
                        </StyledEuropeanaLogo>
                    )}
                </div>
            </StyledInformationWrapper>
        </>
    )
}

export default VideoInformation

VideoInformation.displayName = 'VideoInformation'
