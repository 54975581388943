import styled, {DefaultTheme, css} from 'styled-components'

export const StyledOverlay = styled.div(
    ({theme: {zIndex}}) => css`
        position: fixed;
        inset: 0;
        z-index: ${zIndex.modalOverlay};
    `
)

export const StyledDropDownMenu = styled.div(
    ({theme: {zIndex, palette, spacing}, offset}: {theme: DefaultTheme; offset: number}) => css`
        z-index: ${zIndex.modalOverlay};
        position: absolute;
        background: ${palette.neutral.white};
        box-shadow: 0px 2px 15px -3px rgba(0, 0, 0, 0.07), 0px 10px 20px -2px rgba(0, 0, 0, 0.04);
        border-radius: ${spacing * 2}px;
        border: 0px solid ${palette.gray.disabled};
        right: 0;
        top: ${offset ? -(spacing * 2 + offset) : spacing * 8}px;
        width: fit-content;
        min-width: 166px;

        & span {
            text-wrap: nowrap;
        }
    `
)
