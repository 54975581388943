import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {EventCreationRequest} from '@/types/event'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {httpCreateEvent} from '../services/events.http'

export const useCreateEvent = ({workspaceId, onSuccess}: {workspaceId: string; onSuccess: () => void}) => {
    const client = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.CREATE_EVENT, workspaceId],
        mutationFn: (data: EventCreationRequest) => httpCreateEvent(workspaceId, data),
        onSuccess: () => {
            onSuccess()
            client.invalidateQueries({queryKey: [QUERY_KEYS.EVENTS, workspaceId]})
            client.invalidateQueries({queryKey: [QUERY_KEYS.WORKSPACES_LIST]})
        }
    })
}
