import {EarthIcon, LockIcon} from '@/assets/icons/icons'
import {EventType} from '../../types'
import {
    StyledContentWrapper,
    StyledHeaderWrapper,
    StyledMainWrapper,
    StyledName,
    StyledVisibilityWrapper,
    StyledVideosCount,
    StyledDescription,
    StyledValueLabelWrapper,
    StyledLabel,
    StyledValue,
    StyledFooterWrapper,
    StyledEventTagWrapper,
    StyledDropdownWrapper
} from './style'
import {useTranslation} from 'react-i18next'
import {getEventStatus} from '@/utilities/event'
import {formatLocalDate} from '@/utilities/helpers'
import Button from '@/components/ui/Button'
import {generatePath, useNavigate, useParams} from 'react-router-dom'
import {ROUTE_EVENT_INGESTION} from '@/utilities/constants'
import EventTag from '@/components/ui/EventTag'
import EventDropdownMenu from '@/features/events/components/event-ingestion/drop-down-menu/EventDropdownMenu'
import EventDeletionModal from '@/features/events/components/event-ingestion/event-deletion-modal/EventDeletionModal'
import {useState} from 'react'

// TODO: We should truncate the text after two liens for description

const EventCard = ({event, setEventToEdit}: {event: EventType; setEventToEdit: (event: EventType) => void}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {workspaceId} = useParams()
    const [isOpenEventDeletionModal, setIsOpenEventDeletionModal] = useState(false)

    const onClickDetails = (item: EventType) => {
        navigate(generatePath(ROUTE_EVENT_INGESTION, {workspaceId: `${workspaceId}`, eventId: `${item.id}`}))
    }

    const handleSetEventToEdit = (event: EventType) => {
        setEventToEdit(event)
    }
    const eventStatus = getEventStatus(event)

    return (
        <>
            <StyledMainWrapper>
                <StyledContentWrapper>
                    <StyledHeaderWrapper>
                        <StyledVisibilityWrapper>
                            {event.isPublic ? (
                                <>
                                    <EarthIcon />
                                    <span>{t('common:public')}</span>
                                </>
                            ) : (
                                <>
                                    <LockIcon />
                                    <span>{t('common:private')}</span>
                                </>
                            )}
                        </StyledVisibilityWrapper>
                        <StyledEventTagWrapper>
                            <EventTag status={eventStatus} />
                            <StyledDropdownWrapper>
                                <EventDropdownMenu
                                    item={event}
                                    handleSetEventToEdit={handleSetEventToEdit}
                                    onDeleteClick={() => setIsOpenEventDeletionModal(true)}
                                    eventStatus={eventStatus}
                                />
                            </StyledDropdownWrapper>
                        </StyledEventTagWrapper>
                    </StyledHeaderWrapper>
                    <StyledName>{event.name}</StyledName>
                    <StyledVideosCount>{t('event:video', {VIDEO_COUNT: event?.projectCount ?? 0})}</StyledVideosCount>
                    <StyledDescription>{event.description}</StyledDescription>
                    <StyledValueLabelWrapper>
                        <StyledLabel>{t('event:start_date')}</StyledLabel>
                        <StyledValue>{formatLocalDate(event.startDate)}</StyledValue>
                    </StyledValueLabelWrapper>
                    <StyledValueLabelWrapper>
                        <StyledLabel>{t('event:end_date')}</StyledLabel>
                        <StyledValue>{formatLocalDate(event.endDate)}</StyledValue>
                    </StyledValueLabelWrapper>
                    <StyledFooterWrapper>
                        <Button variant={'primary'} size={'md'} onClick={() => onClickDetails(event)}>
                            {t('common:see_details')}
                        </Button>
                    </StyledFooterWrapper>
                </StyledContentWrapper>
            </StyledMainWrapper>
            {isOpenEventDeletionModal && (
                <EventDeletionModal
                    workspaceId={`${workspaceId}`}
                    eventId={`${event.id}`}
                    onClose={() => setIsOpenEventDeletionModal(false)}
                    eventName={event.name}
                />
            )}
        </>
    )
}

export default EventCard

EventCard.displayName = 'EventCard'
