export const USER_ROLE_CONTRIBUTOR = 'contributor'
export const USER_ROLE_PROVIDER = 'provider'
export const USER_ROLE_ADMIN = 'admin'
export const ANONYMOUS = 'anonymous'

export const USER_SIGNUP_SOURCE = {
    proprietary: 'proprietary',
    europeana: 'europeana'
}

export const ACCOUNT_VISIBILITY = {
    public: 'public',
    private: 'private'
}
