import {CloseCircleIcon} from '@/assets/icons/icons'
import {useTranslation} from 'react-i18next'
import Button from '@/components/ui/Button'
import {useTheme} from 'styled-components'
import {useParams} from 'react-router-dom'
import {useRejectEventRequest} from '../../queries/useRejectEventRequest'
import {useEffect} from 'react'
import {ValidationError, handleApiError} from '@/utilities/helpers'
import Spinner from '@/components/ui/Spinner'

const RejectEventRequest = ({userId}: {userId: string}) => {
    const {t} = useTranslation()
    const theme = useTheme()
    const {workspaceId, eventId} = useParams()

    const {mutate, isPending, isError, error} = useRejectEventRequest({
        workspaceId: `${workspaceId}`,
        eventId: `${eventId}`
    })

    useEffect(() => {
        if (isError) handleApiError({error: error as ValidationError<string>})
    }, [isError])

    const handleReject = () => {
        mutate({userId: `${userId}`})
    }

    return (
        <div>
            {isPending && <Spinner size={48} />}
            <Button variant="outline" size="xs" onClick={handleReject}>
                <CloseCircleIcon fill={theme.palette.gray.main} />
                {t('event_ingestion:reject_request')}
            </Button>
        </div>
    )
}

export default RejectEventRequest

RejectEventRequest.displayName = 'RejectEventRequest'
