import styled, {css} from 'styled-components'

export const StyledFooterRoot = styled.div`
    ${({theme: {typography, mediaQueries, spacing}}) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: ${spacing * 8}px;
        ${typography.textXL};
        margin: ${spacing * 10}px 0px;
        ${mediaQueries.m} {
            gap: ${spacing * 15}px;
        }
    `}
`

export const StyledFooterText = styled.div`
    ${({theme: {typography, palette, spacing, mediaQueries, link}}) => css`
        text-align: center;
        color: ${palette.gray.main};
        ${typography.textL};
        padding: 0px ${spacing * 10}px;

        & a {
            ${link}
        }

        ${mediaQueries.m} {
            padding: 0px;
            max-width: 700px;
        }
    `}
`

export const StyledLogoSection = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: flex;
        gap: ${spacing * 8}px ${spacing * 10}px;
        max-width: 745px;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        & img {
            display: block;
            max-height: 50px;
        }

        ${mediaQueries.m} {
            flex-direction: row;
        }
    `}
`

export const StyledBottomNavWrapper = styled.div`
    width: 100%;
`

export const StyledDivider = styled.div`
    ${({theme: {palette}}) => css`
        border-top: 1px solid ${palette.gray.xlight};
    `}
`

export const StyledLogoLink = styled.a`
    ${() => css`
        cursor: pointer;
    `}
`
