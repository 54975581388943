import {UploadIcon} from '@/assets/icons/icons'
import React from 'react'
import {StyledNoFile, StyledPreviewRoot} from './style'
import {FileWithPath} from 'react-dropzone'

interface PreviewProps {
    uploadedFiles: FileWithPath[]
    defaultImageUrls?: string[]
}

const Preview: React.FC<PreviewProps> = ({uploadedFiles, defaultImageUrls}) => {
    const uploadedImagesUrl = uploadedFiles.map(file => URL.createObjectURL(file))
    const urlsToPreview = uploadedImagesUrl.length ? uploadedImagesUrl : defaultImageUrls

    return (
        <StyledPreviewRoot>
            {urlsToPreview?.length ? (
                <div>
                    {urlsToPreview.map((url, index) => {
                        return (
                            <div key={index}>
                                <img src={url} />
                            </div>
                        )
                    })}
                </div>
            ) : (
                <StyledNoFile>
                    <UploadIcon />
                </StyledNoFile>
            )}
        </StyledPreviewRoot>
    )
}

export default Preview

Preview.displayName = 'Preview'
