import {Trans, useTranslation} from 'react-i18next'
import {
    StyledJobsWrapper,
    StyledJobsTitle,
    StyledButtonWrapper,
    StyledJobsTable,
    StyledJobRowLanguageWrapper,
    StyledJobRowEventWrapper,
    StyledActionWrapper,
    StyledActionsWrapper,
    StyledProcessingLabel,
    StyledStatusWrapper,
    StyledJobActionsWrapper,
    StyledJobActionWrapper
} from './style'
import {
    CloseCircleIcon,
    CopyIcon,
    EarthIcon,
    EditIcon,
    LockIcon,
    PlusIcon,
    RefreshIcon,
    ScaleIcon
} from '@/assets/icons/icons'
import {generatePath, useParams} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {handleApiError} from '@/utilities/helpers'
import Spinner from '@/components/ui/Spinner'
import {useTheme} from 'styled-components'
import {JOB_STATUS} from '@/utilities/constants/video'
import Tag from '@/components/ui/Tag'
import JobStatusHistoryModal from '../../job-status-history-modal/JobStatusHistoryModal'
import {JobItem} from '@/types/job'
import AddTargetLanguageModal from '../add-target-language/AddTargetLanguageModal'
import {useGetVideoDetails} from '@/features/videos/queries/useGetVideoDetails'
import JobDeletionModal from '../delete-target-language/JobDeletionModal'
import DropdownMenu from '../drop-down-menu/DropdownMenu'
import Button from '@/components/ui/Button'
import MarkAsDoneModal from '../mark-as-done-language/MarkAsDoneModal'
import RejectModal from '../reject-language/RejectModal'
import PublishModal from '../publish-language/PublishModal'
import EditEventModal from '../edit-event-modal/EditEventModal'
import {useGetWorkspaceDetails} from '@/features/workspaces/queries/useGetWorkspaceDetails'
import {ROUTE_VIDEO_DETAILS, USER_SIGNUP_SOURCE} from '@/utilities/constants'
import RetryJobModal from '../retry-job-modal/RetryJobModal'
import DeliverSubtitlesModal from '../deliver-subtitles-modal/DeliverSubtitlesModal'
import DeliverButton from '../deliver-button/DeliverButton'
import {CheckCircleIcon, XCloseIcon} from '@components/ui/Icon'
import {useEditJobEvent} from '@features/videos/queries/useEditJobEvent.ts'
import toast from 'react-hot-toast'
import UnassignModal from '../unassign-language/UnassignModal'
import {envVars} from '@/envVars'
import useAuthStore from '@/store/authStore'
import HoverTooltip from '@/components/ui/HoverTooltip'
import {useGetLanguages} from '@/queries/useGetLanguages'
import {getDisabledTargetLanguages} from '@/utilities/video'

const VideoJobs: React.FC = () => {
    const {t} = useTranslation()
    const {videoId, workspaceId} = useParams()
    const {data, isLoading, isError, error} = useGetVideoDetails({workspaceId: `${workspaceId}`, videoId: `${videoId}`})
    const theme = useTheme()
    const [isJobsStatusHistoryModalOpen, setIsJobsStatusHistoryModalOpen] = useState(false)
    const [selectedJob, setSelectedJob] = useState<JobItem | null>(null)
    const [isAddLanguageModalOpen, setIsAddLanguageModalOpen] = useState(false)
    const [isDeleteLanguageModalOpen, setIsDeleteLanguageModalOpen] = useState(false)
    const [isMarkAsDoneModalOpen, setIsMarkAsDoneModalOpen] = useState(false)
    const [isUnassignModalOpen, setIsUnassignModalOpen] = useState(false)
    const [isRejectModalOpen, setIsRejectModalOpen] = useState(false)
    const [isPublishModalOpen, setIsPublishModalOpen] = useState(false)
    const [isAddEventModalOpen, setIsAddEventModalOpen] = useState(false)
    const [isRetryJobModalOpen, setIsRetryJobModalOpen] = useState(false)
    const [isDeliverModalOpen, setIsDeliverModalOpen] = useState(false)
    const {data: workspace} = useGetWorkspaceDetails({id: `${workspaceId}`})
    const accessToken = useAuthStore(store => store.accessToken)
    const {data: languages} = useGetLanguages({name: undefined})

    useEffect(() => {
        if (isError) handleApiError({error})
    }, [isError])

    const onClickViewJobStatusHistory = (item: JobItem) => {
        setIsJobsStatusHistoryModalOpen(true)
        setSelectedJob(item)
    }

    const onCloseViewJobStatusHistory = () => {
        setIsJobsStatusHistoryModalOpen(false)
        setSelectedJob(null)
    }

    const onClickAddTargetLanguage = () => setIsAddLanguageModalOpen(true)

    const onCloseAddTargetLanguage = () => setIsAddLanguageModalOpen(false)

    const onClickMarkAsDone = (item: JobItem) => {
        setIsMarkAsDoneModalOpen(true)
        setSelectedJob(item)
    }

    const onCloseMarkAsDone = () => setIsMarkAsDoneModalOpen(false)

    const onClickUnassign = (item: JobItem) => {
        setIsUnassignModalOpen(true)
        setSelectedJob(item)
    }

    const onCloseUnassign = () => setIsUnassignModalOpen(false)

    const onClickReject = (item: JobItem) => {
        setIsRejectModalOpen(true)
        setSelectedJob(item)
    }

    const onCloseReject = () => setIsRejectModalOpen(false)

    const onClickPublish = (item: JobItem) => {
        setIsPublishModalOpen(true)
        setSelectedJob(item)
    }

    const onClosePublish = () => setIsPublishModalOpen(false)

    const onClickDeleteTargetLanguage = (item: JobItem) => {
        setIsDeleteLanguageModalOpen(true)
        setSelectedJob(item)
    }

    const onCloseDeleteTargetLanguage = () => {
        setIsDeleteLanguageModalOpen(false)
        setSelectedJob(null)
    }

    const onClickAddEvent = (item: JobItem) => {
        setIsAddEventModalOpen(true)
        setSelectedJob(item)
    }
    const onCloseAddEventModal = () => setIsAddEventModalOpen(false)

    const onClickRetryJob = (item: JobItem) => {
        setIsRetryJobModalOpen(true)
        setSelectedJob(item)
    }

    const onCloseRetryJob = () => {
        setIsRetryJobModalOpen(false)
        setSelectedJob(null)
    }

    const handleShareJobLink = (item: JobItem) => {
        const appURL = window.location.host
        const sharePublicEventURL = `${appURL}${generatePath(ROUTE_VIDEO_DETAILS, {videoId: `${item.id}`})}`
        const sharePrivetEventURL = `${appURL}${generatePath(ROUTE_VIDEO_DETAILS, {videoId: `${item.id}`})}?t=${
            item?.privateHash
        }`

        const shareURL = item?.privateHash ? sharePrivetEventURL : sharePublicEventURL
        navigator.clipboard.writeText(shareURL)
        toast.success(t('common:link_copied'))
    }
    const jobsData = data?.jobs

    const {mutate} = useEditJobEvent({
        projectId: `${videoId}`,
        jobId: `${selectedJob?.id}`,
        workspaceId: `${workspaceId}`
    })

    const deleteJob = (item: JobItem) => {
        mutate(null)
        setSelectedJob(item)
    }

    const onClickGoToReview = (item: JobItem) => {
        if (envVars.VITE_OPEN_SOURCE_URL) {
            window.open(`${envVars.VITE_OPEN_SOURCE_URL}?job=${item.id}&token=${accessToken}`, '_blank')
        } else {
            window.open(item.subPlatformUrl, '_blank')
        }
    }

    if (isLoading) return <Spinner size={48} overlay />

    return (
        <>
            <StyledJobsWrapper>
                <StyledJobsTitle>
                    <Trans
                        t={t}
                        i18nKey="video_ingestion:target_languages"
                        values={{COUNT: jobsData?.length ?? 0}}
                        components={{
                            span: <span />
                        }}
                    />
                </StyledJobsTitle>
                <StyledButtonWrapper>
                    <Button variant="transparent" size="sm" onClick={onClickAddTargetLanguage}>
                        <PlusIcon fill={theme.palette.neutral['900']} />
                        {t('video_ingestion:add_target_languages')}
                    </Button>
                </StyledButtonWrapper>
                <StyledJobsTable>
                    <tbody>
                        {jobsData?.map(item => (
                            <tr key={`video_job_${item.id}`}>
                                <td>
                                    <StyledJobRowLanguageWrapper>
                                        {item.eventInfos ? (
                                            <>
                                                {item.eventInfos.isPublic ? (
                                                    <EarthIcon color={theme.palette.blue.main} />
                                                ) : (
                                                    <LockIcon color={theme.palette.gray.dark} />
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                {workspace?.user.signupSource === USER_SIGNUP_SOURCE.europeana ? (
                                                    <EarthIcon color={theme.palette.blue.main} />
                                                ) : (
                                                    <LockIcon color={theme.palette.gray.dark} />
                                                )}
                                            </>
                                        )}
                                        <span>{item.targetLanguage.name}</span>
                                    </StyledJobRowLanguageWrapper>
                                </td>
                                <td style={{width: '20%'}}>
                                    <StyledJobRowEventWrapper>
                                        <span>
                                            {item?.eventInfos?.name
                                                ? item.eventInfos.name.length > 10
                                                    ? `${item.eventInfos.name.substring(0, 10)}...`
                                                    : item.eventInfos.name
                                                : t('video_ingestion:no_event')}
                                        </span>
                                        <StyledActionWrapper>
                                            <EditIcon width={15} height={15} onClick={() => onClickAddEvent(item)} />
                                        </StyledActionWrapper>
                                        {item?.eventInfos?.name && (
                                            <StyledActionWrapper>
                                                <XCloseIcon
                                                    size={16}
                                                    onClick={() => {
                                                        deleteJob(item)
                                                    }}
                                                />
                                            </StyledActionWrapper>
                                        )}
                                    </StyledJobRowEventWrapper>
                                </td>
                                <td>
                                    <StyledStatusWrapper>
                                        <Tag type={item.status} withInfoIcon>
                                            {t(`job_status:${item.status}`)}
                                        </Tag>
                                        {item.status === JOB_STATUS.Processing && (
                                            <StyledProcessingLabel>
                                                {t('video_ingestion:generating_subtitles')}
                                            </StyledProcessingLabel>
                                        )}
                                    </StyledStatusWrapper>
                                </td>
                                <td style={{width: '40%'}}>
                                    <StyledJobActionsWrapper>
                                        {data?.isEuropeana && item.status === JOB_STATUS.Completed && (
                                            <StyledJobActionWrapper>
                                                <DeliverButton
                                                    onClick={() => {
                                                        setIsDeliverModalOpen(true)
                                                        setSelectedJob(item)
                                                    }}
                                                />
                                            </StyledJobActionWrapper>
                                        )}
                                        {item.status === JOB_STATUS.ReadyForAudit && (
                                            <StyledJobActionWrapper>
                                                <Button
                                                    size="xs"
                                                    variant="primary"
                                                    onClick={() => onClickMarkAsDone(item)}
                                                >
                                                    <CheckCircleIcon fill={theme.palette.neutral['25']} />
                                                    {t('video_ingestion:approve')}
                                                </Button>
                                            </StyledJobActionWrapper>
                                        )}
                                        {item.status === JOB_STATUS.ReadyForAudit && (
                                            <StyledJobActionWrapper>
                                                <Button size="xs" variant="outline" onClick={() => onClickReject(item)}>
                                                    <CloseCircleIcon fill={theme.palette.neutral['900']} />
                                                    {t('video_ingestion:reject')}
                                                </Button>
                                            </StyledJobActionWrapper>
                                        )}
                                        {item.status === JOB_STATUS.Rejected && (
                                            <StyledJobActionWrapper>
                                                <Button size="xs" onClick={() => onClickPublish(item)}>
                                                    <EarthIcon color={theme.palette.neutral['25']} />
                                                    {t('video_ingestion:publish')}
                                                </Button>
                                            </StyledJobActionWrapper>
                                        )}
                                        {item.status === JOB_STATUS.Assigned && (
                                            <StyledJobActionWrapper>
                                                <Button size="xs" onClick={() => onClickUnassign(item)}>
                                                    <XCloseIcon color={theme.palette.neutral['25']} />
                                                    {t('video_ingestion:unassign')}
                                                </Button>
                                            </StyledJobActionWrapper>
                                        )}
                                        {item.status === JOB_STATUS.Fail && (
                                            <StyledJobActionWrapper>
                                                <Button size="xs" onClick={() => onClickRetryJob(item)}>
                                                    <RefreshIcon color={theme.palette.neutral['25']} />
                                                    {t('video_ingestion:retry')}
                                                </Button>
                                            </StyledJobActionWrapper>
                                        )}
                                    </StyledJobActionsWrapper>
                                </td>
                                <td>
                                    <StyledActionsWrapper>
                                        {item.status !== JOB_STATUS.Processing &&
                                            item.status !== JOB_STATUS.Fail &&
                                            item.status !== JOB_STATUS.Completed &&
                                            item.status !== JOB_STATUS.Rejected && (
                                                <StyledActionWrapper>
                                                    <HoverTooltip text={t('video_ingestion:share')}>
                                                        <CopyIcon
                                                            fill={theme.palette.neutral['900']}
                                                            fillOpacity="1"
                                                            onClick={() => handleShareJobLink(item)}
                                                            width={16}
                                                            height={16}
                                                        />
                                                    </HoverTooltip>
                                                </StyledActionWrapper>
                                            )}
                                        {(item.status === JOB_STATUS.ReadyForAudit ||
                                            item.status === JOB_STATUS.Rejected ||
                                            item.status === JOB_STATUS.Completed) && (
                                            <StyledActionWrapper>
                                                <HoverTooltip text={t('video_ingestion:go_to_review')}>
                                                    <ScaleIcon
                                                        fill={theme.palette.neutral['900']}
                                                        fillOpacity="1"
                                                        onClick={() => onClickGoToReview(item)}
                                                        width={16}
                                                        height={16}
                                                    />
                                                </HoverTooltip>
                                            </StyledActionWrapper>
                                        )}
                                        <StyledActionWrapper>
                                            <DropdownMenu
                                                item={item}
                                                onClickDeleteTargetLanguage={onClickDeleteTargetLanguage}
                                                onClickDownloadSrt={() =>
                                                    window.open(item.contributorSubtitlesUrl, '_blank')
                                                }
                                                onClickViewJobStatusHistory={onClickViewJobStatusHistory}
                                            />
                                        </StyledActionWrapper>
                                    </StyledActionsWrapper>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </StyledJobsTable>
            </StyledJobsWrapper>
            {isJobsStatusHistoryModalOpen && (
                <JobStatusHistoryModal
                    videoId={`${videoId}`}
                    workspaceId={`${workspaceId}`}
                    jobId={`${selectedJob?.id}`}
                    onClose={onCloseViewJobStatusHistory}
                    targetLanguage={selectedJob?.targetLanguage}
                    status={selectedJob?.status ?? 'processing'}
                    videoName={data?.name ?? ''}
                />
            )}
            {isAddLanguageModalOpen && (
                <AddTargetLanguageModal
                    onClose={onCloseAddTargetLanguage}
                    workspaceId={workspaceId}
                    videoId={videoId}
                    disabledLanguages={[
                        ...(jobsData?.map(item => item.targetLanguage.id) ?? []),
                        ...getDisabledTargetLanguages(languages?.data, parseInt(String(data?.sourceLanguage?.id)))
                    ]}
                />
            )}
            {isDeleteLanguageModalOpen && (
                <JobDeletionModal
                    onClose={onCloseDeleteTargetLanguage}
                    workspaceId={workspaceId}
                    videoId={videoId}
                    jobId={`${selectedJob?.id}`}
                />
            )}
            {isMarkAsDoneModalOpen && (
                <MarkAsDoneModal
                    onClose={onCloseMarkAsDone}
                    videoId={`${videoId}`}
                    workspaceId={`${workspaceId}`}
                    jobId={`${selectedJob?.id}`}
                    userId={`${selectedJob?.userId}`}
                />
            )}
            {isUnassignModalOpen && (
                <UnassignModal
                    onClose={onCloseUnassign}
                    videoId={`${videoId}`}
                    workspaceId={`${workspaceId}`}
                    jobId={`${selectedJob?.id}`}
                />
            )}
            {isRejectModalOpen && (
                <RejectModal
                    onClose={onCloseReject}
                    videoId={`${videoId}`}
                    workspaceId={`${workspaceId}`}
                    jobId={`${selectedJob?.id}`}
                    userId={`${selectedJob?.userId}`}
                />
            )}
            {isPublishModalOpen && (
                <PublishModal
                    onClose={onClosePublish}
                    videoId={`${videoId}`}
                    workspaceId={`${workspaceId}`}
                    jobId={`${selectedJob?.id}`}
                />
            )}
            {isAddEventModalOpen && (
                <EditEventModal
                    onClose={onCloseAddEventModal}
                    videoId={`${videoId}`}
                    workspaceId={`${workspaceId}`}
                    jobId={`${selectedJob?.id}`}
                />
            )}
            {isRetryJobModalOpen && (
                <RetryJobModal
                    onClose={onCloseRetryJob}
                    workspaceId={`${workspaceId}`}
                    videoId={`${videoId}`}
                    jobId={`${selectedJob?.id}`}
                />
            )}
            {isDeliverModalOpen && (
                <DeliverSubtitlesModal
                    workspaceId={`${workspaceId}`}
                    videoId={`${videoId}`}
                    jobId={`${selectedJob?.id}`}
                    onClose={() => setIsDeliverModalOpen(false)}
                />
            )}
        </>
    )
}

export default VideoJobs
