import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {httpReject} from '@/features/videos/services/video.http'
import {useMutation, useQueryClient} from '@tanstack/react-query'

export interface RejectRequest {
    projectId: string
    jobId: string
    userId: string
    workspaceId?: string
    onSuccess: () => void
}

export const useReject = ({projectId, jobId, userId, workspaceId, onSuccess}: RejectRequest) => {
    const client = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.REJECT],
        mutationFn: () => httpReject(projectId, jobId, userId),
        onSuccess: () => {
            onSuccess()
            client.invalidateQueries({queryKey: [QUERY_KEYS.WORKSPACE_VIDEO_DETAILS, workspaceId, projectId]})
            client.invalidateQueries({queryKey: [QUERY_KEYS.JOB_STATUS_HISTORY, projectId]})
        }
    })
}
