import React, {useEffect} from 'react'
import {StyledSubtitle} from './style'
import {zodResolver} from '@hookform/resolvers/zod/src/zod.js'
import {FormProvider, useForm} from 'react-hook-form'
import ListSelect from '@/components/commons/ListSelect/ListSelect'
import {useTranslation} from 'react-i18next'
import Modal from '@/components/commons/Modal'
import {Language, ValueLabel} from '@/types/commons'
import {TargetLanguageForm} from '@/features/videos/types/video'
import {
    TARGET_LANGUAGE_MODEL,
    TargetLanguageSchema,
    TargetLanguageValidationSchema
} from '@/features/videos/services/TargetLanguageFormModel'
import {httpGetLanguages} from '@/services/languages.http'
import {JobCreationRequest, JobStatus} from '@/types/job'
import {useCreateJob} from '@/features/videos/queries/useCreateJob'
import {handleApiError} from '@/utilities/helpers'
import Spinner from '@/components/ui/Spinner'

interface AddTargetLanguageModalProps {
    onClose: () => void
    workspaceId?: string
    videoId?: string
    disabledLanguages: number[]
    status?: JobStatus
}
const adaptJobToBeSent = (values: TargetLanguageForm): JobCreationRequest => ({
    targetLanguagesIds: values.targetLanguagesIds.map(item => parseInt(`${item.value}`))
})

const AddTargetLanguageModal: React.FC<AddTargetLanguageModalProps> = ({
    onClose,
    workspaceId,
    videoId,
    disabledLanguages
}: AddTargetLanguageModalProps) => {
    const {t} = useTranslation()
    const {mutate, isPending, isError, error} = useCreateJob({
        workspaceId: `${workspaceId}`,
        videoId: `${videoId}`,
        onSuccess: onClose
    })

    useEffect(() => {
        if (isError) handleApiError({setError, error})
    }, [isError])

    const methods = useForm<TargetLanguageValidationSchema>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(TargetLanguageSchema)
    })

    const {
        handleSubmit,
        reset,
        formState: {isValid, isDirty, errors},
        setError
    } = methods

    const onClickClose = () => {
        reset()
        onClose()
    }

    const onSubmit = (values: TargetLanguageForm) => {
        mutate(adaptJobToBeSent(values))
    }

    return (
        <FormProvider {...methods}>
            {isPending && <Spinner size={48} overlay />}
            <Modal
                overlay
                title={'add_target_language_modal:title'}
                onClose={onClickClose}
                cancelButtonCallback={onClickClose}
                isSubmitButtonDisabled={!isValid || !isDirty}
                submitButtonCallback={handleSubmit(onSubmit)}
                submitButtonText={'add_target_language_modal:submit_button'}
            >
                <StyledSubtitle>{t('add_target_language_modal:subtitle')}</StyledSubtitle>
                <ListSelect
                    withFilter={false}
                    name={TARGET_LANGUAGE_MODEL.TargetLanguagesIds}
                    label={t('add_target_language_modal:languages')}
                    placeholder={t('add_target_language_modal:languages_placeholder')}
                    errorMessage={`${t(errors[TARGET_LANGUAGE_MODEL.TargetLanguagesIds]?.message || '')}`}
                    height={200}
                    fetchFunction={(name, page) => httpGetLanguages(name, page)}
                    multiple
                    adapterFunction={data =>
                        data?.data?.map(
                            (item: Language): ValueLabel => ({
                                value: item.id,
                                label: item.name,
                                isDisabled: disabledLanguages.includes(item.id)
                            })
                        )
                    }
                />
            </Modal>
        </FormProvider>
    )
}

export default AddTargetLanguageModal

AddTargetLanguageModal.displayName = 'AddTargetLanguageModal'
