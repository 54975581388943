import {useMutation} from '@tanstack/react-query'
import {PasswordResetFormData} from '@/types/auth.ts'
import {httpPasswordReset} from '@/services/auth.http'
import {MUTATION_KEYS} from '@/queryClient'

export const usePasswordReset = () =>
    useMutation({
        mutationKey: [MUTATION_KEYS.PASSWORD_RESET],
        mutationFn: (params: PasswordResetFormData) => httpPasswordReset(params)
    })
