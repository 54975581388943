import {createBrowserRouter, Outlet} from 'react-router-dom'
import PrivateRoute from '@route-guards/private-route/PrivateRoute.tsx'
import PublicRoute from '@route-guards/public-route/PublicRoute.tsx'
import PublicLayout from '@layouts/public-layout/PublicLayout.tsx'
import PrivateLayout from '@layouts/private-layout/PrivateLayout.tsx'
import {routes, USER_ROLE_ADMIN, USER_ROLE_CONTRIBUTOR, USER_ROLE_PROVIDER} from '@utilities/constants'
import RoleBasedRoute from '@route-guards/role-based-route/RoleBasedRoute.tsx'
import WorkspaceLayout from './layouts/workspace-layout/WorkspaceLayout'
import {PageNotFound} from './pages'
import ContributorLayout from './layouts/contributor-layout/ContributorLayout'
import CommonLayout from './layouts/common-layout/CommonLayout'

const router = createBrowserRouter(
    [
        {
            element: <PrivateRoute />, //all authenticated user layout route
            children: [
                {
                    element: <PrivateLayout withHeader />, // common private layout
                    children: [
                        // Place common routes here that are allowed for all roles.
                        {...routes.MY_PROFILE},
                        {...routes.CHANGE_PASSWORD},
                        {
                            // Unique routes for each role
                            element: <RoleBasedRoute grantedRoles={[USER_ROLE_ADMIN]} />, // Role based guard
                            children: []
                        },
                        {
                            element: <RoleBasedRoute grantedRoles={[USER_ROLE_PROVIDER]} />,
                            children: []
                        },
                        {
                            element: <RoleBasedRoute grantedRoles={[USER_ROLE_CONTRIBUTOR]} />,
                            children: [
                                {
                                    element: <ContributorLayout />
                                }
                            ]
                        },
                        // allowed routes for multiple roles
                        {
                            element: <RoleBasedRoute grantedRoles={[USER_ROLE_ADMIN, USER_ROLE_PROVIDER]} />,
                            children: [
                                {...routes.WORKSPACE},
                                {
                                    element: <WorkspaceLayout />,
                                    children: [
                                        {...routes.WORKSPACE_DETAILS},
                                        {...routes.VIDEO_CREATION},
                                        {...routes.VIDEO_INGESTION},
                                        {...routes.EVENT_INGESTION}
                                    ]
                                }
                            ]
                        },
                        {
                            element: <RoleBasedRoute grantedRoles={[USER_ROLE_ADMIN, USER_ROLE_CONTRIBUTOR]} />,
                            children: []
                        },
                        {
                            element: <RoleBasedRoute grantedRoles={[USER_ROLE_PROVIDER, USER_ROLE_CONTRIBUTOR]} />,
                            children: []
                        }
                    ]
                }
            ]
        },
        {
            element: <PublicRoute />, // anonymous user layout route
            children: [
                {
                    element: <PublicLayout />,
                    children: [
                        {...routes.LOGIN},
                        {...routes.SIGNUP_CONTRIBUTOR},
                        {...routes.SIGNUP_PROVIDER},
                        {...routes.SIGNUP},
                        {...routes.EMAIL_ACTIVATION},
                        {...routes.PASSWORD_RESET}
                    ]
                }
            ]
        },
        {
            element: <CommonLayout />, //pages available for unauthorized users or for all user roles
            children: [
                {...routes.HOME},
                {...routes.LEADER_BOARD},
                {...routes.VIDEOS_PAGE},
                {...routes.VIDEOS_DETAILS},
                {...routes.EVENTS_PAGE},
                {...routes.EVENTS_LIST},
                {...routes.EVENT_DETAILS},
                {...routes.THE_PROJECT},
                {...routes.USER_PROFILE},
                {...routes.TERMS_AND_CONDITIONS},
                {...routes.PRIVACY_POLICY}
            ]
        },
        {
            element: <Outlet />, // sso callback
            children: [
                {
                    element: <Outlet />,
                    children: [{...routes.EUROPEANA_CALLBACK}]
                }
            ]
        },
        {
            path: '*', // Not found route
            element: <PageNotFound />
        }
    ],
    {
        basename: '/'
    }
)

export default router
