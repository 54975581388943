import styled, {css} from 'styled-components'

export const StyledTooltipWrapper = styled.div`
    ${({theme: {typography, palette, zIndex, spacing}}) => css`
        position: relative;
        & > span {
            display: none;
        }
        &:hover {
            & > span {
                display: inline;
                position: absolute;
                top: -30px;
                white-space: nowrap;
                ${typography.textS}
                z-index: ${zIndex.modalOverlay};
                position: absolute;
                background: ${palette.neutral.white};
                box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
                border-radius: ${spacing * 2}px;
                padding: ${spacing * 2}px;
                color: ${palette.neutral[700]};
            }
        }
    `}
`
