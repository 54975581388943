import React from 'react'
import {StyledInfoSection, StyledLeaderBoardWrapper, StyledRoot, StyledTitle, StyledTitleWrapper} from './style'
import {MyProfileIcon} from '@/assets/icons/icons'
import {useTranslation} from 'react-i18next'
import Container from '@/components/ui/Container'
import LeaderBoard from '@/features/leader-board/components/leader-board/LeaderBoard'
import ProfileCard from '@/features/users/components/profile-card/ProfileCard'
import useAuthStore from '@/store/authStore'
import {USER_ROLE_ADMIN} from '@/utilities/constants'
import {useTheme} from 'styled-components'

const ProviderProfile: React.FC = () => {
    const {t} = useTranslation()
    const {providerContainerSize} = useTheme()
    const user = useAuthStore(store => store.user)
    const isAdmin = user?.type === USER_ROLE_ADMIN

    return (
        <Container maxWidth={providerContainerSize}>
            <StyledRoot>
                <StyledInfoSection>
                    <StyledTitleWrapper>
                        <MyProfileIcon />
                        <StyledTitle>{t('my_profile:title')}</StyledTitle>
                    </StyledTitleWrapper>
                    <ProfileCard />
                </StyledInfoSection>
                {isAdmin && (
                    <StyledLeaderBoardWrapper>
                        <LeaderBoard />
                    </StyledLeaderBoardWrapper>
                )}
            </StyledRoot>
        </Container>
    )
}

export default ProviderProfile

ProviderProfile.displayName = 'ProviderProfile'
