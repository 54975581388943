import styled, {DefaultTheme, css} from 'styled-components'

export const StyledMainWrapper = styled.div`
    ${({theme: {palette, typography, spacing}, height}: {theme: DefaultTheme; height: number | null}) => css`
        width: 100%;
        position: relative;
        background-color: ${palette.neutral['900']};
        height: ${height}px;
        & video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        & button {
            position: absolute;
            top: calc(50% - 25px);
            left: calc(50% - 25px);
            width: 50px;
            height: 50px;
            background-color: ${palette.neutral['400']};
            border-radius: 25px;
            color: ${palette.neutral[100]};
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            opacity: 0.75;
        }

        & span {
            ${typography.textS}
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: ${palette.neutral['900']};
            border-top-left-radius: 10px;
            color: ${palette.neutral[100]};
            padding: ${spacing * 2}px ${spacing * 3}px;
            opacity: 0.75;
        }
    `}
`
