import styled, {css, DefaultTheme} from 'styled-components'

export const StyledOverlay = styled.div(
    ({theme: {zIndex}}: {theme: DefaultTheme}) => css`
        position: fixed;
        inset: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: grid;
        align-content: center;
        justify-content: center;
        z-index: ${zIndex.modalOverlay};
    `
)

interface StyledModalProps {
    maxWidth?: number
    theme: DefaultTheme
}

export const StyledModal = styled.div(
    ({maxWidth, theme: {palette, zIndex, mediaQueries}}: StyledModalProps) => css`
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: ${zIndex.modal};
        border-radius: 16px;
        background: ${palette.neutral.white};
        width: 90%;
        ${mediaQueries.m} {
            width: 100%;
            max-width: ${maxWidth}px;
        }
    `
)
