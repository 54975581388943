import {StyledFiltersList, StyledTargetsList, StyledVideoFiltersRoot} from './style'
import Checkbox from '@/components/ui/Checkbox'
import {useGetVideoFilters} from '../../queries/useGetVideoFilters'
import {useEffect} from 'react'
import {handleApiError} from '@/utilities/helpers'
import Spinner from '@/components/ui/Spinner'
import useFiltersParams from '@/hooks/useFiltersParams'
import {FILTERS_PARAMS} from '@/types/video'
import {getVideoFiltersContent} from '../../fixtures/videoFiltersContent'
import {useTranslation} from 'react-i18next'

const VideoFilters = () => {
    const {t} = useTranslation()
    const {isLoading, data, isError, error} = useGetVideoFilters()

    const [filtersParams, setFiltersParams] = useFiltersParams()

    const platforms = filtersParams.platform ? filtersParams?.platform.split(',') : []
    const statuses = filtersParams?.status ? filtersParams?.status.split(',') : []
    const targets = filtersParams?.targets ? filtersParams?.targets.split(',') : []

    useEffect(() => {
        if (isError && error instanceof Error) {
            handleApiError({error})
        }
    }, [isError])

    const videoFilters = getVideoFiltersContent(data ?? {statuses: [], targets: []})

    const getOldParams = (key: string) => {
        switch (key) {
            case FILTERS_PARAMS.Platform: {
                return platforms
            }
            case FILTERS_PARAMS.Status: {
                return statuses
            }
            case FILTERS_PARAMS.Targets: {
                return targets
            }
            default:
                return []
        }
    }

    const handleCheckFilter = ({key, value}: {key: string; value: string}) => {
        const oldParam = getOldParams(key)
        if (!oldParam.find(item => item === value)) {
            const newParams = [...oldParam, value].join(',')
            setFiltersParams(key, newParams)
        } else {
            const newParams = oldParam.filter(item => item !== value).join(',')
            setFiltersParams(key, newParams)
        }
    }

    return (
        <StyledVideoFiltersRoot>
            {isLoading && <Spinner size={48} overlay />}
            {/* Statuses */}
            <StyledFiltersList>
                {videoFilters?.statuses.map(status => {
                    return (
                        <Checkbox
                            key={`${status}`}
                            id={`${status}`}
                            value={status}
                            label={`${t(`job_status:${status}`)}`}
                            onChange={() => handleCheckFilter({key: FILTERS_PARAMS.Status, value: status})}
                            checked={!!statuses.find(item => item === status)}
                        />
                    )
                })}
            </StyledFiltersList>

            {/* Targets */}
            <StyledTargetsList>
                {videoFilters?.targets.map(target => {
                    return (
                        <Checkbox
                            key={`${target}`}
                            id={`${target}`}
                            value={target.id}
                            label={target.name}
                            onChange={() => handleCheckFilter({key: FILTERS_PARAMS.Targets, value: `${target.id}`})}
                            checked={!!targets.find(item => item === `${target.id}`)}
                        />
                    )
                })}
            </StyledTargetsList>

            {/* Platform */}
            <StyledFiltersList>
                {videoFilters?.platforms?.map(platform => {
                    return (
                        <Checkbox
                            key={`${platform}`}
                            id={`${platform}`}
                            value={platform}
                            label={`${t(`workspace:filters_platform:${platform}`)}`}
                            onChange={() => handleCheckFilter({key: FILTERS_PARAMS.Platform, value: platform})}
                            checked={!!platforms.find(item => item === platform)}
                        />
                    )
                })}
            </StyledFiltersList>
        </StyledVideoFiltersRoot>
    )
}

export default VideoFilters

VideoFilters.displayName = 'VideoFilters'
